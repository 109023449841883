import { Injectable, OnDestroy } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { ErrorResponse, SensorDataWithValues, SensorIconData, SystemSensorData } from 'src/api/v3/common';
import { TSensorData } from 'src/app/models/sensor-data';
import { LanguageService } from 'src/app/services/language.service';
import { SystemsService } from 'src/app/services/systems.service';
import { environment } from 'src/environments/environment';
import { AuthService } from '../auth.service';
import { LoggerService } from '../logger.service';
import { PersistenceService } from '../persistence.service';
import { RegionService } from '../region.service';
import { RequestService } from '../request.service';
import { Subject } from 'rxjs';
import { SystemService } from './system.service';

@Injectable({
  providedIn: 'root',
})
export class SensorService implements OnDestroy {
  private readonly tag = 'SensorService';
  public readonly sensors = new Map<number, TSensorData>();
  public readonly systemSensors = new Map<number, Set<number>>();
  public readonly sensorIcons = new Map<number, SensorIconData>();
  private readonly iconsReadySource = new Subject<void>();
  public readonly onIconsReady = this.iconsReadySource.asObservable();
  private readonly defaultSensorIcon: SensorIconData = {
    id: 0,
    icon: 'assets/images/sensor_temp.svg',
    name: this.lang.get('sensors.types.temperature'),
    unit: '°C',
    name_keyword: '',
    unit_keyword: ''
  };

  private iconStatus = {
    isLoading: false,
    loadedFromServer: false,
    emptyList: true,
  };
  public watcherStatus: { systemId: number; intervalHandle: ReturnType<typeof setInterval>; timeoutHandle: ReturnType<typeof setTimeout>; isWorking: boolean } = {
    systemId: 0,
    intervalHandle: null,
    timeoutHandle: null,
    isWorking: false
  };

  private cleanupSubscribtion = this.auth.onAccountOrRegionChnage.subscribe(() => {
    this.sensors.clear();
    this.systemSensors.clear();
  });
  constructor(
    private auth: AuthService,
    private region: RegionService,
    private sanitizer: DomSanitizer,
    private lang: LanguageService,
    private l: LoggerService,
    private req: RequestService,
    private storage: PersistenceService,
    private ss: SystemsService,
    private systemService: SystemService,
  ) {
    this.l.log('+', this.tag);
    this.storage.get('sensorIcons', []).map((s) => this.addSensorIcon(s));
    if ( !environment.production ) {
      this.l.log('Užsikrovėm sensorių ikonų duomenis: ' + ([...this.sensorIcons.entries()].length), this.tag);
    }
    this.ss.onActiveSystemChange.subscribe(() => {
      this.handleSystemChange();
    });
    this.lang.onTranslationsChange.subscribe(() => {
      this.translateIconData();
    });
  }

  public get iconsVersion() {
    return this.storage.get('sensorIconsVersion', '');
  }

  ngOnDestroy(): void {
    this.cleanupSubscribtion.unsubscribe();
  }

  private addSensorIcon(si: SensorIconData) {
    this.iconStatus.emptyList = false;
    if ( !si.name_keyword || si.name_keyword === '' ) {
      si.name_keyword = si.name;
      si.name = this.lang.get('sensors.types.' + si.name_keyword);
    }
    if ( !si.unit_keyword || si.unit_keyword === '' ) {
      si.unit_keyword = si.unit;
      si.unit = this.lang.get('sensors.units.' + si.unit_keyword);
    }
    this.sensorIcons.set(si.id, si);
  }

  private translateIconData() {
    this.sensorIcons.forEach((val, key) => {
      val.name = this.lang.get('sensors.types.' + val.name_keyword);
      val.unit = this.lang.get('sensors.units.' + val.unit_keyword);
    });
    this.storage.set('sensorIcons', [...this.sensorIcons.values()]);
  }

  ingestSensor(sensor?: SystemSensorData): TSensorData | undefined {
    if (!sensor) { return; }
    const { alarm_high: notificationAboutHigh, alarm_low: notificationAboutLow, enabled, high_value: highValue, low_value: lowValue, system_id, ...rest } = sensor;
    const systemId = Number(system_id);
    const processedSensor: TSensorData = {
      alarmHigh: false,
      alarmLow: false,
      notificationAboutHigh,
      notificationAboutLow,
      active: !!enabled,
      enabled: !!enabled,
      highValue,
      lowValue,
      temperature: '',
      ...rest,
    };

    this.sensors.set(sensor.id, processedSensor);
    if (!this.systemSensors.has(systemId)) {
      this.systemSensors.set(systemId, new Set());
    }
    this.systemSensors.get(systemId)?.add(sensor.id);
    return processedSensor;
  }

  public async loadIcons() {
    if ( this.iconStatus.isLoading ) { return; }
    this.iconStatus.isLoading = true;
    this.l.log('Nuskaitom iš serverio sensorių tipus', this.tag);
    const result = await this.req.systemSensor.getSensorIcons().toPromise();
    if ( result.success ) {
      this.iconStatus.loadedFromServer = true;
      result.icons.map((d) => this.addSensorIcon(d));
      this.storage.set('sensorIcons', [...this.sensorIcons.values()]);
      this.storage.set('sensorIconsVersion', result.version);
      this.l.log(`Atsiuntėm sensorių tipus: ${result.icons.length}. Versija: ${result.version}`, this.tag);
    }
    this.iconStatus.isLoading = false;
    this.iconsReadySource.next();
  }

  public getSensorIcon(iconId: number): string {
    if ( this.sensorIcons.has(iconId) ) {
      const icon = this.sensorIcons.get(iconId);
      return icon.icon;
    }
    if ( !this.iconStatus.loadedFromServer && (this.iconStatus.emptyList || iconId !== 0) ) {
      this.loadIcons();
    }
    return this.defaultSensorIcon.icon;
  }

  public getSensorIconUrl(iconId: number): SafeUrl {
    const urlString = this.getSensorIcon(iconId);
    const url = new URL(urlString !== '' ? urlString : this.defaultSensorIcon.icon, this.region.regionBaseUrl);
    return this.sanitizer.bypassSecurityTrustUrl(url.href);
  }

  private handleSystemChange() {
    if ( this.watcherStatus.intervalHandle !== null ) {
      if (this.ss.activeSystem === null || this.ss.activeSystem.id !== this.watcherStatus.systemId) {
        this.l.log('Pasikeitė sistema. Stabdom watcher.', this.tag);
        this.stopSensorWatch();
      }
    }
  }

  public startWatcher(singleTime?: boolean) {
    if (this.watcherStatus.intervalHandle !== null && (singleTime === undefined || !singleTime)) {
      this.l.log('Bandyta paleisti watcher, bet jis jau aktyvus.', this.tag);
      return;
    }
    if ( !this.ss.activeSystem ) {
      this.l.log('Kažkodėl nėra aktyvios sistemos, nors norima startuoti sensorių stebėjimą.', this.tag);
      return;
    }
    if (singleTime === undefined || !singleTime) {
      const shouldAutoRefresh = localStorage.getItem('sensor_auto_refresh_' + this.ss.activeSystem.id);
      if (shouldAutoRefresh !== null && shouldAutoRefresh === '0') {
        this.l.log('Nustatymuose pažymėta, kad sensorių nereikia automatiškai atnaujinti. Stabdom.', this.tag);
        this.stopSensorWatch();
        return;
      }
    }
    this.watcherStatus.systemId = this.ss.activeSystem.id;
    this.l.log('Pradedam sensorių stebijimą sistemai: ', this.tag, this.watcherStatus.systemId);
    this.watcherStatus.timeoutHandle = setTimeout(() => {
      this.performSensorRead(singleTime);
      this.watcherStatus.timeoutHandle = null;
    }, 1000);
    if (!singleTime) {
      this.watcherStatus.intervalHandle = setInterval(() => {
        this.performSensorRead();
      }, 60000); // Kas minutę tikrinam sensorių duomenis.
    }
  }

  public stopSensorWatch() {
    this.watcherStatus.isWorking = false;
    clearTimeout(this.watcherStatus.timeoutHandle);
    clearInterval(this.watcherStatus.intervalHandle);
    if ( this.watcherStatus.timeoutHandle !== null || this.watcherStatus.intervalHandle !== null ) {
      this.l.log('Sensorių stebėjimas sustabdytas.', this.tag);
    }
    this.watcherStatus.timeoutHandle = null;
    this.watcherStatus.intervalHandle = null;
  }

  private performSensorRead(singleTime?: boolean) {
    const shouldAutoRefresh = localStorage.getItem('sensor_auto_refresh_' + this.ss.activeSystem.id);
    if (singleTime === undefined || !singleTime) {
      if (shouldAutoRefresh !== null && shouldAutoRefresh === '0') {
        this.l.log('Nustatymuose pažymėta, kad sensorių nereikia automatiškai atnaujinti. Stabdom.', this.tag);
        this.stopSensorWatch();
        return;
      }
    }
    this.watcherStatus.isWorking = true;
    this.l.log('Užklausiam sensorių duomenų sistemai: ', this.tag, this.watcherStatus.systemId);
    const systemId = this.watcherStatus.systemId;
    this.req.systemSensor.getSensorValues({ systemId: this.watcherStatus.systemId }).subscribe(
      (result) => {
        this.watcherStatus.isWorking = false;
        if (result.success) {
          this.l.log('Sensorių duomenys gauti.', this.tag, result);
          if (this.watcherStatus.systemId !== systemId) {
            this.l.log('Jau visai kita sistema. Todėl nieko nedarom su rezultatu.', this.tag);
            return;
          }
          this.applySensorValues(result.sensors, systemId);
        } else {
          this.l.log('Nepavyko gauti sensorių duomenų. ' + (result as ErrorResponse).error, this.tag);
        }
      },
      (error) => {
        this.watcherStatus.isWorking = false;
        this.l.log('Klaida gaunant sensorių duomenis.', this.tag);
      }
    );
  }

  private applySensorValues(sensors: SensorDataWithValues[], systemId: number) {
    const system = this.ss.getSystem(systemId) || this.systemService.systems.get(systemId);
    if ( !system ) {
      return; // Matyt jau nebėra
    }
    for (const iSensor of sensors) {
      const found = system.sensors.find(s => s.queue_no === iSensor.queue_no);
      if ( !found ) { continue; }
      const isBad = Math.abs(iSensor.status) > 9999;
      found.enabled = iSensor.enabled === 1;
      found.temperature = isBad ? '' : iSensor.status.toString();
      if (isBad) {
        this.l.log('Kažkokia negera reikšmė sensoriui. ', this.tag, {
          sensorius: found.queue_no,
          val: iSensor.status,
        });
      }
    }
    if ( this.ss.activeSystem && this.ss.activeSystem.id === systemId ) {
      this.ss.saveActiveSystem(systemId);
    }
  }

  public getSensorIconData(sensorType: number): SensorIconData {
    return this.sensorIcons.get(sensorType) ?? this.defaultSensorIcon;
  }
}
