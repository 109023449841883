import { Injectable } from '@angular/core';
import { LoggerService } from '../api/logger.service';
import { PlatformService } from '../api/platform.service';
import { PgmIconData } from '../models/pgm';
import { ReactionBasicData, SensorIconData } from 'src/api/v3/common';
import { ReactionService } from '../api/system/reaction.service';
import { PgmService } from '../api/system/pgm.service';
import { SensorService } from '../api/system/sensor.service';
import { PhoneStateService } from '../api/phone-state.service';

export type IconType = 'sensor' | 'pgm' | 'reactions';

@Injectable({
  providedIn: 'root',
})
export class IconsService {
  private tag = 'IconsService';

  constructor(
    private l: LoggerService,
    private ps: PlatformService,
    private reactions: ReactionService,
    private pgmS: PgmService,
    private senS: SensorService,
    psi: PhoneStateService
  ) {
    this.l.log('+', this.tag);
    psi.onRequestIconPaths.subscribe( (data) => this.retrieveIcons(data.type as IconType, data.version) );
  }

  /**
   * Šią funkciją iškviečia native apsai norėdami pasitikrinti ir atsinaujinti ikonas
   *
   * @param type pgm, sensor, arba reactions
   * @param version versija, kokią turi native apsas
   */
  public retrieveIcons(type: IconType, version: string) {
    this.l.log('Gaunami ikonu adresai', this.tag, { type, version });
    let localVersion = '';
    let result: PgmIconData[] | SensorIconData[] | ReactionBasicData[] = [];
    if ( type === 'pgm' ) {
      result = [...this.pgmS.pgmIcons.values()];
      localVersion = this.pgmS.iconsVersion;
    } else if ( type === 'reactions' ) {
      result = [...this.reactions.basicReactions.values()];
      localVersion = this.reactions.version;
    } else if ( type === 'sensor' ) {
      result = [...this.senS.sensorIcons.values()];
      localVersion = this.senS.iconsVersion;
    }
    if (localVersion === version) {
      this.l.log('Versijos vienodos, nieko nedarom.', this.tag);
      return;
    }
    if (this.ps.isAndroid()) {
      this.ps.androidHandler().iconsReceived(type, localVersion, JSON.stringify(result));
    } else if (this.ps.isApple()) {
      this.ps.appleHandler().iconsReceived.postMessage({
        type,
        localVersion,
        json: JSON.stringify(result),
      });
    }
  }

  getSystemSignalLevelIcon(signalLevel: number): string {
    if (signalLevel <= 2) {
      return 'assets/images/icon-level_1-2.svg';
    } else if (signalLevel > 2 && signalLevel <= 4) {
      return 'assets/images/icon-level_3-4.svg';
    } else if (signalLevel > 4 && signalLevel <= 6) {
      return 'assets/images/icon-level_5-6.svg';
    } else if (signalLevel > 6 && signalLevel <= 8) {
      return 'assets/images/icon-level_7-8.svg';
    } else if (signalLevel > 8) {
      return 'assets/images/icon-level_9-10.svg';
    }
  }
}
