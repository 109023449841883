import { Component, OnInit } from '@angular/core';
import { ActivatedRouteSnapshot, ChildrenOutletContexts, Router, RouterModule } from '@angular/router';
import { LoggerService } from 'src/app/api/logger.service';
import { PlatformService } from 'src/app/api/platform.service';
import { SystemService } from 'src/app/api/system/system.service';
import { UResolvable } from 'src/app/guards/UResolvable';
import { GlobalService } from 'src/app/services/global.service';
import { LocatorService } from 'src/app/services/locator.service';
import { SystemsService } from 'src/app/services/systems.service';
import { CommonModule } from '@angular/common';
import { RouterWithTransition } from 'src/app/general/router-with-transition';

@Component({
  selector: 'app-active-system',
  template: `<ng-container *ngIf="systems.activeSystem"><div [@pageTransition]="getPageTransitionAnimationData()"><router-outlet ></router-outlet></div></ng-container>`,
  styles: ``,
  standalone: true,
  imports: [RouterModule, CommonModule],
})
export class ActiveSystemComponent extends RouterWithTransition implements OnInit, UResolvable<typeof ActiveSystemComponent> {

  constructor(public systems: SystemsService, contexts: ChildrenOutletContexts) {
    super(contexts);
  }

  public static async resolve(route: ActivatedRouteSnapshot) {
    const l = LocatorService.injector.get(LoggerService);
    const platform = LocatorService.injector.get(PlatformService);
    const systemService = LocatorService.injector.get(SystemService);
    const ss = LocatorService.injector.get(SystemsService);
    const router = LocatorService.injector.get(Router);
    const g = LocatorService.injector.get(GlobalService);

    const systemId = route.paramMap.get('systemId');
    if ( systemId !== null && !isNaN(Number(systemId)) && platform.isDesktopView() ) {
      const systemIdInt = parseInt(systemId, 10);
      if ( ss.activeSystem ) {
        l.log('Atnaujinam aktyvios sistemos duomenis ' + systemId);
        (async () => { // nelaukiam, galutinis variantas = atnaujinam esamos sistemos duomenis.
          const update = await systemService.getSystem(systemIdInt);
          ss.setCurrentSystem(update);
          if ( !update ) {
            ss.removeSystem(systemIdInt);
            router.navigate(g.getHomeUrl());
          }
        })();
        return;
      }

      l.log('Nustatom aktyvia sistema ' + systemId);
      const currentSystem = await systemService.getSystem(systemIdInt);
      ss.setCurrentSystem(currentSystem);
      if ( !currentSystem ) {
        ss.removeSystem(systemIdInt);
        router.navigate(g.getHomeUrl());
      }
      return;
    }
  }

  ngOnInit(): void { }
}
