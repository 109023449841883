import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PortalComponent } from './portal/portal.component';
import { TeleportDirective } from './teleport.directive';

@NgModule({
  declarations: [TeleportDirective, ],
  imports: [CommonModule, PortalComponent],
  exports: [TeleportDirective, PortalComponent],
})
export class TeleportModule {}
