import { SafeHtml } from '@angular/platform-browser';

/* eslint-disable no-shadow */
export type MessageBoxInputs = {
  buttons: MessageBoxButtons;
  headerText?: string;
  messageContent?: SafeHtml | string;
  youtubeVideoId?: string;
  iconType?: MessageBoxIcon;
  button1Text?: string;
  button2Text?: string;
  showCloseButton?: boolean;
  canCloseByOverlay?: boolean;
  alignCenter?: boolean;
  mustScrollToBottom?: boolean;
  checkboxes?: MessageBoxCheckbox[];
  subtextTopRight?: string;
  subtextTopLeft?: string;
  subtextBottomRight?: string;
  subtextBottomLeft?: string;
};

export enum MessageBoxButtons {
  Ok,
  YesNo,
  OkCancel,
  YesNoCancel,
  YesNoClose,
  Close,
  CustomButton1,
  CustomButton1Link,
  CustomButton1Cancel,
  CustomButton1Close,
  CustomButton1Button2,
  CustomButton1Button2Link,
  CustomButton1Button2Cancel,
  CustomButton1Button2Close,
}

export enum MessageBoxIcon {
  None,
  Info,
  Warning,
  Error,
  Success,
}

export const LinkStyleConfigs = [
  MessageBoxButtons.OkCancel,
  MessageBoxButtons.Close,
  MessageBoxButtons.CustomButton1Link,
  MessageBoxButtons.CustomButton1Button2Link,
];

export const DefaultSetupConfigs = [
  MessageBoxButtons.Ok,
  MessageBoxButtons.YesNo,
  MessageBoxButtons.YesNoCancel,
  MessageBoxButtons.YesNoClose,
  MessageBoxButtons.OkCancel,
  MessageBoxButtons.Close,
];

export const ConfigsWithSecondButton = [
  MessageBoxButtons.YesNo,
  MessageBoxButtons.OkCancel,
  MessageBoxButtons.YesNoCancel,
  MessageBoxButtons.YesNoClose,
  MessageBoxButtons.CustomButton1Button2,
  MessageBoxButtons.CustomButton1Button2Link,
  MessageBoxButtons.CustomButton1Button2Cancel,
  MessageBoxButtons.CustomButton1Button2Close,
];

export const ConfigsWithCancelOrCloseButton = [
  MessageBoxButtons.YesNoCancel,
  MessageBoxButtons.YesNoClose,
  MessageBoxButtons.CustomButton1Cancel,
  MessageBoxButtons.CustomButton1Close,
  MessageBoxButtons.CustomButton1Button2Cancel,
  MessageBoxButtons.CustomButton1Button2Close,
];

export type MessageBoxCheckbox = {
  label: string;
  checked: boolean;
  id: string;
};