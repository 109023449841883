import { PlatformService } from '../platform.service';
import { AndroidRtcService } from './android-rtc.service';
import { RtcService } from './rtc.service';
import { WebRtcService } from './web-rtc.service';

export const rtcFactory = (platform: PlatformService): RtcService => {
  if (platform.isAndroid()) {
    return new AndroidRtcService();
  }
  return new WebRtcService();
};
