<div class="cpsli_container {{showBorder ? 'cpsli_border' : ''}}">
    <ng-container *ngIf="systemItem">
        <div class="cpsli_icon" *ngIf="systemItem.theme.fullBackground !== ''" [ngStyle]="{ background: systemItem.theme.fullBackground }"></div>
        <div class="cpsli_icon" *ngIf="systemItem.theme.fullBackground === ''" [ngStyle]="{ background: 'linear-gradient(180deg, ' + systemItem.theme.startColor + ' 0%, ' + systemItem.theme.endColor + ' 100%)' }"></div>
        <div class="cpsli_system_name" (click)="gotoSystem()">{{systemItem.name}}</div>
        <div class="cpsli_status">{{lang.get('systems.statuses.' + (systemItem.online ? 'online' : 'offline'))}}<div class="cpsli_status_{{(systemItem.online ? 'online' : 'offline')}}"></div></div>
        <div class="cpsli_installer" *ngIf="showInstaller">{{lang.get('systems.labels.installerDDot')}} {{installerNameText}}</div>
        <div class="cpsli_address" *ngIf="showAddress">{{lang.get('systems.labels.address')}}: {{systemItem.address}}</div>
    </ng-container>
    <ng-container *ngIf="systemWithDevice">
        <div 
            *ngIf="systemWithDevice.supportsAreas"
            (click)="iconClicked($event)"
            [ngClass]="{ 'cpsli_icon': true, 'cpsli_icon-highlighted': !systemWithDevice.hasSingleArea && systemWithDevice.firstStatusIcon !== systemWithDevice.secondStatusIcon }"
            [style]="systemWithDevice.statusColor"
        >
            <ng-container *ngIf="systemWithDevice.hasSingleArea || systemWithDevice.firstStatusIcon === systemWithDevice.secondStatusIcon; else multiStatus">
                <div class="svg" [inlineSVG]="systemWithDevice.firstStatusIcon"></div>
            </ng-container>
            <ng-template #multiStatus>
                <div class="svg" [inlineSVG]="'assets/images/area-general-icon.svg'"></div>
                <app-context-menu-data-table
                    *ngIf="areaStatusMenu.isVisible && !systemWithDevice.hasSingleArea && systemWithDevice.firstStatusIcon !== systemWithDevice.secondStatusIcon"
                    (contextVisibilityChange)="areaStatusMenu.isVisible = $event"
                    [headerText]="systemWithDevice.name === '' ? systemWithDevice.imei : systemWithDevice.name"
                    [mouseEvent]="areaStatusMenu.event"
                >
                    <div class='context_area_status' *ngFor="let area of systemWithDevice.areas"><span [style.fill]="areaService.getAreaStatusColor(area.status, area.alarm_in_memory)" [style.color]="areaService.getAreaStatusColor(area.status, area.alarm_in_memory)" [inlineSVG]="areaService.getIconByAreaStatus(area.status, area.alarm_in_memory, area.alarm_type)"></span><span>{{area.name}}</span></div>
                </app-context-menu-data-table>
            </ng-template>
        </div>
        <div class="cpsli_system_name" (click)="gotoSystem()">{{systemWithDevice.system_id !== null ? systemWithDevice.name : systemWithDevice.imei}}</div>
        <div class="cpsli_status">{{lang.get('systems.statuses.' + systemWithDevice.connectionStatus)}}<div class="cpsli_status_{{systemWithDevice.connectionStatus}}"></div></div>
        <div class="cpsli_installer" *ngIf="showInstaller">{{lang.get('systems.labels.installerDDot')}} {{installerNameText}}</div>
        <div class="cpsli_address" *ngIf="showAddress">{{lang.get('systems.labels.address')}}: {{systemWithDevice.address}}</div>
    </ng-container>
    <div class="cpsli_delete" *ngIf="canDelete" (click)="deleteSystem()"><svg viewBox="0 0 13.426 13.423"><path d="M19.589,18l4.8-4.8A1.124,1.124,0,0,0,22.8,11.616l-4.8,4.8-4.8-4.8A1.124,1.124,0,1,0,11.616,13.2l4.8,4.8-4.8,4.8A1.124,1.124,0,0,0,13.2,24.384l4.8-4.8,4.8,4.8A1.124,1.124,0,1,0,24.384,22.8Z" transform="translate(-11.285 -11.289)" fill="#666"/></svg></div>
    <div class="cpsli_arrow" *ngIf='showArrow' (click)="gotoSystem()"><svg viewBox="0 0 8.402 14.697"><path d="M13.783,13.54l5.561-5.557A1.05,1.05,0,1,0,17.857,6.5l-6.3,6.3a1.048,1.048,0,0,0-.031,1.448l6.327,6.34A1.05,1.05,0,0,0,19.34,19.1Z" transform="translate(19.653 20.891) rotate(180)"/></svg></div>
</div>