import { Type } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { Observable } from 'rxjs';

export interface UResolvableClass {
  /**
   * Papildyta resolve funkcija.
   *
   * @param route Route
   * @param param Paskutinis URL segmentas (dažniausiai parametro reikšmė). Čia yra workaround, kadangi ActivatedRouteSnaphot.params masyvas visada tuščias.
   */
  resolve(route: ActivatedRouteSnapshot, param?: string): Promise<any> | Observable<any>;
}

export type UResolvable<C extends Type<unknown> & UResolvableClass> = InstanceType<C>;

export const isUResolvable = (value: any): value is UResolvableClass => 'resolve' in value && typeof value.resolve === 'function';
