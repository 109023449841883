import { ChangeDetectorRef, Component, ElementRef, HostListener, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { Region } from 'src/api/v3/common';
import { AppSettings } from 'src/app-settings';
import { AccountInfo, AuthService } from 'src/app/api/auth.service';
import { RegionService } from 'src/app/api/region.service';
import { EventService } from 'src/app/api/system/event.service';
import { SystemService } from 'src/app/api/system/system.service';
import { LanguageAware } from 'src/app/general/language-aware';
import { LoginService } from 'src/app/login/login.service';
import { AppRegion } from 'src/environments/environment.types';
import { LocatorService } from 'src/app/services/locator.service';
import { TagService } from 'src/app/api/tag.service';
import requests from 'src/api/v3/requests';
import { PermissionRole } from 'src/api/v3/permission';
import { CommonModule } from '@angular/common';
import { StringFormInputComponent } from '../form-input/form-input.component';
import { MessageboxComponent } from 'src/app/popups/messagebox/messagebox.component';
import { InlineSVGModule } from 'ng-inline-svg-w';
import { TeleportModule } from 'src/app/teleport/teleport.module';

@Component({
  selector: 'app-region-switcher',
  templateUrl: './region-switcher.component.html',
  styleUrls: ['./region-switcher.component.scss'],
  standalone: true,
  imports: [CommonModule, StringFormInputComponent, MessageboxComponent, InlineSVGModule, TeleportModule],
})
export class RegionSwitcherComponent extends LanguageAware implements OnInit, OnDestroy {
  public showRegionSelection = false;
  public PermissionRole = PermissionRole;
  public currentRegion = this.region.ActiveRegion.backEndHost;
  public regionsEnabled = AppSettings.regionsEnabled;
  private subscriptions: Subscription[] = [];
  public showPasswordMessagebox = false;
  public selectedRegion: any;
  public activeRegionName = '';

  constructor(
    cdRef: ChangeDetectorRef,
    private elementRef: ElementRef,
    public region: RegionService,
    public auth: AuthService,
    public event: EventService,
    public system: SystemService,
    private router: Router,
    private login: LoginService,
  ) {
    super(cdRef, { noVisualModifications: true });
    this.us.populateUserRegions();
    this.updateActiveRegionName();
    this.us.onUserDataChanged.subscribe(() => {
      this.us.populateUserRegions();
      this.updateActiveRegionName();
    });
  }

  @HostListener('document:click', ['$event'])
  onClick(event: MouseEvent) {
    const target = event.target as HTMLButtonElement;
    const clickedInside = this.elementRef.nativeElement.contains(target);
    if (!clickedInside) {
      this.showRegionSelection = false;
    }
  }
  @HostListener('document:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    if (event.key === 'Escape') {
      this.showRegionSelection = false;
    } else if(event.key === 'Enter' && this.showPasswordMessagebox) {
      this.changeRegion();
    }
  }

  ngOnInit(): void {}

  ngOnDestroy(): void {
    if(this.subscriptions.length > 0) {
      this.subscriptions.forEach(sub => {
        sub.unsubscribe();
      });
    }
  }

  private updateActiveRegionName() {
    this.currentRegion = this.region.ActiveRegion.backEndHost;
    const region = this.us.userRegions.find(iRegion => iRegion.api_host_to_use === this.region.ActiveRegion.backEndHost );
    this.activeRegionName = region ? region.name : this.region.getNameForRegion(this.region.ActiveRegion);
  }

  public toggleRegionSelect() {
    this.showRegionSelection = !this.showRegionSelection;
  }

  public async onRegionSelect(region: Region) {
    this.selectedRegion = region;
    if (this.selectedRegion.region_version < 2) {
      this.toaster.postError('Sena versija');
      return;
    }
    const userInRegionExists = await this.checkIfUserExistInSelectedRegion();
    if(!userInRegionExists) {
      this.toaster.postError(this.trans('auth.errors.noUserInRegion'));
      return;
    }
    const accountInSelectedRegion = this.checkIfAccountIsRegisteredInRegionAndHasToken();
    if(accountInSelectedRegion) {
      this.switchToRegisteredSwitcherAccount(accountInSelectedRegion as AccountInfo);
    } else {
      this.showPasswordMessagebox = true;
    }
  }

  private async switchToRegisteredSwitcherAccount(accountInSelectedRegion: AccountInfo) {
    this.us.clearUserData();
    this.auth.switchAccount(accountInSelectedRegion);
    try {
      await this.doRefresh();
    } catch (e) {
      this.l.log('Refresh failed', 'RegionSwitcherComponent', { e });
    }
    await this.router.navigate(this.login.determineLoginDestination());
  }

  private async doRefresh() {
    this.l.log('Refresh', 'RegionSwitcherComponent');
    const result = await requests.user.me({systemId: 0, areaId: 0, getSystemStatus: false}).toPromise();
    if (result.success) {
      const { success, lastSystem, tags, ...user } = result;
      this.us.isCurrentUserFetched = true;
      this.us.setCurrentUserFromRaw(user);
      const tagService = LocatorService.injector.get(TagService);
      tags.forEach(t => tagService.ingestTag(t));
      this.auth.registerCurrentUserData.next(this.us.currentUser);
      this.us.change();
      this.us.allUsersLoaded = false;
      if(this.us.currentUser.language !== localStorage.getItem('lang')) {
        this.api.post('/translations', {
          language: this.us.currentUser.language,
          version: '-1',
        }, false).subscribe((data) => {
          if (data.success) {
            this.ls.selectedLanguage.value = data.code;
            this.ls.applyTranslations(data.translations, data.version, data.code);
            this.api.post('/language/set', { language: this.ls.selectedLanguage.value }, true);
            if ( !this.us.currentUser.permissions.permissions.systems.view && !this.us.currentUser.permissions.permissions.unassigned_devices.view ) {
              this.systems.loadSystems();
            }
          }
        });
      } else {
        if ( !this.us.currentUser.permissions.permissions.systems.view && !this.us.currentUser.permissions.permissions.unassigned_devices.view ) {
          this.systems.loadSystems();
        }
      }
    } else {
      this.l.log('Refresh failed', 'RegionSwitcherComponent', { result });
      return;
    }
  }

  private useRegion() {
    const beforeRegion = this.activeRegionSnapshot();
    const regionToUse = this.getBestRegion();
    if (regionToUse) {
      this.region.useRegion(regionToUse);
    } else {
      this.region.useRegion({
        ...beforeRegion,
        id: this.currentRegion.substring(0, 5),
        backEndHost: this.currentRegion,
      });
    }
  }

  private getBestRegion(): AppRegion {
    if(!this.selectedRegion) { return AppSettings.regions[0]; }
    this.currentRegion = this.selectedRegion.api_host_to_use ?? this.selectedRegion.backEndHost;
    const bestRegion = AppSettings.regions.find((r) => r.backEndHost === this.currentRegion);
    return bestRegion ?? AppSettings.regions[0];
  }

  private async checkIfUserExistInSelectedRegion(): Promise<boolean> {
    const beforeRegion = this.activeRegionSnapshot();
    const regionToUse = this.getBestRegion();
    return new Promise(async resolve => {
      this.region.setActiveRegionValue(regionToUse);
      const res = await this.login.checkEmail(this.us.currentUser.email).toPromise();
      this.region.setActiveRegionValue(beforeRegion);
      resolve(res.result !== 'no_user');
    });
  }

  public changeRegion() {
    const beforeRegion = this.activeRegionSnapshot();
    const regionToUse = this.getBestRegion();
    this.region.setActiveRegionValue(regionToUse);
    this.api.post('/login', { email: this.us.currentUser.email, password: this.us.userPassword, }, false).subscribe(
      (response) => {
        this.region.setActiveRegionValue(beforeRegion);
        if (response.success) {
          this.useRegion();
          this.setUserDataFromResponse(response);
          this.router.navigate(this.login.determineLoginDestination());
        } else {
          this.us.userPassword = '';
          this.toaster.postError(response.error);
        }
      }
    );
  }

  private setUserDataFromResponse(response) {
    this.api.setToken(response.token);
    this.us.setCurrentUserFromRaw(response);
    this.us.change();
    this.us.setLoginType('default');
  }

  private checkIfAccountIsRegisteredInRegionAndHasToken(): AccountInfo | boolean {
    const bestRegion = this.getBestRegion();
    const regionToUse = bestRegion !== undefined ? bestRegion : this.selectedRegion;
    if(this.auth.availableAccounts.length === 0) { return false; }
    const account = this.auth.availableAccounts.find(a => a.token !== null && a.email === this.us.currentUser.email && a.region.id === regionToUse.id);
    return account !== undefined ? account : false;
  }

  private activeRegionSnapshot(): AppRegion {
    return this.region.ActiveRegion;
  }

}
