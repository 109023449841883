<div>
    <div *ngIf="!areaIsPanicButtonsArea" class="white_round" id='av-state-back'>
        <div id='av-buttons' *ngIf="systems.activeSystem.directControl || areaService.isOutputAssigned(systems.activeArea.id)">
            <div *ngIf="showForgetPin" class="av_forget_button" (click)="forgetPin()">
                <div>{{trans('systems.menu.forgetAreaPin')}}</div>
            </div>
            <div *ngIf='areaService.showButtons(systems.activeArea.id) && systems.activeSystemHelper.supports.sleepState()' id='av-state-sleep' [ngClass]="{ 'av-action-button': true, 'av-action-button-active': systems.activeArea.status === 4 }" (click)='changeAreaStateTo(4)'>
                <div>
                    <div *ngIf='!areaService.shouldShowLoading(4, systems.activeArea)' [inlineSVG]="'assets/images/sleep.svg'"></div>
                    <app-loader-dots *ngIf='areaService.shouldShowLoading(4, systems.activeArea)' [width]="'32px'" [height]="'32px'"></app-loader-dots>
                    <div>{{areaService.getDescriptionForStatus(4, false, systems.activeArea)}}</div>
                </div>
            </div>
            <div *ngIf='areaService.showButtons(systems.activeArea.id)' [ngClass]="{ 'av-state-arm': true, 'av-action-button': true, 'av-action-button-active': systems.activeArea.status === 2, 'av-state-arm-single': !systems.activeSystemHelper.supports.sleepState() }" (click)='changeAreaStateTo(2)'>
                <div>
                    <div *ngIf='!areaService.shouldShowLoading(2, systems.activeArea)' [inlineSVG]="'assets/images/arm.svg'"></div>
                    <app-loader-dots *ngIf='areaService.shouldShowLoading(2, systems.activeArea)' [width]="'32px'" [height]="'32px'"></app-loader-dots>
                    <div>{{areaService.getDescriptionForStatus(2, false, systems.activeArea)}}</div>
                </div>
            </div>
            <div *ngIf='areaService.showButtons(systems.activeArea.id)' [ngClass]="{ 'av-state-disarm': true, 'av-action-button': true, 'av-action-button-active': systems.activeArea.status === 1, 'av-state-disarm-single': !systems.activeSystemHelper.supports.stayState()  }" (click)='changeAreaStateTo(1)'>
                <div>
                    <div *ngIf='!areaService.shouldShowLoading(1, systems.activeArea)' [inlineSVG]="'assets/images/disarm.svg'"></div>
                    <app-loader-dots *ngIf='areaService.shouldShowLoading(1, systems.activeArea)' [width]="'32px'" [height]="'32px'"></app-loader-dots>
                    <div>{{areaService.getDescriptionForStatus(1, false, systems.activeArea)}}</div>
                </div>
            </div>
            <div *ngIf='areaService.showButtons(systems.activeArea.id) && systems.activeSystemHelper.supports.stayState()' id='av-state-stay' [ngClass]="{ 'av-action-button': true, 'av-action-button-active': systems.activeArea.status === 3 }" (click)='changeAreaStateTo(3)'>
                <div>
                    <div *ngIf='!areaService.shouldShowLoading(3, systems.activeArea)' [inlineSVG]="'assets/images/stay.svg'"></div>
                    <app-loader-dots *ngIf='areaService.shouldShowLoading(3, systems.activeArea)' [width]="'32px'" [height]="'32px'"></app-loader-dots>
                    <div>{{areaService.getDescriptionForStatus(3, false, systems.activeArea)}}</div>
                </div>
            </div>
            <app-take-action-button *ngIf='showAlarmButton' class='av-status-alarmed' [areaItem]='systems.activeArea'></app-take-action-button>
        </div>
        <div *ngIf='systems.activeArea.status === 5 && areaService.busyArea === null' id='av-status-changing'>{{trans('systems.statuses.area.armInProgress')}}</div>
        <div *ngIf='!systems.activeSystem.directControl && !areaService.isOutputAssigned(systems.activeArea.id)' id='av-indirect-control-info' (click)='goToSettings()'>{{trans('systems.labels.clickToAssignOut')}}</div>
    </div>
    <div *ngIf="!areaIsPanicButtonsArea" class="separator16"></div>
    <div *ngIf="areaIsPanicButtonsArea && systems.activeArea.alarmed" id='av-end-panic'>
      <app-take-action-button class='av-status-alarmed' [areaItem]='systems.activeArea'></app-take-action-button>
    </div>
    <div class="white_round" id='av-zones-back'>
        <app-button-single-line-arrow id='av-zones-button' [routerLink]="['zone-status-list']">{{ areaIsPanicButtonsArea ? trans('systems.titles.panicButtonsList') : trans('systems.titles.zoneStatuses') }}</app-button-single-line-arrow>
    </div>
    <div class="separator16"></div>
    <div *ngIf='systems.activeSystemHelper.can.see.events()' class="white_round" id='av-events-back'>
        <div *ngIf='systems.activeSystemHelper.can.see.events()' id='av-events-header'>
            {{trans('systems.titles.events')}}
        </div>
        <div *ngIf='systems.activeSystemHelper.can.see.events()' id='av-events-container'>
            <div class="av-event" *ngFor='let iDay of areaEvents'>
                <div><span>{{iDay.dayTitle}}</span><hr/></div>
                <div class="av-event-details" *ngFor='let iEvent of iDay.dayEvents; first as isFirst'>
                    <hr *ngIf='!isFirst' />
                    <div class="av-event-details-grid">
                        <div class="av-event-icon" [inlineSVG]="reactions.getReactionIcon(iEvent.reaction)"></div>
                        <div class="av-event-text">{{iEvent.title}}</div>
                        <div class="av-event-time">{{dt.formatTimeFromNumber(iEvent.time)}}</div>
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf='systems.activeSystemHelper.can.see.events()' id='av-events-more-back'>
            <div *ngIf='systems.activeSystemHelper.can.see.events()' id='av-events-more' (click)='goToEvents()'>
                {{trans('general.more')}}
            </div>
        </div>
    </div>
    <div class="separator16"></div>
</div>
