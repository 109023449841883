import { CommonModule } from '@angular/common';
import { ChangeDetectorRef, Component, Input, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { AlarmType } from 'src/api/v3/system.area';
import { LanguageAware } from 'src/app/general/language-aware';
import { TAreaData } from 'src/app/models/area-data';
import { AreaService } from 'src/app/services/area.service';
import { PopupService } from 'src/app/services/popup.service';
import { AreaAlarmPopupComponent } from 'src/app/system/area-alarm-popup/area-alarm-popup.component';
import { LoaderDotsComponent } from '../loader-dots/loader-dots.component';
import { InlineSVGModule } from 'ng-inline-svg-w';

@Component({
  selector: 'app-take-action-button',
  templateUrl: './take-action-button.component.html',
  styleUrls: ['./take-action-button.component.scss'],
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [CommonModule, LoaderDotsComponent, InlineSVGModule],
})
export class TakeActionButtonComponent extends LanguageAware implements OnInit {
  @Input() areaItem: TAreaData = null;
  public readonly AlarmType = AlarmType;
  public shouldShowLoader = false;

  constructor(cdRef: ChangeDetectorRef, public areaService: AreaService, private pp: PopupService) {
    super(cdRef, { noVisualModifications: true, shouldScrollTop: false });
  }

  ngOnInit(): void {}

  public showAlarmWindow() {
    if (this.areaService.alarmedActionInProgress) {
      return;
    }
    this.pp.openPopup(AreaAlarmPopupComponent, {
      paramSetter: (p) => {
        p.activeArea = this.areaItem;
        p.disarmClicked.subscribe(() => { this.disarmTheAlarmedArea(); p.closeWindow(); });
        p.actionStarted.subscribe(() => { this.shouldShowLoader = true; });
        p.actionCompleted.subscribe(() => { this.shouldShowLoader = false; });
      },
      attachToClass: 'mobile-app'
    });
  }

  public disarmTheAlarmedArea() {
    if (!this.systems.activeSystemHelper.can.control.area(this.areaItem.queue_no)) {
      this.toaster.postError(this.trans('systems.errors.operationNotAllowed'));
      return;
    }
    if (this.areaService.isBusy()) {
      this.toaster.postError(this.trans('systems.errors.busyArea'));
      return;
    }
    if (this.areaItem.status === 1) {
      return;
    }
    this.areaService.alarmedActionInProgress = true;
    this.areaService.busyArea = {
      systemId: this.systems.activeSystem.id,
      areaId: this.areaItem.id,
      oldRememberPin: !this.areaItem.showKeypad,
      newRememberPin: !this.areaItem.showKeypad,
      showPinKeypad: this.areaItem.showKeypad,
      oldStatus: this.areaItem.status,
      newStatus: 1,
      pinToUse: '',
      zonesToBypass: [],
      showZoneBypass: false,
    };
    if (!this.systems.activeSystem.directControl || !this.areaService.busyArea.showPinKeypad) {
      this.areaService.busyArea.showPinKeypad = false;
      this.areaService.performStatusChange();
    }
  }
}
