import { Injectable } from '@angular/core';
import { Router, UrlTree } from '@angular/router';
import { UserService } from '../api/user.service';
import { GlobalService } from '../services/global.service';

/**
 * Patikrina ar prisijungęs vartotojas gali matyti visas sistemas arba registruotus modulius.
 * Jeigu gali, vadinasi tai nėra paprastas vartotojas ir jis yra peradresuojamas į jam skirtą UI.
 */

@Injectable({
    providedIn: 'root',
})
export class SimpleUserGuard  {
    constructor(
        private userService: UserService,
        private globalService: GlobalService,
        private router: Router,
    ) {}

    public async canActivate(): Promise<UrlTree | boolean> {
        if ( this.userService.currentUser.permissions.permissions.systems.view || this.userService.currentUser.permissions.permissions.unassigned_devices.view ) {
            return this.router.createUrlTree(this.globalService.getHomeUrl());
        }
        return true;
    }
}
