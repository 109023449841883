import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ApiRequestService } from '../services/api-request.service';
import { GlobalService } from '../services/global.service';
import { Location } from '@angular/common';
import { UserService } from '../api/user.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuardService  {
  constructor(
    private router: Router,
    private rs: ApiRequestService,
    private g: GlobalService,
    private l: Location,
    private us: UserService,
  ) {

  }

  canActivate() {
    if (!this.rs.hasToken()) {
      if (this.g.isLoaded()) {
        this.router.navigate(['/login']);
      } else {
        this.g.setRedirectionURL('/login');
        this.router.navigate(['/']);
      }
    } else {
      if (this.g.isLoaded()) {
        return true;
      } else {
        const url = this.l.path();
        if (this.rs.hasToken() && !this.us.currentUser ) {
          this.us.loadUserDataFromStorage();
        }
        return true;
      }
    }
  }
}
