import { Component } from '@angular/core';
import { RouterModule } from '@angular/router';
import { Routes } from 'src/api/v3/common';
import { HelpComponent } from './help.component';
import { TicketComponent } from './ticket/ticket.component';

@Component({
  selector: 'app-help-container',
  standalone: true,
  imports: [RouterModule],
  template: `<router-outlet></router-outlet>`,
  styles: ``,
})
export class HelpContainerComponent {
  constructor() {
    console.log('help container +');
  }
}

export const routes: Routes = [
  { path: '', component: HelpContainerComponent, children: [
    { path: '', component: HelpComponent, data: { animation: 1 } },
    { path: 'ticket', component: TicketComponent, data: { animation: 2 } },
  ]},
];