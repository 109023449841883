import { BrowserModule } from '@angular/platform-browser';
import { NgModule, Injector, ErrorHandler } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HttpClientModule } from '@angular/common/http';
import { LocatorService } from './services/locator.service';
import { NgCircleProgressModule } from 'ng-circle-progress';
import { TeleportModule } from './teleport/teleport.module';
import { RtcService } from './api/rtc/rtc.service';
import { rtcFactory } from './api/rtc/rtc-factory';
import { PlatformService } from './api/platform.service';
import { ConsentComponent } from './general/consent/consent.component';
import { googleAuthFactory, GoogleAuthService } from './api/google-auth.service';
import { GlobalErrorCatcher } from './global-error-catcher';
import { sendError } from './services/error-reporter';
import { provideLottieOptions } from 'ngx-lottie';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';


// const googleConfig = new AuthServiceConfig([
//   {
//     id: GoogleLoginProvider.PROVIDER_ID,
//     provider: new GoogleLoginProvider(
//       '131091336687-8kg1fhrbstoakft532hajc6kmke10ft3.apps.googleusercontent.com'
//     ),
//   },
// ]);

export const playerFactory = () => import(/* webpackChunkName: 'lottie-web' */ 'lottie-web');

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    NgCircleProgressModule.forRoot({
      responsive: true,
      animation: true,
      animationDuration: 350,
      outerStrokeColor: '#EF476F',
      backgroundColor: 'white',
      showInnerStroke: false,
      outerStrokeWidth: 20,
      titleFontSize: '60px',
      showSubtitle: false,
      showUnits: false,
    }),
    TeleportModule,
    ConsentComponent,
    BrowserAnimationsModule,
  ],
  exports: [],
  bootstrap: [AppComponent],
  providers: [
    { provide: RtcService, useFactory: rtcFactory, deps: [PlatformService, Injector] },
    { provide: GoogleAuthService, useFactory: googleAuthFactory, deps: [PlatformService, Injector] },
    { provide: ErrorHandler, useClass: GlobalErrorCatcher },
    provideLottieOptions({ player: playerFactory}),
  ],
})
export class AppModule {
  constructor(private injector: Injector) {
    LocatorService.injector = this.injector;
    window.addEventListener('error', (ev: ErrorEvent) => {
      if ( ev.error ) {
        sendError(ev.error);
      }
    });
  }
}
