import { ChangeDetectorRef, Component } from '@angular/core';
import { LanguageAware } from '../../language-aware';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-home-device-status',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './home-device-status.component.html',
  styleUrl: './home-device-status.component.scss'
})
export class HomeDeviceStatusComponent extends LanguageAware {
  constructor(cdRef: ChangeDetectorRef) {
    super(cdRef, { noVisualModifications: true });
  }

}
