<div class="relative">
  <div (click)="toggleRegionSelect()" class="flex">
    <p class="region-selected">{{ activeRegionName }}</p>
    <ng-container *ngIf="us.currentUser && (us.currentUser.permissions.permissions.regions.view || us.userHasFewRegions)">
      <div *ngIf="!showRegionSelection" class="arrowDownSvg" [inlineSVG]="'assets/images/arrow-down.svg'" (click)="toggleRegionSelect(); $event.stopPropagation()"></div>
      <div *ngIf="showRegionSelection" class="arrowUpSvg" [inlineSVG]="'assets/images/arrow-down.svg'" (click)="toggleRegionSelect(); $event.stopPropagation()"></div>
    </ng-container>
  </div>
  <div *ngIf="showRegionSelection" class="absolute">
    <ul class="region-list">
      <ng-container *ngIf="us.currentUser && (us.currentUser.permissions.permissions.regions.view || us.userHasFewRegions)">
        <li *ngFor="let iRegion of us.userRegions" (click)="onRegionSelect(iRegion); showRegionSelection = false" [ngClass]="['region-text', iRegion.api_host_to_use === region.ActiveRegion.backEndHost ? 'active' : '']">
          <div class="flex">
            {{ iRegion.name }}
            <div *ngIf="iRegion.api_host_to_use === region.ActiveRegion.backEndHost" class="simpleCheckSvg" [inlineSVG]="'assets/images/check-simple.svg'"></div>
          </div>
        </li>
      </ng-container>
    </ul>
  </div>
</div>
<ng-template appTeleport="footer">
  <app-messagebox
    *ngIf="showPasswordMessagebox"
    (okClicked)="changeRegion()"
    (messageboxClose)="showPasswordMessagebox = false"
  >
    <p class="custom_header_text">{{trans('users.labels.passwordEmail')}}</p>
    <app-string-input type="password" [label]="trans('users.labels.password')" [(value)]="us.userPassword"></app-string-input>
  </app-messagebox>
</ng-template>
