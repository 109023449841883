import { Injectable } from '@angular/core';
import { Router, UrlTree } from '@angular/router';
import { AuthService } from '../api/auth.service';
import { PlatformService } from '../api/platform.service';

@Injectable({
  providedIn: 'root'
})
export class MobileGuardService  {

  constructor(
    private platform: PlatformService,
    private router: Router,
    private auth: AuthService,
  ) { }

  canActivate(): UrlTree | boolean {
    if ( !this.auth.hasToken() ) {
      return true;
    }
    if ( this.platform.isDesktopView() ) {
      return this.router.parseUrl('/d');
    }

    return true;
  }
}
