<div [ngClass]="{ 'lr_overlay': true, 'lr_overlay_gray': overlayIsGray, 'lr_overlay_inline': isInline }" *ngIf='isVisible'>
    <div class="lr_container" [style.backgroundColor]="backgroundColor">
        <div class="lr_loader_container" [style.height]="height.toString() + heightUnits">
            <div class="lr_text">{{displayText}}</div>
            <div class="lr_loader" [style.height]="(height * 0.8).toString() + heightUnits" [style.width]="(height * 0.8).toString() + heightUnits">
                <svg viewBox="25 25 50 50">
                    <circle class="lr_circle" cx="50" cy="50" r="20" fill="none"></circle>
                </svg>
            </div>
        </div>
        <div class="lr_messages" *ngIf='messages.length > 0' #messageContainer>
            <div class="lr_message_item" *ngFor='let msg of messages'>
                <div class="lr_message_text" [innerHtml]="msg.text"></div>
                <div class="lr_message_subtext" [innerHtml]="msg.subText"></div>
            </div>
        </div>
        <div class="lr_cancel" *ngIf='showCancelButton'>
            <div class="button" (click)="cancelClicked.emit()">{{cancelButtonText}}</div>
        </div>
    </div>
</div>