import { CommonModule } from '@angular/common';
import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { InlineSVGModule } from 'ng-inline-svg-w';

export interface FooterButton {
  label: string;
  callback: any;
  loadingCompleteLabel?: string;
  loadingLabel?: string;
  loadingComplete?: boolean;
  loading?: boolean;
  useLoadingCompleteAnimation?: boolean;
}

@Component({
  selector: 'app-desktop-footer',
  templateUrl: './desktop-footer.component.html',
  styleUrls: ['./desktop-footer.component.scss'],
  standalone: true,
  imports: [InlineSVGModule, CommonModule],
})
export class DesktopFooterComponent implements OnInit, OnChanges {
  @Input() buttons: FooterButton[];
  @Input() loading: boolean;
  public footerButtons: FooterButton[];
  public loadingComplete = false;
  private iconVisibilityDuration = 2000;
  private delayTimer: any;

  constructor() {}

  ngOnInit(): void {
    this.footerButtons = Array.from(this.buttons);
    this.footerButtons.map(b => b.useLoadingCompleteAnimation === undefined ? b.useLoadingCompleteAnimation = true : undefined); // nustatom default reikšmę į true
    this.footerButtons.reverse();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.loading && changes.loading.previousValue !== undefined) {
      if (changes.loading.currentValue === false && this.footerButtons.find(b => b.useLoadingCompleteAnimation)) {
        this.handleLoadingComplete();
      } else {
        this.loadingComplete = false;
      }
    }
    if ( changes.buttons ) {
      this.footerButtons = Array.from(this.buttons);
      this.footerButtons.map(b => b.useLoadingCompleteAnimation === undefined ? b.useLoadingCompleteAnimation = true : undefined); // nustatom default reikšmę į true
      this.footerButtons.reverse();
    }
  }
  async handleLoadingComplete(): Promise<void> {
    clearTimeout(this.delayTimer);
    this.loadingComplete = true;
    await new Promise(resolve => this.delayTimer = setTimeout(resolve, this.iconVisibilityDuration));
    this.loadingComplete = false;
  }
}
