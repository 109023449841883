import {
  AfterViewChecked,
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  Output,
  Renderer2,
} from '@angular/core';
import { PlatformService } from 'src/app/api/platform.service';
import { LanguageAware } from 'src/app/general/language-aware';
import { PopupType } from 'src/app/models/popup-type';
import { PopupService } from 'src/app/services/popup.service';
import { StringFormInputComponent, useUniqueId } from '../form-input/form-input.component';
import { CommonModule } from '@angular/common';
import { NameValueComponent } from 'src/app/components/name-value/name-value.component';

const collapseLabel = <TValue>(label: string | ((value: TValue) => string), value: TValue) => (typeof label === 'function' ? label(value) : label);

export type DropdownItem<TValue extends string | number> = {
  label: string | ((value: TValue) => string);
  value: TValue;
  default?: boolean;
  extraParam?: Record<string, number | string>;
  subLabels?: string[];
};

type DropdownItemEx<TValue extends string | number> = DropdownItem<TValue> & {
  visible: boolean;
  enabled: boolean;
  active: boolean;
}

@Component({
  selector: 'app-dropdown',
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.scss'],
  standalone: true,
  imports: [CommonModule, NameValueComponent, StringFormInputComponent],
})
export class DropdownComponent<TValue extends string | number> extends LanguageAware implements AfterViewInit, AfterViewChecked {
  public collapseLabel = collapseLabel;
  public dropdownUniqueId = useUniqueId('dropdown-input-');
  public shown = false;
  public scrollDirty = false;
  public shouldOpenUp = false;
  private inDropdownList = false;
  private showFullList = true;
  private _value: TValue;
  private _items: DropdownItemEx<TValue>[] = [];
  private _hidden: TValue[] = [];
  private _disabled: TValue[] = [];
  private filteredItems: DropdownItemEx<TValue>[] = [];
  public get value(): TValue {
    return this._value;
  }
  public get selectedDropdownItem(): DropdownItem<TValue> {
    return this._items.find(i => i.value === this._value);
  }
  @Input()
  public set value(value: TValue) {
    this._value = value;
    if ( value !== null ) {
      this._items.map(i => i.active = i.value === value);
    }
    this.setInitialTextBoxText();
  }
  @Input() label: string;
  @Input() disabled = false;
  @Input() hasError = false;
  @Input() size?: 'normal' | 'small';
  @Input()
  public set items(value: DropdownItem<TValue>[]) {
    this._items = value.map(i => ({...i, enabled: !this._disabled.includes(i.value), visible: !this._hidden.includes(i.value), active: this.value !== null && this.value === i.value}));
    if ( !this._textBox && this._value !== undefined ) {
      this.setInitialTextBoxText();
    }
  }
  public get items(): DropdownItem<TValue>[] {
    return this._items;
  }
  @Input() 
  public set disabledValues(value: TValue[]) {
    this._disabled = value;
    this._items.map(i => i.enabled = !this._disabled.includes(i.value));
  }
  public get disabledValues(): TValue[] { return this._disabled }
  @Input()
  public set hiddenValues(value: TValue[]) {
    this._hidden = value;
    this._items.map(i => i.visible = !this._hidden.includes(i.value));
  }
  public get hiddenValues(): TValue[] { return this._hidden }
  @Output() valueChange = new EventEmitter<TValue>();
  private _textBox = '';
  public get textBox() {
    return this._textBox;
  }
  public set textBox(value) {
    if(value === '') { 
      this.showFullList = true;
      return;
    }
    this.showFullList = this.items.find(item => item.label === value && item.value === this.selectedDropdownItem?.value) ? true : false;
    this._textBox = value;
    this.updateCurrentActive();
    this.shown = true;
    this.checkWhereToOpenDropdownList();
  }

  constructor(
    cdRef: ChangeDetectorRef,
    private element: ElementRef,
    private pp: PopupService,
    public platform: PlatformService,
    private renderer: Renderer2,
  ) {
    super(cdRef, { noVisualModifications: true, shouldScrollTop: false });
  }

  @HostListener('document:mousedown', ['$event'])
  onMouseDown(event: MouseEvent) {
    const target = event.target as HTMLButtonElement;
    const clickedInside = this.element.nativeElement.contains(target);
    if (this.shown && (!clickedInside && target.id !== 'dropdown-list' || target.id === 'dropdown-wrap')) {
      this.closeSelection();
    }
  }

  @HostListener('document:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent): void {
    if ((event.key === 'Escape' || event.key === 'Tab') && this.shown) {
      this.closeSelection();
    }
  }

  @HostListener('document:mousewheel', ['$event'])
  onDocumentMousewheelEvent() {
    if(this.shown && !this.inDropdownList) {
      this.closeSelection();
    }
  }

  private focusOnInputField() {
    const inputElement = this.renderer.selectRootElement(`#${this.dropdownUniqueId}`);
    if (inputElement) {
      inputElement.focus();
    }
  }

  public blurInputField() {
    const inputElement = this.renderer.selectRootElement(`#${this.dropdownUniqueId}`);
    if (inputElement) {
      inputElement.blur();
    }
  }

  public get shownItems() {
    if ( this.hiddenValues.length > 0 ) {
      return this.showFullList ? this._items.filter(i => i.visible ) : this.filteredItems.filter(i => i.visible );
    } else {
      return this.showFullList ? this._items : this.filteredItems;
    }
  }

  public filterItemsList() {
    if (!this.textBox) {
      this.filteredItems = this._items;
      return;
    }
    const items = this.showFullList ? this._items : this._items.filter((item) => {
      const label = collapseLabel(item.label, item.value);
      return label.toLowerCase().startsWith(this.textBox.toLowerCase()) || item.subLabels && item.subLabels.some(sl => sl.toLowerCase().startsWith(this.textBox.toLowerCase()));
    });
    this.filteredItems = items;
  }

  public updateCurrentActive(change?: number) {
    let currentActive = this._items.indexOf(this._items.find(fi => fi.active));
    if (change !== undefined ) {
      this._items.map(i => i.active = false);
      if ( currentActive === -1 ) {
        currentActive = 0;
      }
      while ( currentActive >= 0 && currentActive < this._items.length ) {
        currentActive += change;
        if ( currentActive < 0 ) { break; }
        if ( currentActive >= this._items.length ) { break; }
        const item = this._items[currentActive];
        if ( item.visible && item.enabled || item.value === this.value ) {
          item.active = true;
          break;
        }
      }
      this.scrollDirty = true;
      this.shown = true;
      this.checkWhereToOpenDropdownList();
    }
    if ( currentActive < 0 && this._items.length > 0 ) {
      const found = this._items.find(i => i.visible && i.enabled || i.value === this.value);
      if ( found ) { found.active = true; }
      this.scrollDirty = true;
    }
    if ( currentActive >= this._items.length ) {
      currentActive = this._items.length;
      while ( currentActive >= 0 ) {
        currentActive--;
        const item = this._items[currentActive];
        if ( item.visible && item.enabled || item.value === this.value) {
          item.active = true;
          break;
        }
      }
    }
  }

  ngAfterViewChecked(): void {
    if (!this.scrollDirty) {return;}
    this.scrollDirty = false;
    const el = this.element.nativeElement.querySelector('.item.active') as HTMLElement | null;
    if (!el) {return;}
    el.scrollIntoView({ block: 'nearest' });
  }

  public selectItem(item?: DropdownItemEx<TValue>) {
    if ( !item ) {
      item = this._items.find(i => i.active);
    }
    if( !item ) {
      this.valueChange.emit(this.items.length === 0 ? undefined : this.items[0].value);
      this.textBox = this.items.length === 0 ? collapseLabel('', undefined) : collapseLabel(this.items[0].label, this.items[0].value);
      this.shown = false;
    } else {
      if ( this.disabledValues.includes(item.value)) {
        if ( item.value === this.value ) {
          this.shown = false;
          this.blurInputField();
        }
        return;
      }
      this.valueChange.emit(item.value);
      this.textBox = collapseLabel(item.label, item.value);
      this.shown = false;
    }
    this.blurInputField();
  }

  public onFocus() {
    if(this.shown) { return; }
    this.shown = true;
    this.showFullList = true;
    this.scrollDirty = true;
    this.setEmptyInputIfNoValueSelected();
  }

  public onBlur() {
    const activeItem = this.items.find(i => i.value === this.value);
    if(!activeItem) { return; }
    if(this._textBox !== activeItem.label) {
      this.setInitialTextBoxText();
    }
  }

  public changeSlidoutValue() {
    if(this.disabled) { return; }
    this.pp.showSlideout<DropdownItem<TValue>>(
      {
        headerText: this.label,
        items: this.items,
        onSubmit: (res) => {
          const result = res as DropdownItem<TValue>;
          this.valueChange.emit(result.value);
          this.textBox = collapseLabel(result.label, result.value);
        },
      },
      this.items.length > 0 && typeof this.items[0].label === 'function' ? PopupType.SlideoutWithFunctionValue : PopupType.SlideoutWithValue
    );
  }

  private setInitialTextBoxText() {
    // eslint-disable-next-line eqeqeq
    const itm = this.items.find((item) => item.value == this._value) ?? this.items.find((item) => item.default);
    this._textBox = collapseLabel(itm?.label, itm?.value);
  }

  public showSelection() {
    if(this.disabled) { return; }
    if(!this.shown) {
      this.shown = true;
      this.showFullList = true;
      this.scrollDirty = true;
      this.focusOnInputField();
    }
    this.setEmptyInputIfNoValueSelected();
    setTimeout(() => {
      this.checkWhereToOpenDropdownList();
    }, 50);
  }

  public closeSelection() {
    this.shown = false;
    if(this._textBox === '') {
      this.setInitialTextBoxText();
    }
  }

  public toggleSelection() {
    if(this.disabled) { return; }
    if(this.shown) {
      this.closeSelection();
    } else {
      this.showSelection();
    }
  }

  private checkWhereToOpenDropdownList() {
    if (!this.shown) { return; }
    const dropdownList = this.element.nativeElement.querySelector('#dropdown-list');
    if (!dropdownList) { return; }
    const dropdownListRect = dropdownList.getBoundingClientRect();
    const dropdownListHeight = dropdownListRect.height;
    const dropdownComponentRect = this.element.nativeElement.getBoundingClientRect();
    const spaceBelow = window.innerHeight - dropdownComponentRect.bottom;
    this.shouldOpenUp = spaceBelow < dropdownListHeight + 40;
  }

  public onMouseenter() {
    this.inDropdownList = true;
  }

  public onMouseleave() {
    this.inDropdownList = false;
  }

  private setEmptyInputIfNoValueSelected() {
    if(this.value === 0 || this.value === '' || this.value === null) {
      this._textBox = '';
    }
  }

}
