import { Injectable, OnDestroy } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ErrorResponse, FilterCriteria, SystemData, SystemProtegusUserData } from 'src/api/v3/common';
import { DbSystemData, RegisteredDevice, SmallSystemData, SmallSystemDataWithComputation, SystemStatus } from 'src/api/v3/system';
import { DataTableGetter } from 'src/app/company/components/data-table/data-table.component';
import { TAreaData } from 'src/app/models/area-data';
import { TCamera } from 'src/app/models/camera';
import { TDeviceUser } from 'src/app/models/device-user';
import { TEventData } from 'src/app/models/event-data';
import { TSystemData } from 'src/app/models/system-data';
import { TZoneData } from 'src/app/models/zone-data';
import { LanguageService } from 'src/app/services/language.service';
import { LocatorService } from 'src/app/services/locator.service';
import { AuthService } from '../auth.service';
import { LoggerService } from '../logger.service';
import { PermissionService } from '../permission.service';
import { RequestService } from '../request.service';
import { RtcService } from '../rtc/rtc.service';
import { TagService } from '../tag.service';
import { UserService } from '../user.service';
import { AreaService } from './area.service';
import { CameraService } from './camera.service';
import { EventService } from './event.service';
import { PgmService } from './pgm.service';
import { SensorService } from './sensor.service';
import { ThermostatService } from './thermostat.service';
import { ZoneService } from './zone.service';
import { BehaviorSubject, Subject } from 'rxjs';
import requests from 'src/api/v3/requests';
import { ToasterService } from 'src/app/services/toaster.service';
import { CompanyService } from '../company.service';
import { SystemsService } from 'src/app/services/systems.service';
import { AreaState, areaStateValues, SystemAreaData } from 'src/api/v3/system.area';
import { Tag } from 'src/api/v3/tag';
import { AreaService as AreaServiceOrig } from 'src/app/services/area.service';
import { Colors } from 'src/assets/css/instance-colors';
import { EventsService } from 'src/app/services/events.service';
import { PermissionRole } from 'src/api/v3/permission';

@Injectable({
  providedIn: 'root',
})
export class SystemService implements OnDestroy {
  private readonly tag = 'SystemService';

  public readonly systems = new Map<number, TSystemData>();
  public readonly filteredSystems = new Map<number, TSystemData>();
  public readonly partialSystems = new Map<number, Partial<DbSystemData>>();
  public readonly pendingSystemms = new Map<number, Promise<TSystemData>>();

  private previousFilterCriteria = '';
  public hasMoreSystemsWithDevices = true;
  public hasMoreFilteredSystemsWithDevices = true;
  private _systemListLoadingSubject = new BehaviorSubject<boolean>(false);

  public systemsWithDevices: SmallSystemDataWithComputation[] = [];
  public filteredSystemsWithDevices: SmallSystemDataWithComputation[] = [];

  private get eventService() { return LocatorService.injector.get(EventService); }
  private get areaService() { return LocatorService.injector.get(AreaService); }
  private get zoneService() { return LocatorService.injector.get(ZoneService); }
  private get pgmService() { return LocatorService.injector.get(PgmService); }
  private get sensorService() { return LocatorService.injector.get(SensorService); }
  private get thermostatService() { return LocatorService.injector.get(ThermostatService); }
  private get cameraService() { return LocatorService.injector.get(CameraService); }
  private get userService() { return LocatorService.injector.get(UserService); }
  private get lang() { return LocatorService.injector.get(LanguageService); }
  private get permissionService() { return LocatorService.injector.get(PermissionService); }
  private get loggerService() { return LocatorService.injector.get(LoggerService); }
  readonly systemListLoading$ = this._systemListLoadingSubject.asObservable();
  private systemViewIdChange = new Subject<number>();
  public onSystemInViewIdChange = this.systemViewIdChange.asObservable();

  private cleanupSubscribtion = this.auth.onAccountOrRegionChnage.subscribe(() => {
    this.systems.clear();
    this.pendingSystemms.clear();
    this.systemsWithDevices = [];
    this.filteredSystemsWithDevices = [];
    this.hasMoreSystemsWithDevices = true,
    this.hasMoreFilteredSystemsWithDevices = true;
  });
  constructor(
    private auth: AuthService,
    private req: RequestService,
    private sanitzer: DomSanitizer,
    private rtc: RtcService,
    private tagService: TagService,
    private es: EventsService,
  ) {
    this.loggerService.log('SystemService +');
  }
  ngOnDestroy(): void {
    this.cleanupSubscribtion?.unsubscribe();
  }

  public ingestSystem(system?: SystemData | null, isFilteringSystems = false): TSystemData | undefined {
    if (!system || this.userService.currentUser === null) { return; }

    system.events.events.forEach((event) => this.eventService.ingestEvent(this.es.convertFromRaw(event), system.id));
    system.areas.forEach((area) => this.areaService.ingestArea(area));
    system.zones.forEach((zone) => this.zoneService.ingestZone(zone));
    system.device_users.forEach((user) => this.userService.ingestDeviceUser(user));
    const deviceUser = system.device_users.length === 0 ? undefined : [...this.userService.systemDeviceUsers.get(system.id).values()].find(u => u.protegus_user_id === this.userService.currentUser.id);
    system.pgms.forEach((pgm) => {
      if ( pgm.io_ability === 'W' && pgm.io_type === 3 ) { return; }
      let userPgms = this.userService.currentUser.permissions?.role !== PermissionRole.GeneralUser ? -1 : 0;
      if (system.amIMaster) {
        userPgms = -1;
      } else if (deviceUser !== undefined) {
        userPgms = deviceUser.pgms;
      }
      if (userPgms !== null && userPgms > -1) {
        let value = false;
        /* eslint-disable no-bitwise */
        if ( pgm.queue_no < 3 ) {
          value = (userPgms & (0x01 << (pgm.queue_no + 2))) > 0;
        } else if ( pgm.queue_no > 5 ) {
          value = (userPgms & (0x01 << (pgm.queue_no - 1))) > 0;
        } else {
          // GV moduliuose 3 4 5 1 2
          value = (userPgms & (0x01 << (pgm.queue_no - 3))) > 0;
        }
        /* eslint-enable no-bitwise */
        if (value) {
          this.pgmService.ingestPgm(pgm, system.id);
        }
      } else {
        this.pgmService.ingestPgm(pgm, system.id);
      }
    });
    system.sensors.forEach((sensor) => this.sensorService.ingestSensor(sensor));
    system.thermostats.forEach((thermostat) => this.thermostatService.ingestThermostat(thermostat));
    system.cameras.forEach((camera) => this.cameraService.ingestCamera(camera));
    system.protegus_users.forEach((user) => this.userService.ingestProtegusUser(user, { id: (system as SystemData).id, name: (system as SystemData).name }));
    system.related_permissions.forEach((r) => this.permissionService.ingestIntoSessionStorage(r));

    const processedSystem: TSystemData = {
      id: system.id,
      areas: [],
      pgms: [],
      notifications: system.notifications?.notifications.map((n) => ({ id: n.id, name: n.name, enabled: n.on, useAlertSound: n.alert_sound_on })) ?? [],
      sensors: [],
      zones: [],
      events: [],
      protegus_users: [], // Užpildoma žemiau per defineProperties
      device_users: [], // Užpildoma žemiau per defineProperties
      cameras: [],
      thermostats: [],
      name: system.name,
      online: system.online,
      supported_commands: system.supported_commands,
      amIMaster: system.amIMaster,
      mpass: system.mpass,
      address: system.address,
      timeZone: system.timeZone,
      imei: system.imei,
      notificationSoundsEnabled: system.notifications?.sounds_on ?? false,
      notificationsEnabled: system.notifications?.global_value ?? false,
      signalLevel: system.signalLevel,
      hwType: system.hwType,
      canBypassZone: system.canBypassZone,
      canUnbypassZone: system.canUnbypassZone,
      directControl: !!system.direct,
      noSleepStay: system.noSleepStay,
      maxDeviceUsers: system.maxDeviceUsers,
      panel: system.centralPanel,
      coordinates: system.coordinates,
      eventConfiguration: JSON.parse(system.eventConfiguration),
      canEditUsers: system.canEditUsers, // TODO: Remove
      theme: {
        startColor: system.theme?.background_start,
        endColor: system.theme?.background_end,
        fullBackground: system.theme?.full_background,
      },
      installerId: system.installer_id,
      installerEmail: system.installerEmail,
      installerName: system.installerName,
      logo: system.logo,
      company_id: system.company_id,
      assistedById: system.assistedById,
      assistedByEmail: system.assistedByEmail,
      supportsFireReset: system.supportsFireReset,
      amIWorking: system.amIWorking ?? false,
      privacyOfOwners: system.privacyOfOwners ?? [],
      owners: system.owners ?? [],
      deviceId: system.device_id ?? 0,
      created_at: system.created_at,
      tags: system.tags.map((t) => ({ textColor: TagService.getTextColor(t.color), ...t })),
      companyName: system.companyName,
      installerAccess: system.installerAccess,
      showSosButton: system.showSosButton,
      status: system.status,
      fw_version: system.fw_version,
      sos_type: system.sos_type,
    };
    Object.defineProperties(processedSystem, {
      events: {
        get: () => [...(this.eventService.systemEvents.get(processedSystem.id)?.values() ?? [])].map((id) => this.eventService.events.get(id)),
        set: (events: TEventData[]) => {
          this.eventService.systemEvents.get(processedSystem.id)?.clear();
          events.forEach((event: TEventData) => this.eventService.ingestEventData(event));
        },
      },
      areas: {
        get: () => [...(this.areaService.systemAreas.get(processedSystem.id)?.values() ?? [])].map((id) => this.areaService.areas.get(id)),
        set: (areas: TAreaData[]) => {
          this.areaService.systemAreas.get(processedSystem.id)?.clear();
          areas.forEach((area) => this.areaService.ingestAreaData(area, processedSystem.id));
        },
      },
      zones: {
        get: () => [...(this.zoneService.systemZones.get(processedSystem.id)?.values() ?? [])],
        set: (zones: TZoneData[]) => {
          this.zoneService.systemZones.get(processedSystem.id)?.clear();
          zones.forEach((zone) => this.zoneService.ingestZoneData(zone, processedSystem.id));
        },
      },
      pgms: {
        get: () => [...(this.pgmService.systemPgms.get(processedSystem.id)?.values() ?? [])].map((id) => this.pgmService.pgms.get(id)),
        set: (pgms) => pgms.forEach((pgm) => this.pgmService.ingestPgm(pgm, system.id)),
      },
      sensors: {
        get: () => [...(this.sensorService.systemSensors.get(processedSystem.id)?.values() ?? [])].map((id) => this.sensorService.sensors.get(id)),
        set: (sensors) => sensors.forEach((sensor) => this.sensorService.ingestSensor(sensor)),
      },
      thermostats: {
        get: () => [...(this.thermostatService.systemThermostats.get(processedSystem.id)?.values() ?? [])].map((id) => this.thermostatService.thermostats.get(id)),
        set: (thermostats) => thermostats.forEach((thermostat) => this.thermostatService.ingestThermostat(thermostat)),
      },
      cameras: {
        get: () => [...(this.cameraService.systemCameras.get(processedSystem.id)?.values() ?? [])].map((id) => this.cameraService.cameras.get(id)),
        set: (cameras: TCamera[]) => {
          this.cameraService.systemCameras.get(processedSystem.id)?.clear();
          cameras.forEach((camera) => this.cameraService.ingestCameraData(camera, processedSystem.id));
        },
      },
      protegus_users: {
        get: (): SystemProtegusUserData[] => [...(this.userService.systemUsers.get(processedSystem.id)?.values() ?? [])].map(
          (sud) => ({...this.userService.users.get(sud.id), ...this.userService.systemUsers.get(processedSystem.id).find(us => us.id === sud.id)})
        ),
        set: (protegus_users) => protegus_users.forEach((user) => this.userService.ingestProtegusUser(user, {id: (processedSystem as TSystemData).id, name: (processedSystem as TSystemData).name })),
      },
      device_users: {
        get: () => [...(this.userService.systemDeviceUsers.get(processedSystem.id)?.values() ?? [])],
        set: (newUsers: TDeviceUser[]) => {
          this.userService.systemDeviceUsers.get(processedSystem.id)?.clear();
          newUsers.forEach((user) => this.userService.ingestDeviceUserData(user, processedSystem.id));
        },
      },
    });
    if(isFilteringSystems) {
      this.filteredSystems.set(processedSystem.id, processedSystem);
    } else {
      this.systems.set(processedSystem.id, processedSystem);
    }
    this.rtc.updateIntrests([...new Set([...this.systems.keys(), ...this.partialSystems.keys()])]);
    this.loggerService.log('Ingesting system ' + system.id + ' ' + system.name, this.tag);
    return processedSystem;
  }

  public async getSystem(id: number): Promise<TSystemData | null> {
    if (this.systems.has(id)) {
      return this.systems.get(id);
    }
    if (this.pendingSystemms.has(id)) {
      return await this.pendingSystemms.get(id);
    }
    const promise = new Promise<TSystemData|null>(async (resolve) => {
      try {
        const res = await this.req.system.getSystem({ system_id: id }).toPromise();
        if (res.success) {
          const systemsService = LocatorService.injector.get(SystemsService);
          this.ingestSystem(res.system);
          return resolve(systemsService.convertSystemFromRaw(res.system));
        }
      } catch (e) {
        return resolve(null);
      }
    });
    this.pendingSystemms.set(id, promise);
    const system = await promise;
    this.pendingSystemms.delete(id);
    return system;
  }

  public async loadPartialSystemData(limit: number = 100): Promise<void> {
    const res = await this.req.system.getSystemsLite({ columns: ['id', 'imei', 'name'], limit }).toPromise();
    if (res.success) {
      const allSystems = res.totalSystems;

      res.systems.forEach((system) => this.partialSystems.set(system.id, system));
      while (this.partialSystems.size < allSystems) {
        const resl = await this.req.system.getSystemsLite({ offset: this.partialSystems.size, columns: ['id', 'imei', 'name'], limit }).toPromise();
        if (resl.success) {
          resl.systems.forEach((system) => this.partialSystems.set(system.id, system));
        }
      }
      this.rtc.updateIntrests([...new Set([...this.systems.keys(), ...this.partialSystems.keys()])]);
    }
  }

  public getSystemName(id: number): string {
    if (this.partialSystems.has(id)) {
      return this.partialSystems.get(id).name;
    }
    if (this.systems.has(id)) {
      return this.systems.get(id).name;
    }
    return this.lang.get('systems.titles.unknown');
  }

  public getSystemsGetter(resultsFiltered: boolean, systemsFilterCriteria?: FilterCriteria) {
    if (systemsFilterCriteria || resultsFiltered) {
      return this.getFilteredSystemsGetter(systemsFilterCriteria);
    } else {
      this.previousFilterCriteria = null;
      return this.getAllSystemsGetter();
    }
  }

  private getAllSystemsGetter(): DataTableGetter<SmallSystemDataWithComputation> {
    return async (current, columns, more) => {
      if (!more && current <= this.systemsWithDevices.length) {
        return this.systemsWithDevices;
      }
      if ( !this.hasMoreSystemsWithDevices ) {
        return this.systemsWithDevices;
      }
      this.updateSystemListLoadingStatus(true);
      const deviceCount = this.systemsWithDevices.filter(sd => sd.system_id === null ).length;
      const result = await requests.system.getSystemsWithDevices({
          deviceOffset: current,
          systemOffset: current > deviceCount ? current - deviceCount : 0,
      }).toPromise();
      if ( result.success ) {
        if ( current === 0 ) {
          this.systemsWithDevices = [];
          this.hasMoreSystemsWithDevices = true;
        }
        this.hasMoreSystemsWithDevices = result.list.length > 0;
        for ( const iSystem of result.list ) {
          if ( this.systemsWithDevices.find(si => si.registration_id === iSystem.registration_id && si.system_id === iSystem.system_id) ) { continue; } // Čia tam, jeigu per websocket tokią sistemą jau gavom
          if ( iSystem.registration_id === null ) { // Sistem be device registracijos.
            iSystem.id = iSystem.system_id;
          }
          this.ingestSystemWithDevice(iSystem);
        }
      } else {
        const toaster = LocatorService.injector.get(ToasterService);
        toaster.postError((result as ErrorResponse).error);
      }
      this.updateSystemListLoadingStatus(false);
      return this.systemsWithDevices;
    };
  }

  private getFilteredSystemsGetter(systemsFilterCriteria: FilterCriteria|undefined): DataTableGetter<SmallSystemDataWithComputation> {
    if ( this.previousFilterCriteria === '' && !systemsFilterCriteria ) {
      throw new Error('Klaida kode. Bandoma filtruoti nenurodžius filtro duomenų.');
    }
    let filterCriteriaString;
    if ( systemsFilterCriteria ) {
      filterCriteriaString = JSON.stringify(systemsFilterCriteria);
      if(systemsFilterCriteria.lastPage === 0 && this.previousFilterCriteria !== filterCriteriaString) {
        this.filteredSystemsWithDevices = [];
        this.hasMoreFilteredSystemsWithDevices = true;
      }
    } else { // kitu atveju laikom, kad filtras nepasikeitė ir naudojam buvusį.
      filterCriteriaString = this.previousFilterCriteria;
    }
    return async (current, columns, more) => {
      if (!more && current <= this.filteredSystemsWithDevices.length && this.filteredSystemsWithDevices.length > 0) {
        return this.filteredSystemsWithDevices;
      }
      if ( !this.hasMoreFilteredSystemsWithDevices ) {
        return this.filteredSystemsWithDevices;
      }
      this.updateSystemListLoadingStatus(true);
      this.previousFilterCriteria = filterCriteriaString;
      const currentFilter: FilterCriteria = JSON.parse(this.previousFilterCriteria);
      this.loggerService.log('GET SYSTEMS');
      const filteredDeviceCount = this.filteredSystemsWithDevices.filter(fsd => fsd.system_id === null).length;
      const result = await requests.system.getSystemsWithDevices({
        deviceOffset: current,
        systemOffset: (current > filteredDeviceCount && current > 0) ? current - filteredDeviceCount : 0,
        searchPhrase: currentFilter.searchPhrase,
        searchFields: currentFilter.searchFields,
      }).toPromise();
      if ( result.success ) {
        if ( current === 0 ) {
          this.filteredSystemsWithDevices = [];
        }
        this.hasMoreFilteredSystemsWithDevices = result.list.length > 0;
        for ( const iSystem of result.list ) {
          if ( this.filteredSystemsWithDevices.find(si => si.registration_id === iSystem.registration_id && si.system_id === iSystem.system_id) ) { continue; }
          this.ingestFilteredSystemWithDevice(iSystem);
        }
      } else {
        const toaster = LocatorService.injector.get(ToasterService);
        toaster.postError((result as ErrorResponse).error);
      }
      this.updateSystemListLoadingStatus(false);
      return this.filteredSystemsWithDevices;
    };
  }

  private updateSystemListLoadingStatus(value: boolean) {
    this._systemListLoadingSubject.next(value);
  }

  public removeDeviceLocally(id: number) {
    if ( this.systemsWithDevices.find(d => d.registration_id === id && d.system_id === null) ) {
      this.systemsWithDevices.splice(this.systemsWithDevices.findIndex(d => d.registration_id === id && d.system_id === null), 1);
    }
    if ( this.filteredSystemsWithDevices.find(d => d.registration_id === id && d.system_id === null) ) {
      this.filteredSystemsWithDevices.splice(this.filteredSystemsWithDevices.findIndex(d => d.registration_id === id && d.system_id === null), 1);
    }
  }

  public removeSystemLocally(id: number): number | null {
    let registrationId: number | null = null;
    let registration: SmallSystemDataWithComputation = null;
    if ( this.systemsWithDevices.find(d => d.system_id === id) ) {
      registration = this.systemsWithDevices.find(d => d.system_id === id);
    }
    if ( this.filteredSystemsWithDevices.find(d => d.system_id === id) ) {
      registration = this.filteredSystemsWithDevices.find(d => d.system_id === id);
    }
    registrationId = registration?.registration_id ?? null;
    this.syncChanges(id, null, false, registrationId ?? undefined, registration?.system_status);
    return registrationId;
  }

  public syncChanges(system_id: number | null, system: TSystemData | null, addIfDoesNotExist: boolean = false, device_id: number = null, systemStatus: SystemStatus = null) {
    const companyService = LocatorService.injector.get(CompanyService);
    const companyItem = system ? companyService.listStorage.find(l => l.value === system.company_id) : null;
    const setValues = (ssd: SmallSystemDataWithComputation) => {
      if ( !ssd ) { return; }
      ssd.name = system?.name ?? '';
      ssd.company_id = system?.company_id ?? ssd.company_id;
      if ( companyItem ) {
        ssd.companyName = companyItem.label as string;
      } else if ( ssd.company_id > 0 && this.userService.currentUser.company_id === ssd.company_id && addIfDoesNotExist ) {
        ssd.companyName = this.userService.currentUser.belongsToCompany?.name ?? null;
      } else if ( companyService.companies.has(ssd.company_id) ) {
        ssd.companyName = companyService.companies.get(ssd.company_id).name;
      } else {
        ssd.companyName = null;
      }
      ssd.tags = system?.tags ?? ssd.tags;
      ssd.address = system?.address ?? '';
      ssd.assistedByEmail = system?.assistedByEmail ?? null;
      ssd.assistedById = system?.assistedById ?? null;
      ssd.installer_id = system?.installerId ?? null;
      ssd.installerEmail = system?.installerEmail ?? null;
      ssd.installerName = system?.installerName ?? null;
      ssd.hw_type = system?.hwType ?? '';
      ssd.device_id = system?.deviceId ?? 0;
      ssd.owner = system?.owners.length > 0 ? system.owners[0].email : '';
      ssd.system_id = system?.id ?? null;
      ssd.system_status = system?.status ?? systemStatus ?? null;
      if ( !system ) {
        ssd.object_id = '';
        ssd.owner = '';
        ssd.areas = [];
        ssd.connectionStatus = ssd.system_status && ssd.system_status.activated_at ? ssd.system_status.system_status : 'inactive';
        ssd.hasSingleArea = false;
        ssd.supportsAreas = false;
        ssd.firstStatusIcon = '';
        ssd.secondStatusIcon = '';
        ssd.statusColor = null;
      } else {
        ssd.connectionStatus = system.online ? 'online' : 'offline';
        ssd.areas = system.areas.map<SystemAreaData>(a => ({
          alarm_in_memory: a.alarmed,
          alarm_time: a.alarmTime,
          alarm_type: a.alarmType,
          can_bypass: a.canBypass,
          id: a.id,
          last_person_name: a.lastPerson,
          monas_id: null,
          name: a.name,
          queue_no: a.queue_no,
          show_keypad: a.showKeypad,
          status: a.status,
          status_time: a.statusTime,
          system_id: system.id,
          zones: [],
        }));
        if ( addIfDoesNotExist && ssd.registration_id === undefined ) {
          ssd.id = device_id;
          ssd.last_ip_com = null;
          ssd.imei = system.imei;
          ssd.created_at = '';
          ssd.object_id = '';
          ssd.supported_commands = system.supported_commands;
          ssd.enable_direct_control = system.directControl ? 1 : 0;
          ssd.ns = system.noSleepStay ? 1 : 0;
          ssd.registration_id = device_id;
          this.ingestSystemWithDevice(ssd);
        } else {
          const computed = this.performSystemComputation(ssd);
          ssd.hasSingleArea = computed.hasSingleArea;
          ssd.supportsAreas = computed.supportsAreas;
          ssd.firstStatusIcon = computed.firstStatusIcon;
          ssd.secondStatusIcon = computed.secondStatusIcon;
          ssd.statusColor = computed.statusColor;
        }
      }
    };
    let device = this.systemsWithDevices.find(d => (d.system_id !== null && d.system_id === system_id) || d.imei === system?.imei || (device_id !== null && d.registration_id === device_id));
    if ( !device && system && addIfDoesNotExist ) {
      device = JSON.parse(JSON.stringify({}));
    }
    setValues(device);
    const filteredDevice = this.filteredSystemsWithDevices.find(d => (d.system_id !== null && d.system_id === system_id) || d.imei === system?.imei || (device_id !== null && d.registration_id === device_id));
    setValues(filteredDevice);
  }

  public syncFromDevice(device: RegisteredDevice, tags: Tag[], objectId: string = '') {
    const companyService = LocatorService.injector.get(CompanyService);
    const companyItem = companyService.listStorage.find(l => l.value === device.company_id);
    const setValues = (ssd: SmallSystemData) => {
      if ( !ssd ) {
        ssd = JSON.parse(JSON.stringify({}));
        ssd.id = device.id;
        ssd.imei = device.imei;
        ssd.name = '';
        ssd.last_ip_com = device.job_id;
        ssd.created_at = '';
        ssd.object_id = objectId;
        ssd.installer_id = null;
        ssd.installerName = null;
        ssd.supported_commands = '';
        ssd.hw_type = '';
        ssd.device_id = 0;
        ssd.address = '';
        ssd.installerEmail = null;
        ssd.owner = '';
        ssd.connectionStatus = device.system_status && device.system_status.activated_at ? device.system_status.system_status : 'inactive';
        ssd.enable_direct_control = 0;
        ssd.ns = 1;
        ssd.areas = [];
        ssd.assistedById = null;
        ssd.assistedByEmail = null;
        ssd.registration_id = device.id;
        ssd.tags = tags;
        ssd.company_id = device.company_id;
        ssd.system_status = device.system_status ?? null;
        if ( companyItem ) {
          ssd.companyName = companyItem.label as string;
        } else if ( ssd.company_id > 0 && this.userService.currentUser.company_id === ssd.company_id ) {
          ssd.companyName = this.userService.currentUser.belongsToCompany?.name ?? '';
        }
        ssd.system_id = device.system_id;
        this.ingestSystemWithDevice(ssd);
      } else {
        ssd.company_id = device.company_id;
        if ( companyItem ) {
          ssd.companyName = companyItem.label as string;
        } else if ( ssd.company_id > 0 && this.userService.currentUser.company_id === ssd.company_id ) {
          ssd.companyName = this.userService.currentUser.belongsToCompany?.name ?? '';
        }
        ssd.system_id = device.system_id;
        ssd.system_status = device.system_status ?? null;
        ssd.tags = tags;
      }
    };
    const found = this.systemsWithDevices.find(d => d.registration_id === device.id);
    setValues(found);
    const filteredDevice = this.filteredSystemsWithDevices.find(d => d.registration_id === device.id);
    if ( filteredDevice ) {
      setValues(filteredDevice);
    }
  }

  public getSystemWithDevice(systemId: number): SmallSystemDataWithComputation|undefined {
    const item = this.systemsWithDevices.find(d => d.system_id === systemId);
    if ( !item ) {
      return this.filteredSystemsWithDevices.find(df =>df.system_id === systemId);
    }
    return item;
  }

  /** SVARBU! data objektas negali būti naudojamas tolesniems veiksmams, nes į sistemų sąrašas pridedamas modifikuotas/priaugintas objektas */
  public ingestSystemWithDevice(data: SmallSystemData): SmallSystemDataWithComputation {
    data.tags.forEach(t => this.tagService.ingestTag(t));
    const computed = this.performSystemComputation(data);
    this.systemsWithDevices.push(computed);
    return computed;
  }

  /** SVARBU! data objektas negali būti naudojamas tolesniems veiksmams, nes į sistemų sąrašas pridedamas modifikuotas/priaugintas objektas */
  public ingestFilteredSystemWithDevice(data: SmallSystemData): SmallSystemDataWithComputation {
    data.tags.forEach(t => this.tagService.ingestTag(t));
    const computed = this.performSystemComputation(data);
    this.filteredSystemsWithDevices.push(computed);
    return computed;
  }

  public performSystemComputation(system: SmallSystemData): SmallSystemDataWithComputation {
    const systemsService = LocatorService.injector.get(SystemsService);
    const areaServiceOrig = LocatorService.injector.get(AreaServiceOrig);
    //                                                                              Pagaminam fake TSystemData tik su reikiamais duomenimis patikrinimui
    const supportsAreas = system.system_id !== null && systemsService.systemHelper({ supported_commands: system.supported_commands, hwType: system.hw_type, directControl: system.enable_direct_control === 1, deviceId: system.device_id } as TSystemData).supports.areas();
    let statusColor = {
      '--start-color': 'white',
      '--end-color': 'white',
    };
    let firstStatusIcon = '';
    let secondStatusIcon = '';
    if ( supportsAreas && system.system_id !== null && system.areas.length > 0 ) {
      if ( system.areas.length === 1 ) {
        firstStatusIcon = areaServiceOrig.getIconByAreaStatus(system.areas[0].status, system.areas[0].alarm_in_memory, system.areas[0].alarm_type);
        switch (system.areas[0].status) {
          case AreaState.Disarmed:
            statusColor = {
              '--start-color': Colors.areaStates.disarmed,
              '--end-color': Colors.areaStates.disarmed,
            };
            break;
          case AreaState.ArmingInProgress:
            statusColor = {
              '--start-color': Colors.areaStates.armingInProgress,
              '--end-color': Colors.areaStates.armingInProgress,
            };
            break;
          case AreaState.DisarmingInProgress:
            statusColor = {
              '--start-color': Colors.areaStates.disarmingInProgress,
              '--end-color': Colors.areaStates.disarmingInProgress,
            };
            break;
          case AreaState.Armed:
            statusColor = {
              '--start-color': Colors.areaStates.armed,
              '--end-color': Colors.areaStates.armed,
            };
            break;
          case AreaState.Stay:
            statusColor = {
              '--start-color': Colors.areaStates.armed,
              '--end-color': Colors.areaStates.armed,
            };
            break;
          case AreaState.Sleep:
            statusColor = {
              '--start-color': Colors.areaStates.armed,
              '--end-color': Colors.areaStates.armed,
            };
            break;
          case AreaState.Unknown:
            statusColor = {
              '--start-color': Colors.areaStates.unknown,
              '--end-color': Colors.areaStates.unknown,
            };
          default:
            break;
        }
        if ( system.areas[0].alarm_in_memory ) {
          statusColor = {
            '--start-color': Colors.areaStates.alarmed,
            '--end-color': Colors.areaStates.alarmed,
          };
        }
      } else {
        let startColor = 'white';
        let endColor = 'white';
        for ( const state of areaStateValues ) {
          const found = system.areas.find(a => a.status === state);
          if ( !found ) { continue; }
          if ( startColor !== 'white' && endColor !== 'white' && startColor !== endColor ) { break; }
          const al = found.alarm_in_memory;
          if ( startColor === 'white' ) {
            firstStatusIcon = areaServiceOrig.getIconByAreaStatus(state, al, found.alarm_type);
          }
          switch (state) {
            case AreaState.Disarmed:
              if ( startColor === 'white' ) { startColor = al ? Colors.areaStates.alarmed : Colors.areaStates.disarmed; }
              else {
                endColor = al ? Colors.areaStates.alarmed : Colors.areaStates.disarmed;
                secondStatusIcon = areaServiceOrig.getIconByAreaStatus(state, al, found.alarm_type);
              }
              break;
            case AreaState.ArmingInProgress:
              if ( startColor === 'white' ) { startColor = al ? Colors.areaStates.alarmed : Colors.areaStates.armingInProgress; }
              else {
                endColor = al ? Colors.areaStates.alarmed : Colors.areaStates.armingInProgress;
                secondStatusIcon = areaServiceOrig.getIconByAreaStatus(state, al, found.alarm_type);
              }
              break;
            case AreaState.DisarmingInProgress:
              if ( startColor === 'white' ) { startColor = al ? Colors.areaStates.alarmed : Colors.areaStates.disarmingInProgress; }
              else {
                endColor = al ? Colors.areaStates.alarmed : Colors.areaStates.disarmingInProgress;
                secondStatusIcon = areaServiceOrig.getIconByAreaStatus(state, al, found.alarm_type);
              }
              break;
            case AreaState.Armed:
              if ( startColor === 'white' ) { startColor = al ? Colors.areaStates.alarmed : Colors.areaStates.armed; }
              else {
                endColor = al ? Colors.areaStates.alarmed : Colors.areaStates.armed;
                secondStatusIcon = areaServiceOrig.getIconByAreaStatus(state, al, found.alarm_type);
              }
              break;
            case AreaState.Stay:
              if ( startColor === 'white' ) { startColor = al ? Colors.areaStates.alarmed : Colors.areaStates.armed; }
              else {
                endColor = al ? Colors.areaStates.alarmed : Colors.areaStates.armed;
                secondStatusIcon = areaServiceOrig.getIconByAreaStatus(state, al, found.alarm_type);
              }
              break;
            case AreaState.Sleep:
              if ( startColor === 'white' ) { startColor = al ? Colors.areaStates.alarmed : Colors.areaStates.armed; }
              else {
                endColor = al ? Colors.areaStates.alarmed : Colors.areaStates.armed;
                secondStatusIcon = areaServiceOrig.getIconByAreaStatus(state, al, found.alarm_type);
              }
              break;
            case AreaState.Unknown:
              if ( startColor === 'white' ) { startColor = al ? Colors.areaStates.alarmed : Colors.areaStates.unknown; }
              else {
                endColor = al ? Colors.areaStates.alarmed : Colors.areaStates.unknown;
                secondStatusIcon = areaServiceOrig.getIconByAreaStatus(state, al, found.alarm_type);
              }
              break;
            default:
              break;
          }
        }
        if ( endColor === 'white' ) {
          endColor = startColor;
          secondStatusIcon = firstStatusIcon;
        }
        // Jei pirma sritis armed, antra disarmed, tai ciklas baigiasi, ir jeigu trečia sritis yra alarmed, ji liktų nepastebėta, todėl pažiūrim ar yra alarmed.
        const foundAlarmed = system.areas.find(a => a.alarm_in_memory);
        if ( foundAlarmed && startColor !== Colors.areaStates.alarmed && endColor !== Colors.areaStates.alarmed ) {
          endColor = Colors.areaStates.alarmed;
          secondStatusIcon = areaServiceOrig.getIconByAreaStatus(foundAlarmed.status, foundAlarmed.alarm_in_memory, foundAlarmed.alarm_type);
        }
        statusColor = {
          '--start-color': startColor,
          '--end-color': endColor,
        };
      }
    }
    return {
      ...system,
      supportsAreas,
      hasSingleArea: system.areas.length === 1,
      statusColor,
      firstStatusIcon,
      secondStatusIcon,
    };
  }

  public changeSystemViewId(value: number) {
    this.systemViewIdChange.next(value);
  }
}
