import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { LoggerService } from 'src/app/api/logger.service';
import { DateService } from 'src/app/services/date.service';
import { PlatformService } from 'src/app/api/platform.service';
import { InlineSVGModule } from 'ng-inline-svg-w';

@Component({
  selector: 'app-log-view',
  standalone: true,
  templateUrl: './log-view.component.html',
  styleUrls: ['./log-view.component.scss'],
  imports: [InlineSVGModule],
  encapsulation: ViewEncapsulation.None,
})
export class LogViewComponent implements OnInit {
  constructor(public l: LoggerService, public dt: DateService, public ps: PlatformService) {}

  ngOnInit(): void {}

  public close() {
    this.l.showLogView = false;
  }

  public downloadLogFile() {
    if (this.ps.isDesktop()) {
      this.l.exportJson();
    } else if (this.ps.isAndroid()) {
      const totalLength = this.l.entries.length - 1;
      for (let i = totalLength; i > -1; i--) {
        const currentVal = totalLength - i;
        const line = this.dt.formatTime(this.l.entries[i].ts, 3) + ' [' + this.l.entries[i].tag + '] ' + this.l.entries[i].val;
        this.ps.androidHandler().onDownloadLog(currentVal, totalLength, line);
      }
    }
  }

  public sendMail() {
    this.downloadLogFile();
    this.ps.androidHandler().mailLogFile();
  }
}
