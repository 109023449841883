import { Injectable } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { SystemDeviceUserData, ProtegusUserData, CurrentUserData, SystemProtegusUserData, UserSystemData, Region, CurrentUserDataRaw, FilterCriteria } from 'src/api/v3/common';
import { AllUsersUserData, GetSingleUserResponse } from 'src/api/v3/user';
import { DataTableGetter } from '../company/components/data-table/data-table.component';
import { TDeviceUser } from '../models/device-user';
import { HomeConfiguration, HomeConfigurationElementType, HomeConfigurationRaw } from '../models/home-configuration';
import { AuthService } from './auth.service';
import { LoggerService } from './logger.service';
import { PlatformService } from './platform.service';
import { RequestService } from './request.service';
import { ReactionService } from './system/reaction.service';
import { BehaviorSubject, Subject } from 'rxjs';
import { CompanyService } from './company.service';
import { GlobalService } from '../services/global.service';
import { LocatorService } from '../services/locator.service';
import { IpcomService } from './ipcom.service';
import { IPCom } from '../models/ipcom';
import { PermissionService } from './permission.service';
import { TRegionListItemData } from '../models/region-list-item-data';
import { DropdownItem } from '../ui/dropdown/dropdown.component';
import { EventsService } from '../services/events.service';
import { TagService } from './tag.service';
import requests from 'src/api/v3/requests';
import { SystemsService } from '../services/systems.service';
import { PersistenceService } from './persistence.service';
import { RegionService } from './region.service';
import { PendingSystemService } from './system/pending-system.service';
import * as CryptoJS from 'crypto-js';
import { Permission, PermissionRole } from 'src/api/v3/permission';

export const defaultHomeConfiguration: HomeConfiguration = {
  elementOrder: [
    { position: 1, type: 'device-status', visible: true },
    { position: 2, type: 'events', visible: true },
    { position: 3, type: 'areas', visible: true },
    { position: 4, type: 'outputs', visible: true },
    { position: 5, type: 'sensors', visible: true },
    { position: 6, type: 'cameras', visible: true },
    { position: 7, type: 'thermostats', visible: true },
  ],
  system_id: 0,
  visibleAreas: [],
  visibleOutputs: [],
  visibleSensors: [],
  visibleCameras: [],
  visibleThermostats: [],
};

@Injectable({
  providedIn: 'root',
})
export class UserService {
  private readonly currentUserDataVersion = 5;

  public readonly tag = 'UserService';
  private storeKey = 'a8oiiXKC9Q4VSbOj';
  public currentUser: CurrentUserData = null;
  public isCurrentUserFetched = false;
  public readonly users = new Map<number, ProtegusUserData>();
  public readonly systemUsers = new Map<number, UserSystemData[]>();
  public readonly systemDeviceUsers = new Map<number, Map<number, TDeviceUser>>();
  public usersForList: DropdownItem<number>[] = [];
  private previousFilterCriteria = null;
  private onUserDataChangedSource = new Subject<CurrentUserData>();
  public onUserDataChanged = this.onUserDataChangedSource.asObservable();
  public installerAssistance: { time: number; issue: string } = undefined;
  public countries = [];
  public companies = [];
  public logoBasePath = '';
  public filteredUsers: any[] = [];
  public userPassword = '';
  public appVersionDetails = null;
  public userRegions: Region[] = [];
  public userHasFewRegions = false;
  public refreshReactionList = new Subject<boolean>();
  public onRefreshReactionList = this.refreshReactionList.asObservable();
  public refreshIPComList = new Subject<boolean>();
  private userInEditIdChange = new Subject<number>();
  public onRefreshIPComList = this.refreshIPComList.asObservable();
  public onUserInEditIdChange = this.userInEditIdChange.asObservable();
  public allUsersLoaded = false;
  private _userListLoadingSubject = new BehaviorSubject<boolean>(false);
  readonly userListLoading$ = this._userListLoadingSubject.asObservable();
  private get persistanceService() { return LocatorService.injector.get(PersistenceService); };
  public get userPageSize() { return this.persistanceService.get('page_limit_users', 5); }
  public set userPageSize(value: number) { this.persistanceService.set('page_limit_users', value); }

  constructor(
    private auth: AuthService,
    private platform: PlatformService,
    private sanitizer: DomSanitizer,
    private req: RequestService,
    private l: LoggerService,
    private permissionService: PermissionService,
    private tagService: TagService,
    private regionService: RegionService,
  ) {
    this.regionService.onRegionChanged.subscribe(() => {
      this.buildBaseLogoPath();
    });
    this.buildBaseLogoPath();
    this.auth.onAccountOrRegionChnage.subscribe(() => {
      this.clear();
    });
    this.onUserDataChanged.subscribe(this.auth.registerCurrentUserData);
    setTimeout(() => {
      if (this.auth.hasToken()) {
        this.auth.loadUserData().catch((e) => this.l.log('Napavyko gauti user duomenų', this.tag, e.message));
      }
    }, 0);
  }

  private get reactionsService() { return LocatorService.injector.get(ReactionService); }
  private get companyService() { return LocatorService.injector.get(CompanyService); }
  private get g() { return LocatorService.injector.get(GlobalService); }
  private get ipcomService() { return LocatorService.injector.get(IpcomService); }

  public setCurrentUserFromRaw(user?: CurrentUserDataRaw) {
    if ( !user ) {
      this.setCurrentUser(null);
      return;
    }
    LocatorService.injector.get(EventsService).eventPageSize = user.page_limit.events;
    LocatorService.injector.get(SystemsService).systemPageSize = user.page_limit.systems;
    this.userPageSize = user.page_limit.users;
    this.setCurrentUser({...user, homeConfigurations: this.processHomeConfigurations(user.homeConfigurations)});
  }

  public setCurrentUser(user?: CurrentUserData) {
    this.l.log('Gauti vartotojo duomenys:', this.tag, user);
    this.currentUser = user ? JSON.parse(JSON.stringify(user)) : null;
    if ( !this.currentUser ) {
      this.saveCurrentUser();
      return;
    }

    if (this.platform.isAndroid()) { this.platform.androidHandler().setDateTimeFormat(user.date_format); }
    if (this.platform.isApple()) { this.platform.appleHandler().setDateTimeFormat.postMessage(user.date_format); }
    if ( user.logo ) {
      this.currentUser.logoDataForStorage = user.logo;
      this.currentUser.logo = user.logo;
    }
    if ( user.reactions ) { user.reactions.forEach((reaction) => this.reactionsService.ingestReaction(reaction)); }
    if ( user.ipcoms ) { user.ipcoms.forEach((i) => this.ipcomService.ingestIpcom(i)); }
    Object.defineProperty(this.currentUser, 'ipcoms', {
      get: () => [...this.ipcomService.ipcoms.values()],
      set: (ipcoms: IPCom[]) => {
        this.ipcomService.clear();
        ipcoms.forEach((i) => this.ipcomService.ingestIpcom(i));
      }
    });
    if ( user.regions ) {
      for (const iRegion of this.currentUser.regions) {
        const n = iRegion.api_host.indexOf('://');
        if (n !== -1) {
          iRegion.api_host_to_use = iRegion.api_host.substr(n + 3);
        } else {
          iRegion.api_host_to_use = iRegion.api_host;
        }
      }
    }
    this.currentUser.permission_rules.forEach((r) => this.permissionService.ingestIntoSessionStorage(r));
    if ( user.companies ) { user.companies.forEach((c) => this.companyService.ingestCompany(c)); }
    if ( user.pending_systems ) { (LocatorService.injector.get(PendingSystemService)).hasPendingSystems = user.pending_systems > 0; }
    this.appVersionDetails = user.appVersion;
    this.saveCurrentUser();
    this.g.checkAndNotifyAboutNewVersion();
  }

  public ingestDeviceUser(user?: SystemDeviceUserData): TDeviceUser | undefined {
    if (!user) { return; }
    const { keyboard_code, pin, enable_data, pgms, present, schedule_no, system_code_no, user_id, areas, ...rest } = user;

    const processedUser: TDeviceUser = {
      code: keyboard_code ?? pin ?? '',
      zone_number: system_code_no !== undefined ? system_code_no : user_id !== undefined ? user_id : 0,
      enable_data: enable_data !== undefined ? enable_data : 0,
      schedule_no: schedule_no !== undefined ? schedule_no : 0,
      pgms: pgms !== undefined ? pgms : -1,
      present: present ?? false,
      isOwner: false,
      ownerPermissions: undefined,
      areas: areas ? areas : '',
      ...rest,
    };
    if (!this.systemDeviceUsers.has(user.system_id)) {
      this.systemDeviceUsers.set(user.system_id, new Map<number, TDeviceUser>());
    }
    if ( !this.systemDeviceUsers.get(user.system_id).has(user.id) ) {
      this.systemDeviceUsers.get(user.system_id).set(user.id, processedUser);
    }

    return processedUser;
  }

  public ingestDeviceUserData(user: TDeviceUser, systemId: number) {
    if (!this.systemDeviceUsers.has(systemId)) {
      this.systemDeviceUsers.set(systemId, new Map<number, TDeviceUser>());
    }
    if ( !this.systemDeviceUsers.get(systemId).has(user.id) ) {
      this.systemDeviceUsers.get(systemId).set(user.id, user);
    }
  }

  public ingestProtegusUser(user: SystemProtegusUserData, system: { id: number; name: string }): ProtegusUserData | undefined {
    if (!user || !system.id) { return; }

    const { master, personal_permissions, system_permissions_id, ...rest } = user;
    const userSystemLite = { id: system.id, name: system.name };

    if (!this.users.has(user.id)) {
      rest.systems = [];
      rest.systems.push(userSystemLite);
      this.users.set(user.id, rest);
    } else {
      const existingUser = this.users.get(user.id);
      const userSystemFound = existingUser.systems.find(sys => sys.id === userSystemLite.id);
      if(!userSystemFound) {
        existingUser.systems.push(userSystemLite);
        this.users.set(user.id, existingUser);
      }
    }
    let systemData = this.systemUsers.get(system.id);
    if ( !systemData ) {
      systemData = this.systemUsers.set(system.id, []).get(system.id);
    }
    const userSystemData = systemData.find(u => u.id === user.id );
    if ( !userSystemData ) {
      //this.l.log(`Ingest protegus user [${system.id}]: ${user.email}`, this.tag);
      systemData.push({master, system_permissions_id, personal_permissions, id: rest.id});
    }
    return rest;
  }

  public ingestUserLite(user: AllUsersUserData): ProtegusUserData {
    if (!user) { return; }
    const processedUser: ProtegusUserData = {
      company: '',
      company_id: 0,
      created_at: '',
      dashboard_order: '',
      phone: user.phone_number,
      privacy_policy_accepted: true,
      soc_account: false,
      soc_type: '',
      social_id: '',
      ...user,
    };
    this.l.log('Ingest lite user: ', this.tag, user);
    if (!this.users.has(user.id)) {
      this.users.set(user.id, processedUser);
    } else if(user.systems && user.systems.length > 0) {
      const existingUser = this.users.get(user.id);
      user.systems.forEach(system => {
        const userSystemLite = { id: system.id, name: system.name };
        const userSystemFound = existingUser.systems.find(sys => sys.id === userSystemLite.id);
        if(!userSystemFound) {
          existingUser.systems.push(userSystemLite);
          this.users.set(user.id, existingUser);
        }
      });
    }
    return processedUser;
  }

  private processHomeConfigurations(configurations?: HomeConfigurationRaw[]): HomeConfiguration[] {
    if (!configurations || !configurations.length) {
      return [defaultHomeConfiguration];
    }
    return configurations.map((hc) => {
      const elementOrder = JSON.parse(hc.element_order ?? 'null') ?? defaultHomeConfiguration.elementOrder;
      if (!elementOrder.find((e) => e.type === 'cameras')) { elementOrder.push(defaultHomeConfiguration.elementOrder[4]); }
      if (!elementOrder.find((e) => e.type === 'thermostats')) { elementOrder.push(defaultHomeConfiguration.elementOrder[5]); }
      return {
        system_id: hc.system_id,
        elementOrder,
        visibleAreas: JSON.parse(hc.areas ?? 'null') ?? defaultHomeConfiguration.visibleAreas,
        visibleOutputs: JSON.parse(hc.outputs ?? 'null') ?? defaultHomeConfiguration.visibleOutputs,
        visibleSensors: JSON.parse(hc.sensors ?? 'null') ?? defaultHomeConfiguration.visibleSensors,
        visibleCameras: JSON.parse(hc.cameras ?? 'null') ?? defaultHomeConfiguration.visibleCameras,
        visibleThermostats: JSON.parse(hc.thermostats ?? 'null') ?? defaultHomeConfiguration.visibleThermostats,
      };
    });
  }

  public async loadAllUsers(): Promise<void> {
    const result = await requests.user.getAllUsers({ all: true, loadedUsersCount: this.allUsersLoaded ? this.users.size: 0 }).toPromise();
    if (result.success) {
      result.users.forEach(user => this.ingestUserLite(user));
      result.rules.forEach(rule => this.permissionService.ingestIntoSessionStorage(rule));
      result.tags.forEach(t => this.tagService.ingestTag(t));
    }
    this.allUsersLoaded = true;
  }

  public async loadSingleUserData(id: number): Promise<GetSingleUserResponse> {
    const result = await this.req.user.getSingleUser({ id }).toPromise();
    return result;
  }

  public getUsersGetter(resultsFiltered: boolean): DataTableGetter<ProtegusUserData> {
    if(resultsFiltered) { this.users.clear(); }
    return async (current, columns, more) => {
      if(!more && current <= this.users.size && !resultsFiltered) {
        return this.currentUser.company_id === 0 ? [...this.users.values()] : [...this.users.values()].filter(u => u.company_id !== 0);
      }
      this.updateUserListLoadingStatus(true);
      this.previousFilterCriteria = null;
      await this.loadAllUsers();
      this.updateUserListLoadingStatus(false);
      resultsFiltered = false;
      return this.currentUser.company_id === 0 ? [...this.users.values()] : [...this.users.values()].filter(u => u.company_id !== 0);
    };
  }

  public async loadFilteredUsers(filterCriteria: FilterCriteria): Promise<any> {
    const result = await this.req.user.filterUsers({
      searchPhrase: filterCriteria.searchPhrase,
      searchFields: filterCriteria.searchFields,
      role: -1,
      sort: 0,
      paginationPage: filterCriteria.currentPage === filterCriteria.lastPage ? filterCriteria.lastPage : filterCriteria.currentPage + 1
    }).toPromise();
    if (result.success) {
      result.list.data.forEach((dataItem: AllUsersUserData) => this.ingestUserLite(dataItem));
      result.rules.forEach(rule => this.permissionService.ingestIntoSessionStorage(rule));
      result.tags.forEach(tag => this.tagService.ingestTag(tag));
      return result.list;
    }
  }

  public getFilteredUsersGetter(filterCriteria: FilterCriteria): DataTableGetter<ProtegusUserData> {
    return async (current, columns, more) => {
      const filterCriteriaString = JSON.stringify(filterCriteria);
      const newFilterCriteria = this.previousFilterCriteria !== filterCriteriaString;

      if(filterCriteria.lastPage === 0 && newFilterCriteria) {
        this.users.clear();
      }
      if(!more && current <= this.users.size && this.users.size > 0) {
        return [...this.users.values()];
      }
      if(newFilterCriteria) {
        this.updateUserListLoadingStatus(true);
        this.previousFilterCriteria = filterCriteriaString;
        try {
          await this.loadFilteredUsers(filterCriteria).then((result) => {
            filterCriteria.currentPage = result.current_page;
            filterCriteria.lastPage = result.last_page;
          });
        } catch (e) { }
        this.updateUserListLoadingStatus(false);
      }
      return [...this.users.values()];
    };
  }

  /** Iššaukia onUserDataChanged event */
  public change() {
    this.onUserDataChangedSource.next(this.currentUser);
  }

  public saveCurrentUser() {
    const crypted = CryptoJS.AES.encrypt(JSON.stringify(this.currentUser), this.storeKey).toString();
    this.l.log('Saugomi vartotojo duomenys:', this.tag, this.currentUser);
    localStorage.setItem('user_data', crypted);
    localStorage.setItem('user_data_version', this.currentUserDataVersion.toString());
  }

  public setLoginType(type: string) {
    localStorage.setItem('login_type', type);
  }

  public getLoginType(): string {
    return localStorage.getItem('login_type');
  }

  public clearUserData() {
    this.l.log('Clear user data', this.tag);
    this.clear();
    localStorage.removeItem('token');
    localStorage.removeItem('user_data');
    localStorage.removeItem('last_system');
    localStorage.removeItem('last_system_data');
    localStorage.removeItem('login_type');
    this.setCurrentUser(null);
    this.change();
    this.saveCurrentUser();
  }

  private clear() {
    localStorage.removeItem('last_system');
    localStorage.removeItem('last_system_data');
    this.users.clear();
    this.systemDeviceUsers.clear();
    this.systemUsers.clear();
    this.usersForList = [];
    this.filteredUsers = [];
    this.previousFilterCriteria = null;
    this.installerAssistance = undefined;
    this.companies = [];
    this.allUsersLoaded = false;
  }

  public loadUserDataFromStorage() {
    const userString = localStorage.getItem('user_data');
    if ( !userString ) { return; }

    const dataVersion = localStorage.getItem('user_data_version') ?? '0';
    let temp = null;
    if ( dataVersion === '0' ) {
      temp = JSON.parse(userString);
    } else {
      try {
        const bytes = CryptoJS.AES.decrypt(userString, this.storeKey);
        if (bytes.toString()) {
          temp = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
        }
      } catch (eJson) {}
    }

    if ( !temp ) {
      this.l.log('Užkraunami išsaugoti vartotojo duomenys: NULL', this.tag);
      this.setCurrentUser(null);
      this.change();
      return;
    }

    let resaveUser = this.migrateUserData(parseInt(dataVersion), temp);
    this.currentUser = temp;
    this.l.log('Užkraunami išsaugoti vartotojo duomenys: ', this.tag, this.currentUser);
    if ( resaveUser ) {
      this.l.log('Buvo atlikta užkrautų duomenų modifikacija. Reikia atnaujinti saugyklos duomenis.', this.tag);
      this.saveCurrentUser();
    }
    this.change();
  }

  public getRegionsGetter(): DataTableGetter<TRegionListItemData> {
    return async (current, columns, more) => this.currentUser.regions;
  };

  public populateUserRegions(): void  {
    if(!this.currentUser) {
      return;
    }
    if(this.currentUser.permissions.permissions.regions.view) {
      this.userRegions = this.currentUser.regions;
    } else {
      this.checkIfUserHasFewRegions();
      if (this.userHasFewRegions) {
        this.auth.loadMergedRegionAccounts();
        const accounts = this.auth.mergedRegionAccounts;
        const user = accounts.find(acc => acc.id === this.currentUser.id);
        this.userRegions = (user && user.regions.length > 1) ? user.regions : [];
      } else {
        this.userRegions = [];
      }
    }
  }

  private checkIfUserHasFewRegions(): void {
    this.auth.loadMergedRegionAccounts();
    const accounts = this.auth.mergedRegionAccounts;
    const user = accounts.find(acc => acc.id === this.currentUser.id);
    this.userHasFewRegions = user && user.regions.length > 1;
  }

  public refreshReactionListChange(value: boolean) {
    this.refreshReactionList.next(value);
  }

  public refreshIPComListChange(value: boolean) {
    this.refreshIPComList.next(value);
  }

  public changeUserInEditId(value: number) {
    this.userInEditIdChange.next(value);
  }

  public canViewAnyOfGeneralPermissions(): boolean {
    for ( const iPermission of this.permissionService.allGeneralPermissions ) {
      if ( this.currentUser.permissions.permissions[iPermission].view ) { return true; }
    }

    return false;
  }

  private updateUserListLoadingStatus(value: boolean) {
    this._userListLoadingSubject.next(value);
  }

  private buildBaseLogoPath() {
    this.logoBasePath = this.regionService.ActiveRegion.protocol
        + this.regionService.ActiveRegion.backEndHost
        + (this.regionService.ActiveRegion.port !== '' ? ':' + this.regionService.ActiveRegion.port : '')
        + '/' + CompanyService.LOGO_BASE_PATH;
  }

  /**
   * Naudojama po kompanijos ištrynimo.
   * 
   * @param companyId Kompanija, kurios vartotojus ištrinti.
   * @param ownerId Kompanijos sąvininkas, kurio nereikia trinti.
   */
  public removeCompanyUsers(companyId: number, ownerId: number | null) {
    const companyUsers = [...this.users.values()].filter(u => u.company_id === companyId && u.id !== ownerId);
    for ( const user of companyUsers ) {
      this.users.delete(user.id);
    }
  }

  /**
   * Migruoja senus išsaugotus vartotojo duomenis į naują versiją.
   * NEDAROME `else if` tam, kad migracija galėtų įvykti ir nuo pačios mažiausios versijos.
   * 
   * **Būtinai** nepamiršti kiekviename versijos migravime pridėti `modified = true` bei `fromVersion++`.
   * @param fromVersion Nuo kurios versijos bus naujinama.
   * @param oldUserData Iš local storage užkrauti duomenys prie migraciją.
   * @returns 
   */
  private migrateUserData(fromVersion: number, oldUserData: any): boolean {
    let modified = false;
    if ( fromVersion === 0 ) {
      this.l.log('Migruojam vartotoją iš v0.', this.tag);
      modified = true;
      fromVersion++;

      const hasPermissions = oldUserData.hasOwnProperty('permissions');
      if ( !hasPermissions || !oldUserData.permissions ) {
        oldUserData.permissions = PermissionService.getCopyOfRule(PermissionService.defaultGeneralUserRule);
      }
      if (oldUserData.hasOwnProperty('ipcoms') && !oldUserData.permissions.permissions.ipcom_settings.view) {
        delete oldUserData.ipcoms;
      }
      if ( !oldUserData.permissions.permissions.hasOwnProperty('dev_setup_templates') ) {
        this.l.log('Išsaugotuose duomenys neturėjome dev_setup_templates.', this.tag);
        oldUserData.permissions.permissions.dev_setup_templates = {...PermissionService.defaultGeneralUserRule.permissions.dev_setup_templates};
      }
      if ( !oldUserData.permissions.permissions.hasOwnProperty('unassigned_devices') ) {
        this.l.log('Išsaugotuose duomenys neturėjome unassigned_permissions.', this.tag);
        oldUserData.permissions.permissions.unassigned_devices = {...PermissionService.defaultGeneralUserRule.permissions.unassigned_devices};
      }
      if (oldUserData.logoDataForStorage !== undefined && oldUserData.logoDataForStorage !== '') {
        oldUserData.logo = oldUserData.logoDataForStorage;
      }
    }
    if ( fromVersion === 1 ) {
      this.l.log('Migruojam vartotoją iš v1.', this.tag);
      modified = true;
      fromVersion++;

      if ( !oldUserData.permissions.permissions.hasOwnProperty('pending_systems') ) {
        this.l.log('Išsaugotuose duomenys neturėjome pending_systems.', this.tag);
        let pendingSystemPerm: Permission;
        switch (oldUserData.permissions.role) {
          case PermissionRole.SuperAdmin: pendingSystemPerm = {...PermissionService.maxSuperAdminPermissions.pending_systems}; break;
          case PermissionRole.Company: pendingSystemPerm = {...PermissionService.maxCompanyAdminPermissions.pending_systems}; break;
          default: pendingSystemPerm = {...PermissionService.defaultGeneralUserRule.permissions.pending_systems}; break;
        }
        oldUserData.permissions.permissions.pending_systems = pendingSystemPerm;
      }
    }
    if ( fromVersion === 2 ) {
      this.l.log('Migruojam vartotoją iš v2.', this.tag);
      modified = true;
      fromVersion++;

      if ( !oldUserData.permissions.permissions.hasOwnProperty('company_payments') ) {
        this.l.log('Išsaugotuose duomenys neturėjome company_payments.', this.tag);
        let companyPaymentsPerm: Permission;
        switch (oldUserData.permissions.role) {
          case PermissionRole.SuperAdmin: companyPaymentsPerm = {...PermissionService.maxSuperAdminPermissions.company_payments}; break;
          case PermissionRole.Company: companyPaymentsPerm = {...PermissionService.maxCompanyAdminPermissions.company_payments}; break;
          default: companyPaymentsPerm = {...PermissionService.defaultGeneralUserRule.permissions.company_payments}; break;
        }
        oldUserData.permissions.permissions.company_payments = companyPaymentsPerm;
      }
    }
    if ( fromVersion === 3 ) {
      this.l.log('Migruojam vartotoją iš v3.', this.tag);
      modified = true;
      fromVersion++;

      if ( !oldUserData.permissions.permissions.hasOwnProperty('system_notes') ) {
        this.l.log('Išsaugotuose duomenys neturėjome system_notes.', this.tag);
        let permission: Permission;
        switch (oldUserData.permissions.role) {
          case PermissionRole.SuperAdmin: permission = {...PermissionService.maxSuperAdminPermissions.system_notes}; break;
          case PermissionRole.Company: permission = {...PermissionService.maxCompanyAdminPermissions.system_notes}; break;
          case PermissionRole.Installer: permission = {...PermissionService.maxInstallerPermissions.system_notes}; break;
          default: permission = {...PermissionService.defaultGeneralUserRule.permissions.system_notes}; break;
        }
        oldUserData.permissions.permissions.system_notes = permission;
      }
    }
    if ( fromVersion === 4 ) {
      this.l.log('Migruojam vartotoją iš v4.', this.tag);
      modified = true;
      fromVersion++;

      if ( !oldUserData.permissions.permissions.hasOwnProperty('monitoring_stations') ) {
        this.l.log('Išsaugotuose duomenys neturėjome monitoring_stations.', this.tag);
        let permission: Permission;
        switch (oldUserData.permissions.role) {
          case PermissionRole.SuperAdmin: permission = {...PermissionService.maxSuperAdminPermissions.monitoring_stations}; break;
          case PermissionRole.Company: permission = {...PermissionService.maxCompanyAdminPermissions.monitoring_stations}; break;
          default: permission = {...PermissionService.defaultGeneralUserRule.permissions.monitoring_stations}; break;
        }
        oldUserData.permissions.permissions.monitoring_stations = permission;
      }
    }

    return modified;
  }
}
