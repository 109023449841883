import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { InlineSVGModule } from 'ng-inline-svg-w';
import { AppSettings } from 'src/app-settings';
import { LanguageService } from 'src/app/services/language.service';

@Component({
  selector: 'app-plus-button',
  templateUrl: './plus-button.component.html',
  styleUrls: ['./plus-button.component.scss'],
  standalone: true,
  imports: [CommonModule, InlineSVGModule],
})
export class PlusButtonComponent implements OnInit {
  @Input() buttonText = '';
  @Input() centerButton = false;
  @Input() showLimiter = false;
  @Input() currentCount = 0;
  @Input() maxLimit = 0;
  @Input() aboveFooter = false;
  @Output() plusClicked: EventEmitter<any> = new EventEmitter();

  constructor(public lang: LanguageService) {}

  ngOnInit(): void {
    this.showLimiter = this.showLimiter && AppSettings.limitationSystemEnabled;
  }

  public pliusButtonClick() {
    if (this.showLimiter && this.currentCount === this.maxLimit) {
      return;
    }
    this.plusClicked.emit();
  }
}
