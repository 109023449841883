<div *ngIf="desktopView" class="header">
  <h4 class="title"><app-back-button [style.display]="'inline-block'"></app-back-button>{{trans('general.help')}}</h4>
</div>
<div [ngClass]="{'white-background-wrap' : true, 'desktop': desktopView}" >
  <div class="white_round padding_sides8">
    <div *ngIf="desktopView" class="header-ticket">
      <h2 class="text">{{ trans('help.supportTicket') }}</h2>
    </div>
    <ng-container *ngIf="!this.us.currentUser?.email">
      <div class="text-input-wrap">
        <app-text-edit id='ht_email' (exitedField)="emailSet($event)" [inputType]="'email'" [isReadOnly]="miniStatus.isVisible()" [value]='email' [isReadOnly]="miniStatus.isVisible()">{{trans('help.yourEmail')}}</app-text-edit>
        <app-form-error *ngIf="val.hasError('email')">{{ val.getError('email') }}</app-form-error>
      </div>
    </ng-container>
    <div class="textarea-wrap">
      <div class="textarea-inner">
        <app-form-input type="textarea" [label]="trans('help.describeIssue')" [(value)]="formContent" rows="15"></app-form-input>
      </div>
      <div *ngIf="val.hasError('formContent')" class="formContentError">
        <app-form-error>{{ val.getError('formContent') }}</app-form-error>
      </div>
    </div>
  </div>
  <div *ngIf="platform.isDesktopView() && email !== ''" class="button-wrap">
    <app-button type="filled-secondary" [isLoading]="isLoading" (click)="sendReport()">{{ trans('help.submit') }}</app-button>
  </div>
  <div class="separator16"></div>
</div>
<app-loader-round [isVisible]="isLoading" [displayText]="trans('help.sending')" [overlayIsGray]="true"></app-loader-round>