<app-base-popup #basePopup [canCancel]="false" (popupCancelled)="closeWindow()">
    <div class="white_round padding16 aap_container" >
        <div class='aap-icon' *ngIf="!areaIsVirtualArea() && activeArea.alarmType === AlarmType.General"><div [inlineSVG]="'assets/images/area_alarmed.svg'"></div></div>
        <div class='aap-icon' *ngIf="areaIsVirtualArea() || activeArea.alarmType === AlarmType.Fire"><div [inlineSVG]="'assets/images/event_fire.svg'"></div></div>
        <div class='aap-alarm-text' *ngIf="!areaIsVirtualArea() && activeArea.alarmType === AlarmType.General">{{trans('systems.statuses.area.alarm')}}</div>
        <ng-container *ngIf="!areaIsPanicButtonsArea() && !areaIsVirtualArea()">
          <div class='aap-alarm-text' *ngIf="activeArea.alarmType === AlarmType.Fire">{{trans('systems.statuses.area.alarmFire')}}</div>
          <div class='aap-area-name'>{{activeArea.name}}</div>
          <div class='aap-button button' (click)='doDisarm()' *ngIf="activeArea.status !== 1">{{areaService.getDescriptionForStatus(1, false, activeArea)}}</div>
          <div class='aap-button button' *ngIf='systems.activeSystemHelper.supports.silenceSiren()' (click)='doSilence()'>{{trans('systems.buttons.silenceSiren')}}</div>
          <div class='aap-button button' *ngIf='systems.activeSystemHelper.supports.cancelAlarm()' (click)='doCancel()'>{{trans('systems.buttons.cancelAlarm')}}</div>
          <div class='aap-button button' *ngIf='systems.activeSystem.supportsFireReset && activeArea.alarmType === AlarmType.Fire && systems.activeSystemHelper.can.resetFireSensors()' (click)='gotoFireReset()'>{{trans('systems.buttons.sensors_reset')}}</div>
        </ng-container>
        <ng-container *ngIf="areaIsPanicButtonsArea()">
          <div class='aap-area-name'>{{activeArea.name}}</div>
          <div class='aap-button button' *ngIf='systems.activeSystemHelper.supports.cancelAlarm()' (click)='doCancel()'>{{trans('systems.statuses.area.endPanicAlarm')}}</div>
        </ng-container>
        <ng-container *ngIf="areaIsVirtualArea()">
          <div class='aap-alarm-text aap-alarm-text-capitalize'>{{trans('systems.statuses.area.fireAlarm')}}</div>
          <div *ngIf="fireInGroupsText !== '' || fireInLoopsText !== ''" class='aap-area-name-small'>
            <span *ngIf="fireInGroupsText !== ''">{{fireInGroupsText}}</span>
            <span *ngIf="fireInGroupsText !== '' && fireInLoopsText !== ''">, </span>
            <span *ngIf="fireInLoopsText !== ''" class='aap-area-name-small'>{{trans('systems.labels.inLoop')}}: {{fireInLoopsText}}</span>
          </div>
          <div class='aap-button-status button' (click)='doDisarm()'>{{trans('systems.buttons.restore_status')}}</div>
          <ng-container *ngIf="systems.activeSystemHelper.can.see.outputs() && visibleOutputs.length > 0">
            <div class='aap-alarm-text-secondary'>{{trans('systems.statuses.chooseAction')}}</div>
            <app-pgm-button *ngFor='let pgm of visibleOutputs' [pgm]='pgm' [buttonType]="'switch'"></app-pgm-button>
          </ng-container>
        </ng-container>
        <div [ngClass]="areaIsVirtualArea() ? 'aap-close-link' : 'aap-close'" (click)='closeWindow()'>{{trans('general.close')}}</div>
    </div>
</app-base-popup>
<app-area-keypad *ngIf='activeArea !== null && showPinKeypad' [keypadType]='"slideout"' (cancelClicked)='keypadCancelled()' [maxLength]='6' [minLength]='4' (confirmClicked)='pinConfirmed($event.pin, $event.remember_pin)'></app-area-keypad>
