import { Injectable } from '@angular/core';
import { TEventData } from '../models/event-data';
import { LanguageService } from './language.service';
import { DateService } from './date.service';
import { RequestService } from '../api/request.service';
import { LocatorService } from './locator.service';
import { PersistenceService } from '../api/persistence.service';
import { ReactionService } from '../api/system/reaction.service';

@Injectable({
  providedIn: 'root',
})
export class EventsService {
  private get persistanceService() { return LocatorService.injector.get(PersistenceService); };
  public get eventPageSize() { return this.persistanceService.get('page_limit_events', 5); }
  public set eventPageSize(value: number) { this.persistanceService.set('page_limit_events', value); }

  constructor(
    private ls: LanguageService,
    private dt: DateService,
    private req: RequestService,
    private reactionService: ReactionService,
    private dateService: DateService,
  ) {}
  public allEvents: TEventData[] = [];

  /**
   * Pateiktus įvykius sugrupuoja pagal dienas.
   *
   * @param eventsToGroup Įvykių sąrašas, kurį naudoti grupavimui
   * @param lastKnownDate Paskutinė data, kuri jau buvo pradėta grupuoti.
   * ( Naudojama tuo atveju, jeigu įvykių sąrašas papildomas nauju ).
   * 0 reiškia nėra reikšmės, todėl bus grupuojama nuo pirmo įvykio dienos.
   * @param eventCountToProcess Nurodo maksimalų kiekį įvykių, kurie bus naudojami grupavimui.
   */
  public groupEvents(eventsToGroup: TEventData[], lastKnownDate: number, eventCountToProcess: number): any[] {
    if (eventCountToProcess === 0) {
      eventCountToProcess = 99999999;
    }

    const groupedEvents = [];
    let currentDayTitle = '';
    if (lastKnownDate !== 0) {
      const lastDate = new Date(lastKnownDate * 1000);
      currentDayTitle = this.dt.getDayTitle(lastDate);
      groupedEvents.push({
        dayTitle: currentDayTitle,
        dayEvents: [],
      });
    }

    for (const iEvent of eventsToGroup.sort((e1, e2) => e2.id - e1.id)) {
      const eventDayTitle = this.dt.getDayTitle(new Date(iEvent.time * 1000));
      if (currentDayTitle !== eventDayTitle) {
        currentDayTitle = eventDayTitle;
        groupedEvents.push({
          dayTitle: currentDayTitle,
          dayEvents: [],
        });
      }
      groupedEvents[groupedEvents.length - 1].dayEvents.push(iEvent);
      eventCountToProcess--;
      if (eventCountToProcess === 0) {
        break;
      }
    }

    return groupedEvents;
  }

  public convertFromRaw(eventData: any): TEventData {
    const event: TEventData = {
      id: eventData.id,
      title: eventData.title,
      areaText: eventData.subTitle,
      time: eventData.time,
      areaId: eventData.area,
      reaction: eventData.reaction,
      isAreaEvent: eventData.area_event,
      systemId: eventData.system_id,
      areaInfo: eventData.area_info ?? '',
      iconPath: this.reactionService.getReactionIcon(eventData.reaction),
      timeString: this.dateService.formatTimeFromNumber(eventData.time),
      longitude: eventData.longitude,
      latitude: eventData.latitude,
      userName: eventData.user_name,
    };

    return event;
  }
}
