import { CommonModule } from '@angular/common';
import { Component, OnInit, ChangeDetectorRef, ViewEncapsulation, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { ToggleButtonComponent } from 'src/app/components/toggle-button/toggle-button.component';
import { LanguageAware } from 'src/app/general/language-aware';
import { TBackgroundType } from 'src/app/models/background-types';
import { TZoneData } from 'src/app/models/zone-data';
import { ZoneService } from 'src/app/services/zone.service';
import { AreaKeypadComponent } from '../area-keypad/area-keypad.component';
import { InlineSVGModule } from 'ng-inline-svg-w';

@Component({
  selector: 'app-zone-status-list',
  templateUrl: './zone-status-list.component.html',
  styleUrls: ['./zone-status-list.component.scss'],
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [CommonModule, ToggleButtonComponent, AreaKeypadComponent, InlineSVGModule],
})
export class ZoneStatusListComponent extends LanguageAware implements OnInit, OnDestroy {
  private zoneToBypass: TZoneData = null;
  public showKeypad = false;
  public showWarning = false;
  private readonly panicButtonsAreaStatus = 18;
  private refreshSubscriber: Subscription;

  constructor(
    cdRef: ChangeDetectorRef,
    public zs: ZoneService,
    private router: Router,
    public r: ActivatedRoute,
  ) {
    super(cdRef, { backgroundType: TBackgroundType.Gray });
    this.tag = 'AreaZones';
    this.headerBar.showHeader({
      headerText: this.systems.activeArea.status !== this.panicButtonsAreaStatus ? this.trans('systems.titles.zones') : this.trans('systems.titles.panicButtonsList'),
      backUrl: '*',
      showDotMenu: true,
    });
    localStorage.setItem('ref', 'zone-status-list');
    this.headerBar.setDotMenuItems([
      {
        name: this.trans('systems.menu.settings'),
        action: () => { this.router.navigate([...this.g.getHomeUrl(), 'settings', 'zones']); },
      },
    ]);
    const systemId = this.systems.activeSystem.id;
    if (this.systems.activeSystem.hwType !== 'G16T' && this.systems.activeSystem.hwType !== 'E16T') {
      this.zs.getZoneStatuses(this.systems.activeArea.queue_no, this.systems.activeSystem.id, (z) => {
        this.gotZoneStatuses(z, systemId);
      });
    }

    this.showWarning = this.systems.activeSystem.panel >= 0x10 && this.systems.activeSystem.panel < 0x20;
    this.refresher.enableRefresher();
    this.refreshSubscriber = this.refresher.onRefresh.subscribe(() => {
    if (this.systems.activeSystem.hwType !== 'G16T' && this.systems.activeSystem.hwType !== 'E16T') {
      this.zs.getZoneStatuses(this.systems.activeArea.queue_no, this.systems.activeSystem.id, (z) => {
        this.gotZoneStatuses(z, systemId);
      });
    }
    });
  }

  ngOnInit(): void {}

  ngOnDestroy() {
    this.refreshSubscriber?.unsubscribe();
  }

  public doBypass(zone: TZoneData, event) {
    if (!this.systems.activeSystemHelper.can.bypassZone()) {
      return;
    }
    this.log('Darom bypass', zone.queue_no);
    if (event !== undefined) {
      event.preventDefault();
    }
    this.zoneToBypass = zone;
    if (this.systems.activeArea.showKeypad) {
      this.showKeypad = true;
    } else {
      this.zs.performBypass(zone);
    }
  }

  public zoneIsPanicButton(): boolean {
   return this.systems.activeArea.status === this.panicButtonsAreaStatus ? true : false;
  }

  public getZones(): TZoneData[] {
    return this.systems.activeSystem.zones.filter((z) => z.areas.find((a) => a === this.systems.activeArea.queue_no) !== undefined && z.visible);
  }

  private gotZoneStatuses(zones: any, systemId: number) {
    this.log('Grizo zonu buseson: ', zones);
    const system = this.systems.getSystem(systemId) || this.systemService.systems.get(systemId);
    if (system === undefined) {
      return;
    }

    for (const iZone of Object.keys(zones)) {
      const zone = system.zones.find((z) => z.queue_no === parseInt(iZone, 10));
      if (zone === undefined) {
        continue;
      }
      zone.bypassed = zones[iZone].bypass;
      zone.alarmed = zones[iZone].alarm;
      zone.failed = zones[iZone].failure;
    }
  }

  public keypadCancelled() {
    this.showKeypad = false;
  }

  public pinConfirmed(pin?: string, remember?: boolean) {
    this.showKeypad = false;
    this.zs.performBypass(this.zoneToBypass, pin, remember);
  }
}
