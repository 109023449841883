import { Component, OnInit, ChangeDetectorRef, ViewEncapsulation } from '@angular/core';
import { LanguageAware } from '../language-aware';
import { Router } from '@angular/router';
import { TSystemData } from 'src/app/models/system-data';
import { GoogleAuthService } from 'src/app/api/google-auth.service';
import { AuthService } from 'src/app/api/auth.service';
import { MessageboxService } from 'src/app/services/messagebox.service';
import { TBackgroundType } from 'src/app/models/background-types';
import { CommonModule } from '@angular/common';
import { InlineSVGModule } from 'ng-inline-svg-w';

@Component({
  selector: 'app-account-delete',
  templateUrl: './account-delete.component.html',
  styleUrls: ['./account-delete.component.scss'],
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [CommonModule, InlineSVGModule],
})
export class AccountDeleteComponent extends LanguageAware implements OnInit {
  constructor(
    cdRef: ChangeDetectorRef,
    private router: Router,
    private google: GoogleAuthService,
    private auth: AuthService,
    private messagebox: MessageboxService
  ) {
    super(cdRef, { backgroundType: TBackgroundType.Gray });
    this.headerBar.showHeader({
      headerText: this.trans('users.labels.deleteProfile'),
      backUrl: '/profile',
    });
    this.footerBar.showFooter(this.trans('users.labels.deleteProfile'), '', false, false);
    const that = this;
    this.footerBar.onButton1Click = () => {
      that.doDelete();
    };
    this.footerBar.onSumCorrect = () => {
      that.confirmedDeletion();
    };
  }

  ngOnInit(): void {}

  private doDelete() {
    this.footerBar.showConfirmation();
  }

  public confirmedDeletion() {
    if (this.miniStatus.isVisible()) {
      this.miniStatus.flash();
      return;
    }
    const userEmail = this.us.currentUser.email;
    this.miniStatus.show(this.trans('users.labels.deleting'));
    this.api.delete('/account?sum=true&acknowledged=true', true).subscribe(
      (result) => {
        this.miniStatus.hide();
        if (result.success) {
          this.auth.forgetAccount(this.auth.getAccountByEmail(userEmail));
          this.api.setToken('');
          if (this.platform.isDesktop()) {
            const loginType = this.us.getLoginType();
            if (loginType !== null && loginType === 'google') {
              this.google.signOut(true);
            }
          } else if (this.platform.isAndroid()) {
            this.platform.androidHandler().doLogOutGoogle();
          }
          localStorage.removeItem('last_system');
          localStorage.removeItem('last_system_data');
          this.router.navigate(['/login']);
        } else {
          this.messagebox.open({
            buttons: this.messagebox.buttons.Close,
            headerText: this.trans('general.titleError'),
            messageContent: result.error,
            iconType: this.messagebox.iconType.Error
          });
        }
      },
      (error) => {
        this.miniStatus.hide();
      }
    );
  }

  public getOwnedSystems(): TSystemData[] {
    return this.systems.systems.filter((s) => s.amIMaster);
  }
}
