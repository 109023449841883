import { BrowserOptions } from '@sentry/browser';
import { ConfigType } from 'src/api/requests/config';

export interface AppRegion {
  id: string;
  backEndHost: string;
  port: string;
  protocol: string;
  gmtOffsetStart: number;
  gmtOffsetEnd: number;
}

export interface AppRegionWithName extends AppRegion {
  name: string;
}

type RGB = `rgb(${number}, ${number}, ${number})`;
type RGBA = `rgba(${number}, ${number}, ${number}, ${number})`;
type HEX = `#${string}`;

type Color = RGB | RGBA | HEX;

export interface Environment {
  production: boolean;
  regionsEnabled: boolean;
  /** Palaikomi regionai */
  regions: AppRegion[];
  /** Nurodo ar vykdyti loginimą į "console" */
  logToConsole: boolean;
  /** Rodo "Log" mygtuką bei vykdo veiksmų loginimą. Naudinga tik beta testavimams. Production turi būti "false" */
  showLogger: boolean;
  /** Nurodo ar soniniame meniu bus matoma Help dalis */
  showHelp: boolean;
  /** Nurodo ar reikia rodyti versiją */
  showAppVersion: boolean;
  appVersionMajor: number;
  appVersionMinor: number;
  appVersionBuild: number;
  appVersionDate: number;
  appCopyright: string;
  /** Nurodo ar super admin gali peradresuoti modulius tarp regionų */
  deviceTransferEnabled: boolean;
  /** Nurodo ar vartotojai gali peradresuoti modulius tarp v1 ir v2 versijų */
  deviceTransferToAppEnabled: boolean;
  /** Nurodo ar turi veikti ribojimų sistema. (Kamerų kiekis, sistemų kiekis ir t.t.) */
  limitationSystemEnabled: boolean;
  /** Nurodo ar super adminui reikia krauti pilną vartotojų sąrašą */
  loadAllUsersForSU: boolean;
  /** Nurodo ar super adminui reikia krauti pilną sistemų sąrašą */
  loadAllSystemsForSU: boolean;
  /** Nurodo ar galima kurti vartotojus, kurie dar neegzistuoja */
  canAutoCreateAccounts: boolean;
  /** Pasikeitė reikšmė! Dabar čia nurodo ar galima vartotojų registracija. true - negalima, false - galima. Kintamasis kol kas liko su senu pavadinimu. */
  onlySUcanAddRemoveUsers: boolean;
  /** Nurodo ar rodyti google login mygtuka */
  googleLoginSupported: boolean;
  /** Nurodo ar rodyti apple login mygtuka */
  appleLoginSupported: boolean;
  /** Web aplikacijos pavadinimas, kuris bus rodomas naršyklių tab'e. pvz "Protegus" */
  title: string;
  /** Fizinis įmonės adresas */
  address: string;
  /** Įmonės email, kuriuo galima susisiekti */
  email: string;
  /** Įmonės telefonas, kuriuo galima paskambinti */
  phone: string;
  /** Įmonės PVM mokėtojo kodas */
  taxId: string;
  /** Įmonės web puslapis (tai nėra web app!) */
  companyUrl: string;
  /**
   * Nurodo kiek laiko nuo srities aliarminės būsenos bus rodomas "Take action" mygtukas.
   * **Sekundėmis!**
   */
  takeActionButtonDuration: number;
  /** Nurodo ar galima trinti vartotojus esant vartotojų sąraše */
  canDeleteUserFromList: boolean;
  /** Nurodo ar galima trinti sistemą esant sistemų sąraše */
  canDeleteSystemFromList: boolean;
  /** Nurodo ar rodyti Advanced meniu */
  showConfig: boolean;
  googleClientIdWeb: string;
  sentry: Partial<BrowserOptions>;
  /** Pasakom ar siusti js error į api serveri */
  localReport: boolean;
  /** Nurodo ar kompiluoti Dessktop company */
  companyDesktopEnabled: boolean;
  /** Google reCAPTCHA siteKey */
  greSiteKey: string;
  /** WP17 rodomas vardas */
  wifiGateDevice37Name: 'WP17' | 'ZEM-CWI' | 'AP-114';
  /** Pirminė CSS spalva */
  primaryColor: Color;
  /** Antrinė CSS spalva */
  secondaryColor: Color;
  /** Nurodo ar login lange rodyti "Register company" nuorodą */
  companyRegistrationSupported: boolean;
  configTypeOverrides: { type: ConfigType; override: string }[];
  companyPaymentsEnabled: boolean;
}

// eslint-disable-next-line no-shadow
export enum UrlProtocol {
  http = 'http://',
  https = 'https://',
}
