import { Injectable, OnDestroy } from '@angular/core';
import { from, Observable, of, pipe, Subject, Subscription, throwError } from 'rxjs';
import { catchError, delay, map, mergeMap, retry, retryWhen } from 'rxjs/operators';
import http, {
  BodyType,
  defaultOptions,
  RequestMethod,
  RequestOptions,
  RequestOptionsInternal,
  ResponseGeneric,
  ReturnTypeOptionValue,
  internals,
  isFormData,
  isBlob,
} from 'src/api/http';
import * as system from 'src/api/v3/system';
import * as systemNotification from 'src/api/v3/system.notification';
import * as systemThermostat from 'src/api/v3/system.thermostat';
import * as systemSensor from 'src/api/v3/system.sensor';
import * as systemZone from 'src/api/v3/system.zone';
import * as systemArea from 'src/api/v3/system.area';
import * as systemConfig from 'src/api/v3/system.config';
import * as systemPgm from 'src/api/v3/system.pgm';
import * as login from 'src/api/v3/login';
import * as misc from 'src/api/v3/misc';
import * as reaction from 'src/api/v3/reaction';
import * as event from 'src/api/v3/event';
import * as systemCamera from 'src/api/v3/system.camera';
import * as user from 'src/api/v3/user';
import * as company from 'src/api/v3/company';
import * as ip from 'src/api/ip';
import { RegionService } from './region.service';
import { AuthService } from './auth.service';
import { LoggerService } from '../api/logger.service';
import { LocaleService } from './locale.service';
import * as region from 'src/api/v3/region';

// eslint-disable-next-line no-shadow
export enum HttpRequestErrorType {
  Unknown = 0,
  Timeout = 1,
  Unauthorized = 2,
  Forbidden = 6,
  ClientDataMaliformed = 7,
  Offline = 3,
  ClientError = 4,
  ServerError = 5,
}

export class HttpRequestError extends Error {
  public type: HttpRequestErrorType;
  public eid?: string;
  public statusCode?: number;
  public url?: string;

  constructor(type: HttpRequestErrorType, message?: string, innerError?: Error, url?: string) {
    super(message);
    (this as any).cause = innerError;
    this.type = type;
    super.message = message;
    this.url = url;
  }
}

type SimpleHttpHeaders = Record<string, string>;

@Injectable({
  providedIn: 'root',
})
export class RequestService implements OnDestroy {
  public static tag = 'RequestService';
  public system = system;
  public systemConfig = systemConfig;
  public systemNotification = systemNotification;
  public systemThermostat = systemThermostat;
  public systemSensor = systemSensor;
  public systemZone = systemZone;
  public systemArea = systemArea;
  public systemCamera = systemCamera;
  public systemPgm = systemPgm;
  public login = login;
  public misc = misc;
  public reaction = reaction;
  public event = event;
  public user = user;
  public company = company;
  public ip = ip;
  public region = region;
  private httpError = new Subject<HttpRequestError>();
  public onHttpError = this.httpError.asObservable();
  private httpHeaderCache: Record<'true' | 'false', SimpleHttpHeaders> = this._buildCache();
  private tokenSubscription: Subscription;
  private localeSubscription: Subscription;

  // Žemiau apibrėžti metodai skirti lengvam seno kodo panaudojimui
  /**
   * @deprecated
   */ // @ts-ignore
  public post: typeof http.post = (url, body, options) => http.post('/v3/api' + url, body, options);
  /**
   * @deprecated
   */ // @ts-ignore
  public get: typeof http.get = (url, body, options) => http.get('/v3/api' + url, body, options);
  /**
   * @deprecated
   */ // @ts-ignore
  public delete: typeof http.delete = (url, body, options) => http.delete('/v3/api' + url, body, options);

  constructor(private auth: AuthService, private regionService: RegionService, private l: LoggerService, private locale: LocaleService) {
    internals.doRequest = this.doRequest.bind(this) as typeof internals.doRequest;
    this.tokenSubscription = this.auth.onTokenChange.subscribe(() => {
      this.httpHeaderCache = this._buildCache();
    });
    this.localeSubscription = this.locale.onLocaleChange.subscribe(() => {
      this.httpHeaderCache = this._buildCache();
    });
  }

  ngOnDestroy(): void {
    this.tokenSubscription.unsubscribe();
    this.localeSubscription.unsubscribe();
  }

  private _buildCache() {
    return {
      true: this._getHttpHeaders({ auth: true }),
      false: this._getHttpHeaders({ auth: false }),
    };
  }

  private _getHttpHeaders<TOptions extends Pick<RequestOptionsInternal<TReturnTypeValue>, 'auth'>, TReturnTypeValue extends ReturnTypeOptionValue = 'json'>(
    options: TOptions
  ): SimpleHttpHeaders {
    if (options.auth === false) {
      return {
        'Accept-Language': this.locale.locale,
      };
    }
    return {
      Authorization: 'Bearer ' + (this.auth.getToken() ?? ''),
      'My-Language': this.locale.locale,
    };
  }

  public getHttpHeaders<TOptions extends Pick<RequestOptionsInternal<TReturnTypeValue>, 'auth'>, TReturnTypeValue extends ReturnTypeOptionValue = 'json'>(
    options: TOptions
  ): SimpleHttpHeaders {
    const key = options.auth === false || (options.auth === 'ifHas' && !this.auth.hasToken()) ? 'false' : 'true';
    return (this.httpHeaderCache[key] = this.httpHeaderCache[key] ?? this._getHttpHeaders(options));
  }

  private async buildRequestInit<TBody extends BodyType>(options: RequestOptionsInternal<unknown>, body?: TBody): Promise<RequestInit> {
    const headers = new Headers({
      Accept: 'application/json',
      ...this.getHttpHeaders(options),
    });
    const opt: RequestInit = {
      method: options.method,
      headers,
    };

    if (body !== undefined) {
      if (isBlob(body)) {
        opt.body = body;
        headers.set('Content-Type', body.type);
      }
      if (isFormData(body)) {
        opt.body = body;
        headers.set('Content-Type', 'multipart/form-data');
      }
      if (typeof body === 'object') {
        opt.body = JSON.stringify(body);
        headers.set('Content-Type', 'application/json');
      }
      if (typeof body === 'string') {
        opt.body = body;
        headers.set('Content-Type', 'text/plain');
      }
      if (typeof body === 'number') {
        opt.body = body.toString();
        headers.set('Content-Type', 'text/plain');
      }
    }
    return opt;
  }

  private doRequest<
    TResponse extends ResponseGeneric<TOptions, TReturnTypeValue>,
    TBody extends BodyType,
    TOptions extends RequestOptions<TReturnTypeValue>,
    TReturnTypeValue extends ReturnTypeOptionValue = 'json'
  >(input: RequestInfo | URL, body: TBody, options: TOptions, method: RequestMethod): Observable<TResponse> {
    const opt: RequestOptionsInternal<TReturnTypeValue> = {
      ...defaultOptions,
      ...options,
      method,
    };

    const isBodyQuery = body instanceof URLSearchParams;
    const normalizedUrl = input instanceof URL ? input.href : (input as string);
    const withQuery = isBodyQuery && [...(body as URLSearchParams).keys()].length > 0 ? `${normalizedUrl}?${body}` : normalizedUrl;
    const finalUrl = normalizedUrl.startsWith('/') ? `${this.regionService.regionBaseUrl}${withQuery}` : withQuery;

    const finalBody = isBodyQuery ? undefined : body;

    return from(
      this.buildRequestInit(opt, finalBody).then((init) => this.processResponse<any, RequestOptionsInternal<TReturnTypeValue>, TReturnTypeValue>(() => fetch(finalUrl, init), opt, finalUrl))
    ) as Observable<TResponse>;
  }

  private async processResponse<
    TRes extends ResponseGeneric<TOptions, TReturnTypeValue>,
    TOptions extends RequestOptionsInternal<TReturnTypeValue>,
    TReturnTypeValue extends ReturnTypeOptionValue = 'json'
  >(factory: () => Promise<Response>, options: TOptions, url: string): Promise<TRes> {
    let triesLeft = 3;
    let lastError: HttpRequestError;
    while (triesLeft > 0) {
      try {
        const response = await factory();
        if (!response.ok) {
          throw response;
        }
        switch (options.returntype) {
          case undefined:
          case 'json':
            return (await response.json()) as TRes;
          case 'blob':
            return (await response.blob()) as TRes;
          case 'text':
            return (await response.text()) as TRes;
          case 'number':
            return (await response.json()) as TRes;
          case 'void':
            return undefined as TRes;
          default:
            break;
        }
      } catch (e) {
        if (e instanceof Response) {
          lastError = await this.handleError(e, !options.silentOnError, url);
          throw lastError;
        } else {
          lastError = await this.handleError(e, false, url);
          await new Promise((resolve) => setTimeout(resolve, 1500));
        }
      }
      triesLeft--;
    }
    this.httpError.next(lastError);
    throw lastError;
  }

  private async handleError(error: Response | TypeError | DOMException, notify: boolean, url?: string): Promise<HttpRequestError> {
    console.groupCollapsed(`[${RequestService.tag}] Gauta HTTP klaida`, { error });
    console.error(error);
    console.groupEnd();
    const errInfo = new HttpRequestError(HttpRequestErrorType.Unknown, '', error instanceof Error ? error : undefined, url);
    if (error instanceof Response) {
      if (error.status === 401) {
        errInfo.type = HttpRequestErrorType.Unauthorized;
      } else if (error.status === 403) {
        errInfo.type = HttpRequestErrorType.Forbidden;
      } else if (error.status >= 400 && error.status < 500) {
        errInfo.type = HttpRequestErrorType.ClientDataMaliformed;
      } else if (error.status >= 500) {
        errInfo.type = HttpRequestErrorType.ServerError;
      }
      try {
        const data = (await error.json()) as { error?: string; eid?: string };
        errInfo.message = data.error;
        errInfo.eid = data.eid;
      } catch (e) {}
      errInfo.statusCode = error.status;
      if ( notify ) { this.httpError.next(errInfo); }
      return errInfo;
    } else {
      if (!navigator.onLine) {
        errInfo.type = HttpRequestErrorType.Offline;
        if ( notify ) { this.httpError.next(errInfo); }
        return errInfo;
      } else {
        errInfo.type = HttpRequestErrorType.ClientError;
        if ( notify ) { this.httpError.next(errInfo); }
        return errInfo;
      }
    }
    return errInfo as never;
  }
}
