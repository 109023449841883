import { Routes } from "src/api/v3/common";
import { HomeComponent } from "src/app/general/home/home.component";
import { EventsComponent } from "../events/events.component";
import { EventControlComponent } from "../event-control/event-control.component";
import { AreaViewComponent } from "../area-view/area-view.component";
import { ZoneStatusListComponent } from "../zone-status-list/zone-status-list.component";
import { QuickOutputsComponent } from "src/app/general/quick-outputs/quick-outputs.component";
import { QuickCamerasComponent } from "src/app/general/quick-cameras/quick-cameras.component";
import { RequestAssistanceComponent } from "../request-assistance/request-assistance.component";
import { ChangeInstallerComponent } from "../change-installer/change-installer.component";
import { UResolver } from "src/app/guards/uresolve.resolver";
import { HomeConfigurationComponent } from "src/app/general/home-configuration/home-configuration.component";
import { advancedSettingsRoutes } from "src/app/general/advanced-settings/advanced-settings.routes";

export const activeSystemRoutes: Routes = [
  { path: '', component: HomeComponent, data: { animation: 1 } },
  { path: 'events', component: EventsComponent, data: { animation: 3 } },
  { path: 'event-control', component: EventControlComponent, data: { animation: 3 } },
  { path: 'area-view', component: AreaViewComponent, data: { animation: 2 } },
  { path: 'area-view/zone-status-list', component: ZoneStatusListComponent, data: { animation: 3 } },
  { path: 'quick-view-outputs', component: QuickOutputsComponent, data: { animation: 2 } },
  { path: 'quick-view-cameras', component: QuickCamerasComponent, data: { animation: 2 } },
  { path: 'request-assistance', component: RequestAssistanceComponent, data: { animation: 2 } },
  { path: 'change-installer/:installer', component: ChangeInstallerComponent, resolve: { u: UResolver } },
  { path: 'home-configuration', component: HomeConfigurationComponent, data: { animation: 2 }, },
  { path: 'settings', loadChildren: () => import('../settings/system-settings.routes').then(m => m.systemSettingsRoutes) },
  { path: 'configure', children: advancedSettingsRoutes },
];