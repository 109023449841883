import { Injectable, NgZone } from '@angular/core';
import { SystemsService } from './systems.service';
import { ApiRequestService } from './api-request.service';
import { Subject } from 'rxjs';
import { EventsService } from './events.service';
import { LoggerService } from '../api/logger.service';
import { Router } from '@angular/router';
import { GlobalService, gvFamily, virtualAreaDevices } from './global.service';
import { TEventData } from '../models/event-data';
import { RtcMessageEvent, RtcMessagePgm, RtcMessageSystemStatusReload, RtcService, WsPendingSystemMessage } from '../api/rtc/rtc.service';
import { UserService, UserService as USN } from '../api/user.service';
import { AuthService } from '../api/auth.service';
import { PgmService } from '../api/system/pgm.service';
import { PlatformService } from '../api/platform.service';
import { TSystemData } from '../models/system-data';
import { LocatorService } from './locator.service';
import { SystemService } from '../api/system/system.service';
import { PermissionService } from '../api/permission.service';
import { SystemConnectionStatus } from 'src/api/v3/system';
import requests from 'src/api/v3/requests';
import { ErrorResponse } from 'src/api/v3/common';
import { PendingSystemService } from '../api/system/pending-system.service';

@Injectable({
  providedIn: 'root',
})
export class WebSocketService {
  private onSystemStatusChangeSource = new Subject<RtcMessageSystemStatusReload>();
  private onAreaStatusChangeSource = new Subject<void>();
  private onPgmStatusChangeSource = new Subject<any>();
  private onSystemAddedSource = new Subject<TSystemData>();
  private onSystemRemovedSource = new Subject<void>();
  private onEventSource = new Subject<TEventData>();
  private onUserAccessSource = new Subject<void>();
  private onConfigurationSource = new Subject<void>();
  private currentActiveSystem = 0;
  // Praneša apie tai, jog pasikeitė sistemos būsena.
  public onSystemStatusChange = this.onSystemStatusChangeSource.asObservable();
  /** Praneša apie tai, jog pasikeitė srities būsena.
   *
   * @param area Srites duomenų objektas
   * > { __id__: _number_, __system_id__: _number_, __name__: _string_, __queue_no__: _number_, __status__: _number_, __alarm_in_memory__: 0 arba 1 }
   */
  public onAreaStatusChange = this.onAreaStatusChangeSource.asObservable();
  /** Praneša apie tai, jog pasikeitė pgm būsena.
   *
   * @param pgm PGM duomenų objektas
   * > { __system_id__: _number_, __id__: _number_, __queue_no__: _number_, __enabled__: 0 arba 1, __on__: 0 arba 1, __icon_number__: _number_ }
   */
  public onPgmStatusChange = this.onPgmStatusChangeSource.asObservable();
  /**Praneša apie tai, jog aktyviam vartotojui buvo pridėta nauja sistema.
   * @param system Sistemos duomenų objektas
   */
  public onSystemAdded = this.onSystemAddedSource.asObservable();
  /**Praneša apie tai, jog aktyviam vartotojui buvo pašalinta sistema.
   * @param systemId Pašalintos sistemos ID
   */
  public onSystemRemoved = this.onSystemRemovedSource.asObservable();
  /**
   * Praneša apie tai, jog gautas naujas įvykis.
   *
   * @param event Objektas.
   * > { __system_id__: _number_, __event_id__: _number_ }
   */
  public onEventReceived = this.onEventSource.asObservable();
  /**
   * Praneša apie tai, jog gautas vartotojo praėjimo kontrolės įvykis.
   *
   * @param event Objektas.
   * _user_id_ nurodo vartotojo numerį modulyje
   * _protegus_user_id_ nurodo vartotojo ID protegus sistemoje
   * > { __system_id__ : _number_, __present__ : _boolean_, __user_id__ : _number_, __protegus_user_id__ : _number_ }
   */
  public onUserAccessReceived = this.onUserAccessSource.asObservable();

  public onConfigurationEventReceived = this.onConfigurationSource.asObservable();

  private tag = 'Websock';

  private areaStatusSubscription = this.rtc.areaStatus.subscribe((data) => this.internalOnAreaStatus(data));
  private configurationSubscription = this.rtc.configurationEvent.subscribe((data) => this.internalOnConfigurationEvent(data));
  private eventsSubscription = this.rtc.events.subscribe((data) => this.internalOnEvent(data));
  private pgmStatusSubscription = this.rtc.pgm.subscribe((data) => this.internalOnPgmStatus(data));
  private systemStatusSubscription = this.rtc.systemStatusChange.subscribe((data) => this.internalOnSystemStatus(data));
  private accessControlSubscription = this.rtc.userAccessControl.subscribe((data) => this.internalOnUserAccess(data));
  private systemAddedSubscription = this.rtc.userSystem.subscribe((data) => this.internalOnUserSystemEvent(data));
  private newVersionSubscription = this.rtc.versionUpdate.subscribe((data) => this.internalOnNewVersion(data));
  private pendingSystemSubscription = this.rtc.pendingSystems.subscribe((data) => this.internalOnPendingSystems(data));

  private systemChangeSubscription = this.ss.onActiveSystemChange.subscribe((newId) => {
    if ( newId !== this.currentActiveSystem ) {
      this.currentActiveSystem = newId;
      this.updateIntrests();
    }
  });
  private get systemService() { return LocatorService.injector.get(SystemService); }

  constructor(
    private ss: SystemsService,
    private rs: ApiRequestService,
    private us: UserService,
    private zone: NgZone,
    private es: EventsService,
    private l: LoggerService,
    private pgmS: PgmService,
    private router: Router,
    private g: GlobalService,
    private rtc: RtcService,
    private n: USN,
    private auth: AuthService,
    private platform: PlatformService,
    private permissionService: PermissionService,
  ) {
    this.l.log('+', this.tag);
    this.rtc.updateIntrests([]);
    if (this.auth.hasToken()) {
      auth.loadUserData().then(() => {
        this.websockInit();
      });
    }
  }

  // Online/Offline/signalo lygio apdorojimas
  private internalOnSystemStatus(system: RtcMessageSystemStatusReload) {
    this.l.log('', this.tag, system);
    const that = this;
    this.zone.run(() => {
      const found = that.ss.getSystem(system.system_id) || this.systemService.systems.get(system.system_id);
      if (found !== undefined) {
        found.online = system.system_status === 'online';
        found.signalLevel = system.signal_level;
        if ( found.status ) {
          found.status.operator = system.operator;
          found.status.registration_id = system.registration_id;
          found.status.person = system.person;
          found.status.activated_at = system.activated_at;
        } else {
          found.status = {
            activated_at: system.activated_at,
            cellular: null,
            frequency: null,
            id: 0,
            lte_band: null,
            operator: system.operator,
            person: system.person,
            registration_id: system.registration_id,
            signal_level: system.signal_level,
            subscription_status: '',
            system_id: found.id,
            system_status: system.system_status,
          };
        }
        this.systemService.syncChanges(found.id, found, false, system.registration_id, { ...system, id: 0, subscription_status: '', cellular: null, lte_band: null, frequency: null});
      } else {
        this.systemService.syncChanges(null, null, false, system.registration_id, { ...system, id: 0, subscription_status: '', cellular: null, lte_band: null, frequency: null});
      }
      that.onSystemStatusChangeSource.next(system);
    });
  }

  // object tipo srities tolesnis apdorojimas.
  private internalOnAreaStatus(area) {
    this.l.log('', this.tag, area);
    this.zone.run(() => {
      const system = this.ss.getSystem(area.system_id) || this.systemService.systems.get(area.system_id);
      if (system !== undefined) {
        const areaLocal = system.areas.find((a) => a.id === area.id);
        if (areaLocal !== undefined) {
          areaLocal.status = area.status;
          areaLocal.statusTime = area.status_time;
          areaLocal.alarmed = area.alarm_in_memory;
          areaLocal.lastPerson = area.last_person_name;
          areaLocal.alarmTime = area.alarm_time;
          areaLocal.alarmType = area.alarm_type;
          this.ss.saveActiveSystem(area.system_id);
        }
      }
      this.onAreaStatusChangeSource.next(area);
    });
  }
  // object tipo PGM tolesnis apdorojimas.
  private internalOnPgmStatus(pgm: RtcMessagePgm) {
    const that = this;
    this.zone.run(() => {
      that.l.log('PGM duomenys', that.tag, pgm);
      that.pgmS.updatePgmData(pgm);
      that.onPgmStatusChangeSource.next(pgm);
    });
  }

  private internalOnUserSystemEvent(event) {
    if (!this.rs.hasToken()) {
      return;
    }
    if (event.system_data !== undefined) {
      // Prideta nauja sistema.
      if (event.system_data.hwType === '') {
        this.l.log('Sistema priskirta, bet dar nesukurta iki galo.', this.tag);
        return;
      }
      this.zone.run(() => {
        this.l.log('Sistema prideta. ', this.tag, event.system_id);
        const data = this.ss.convertSystemFromRaw(event.system_data);
        if (!data) {
          return;
        }
        this.ss.updateOrAddSystem(data);
        this.systemService.ingestSystem(event.system_data);
        this.systemService.syncChanges(data.id, data, true, event.registration_id);
        this.websockInit();
        this.onSystemAddedSource.next(event.system_data);
      });
    } else if (event.system_reread !== undefined) {
      this.l.log('Gautas sistemos reread. Nuskaitom naujus duomenis.', this.tag, event.system_id);
      this.zone.run(() => {
        this.rs.post('/get-system', { system_id: event.system_id }, true).subscribe(
          (result) => {
            if (result.success) {
              this.l.log('Duomenys gauti. Atnaujinam.', this.tag);
              const data = this.ss.convertSystemFromRaw(result.system);
              if (!data) {
                return;
              }
              this.systemService.ingestSystem(result.system);
              this.ss.updateOrAddSystem(data);
              this.systemService.syncChanges(data.id, data);
            }
          },
          (error) => {
            // nieko nedarom
          }
        );
      });
    } else {
      // Pasalinta sistema.
      this.zone.run(() => {
        this.l.log('Sistema pašalinta. ', this.tag, event.system_id);
        this.ss.removeSystem(event.system_id);
        this.systemService.removeSystemLocally(event.system_id);
        if (!this.ss.hasSystems()) {
          this.router.navigate(this.g.getHomeUrl());
        }
        this.websockInit();
        this.onSystemRemovedSource.next(event.system_id);
      });
    }
  }

  private internalOnEvent(event: RtcMessageEvent) {
    const that = this;
    if (!this.rs.hasToken()) {
      return;
    }
    if ( !this.us.currentUser ) { return; }
    that.l.log('Ivykis', that.tag, event);
    const system = that.ss.getSystem(event.system_id) || this.systemService.systems.get(event.system_id);
    if ( !system ) { return; }
    const found = system.protegus_users.find(pu => pu.id === this.us.currentUser.id );
    if ( !found && !this.us.currentUser.permissions.permissions.events.view ) { return; }
    if ( found && !found.personal_permissions && !this.permissionService.getPermission(found.system_permissions_id)?.permissions.sys_events.view ) { return; }
    if ( found && found.personal_permissions && !found.personal_permissions.sys_events.view ) { return; }
    if ( event.area_queue > 0 ) {
      const systemUsers = this.us.systemDeviceUsers.get(system.id);
      const user = (systemUsers ? [...systemUsers.values()] : []).find(u => u.protegus_user_id === this.us.currentUser.id);
      if ( user ) {
        if ( event.area_queue < 3 ) { // 1 ir 2 stovi bit3 ir bit4
          // eslint-disable-next-line no-bitwise
          if ( user.pgms !== null && user.pgms !== -1 && (user.pgms & (1 << (event.area_queue + 3 - 1))) === 0) { return; }
        } else if ( event.area_queue < 6 ) { // 3, 4 ir 5 stovi bit0, bit1, bit2
          // eslint-disable-next-line no-bitwise
          if ( user.pgms !== null && user.pgms !== -1 && (user.pgms & (1 << (event.area_queue - 2 - 1))) === 0) { return; }
        } else { // visi kiti atitinka savo poziciją
          // eslint-disable-next-line no-bitwise
          if ( user.pgms !== null && user.pgms !== -1 && (user.pgms & (1 << (event.area_queue - 1))) === 0 && ( !virtualAreaDevices.includes(system.deviceId) || (system.deviceId === 0 && !['FC', 'G17F'].includes(system.hwType)) )) { return; }
        }
        /**
         * Palaikom tuos, kurie neatsinaujino
         * Galima trint visą dalį po ||, kai db neliks nei vienos sistemos su deviceId 0
         */
        if ( ![...gvFamily, ...virtualAreaDevices].includes(system.deviceId) || (system.deviceId === 0 && !(['GV17', 'WP17', 'CWI', 'FC', 'G17F'].includes(system.hwType))) ) {
          if ( event.area_event && user.areas.length >= event.area_queue && user.areas[event.area_queue - 1] !== '1' ) { return; }
        }
      }
    }

    requests.system.events.getEvent(event).subscribe(
      (data) => {
        if (data.success) {
          that.l.log('Gavom', that.tag, data);
          const systemFound = that.ss.getSystem(event.system_id) || this.systemService.systems.get(event.system_id);
          if (systemFound === undefined) {
            return;
          }
          const convertedEvent = that.es.convertFromRaw(data.event);
          convertedEvent.systemId = event.system_id;
          systemFound.events.unshift(convertedEvent);
          that.ss.saveActiveSystem(event.system_id);
          that.zone.run(() => {
            that.onEventSource.next(convertedEvent);
          });
        } else {
          that.l.log((data as ErrorResponse).error, that.tag);
        }
      },
      (error) => {
        // nieko nedarom.
      }
    );
  }

  private internalOnUserAccess(event) {
    this.l.log('User access', this.tag, event);
    const that = this;
    this.zone.run(() => {
      that.ss.updateAccessData(event);
      that.onUserAccessSource.next(event);
    });
  }

  private internalOnConfigurationEvent(event) {
    const that = this;
    this.zone.run(() => {
      that.onConfigurationSource.next(event);
    });
  }
  private internalOnNewVersion(event) {
    const that = this;
    this.zone.run(() => {
      that.us.appVersionDetails = event;
      that.g.checkAndNotifyAboutNewVersion();
    });
  }

  public websockInit() {
    this.l.log('Websocket init', this.tag);
    this.rtc.updateIntrests([...this.ss.systems.map((s) => s.id), this.ss.activeSystem?.id]);
  }

  public close() {
    this.rtc.close();
  }

  private updateIntrests() {
    this.rtc.updateIntrests([...this.ss.systems.map((s) => s.id), this.ss.activeSystem?.id]);
  }

  private internalOnPendingSystems(data: WsPendingSystemMessage) {
    if ( data.removed ) {
      (LocatorService.injector.get(PendingSystemService)).removePendingSystem(data.removed);
    }
    if ( data.added ) {
      (LocatorService.injector.get(PendingSystemService)).ingestPendingSystem(data.added);
    }
    let totalSum = data.counts.reduce((c, p) => ({company_id: c.company_id, count: c.count + p.count})).count;
    (LocatorService.injector.get(PendingSystemService)).hasPendingSystems = totalSum > 0;
  }
}
