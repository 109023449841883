<div class='tab_container' (click)="showAlarmWindow()">
    <div class="tab_icon" *ngIf="shouldShowLoader">
        <div>
            <app-loader-dots [height]="'25px'" [width]="'25px'" [isWhite]="true"></app-loader-dots>
        </div>
    </div>
    <div class="tab_icon" *ngIf="!shouldShowLoader && areaItem.alarmType === AlarmType.General" [inlineSVG]="'assets/images/area_alarmed_white.svg'"></div>
    <div class="tab_icon" *ngIf="!shouldShowLoader && areaItem.alarmType === AlarmType.Fire" [inlineSVG]="'assets/images/fire_white.svg'"></div>
    <div class="tab_header" *ngIf="areaItem.alarmType === AlarmType.General">{{trans('systems.statuses.area.alarm')}}</div>
    <div class="tab_header" *ngIf="areaItem.alarmType === AlarmType.Fire">{{trans('systems.statuses.area.alarmFire')}}</div>
    <div class="tab_text">{{trans('systems.buttons.takeAction')}}</div>
</div>