import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { LoadingScreenComponent } from './general/loading-screen/loading-screen.component';
import { HomeComponent } from './general/home/home.component';
import { AuthGuardService } from './guards/auth-guard.service';
import { ProfileComponent } from './general/profile/profile.component';
import { PasswordChangeComponent } from './general/password-change/password-change.component';
import { AccountDeleteComponent } from './general/account-delete/account-delete.component';
import { LanguageListComponent } from './general/language-list/language-list.component';
import { AdvancedSettingsComponent } from './general/advanced-settings/advanced-settings.component';
import { ChangeInstallerComponent } from './system/change-installer/change-installer.component';
import { VersionComponent } from './general/version/version.component';
import { MobileAppComponent } from './mobile-app.component';
import { AppSettings } from 'src/app-settings';
import { UResolver } from './guards/uresolve.resolver';
import { LoginBwcResetGuard } from './guards/login-bwc-reset.guard';
import { LoginBwcActivateGuard } from './guards/login-bwc-activate.guard';
import { DesktopUserLayoutComponent } from './desktop-user/desktop-user-layout/desktop-user-layout.component';
import { CompanyMobileHostComponent } from './company/pages/company-mobile-host/company-mobile-host.component';
import { MobileGuardService } from './guards/mobile-guard.service';
import { CompanyAccountSettingsComponent } from './company/pages/company-account-settings/company-account-settings.component';
import { ActiveSystemComponent } from './system/active-system/active-system.component';
import { Routes } from 'src/api/v3/common';
import { PermissionGuard } from './guards/permission.guard';
import { DeviceInformationComponent } from './system/device-information/device-information.component';
import { AuthGuard } from './guards/auth.guard';
import { SimpleUserGuard } from './guards/simple-user.guard';
import { SimpleUserSystemsResolver } from './resolvers/simple-user-systems.resolver';
import { MasterGuard } from './guards/master.guard';
import { DesktopGuard } from './guards/desktop.guard';
import { GlobalResolverService } from './api/global-resolver.service';
import { routes as helpRoutes } from './general/help/help-container.component';
import { activeSystemRoutes } from './system/active-system/active-system.routes';
import { advancedSettingsRoutes } from './general/advanced-settings/advanced-settings.routes';
import { CompanyDropdownListResolver } from './resolvers/company-drop-list.resolver';

const routes: Routes = [
  AppSettings.companyDesktopEnabled ?
    { path: 'd/company', loadChildren: () => import('./company/company.module').then((m) => m.CompanyModule), } :
    { path: 'd/company', redirectTo: 'company' },

  /** Simple User Desktop */
  { path: 'd', component: DesktopUserLayoutComponent, canActivate: [MasterGuard], data: { guards: [AuthGuard, DesktopGuard, SimpleUserGuard] }, resolve: [SimpleUserSystemsResolver], children: [
    { path: '', component: CompanyMobileHostComponent, children: [
      { path: '', component: MobileAppComponent, children: [
        { path: '', component: HomeComponent, data: { animation: 1, bare: true } },
        { path: 'new-system', loadChildren: () => import('./new-system/new-system.module').then(m => m.NewSystemModule) },
        { path: 'password-change', component: PasswordChangeComponent, },
        { path: 'select-language', component: LanguageListComponent, },
        { path: 'help', children: helpRoutes },
      ] },
    ]},
    { path: 'profile', component: CompanyAccountSettingsComponent, },
    { path: ':deviceId/device', component: CompanyMobileHostComponent, resolve: { u: UResolver }, children: [
      { path: '', component: MobileAppComponent, children: [
        { path: '', component: DeviceInformationComponent, resolve: { c: CompanyDropdownListResolver }, canActivate: [ PermissionGuard ], data: { rule: 'unassigned_devices' } },
        { path: 'advanced-settings', component: AdvancedSettingsComponent, canActivate: [ PermissionGuard ], data: { rule: 'sys_advanced_settings' } },
      ]},
    ]},
    { path: 'version', component: VersionComponent, data: { animation: 5 } },
    { path: ':systemId', component: CompanyMobileHostComponent, children: [
      { path: '', component: MobileAppComponent, resolve: { u: UResolver }, data: { extra: 2 }, children: [
        { path: '', component: ActiveSystemComponent, resolve: { u: UResolver }, children: [
          { path: '', children: activeSystemRoutes },
        ] },
      ]},
    ]},
    // Device setup metu einant į advanced settings turi užkrauti be aktyvios sistemos.
    { path: 'configure', component: MobileAppComponent, children: advancedSettingsRoutes },
  ]},

  { path: 'login', loadChildren: () => import('src/app/login/login.module').then(m => m.LoginModule) },
  { path: 'password/reset/:hash', canActivate: [LoginBwcResetGuard], component: LoadingScreenComponent },
  { path: 'reset/:hash', canActivate: [LoginBwcResetGuard], component: LoadingScreenComponent },
  { path: 'activate/:hash', canActivate: [LoginBwcActivateGuard], component: LoadingScreenComponent },
  { path: 'register/:reghash', canActivate: [LoginBwcActivateGuard], component: LoadingScreenComponent },
  {
    path: '', component: MobileAppComponent, resolve: [ GlobalResolverService ], data: { extra: 3 }, canActivate: [MobileGuardService], children: [
      { path: '', canActivate: [ AuthGuard ] , resolve: [ SimpleUserSystemsResolver ], children: [
        { path: '', component: LoadingScreenComponent },
        { path: 'welcome', component: HomeComponent },
        { path: ':deviceId/device', children: [
          { path: '', component: DeviceInformationComponent, canActivate: [ PermissionGuard ], data: { rule: 'unassigned_devices', animation: 2 } },
          { path: 'advanced-settings', component: AdvancedSettingsComponent, canActivate: [ PermissionGuard ], data: { rule: 'sys_advanced_settings', animation: 3 } },
        ]},
        { path: 'new-system', loadChildren: () => import('./new-system/new-system.module').then(m => m.NewSystemModule) },
        { path: 'profile', component: ProfileComponent, canActivate: [AuthGuardService], },
        { path: 'password-change', component: PasswordChangeComponent, canActivate: [AuthGuardService], },
        { path: 'profile/delete', component: AccountDeleteComponent, canActivate: [AuthGuardService], },
        { path: 'select-language', component: LanguageListComponent, canActivate: [AuthGuardService], },
        { path: 'help', children: helpRoutes },
        { path: 'version', component: VersionComponent, data: { animation: 5 } },
        { path: 'change-installer/:installer', component: ChangeInstallerComponent, },
        { path: 'configure', children: advancedSettingsRoutes, canActivate: [AuthGuardService] },

        /** Bendri path SuperAdmin, CompanyAdmin ir Installer */
        { path: 'a', loadChildren: () => import('./sadmin/admin.routes').then(m => m.adminRoutes) },
        // Šiuo metu nenaudojama funkcija. Instaleriams neateina laiškai su patvirtinimu.
        // { path: 'accept-invitation/:hash', component: CpInstallerAcceptComponent, },

        /** Path iš notification */
        { path: 'system/view/:systemId', component: LoadingScreenComponent },
        { path: 'system/events/:systemId', component: LoadingScreenComponent },
        { path: 'area/view/:areaId', component: LoadingScreenComponent },

        // Turi būti paskutinis
        { path: ':systemId', component: ActiveSystemComponent, canActivate: [AuthGuardService], children: [
          { path: '', children: activeSystemRoutes },
        ]},
      ]},

      { path: '**', component: LoadingScreenComponent },
    ],
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled', anchorScrolling: 'enabled' }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule { }
