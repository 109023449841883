import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { LanguageAware } from '../language-aware';
import { Router } from '@angular/router';
import { MessageboxService } from 'src/app/services/messagebox.service';
import { LoginService } from 'src/app/login/login.service';
import { TBackgroundType } from 'src/app/models/background-types';
import { CommonModule } from '@angular/common';
import { PasswordEditComponent } from 'src/app/components/password-edit/password-edit.component';

@Component({
  selector: 'app-password-change',
  templateUrl: './password-change.component.html',
  styleUrls: ['./password-change.component.scss'],
  standalone: true,
  imports: [CommonModule, PasswordEditComponent],
})
export class PasswordChangeComponent extends LanguageAware implements OnInit {
  private working = false;
  public password = '';
  public newPass = '';
  public repeatedPass = '';

  constructor(
    cdRef: ChangeDetectorRef,
    private router: Router,
    private messagebox: MessageboxService,
    private login: LoginService,
  ) {
    super(cdRef, { backgroundType: TBackgroundType.Gray });
    this.headerBar.showHeader({
      headerText: this.trans('users.buttons.changePassword'),
      backUrl: '/profile',
    });
    this.footerBar.showFooter(this.trans('general.done'), '', true, false);
    const that = this;
    this.footerBar.onButton1Click = () => {
      that.doChange();
    };
  }

  ngOnInit(): void {}

  public doChange(passwordData?: {password: string; newPassword: string}) {
    if (this.working) {
      this.miniStatus.flash();
      return;
    }
    this.working = true;
    const that = this;
    this.miniStatus.show(this.trans('general.pleaseWait'));
    this.api
      .post(
        '/change-password',
        {
          password: passwordData ? passwordData.password : this.password,
          newPassword: passwordData ? passwordData.newPassword : this.newPass,
          repeatPassword: passwordData ? passwordData.newPassword : this.repeatedPass,
        },
        true
      )
      .subscribe(
        (result) => {
          if (result.success) {
            this.login.logout();
            that.router.navigate(['/login']);
          } else {
            that.messagebox.open({
              buttons: that.messagebox.buttons.Close,
              headerText: that.trans('general.titleError'),
              messageContent: result.error,
              iconType: that.messagebox.iconType.Error
            });
          }
          that.miniStatus.hide();
          that.working = false;
        },
        (error) => {
          that.working = false;
          that.miniStatus.hide();
        }
      );
  }
}
