export const isNumeric = (value: any): boolean => !isNaN(Number(value)) && value !== '';

export const useDebouncedFunction = <TArgs extends unknown[], TRet>(fn: (...args: TArgs) => TRet | Promise<TRet>, delay = 1000) => {
  let timeoutId: NodeJS.Timeout | number | undefined;
  const pending: {
    resolve: (value: TRet) => void;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    reject: (reason: any) => void;
  }[] = [];

  const debounced = (...args: TArgs) =>
    new Promise<TRet>((resolve, reject) => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
      timeoutId = setTimeout(() => {
        let usedPending: typeof pending;
        Promise.resolve(fn(...args))
          .finally(() => {
            usedPending = [...pending];
            pending.length = 0;
          })
          .then(
            (value) => usedPending.forEach(({ resolve: res }) => res(value)),
            (reason) => usedPending.forEach(({ reject: rej }) => rej(reason))
          );
        timeoutId = undefined;
      }, delay);
      pending.push({ resolve, reject });
    });

  const instant = (...args: TArgs) =>
    new Promise<TRet>((resolve, reject) => {
      pending.push({ resolve, reject });
      if (timeoutId) {
        clearTimeout(timeoutId);
        timeoutId = undefined;
      } else if (pending.length > 1) {
        return;
      }
      let usedPending: typeof pending;
      Promise.resolve(fn.apply(this, args))
        .finally(() => {
          usedPending = [...pending];
          pending.length = 0;
        })
        .then(
          (value) => usedPending.forEach(({ resolve: res }) => res(value)),
          (reason) => usedPending.forEach(({ reject: rej }) => rej(reason))
        );
    });

  const cancel = () => {
    if (timeoutId) {
      clearTimeout(timeoutId);
      timeoutId = undefined;
    }
    const error = new Error('Cancelled');
    const usedPending = [...pending];
    pending.length = 0;
    usedPending.forEach(({ reject }) => reject(error));
    return Promise.reject(error);
  };

  return [delay === 0 ? instant : debounced, instant, cancel];
};
