<div class="nv_container {{showUnderline ? 'nv_container_under' : ''}}" (click)="doClick()">
    <div class="nv_disabled_screen" *ngIf="!isEnabled"></div>
    <div class="nv_icon" *ngIf="icon !== ''"><div [inlineSVG]="icon"></div></div>
    <div class="nv_name">{{nameText}}<span *ngIf="showEdit" class="padding_l16" (click)="doEditClick($event)"><svg width="17" height="15" viewBox="0 0 16.571 14.727"><path d="M11.582,2.394l2.595,2.595a.281.281,0,0,1,0,.4L7.894,11.669l-2.67.3a.56.56,0,0,1-.619-.619l.3-2.67,6.283-6.283A.281.281,0,0,1,11.582,2.394Zm4.66-.659-1.4-1.4a1.125,1.125,0,0,0-1.588,0L12.232,1.35a.281.281,0,0,0,0,.4l2.595,2.595a.281.281,0,0,0,.4,0l1.018-1.018a1.125,1.125,0,0,0,0-1.588Zm-5.2,8.225v2.929H1.841V3.683H8.452a.354.354,0,0,0,.245-.1L9.847,2.432a.345.345,0,0,0-.245-.59H1.381A1.381,1.381,0,0,0,0,3.223V13.349A1.381,1.381,0,0,0,1.381,14.73H11.507a1.381,1.381,0,0,0,1.381-1.381V8.81a.346.346,0,0,0-.59-.245L11.148,9.716A.354.354,0,0,0,11.047,9.96Z" transform="translate(0 -0.004)" fill="#666"/></svg></span></div>
    <div class="nv_value">
        <div class="nv_value_align">
            <ng-content *ngIf="!showArrow"></ng-content>
            <ng-container *ngIf="showArrow">
                <svg class="nvarrow" viewBox="0 0 8.402 14.697">
                    <path d="M13.783,13.54l5.561-5.557A1.05,1.05,0,1,0,17.857,6.5l-6.3,6.3a1.048,1.048,0,0,0-.031,1.448l6.327,6.34A1.05,1.05,0,0,0,19.34,19.1Z" transform="translate(19.653 20.891) rotate(180)"/>
                </svg>
            </ng-container>
        </div>
    </div>
    <div class="nv_error"><app-form-error *ngIf="showError">{{errorText}}</app-form-error></div>
</div>