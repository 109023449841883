import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { PermissionMatchCriteria } from 'src/api/v3/common';
import { PlatformService } from '../api/platform.service';
import { UserService } from '../api/user.service';
import { GlobalService } from '../services/global.service';
import { Permission } from 'src/api/v3/permission';

/**
 * Naudojant šį Guard būtina nurodyti ir "data" su nurodyta taisykle "rule".
 */
@Injectable({
  providedIn: 'root'
})
export class PermissionGuard  {
  constructor(
    private us: UserService,
    private pl: PlatformService,
    private router: Router,
    private g: GlobalService,
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const accessLevel: keyof Permission = route.data.accessLevel ?? 'view';
    if ( !this.us.currentUser ) {
      this.us.loadUserDataFromStorage();
    }
    let result = true;
    try {
      if ( route.data.rule !== undefined ) {
        result = this.us.currentUser?.permissions.permissions[route.data.rule][accessLevel] ?? false;
      } else if ( route.data.rules !== undefined ) {
        const matchCriteria: PermissionMatchCriteria = route.data.permissionMatch ?? PermissionMatchCriteria.ShouldMatchAll;
        result = matchCriteria === PermissionMatchCriteria.ShouldMatchAll;
        route.data.rules.forEach((r, i) => {
          const thisRuleAccessLevel: keyof Permission = route.data.accessLevels && route.data.accessLevels.length >= (i + 1) ? route.data.accessLevels[i] : 'view';
          const thisIsGood = this.us.currentUser?.permissions.permissions[r][thisRuleAccessLevel] ?? false;
          result = matchCriteria === PermissionMatchCriteria.AtLeastOneMatches ? result || thisIsGood : result && thisIsGood;
        });
        return result;
      } else {
        return true;
      }
    } catch (e) {
      console.error(e);
    }
    if ( !result && !state.root.component ) { // Fail pirminio krovimo metu (per naršyklės URL)
      return this.router.createUrlTree(this.g.getHomeUrl());
    }
    return result;
  }
}
