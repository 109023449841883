import { Component, OnInit, Input, ViewEncapsulation, OnDestroy } from '@angular/core';
import { PgmData } from 'src/app/models/pgm';
import { LoggerService } from 'src/app/api/logger.service';
import { WebSocketService } from 'src/app/services/websocket.service';
import { LanguageService } from 'src/app/services/language.service';
import { PopupService } from 'src/app/services/popup.service';
import { ActivatedRoute, Router } from '@angular/router';
import { TEditableComponent } from 'src/app/models/editable-component';
import { EditSystemService } from 'src/app/services/edit-system.service';
import { ElementResizePopupComponent } from 'src/app/popups/element-resize-popup/element-resize-popup.component';
import { PgmService } from 'src/app/api/system/pgm.service';
import { SystemsService } from 'src/app/services/systems.service';
import { ToasterService } from 'src/app/services/toaster.service';
import { CommonModule } from '@angular/common';
import { LoaderDotsComponent } from '../loader-dots/loader-dots.component';
import { NgCircleProgressModule } from 'ng-circle-progress';
import { LongPressMenuComponent } from 'src/app/general/long-press-menu/long-press-menu.component';
import { InlineSVGModule } from 'ng-inline-svg-w';
import { GlobalService } from 'src/app/services/global.service';

@Component({
  selector: 'app-pgm-button',
  templateUrl: './pgm-button.component.html',
  styleUrls: ['./pgm-button.component.scss'],
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [CommonModule, LoaderDotsComponent, NgCircleProgressModule, LongPressMenuComponent, InlineSVGModule],
})
export class PgmButtonComponent implements OnInit, OnDestroy {
  @Input() nameTextColor = '';
  @Input() buttonType: 'icon' | 'flat' | 'switch' = 'icon';
  public currentProgress = -1;
  public secondsLeft = 0;
  @Input() pgm: PgmData = null;
  private countDownTimer = null;
  private tag = 'PGM';
  private pgmListener = null;
  public enableComponents = true;
  public buttonSize = '11';

  constructor(
    private l: LoggerService,
    public pgms: PgmService,
    ws: WebSocketService,
    public lang: LanguageService,
    public pp: PopupService,
    private router: Router,
    private es: EditSystemService,
    private ss: SystemsService,
    private toaster: ToasterService,
    private ar: ActivatedRoute,
    private g: GlobalService,
  ) {
    const that = this;
    this.pgmListener = ws.onPgmStatusChange.subscribe(() => {
      that.runTimer();
    });
  }

  ngOnInit(): void {
    this.tag = 'PGM-' + this.pgm.queue_no;
    this.runTimer();
    const setting = localStorage.getItem('home_pgm_button_size_' + this.pgm.id);
    if (setting !== null) {
      this.buttonSize = setting;
    }
    this.enableComponents = !this.pgms.isPgmInQueue(this.pgm.id);
    const that = this;
    if (!this.enableComponents) {
      this.pgms.setNewCallbackFunction(this.pgm.id, (pgm) => {
        that.l.log('Gautas ACK', that.tag, { result: pgm });
        that.enableComponents = true;
        that.pgms.updatePgmData(pgm);
      });
    }
  }

  ngOnDestroy() {
    if (this.countDownTimer !== null) {
      clearInterval(this.countDownTimer);
      this.countDownTimer = null;
    }
    if (this.pgmListener !== null) {
      this.pgmListener.unsubscribe();
      this.pgmListener = null;
    }
  }

  public showTimer(): boolean {
    if (this.pgm.type !== 'pulse') {
      return false;
    }
    if (this.pgm.pulse_activated_at === 0) {
      return false;
    }
    // this.l.log('Show timer?', 'PgmButton-' + this.pgm.queue_no, {
    //   current: new Date().getTime(),
    //   pulse: this.pgm.pulse_activated_at,
    //   diff: new Date().getTime() - this.pgm.pulse_activated_at,
    //   pulseTime: this.pgm.pulseTime
    // });
    const result = new Date().getTime() - this.pgm.pulse_activated_at < this.pgm.pulseTime * 1000 || this.currentProgress > 0;
    // this.l.log('Rezultatas ', 'PgmButton-' + this.pgm.queue_no, result);
    return result;
  }

  public onPgmClick() {
    if (!this.enableComponents) {
      return;
    }
    if ( !this.ss.currentUserPermissions?.permissions.sys_outputs.execute ) {
      this.toaster.postError(this.lang.get('systems.errors.operationNotAllowed'));
      return;
    }
    this.l.log('Spaudžiamas PGM', this.tag);
    this.enableComponents = false;
    const that = this;
    this.pgms.togglePgm(this.pgm.id, this.pgm.queue_no, (pgm) => {
      that.l.log('Gautas ACK', that.tag, { result: pgm });
      that.enableComponents = true;
      that.pgms.updatePgmData(pgm);
    });
  }

  private runTimer() {
    if (this.showTimer()) {
      this.secondsLeft = this.pgm.pulseTime - Math.floor((new Date().getTime() - this.pgm.pulse_activated_at) / 1000);
      this.l.log('Liko s:', 'PgmButton-' + this.pgm.queue_no, this.secondsLeft);
      const that = this;
      this.tickTimerProgress();
      if ( this.countDownTimer ) {
        clearInterval(this.countDownTimer);
      }
      this.countDownTimer = setInterval(() => {
        that.tickTimerProgress();
        // this.l.log('', that.tag, that.currentProgress);
      }, 1000);
    }
  }

  private tickTimerProgress() {
    if (this.secondsLeft <= 0) {
      clearInterval(this.countDownTimer);
      this.countDownTimer = null;
    }
    this.secondsLeft--;
    this.currentProgress = Math.floor((this.secondsLeft / this.pgm.pulseTime) * 100);
  }

  public editOutput() {
    this.es.beginComponentEdit(TEditableComponent.Output, this.pgm.id, this.pgm);
    this.router.navigate([...this.g.getHomeUrl(), 'settings', 'outputs', 'system-pgm']);
  }

  public showResizePopup() {
    this.pp.openPopup(ElementResizePopupComponent, {
      paramSetter: (p) => {
        p.elementId = this.pgm.id;
        p.elementName = this.pgm.name;
        p.sizeSelected.subscribe(($event) => {
          this.setNewSize($event);
        });
      }
    });
  }

  public setNewSize(newSize: any) {
    this.buttonSize = newSize.width.toString() + newSize.height.toString();
    localStorage.setItem('home_pgm_button_size_' + this.pgm.id, this.buttonSize);
  }
}
