import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { RegionSlim } from 'src/api/v3/region';
import { AppSettings } from 'src/app-settings';
import { AppRegion } from 'src/environments/environment.types';
import { LoggerService } from '../api/logger.service';
import { PlatformService } from './platform.service';
import * as regionApi from 'src/api/v3/region';
import { Region } from 'src/api/v3/common';

@Injectable({
  providedIn: 'root',
})
export class RegionService {
  private activeRegion: AppRegion;
  public activeRegionName: string;
  public quickEditAllowed = true;
  private _regionChangedSubject = new Subject<AppRegion>();
  public onRegionChanged = this._regionChangedSubject.asObservable();
  public regionsSlim: RegionSlim[] = [];
  private regionInEditIdChange = new Subject<number>();
  public onRegionInEditIdChange = this.regionInEditIdChange.asObservable();

  constructor(private logger: LoggerService, private ps: PlatformService) {
    this.activeRegion = AppSettings.regions[0];
    this.autoSelectRegion();
    if (!AppSettings.production) {
      (window as any).region = this;
    }
  }

  public get ActiveRegion(): AppRegion {
    return this.activeRegion;
  }

  public get regionId(): string {
    return this.activeRegion.id ?? '';
  }

  public get currentRegion(): AppRegion | string {
    return this.regionId ? this.regionId : this.activeRegion;
  }

  public get regionBaseUrl(): string {
    return this.activeRegion.protocol + this.activeRegion.backEndHost + (this.activeRegion.port ? ':' + this.activeRegion.port : '');
  }

  /**
   * @deprecated
   */
  public get regionApiUrl(): string {
    return this.regionBaseUrl + '/v3/api';
  }

  public autoSelectRegion(useCustomIfAvailable = true) {
    const offset = new Date().getTimezoneOffset() * -1;
    const customRegion = localStorage.getItem('currentRegion') ? (JSON.parse(localStorage.getItem('currentRegion') ?? 'null') as AppRegion | string) : null;
    if (useCustomIfAvailable && customRegion !== null) {
      this.useRegion(customRegion);
      return;
    }
    for (const iRegion of AppSettings.regions) {
      if (iRegion.gmtOffsetStart === iRegion.gmtOffsetEnd) {
        this.useRegion(iRegion);
        break;
      } else if (iRegion.gmtOffsetStart > iRegion.gmtOffsetEnd) {
        if ((offset >= iRegion.gmtOffsetStart && offset <= 840) || (offset >= -720 && offset <= iRegion.gmtOffsetEnd)) {
          this.useRegion(iRegion);
          break;
        }
      } else if (offset >= iRegion.gmtOffsetStart && offset <= iRegion.gmtOffsetEnd) {
        this.useRegion(iRegion);
        break;
      }
    }
  }

  public lockRegion() {
    localStorage.setItem('currentRegion', JSON.stringify(this.currentRegion));
  }

  public unlockRegion() {
    localStorage.removeItem('currentRegion');
    this.autoSelectRegion();
  }

  public setActiveRegionValue(region: AppRegion) {
    this.activeRegion = region;
  }

  public useRegion(region: AppRegion | string) {
    const prevousRegion = this.activeRegion;
    const initialRegion = region;
    localStorage.setItem('currentRegion', JSON.stringify(region));
    if (typeof region === 'string') {
      const resolvedRegion = AppSettings.regions.find((r) => r.id === region);
      if (!resolvedRegion) {
        this.logger.log('Region not found: ' + initialRegion, 'Region');
        return;
      }
      region = resolvedRegion;
    } else {
      const resolvedRegion = AppSettings.regions.find((r) => r.id === (region as AppRegion).id);
      if ( !resolvedRegion ) {
        localStorage.removeItem('currentRegion');
        this.autoSelectRegion();
        return;
      }
      region = resolvedRegion;
    }
    const isSameRegion = region.id === prevousRegion.id && !!region.id;
    this.logger.log('Nustatomas regionas: ' + region.backEndHost, 'Region', { isSameRegion, region });
    this.activeRegion = region;
    if (this.ps.isAndroid()) {
      this.ps.androidHandler().setRegion(region.backEndHost);
    } else if (this.ps.isApple()) {
      this.ps.appleHandler().setRegion.postMessage(region.backEndHost);
    }
    if (!isSameRegion) { this._regionChangedSubject.next(region); }
  }

  public getNameForRegion(region: AppRegion): string {
    const foundRegion = this.regionsSlim.find(r => region.protocol + region.backEndHost === r.api_host);
    return foundRegion ? foundRegion.name : region.backEndHost;
  }

  public async loadRegions() {
    if(this.regionsSlim.length > 0) { return; }
    const result = await regionApi.getRegions().toPromise();
    this.regionsSlim = result.regions;
  }

  public updateActiveRegionName(userRegions: Region[]) {
    if(userRegions.length === 0) {
      this.activeRegionName = this.getNameForRegion(this.ActiveRegion);
      return;
    }
    const region = userRegions.find(iRegion => iRegion.api_host_to_use === this.ActiveRegion.backEndHost );
    if(!region) {
      this.activeRegionName = this.getNameForRegion(this.ActiveRegion);
      return;
    }
    this.activeRegionName = region.name;
  }

  public changeRegionInEditId(value: number) {
    this.regionInEditIdChange.next(value);
  }
}
