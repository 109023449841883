import { Injectable } from '@angular/core';

import { LoggerService } from '../api/logger.service';
import { UserService } from '../api/user.service';
import { SystemsService } from '../services/systems.service';
import { ApiRequestService } from '../services/api-request.service';

/**
 * Užkrauna arba atnaujina paprasto vartotojo (tas kuris negali matyti visų sistemų) sistemas.
 * Jeigu vykdomas "užkrovimas" tai UI bus blokuojamas, kol nebus gautos sistemos.
 * Jeigu yra bent viena sistema (tai gali būti "last system"), tuomet neblokuojam UI ir naujinam tyliai.
 */

@Injectable({
    providedIn: 'root',
})
export class SimpleUserSystemsResolver  {
    constructor(
        private systemsService: SystemsService,
        private l: LoggerService,
        private userService: UserService,
        private api: ApiRequestService,
    ) {}

    async resolve(): Promise<void> {
        if ( this.userService.currentUser.permissions.permissions.systems.view || this.userService.currentUser.permissions.permissions.unassigned_devices.view ) {
            return;
        }
        if ( !this.systemsService.hasSystems() ) {
            const lastSystemData = this.systemsService.getLastSystemData();
            if ( lastSystemData !== null ) {
                this.systemsService.activeSystem = lastSystemData;
                if ( this.systemsService.activeSystem.eventConfiguration === undefined ) {
                    this.systemsService.activeSystem.eventConfiguration = [];
                }
                this.systemsService.systems.push(this.systemsService.activeSystem);
                this.l.log('Iš local storage užkrauta sistema: ', 'SimpleUserSystemsResolver', lastSystemData);
            }
        }
        if ( this.systemsService.hasSystems() ) {
            this.l.log('Atnaujinam async', 'SimpleUserSystemsResolver');
            this.systemsService.loadSystems(false);
        } else {
            this.l.log('Kraunam sync.', 'SimpleUserSystemsResolver');
            if(this.userService.currentUser.language !== localStorage.getItem('lang')) {
                this.l.log('Nustatome kalbą, kad atitiktų vartotojo pasirinkimą.', 'SimpleUserSystemsResolver');
                this.api.post('/language/set', { language: this.userService.currentUser.language }, true).subscribe(async () => {
                    await new Promise<void>((resolve) => this.systemsService.loadSystems(false, resolve));
                });
            } else {
                await new Promise<void>((resolve) => this.systemsService.loadSystems(false, resolve));
            }
            this.l.log('Baigem sync.', 'SimpleUserSystemsResolver');
        }
    }
}
