import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { Router, RouterLink, RouterLinkActive } from '@angular/router';
import { GlobalResolverService } from 'src/app/api/global-resolver.service';
import { companyRootRoute } from 'src/app/services/global.service';

@Component({
  selector: 'app-company-header-button',
  templateUrl: './company-header-button.component.html',
  styleUrls: ['./company-header-button.component.scss'],
  standalone: true,
  imports: [CommonModule, RouterLink, RouterLinkActive],
})
export class CompanyHeaderButtonComponent implements OnInit {
  @Input() icon: string;
  @Input() label: string;
  @Input() commands: any[] | string;
  @Input() rootCommands?: any[] | string;
  @Input() exact: boolean;
  @Input() active: boolean;
  @Input() sectionVisible: boolean;
  public get resolvedCommands(): any[] | string {
    if (this.rootCommands) { return this.rootCommands; }
    if (typeof this.commands === 'string') {
      return [companyRootRoute, this.commands];
    }
    return [companyRootRoute, ...this.commands];
  }

  public isLoading = false;

  public get iconCss(): string {
    return `url('${this.icon}')`;
  }
  constructor(private router: Router, private gr: GlobalResolverService) {}

  public onClick(): void {
    this.isLoading = true;
    this.gr.waitForNavigationEnd().then(() => (this.isLoading = false));
  }

  ngOnInit(): void {}
}
