<div>
    <div class="padding16">
        <app-text-edit id='pr-email' [isReadOnly]='true' [value]='userToUse.email' [inputType]="'email'">{{trans('auth.form.email')}}</app-text-edit>
    </div>
    <div class="white_round padding16">
        <app-text-edit id='pr-name' [value]='userToUse.name' (valueChanged)="userToUse.name = $event; userDataChanged = true" [isReadOnly]="!myOwnProfile()">{{trans('users.labels.nameProfile')}}</app-text-edit>
        <app-text-edit id='pr-phone' [value]='userToUse.phone' (valueChanged)="userToUse.phone = $event; userDataChanged = true" [isReadOnly]="!myOwnProfile()">{{trans('users.labels.phoneNumber')}}</app-text-edit>
        <app-dropdown [label]="trans('users.labels.country')" [items]="countryList" [(value)]="userToUse.country" [disabled]="!myOwnProfile()" (valueChange)="userDataChanged = true"></app-dropdown>
        <app-name-value *ngIf="myOwnProfile()" [nameText]="trans('users.labels.dateTimeFormat')" (clicked)="changeDateFormat()">{{dateFormatText}}</app-name-value>
        <app-button-single-line-arrow *ngIf='myOwnProfile() && (us.currentUser.permissions?.role !== PermissionRole.Installer || us.currentUser.company_id === 0)' id='pr-pass' (click)='goChangePass()'>{{trans('users.buttons.changePassword')}}</app-button-single-line-arrow>
        <app-button-single-line-arrow *ngIf='myOwnProfile() && us.currentUser.permissions?.role !== PermissionRole.SuperAdmin' id='pr-download' (click)='doDownloadAccount()'>{{trans('users.buttons.downloadAccount')}}</app-button-single-line-arrow>
        <app-button-single-line-arrow *ngIf='myOwnProfile() && us.currentUser.permissions?.role !== PermissionRole.SuperAdmin && (us.currentUser.permissions?.role !== PermissionRole.Installer || us.currentUser.company_id === 0)' id='pr-delete' (click)='goDeleteAccount()'>{{trans('users.labels.deleteProfile')}}</app-button-single-line-arrow>
    </div>
    <div class="separator16"></div>
    <div class="white_round" id='pr-lang'>
        <app-button-single-line-arrow *ngIf='myOwnProfile()' (click)='languageClick()'>{{trans('systems.titles.language')}} (<span id='pr-l-subcode'>{{getLangSubCode()}}</span>)</app-button-single-line-arrow>
    </div>
</div>