import http from '../http';
import { BasicSuccessResponse, objectToParams } from './common';
import { Tag } from './tag';

export type PermissionRule = {
  id: number;
  name: string;
  description: string;
  parent_id: number | null;
  permissions: PermissionList;
  company_id: number;
  role: PermissionRole;
  default: boolean;
  assigned_users?: number;
  companyName: string | null;
  tags: Tag[];
};

export type Permission = {
  create: boolean;
  view: boolean;
  edit: boolean;
  delete: boolean;
  execute: boolean;
};

export type PermissionList = {
  company_settings: Permission;
  ipcom_settings: Permission;
  reactions: Permission;
  roles: Permission;
  systems: Permission;
  events: Permission;
  tags: Permission;
  users: Permission;
  support_tickets: Permission;
  global_settings: Permission;
  panic_settings: Permission;
  regions: Permission;
  impersonation: Permission;
  transfer_company: Permission;
  transfer_device: Permission;
  sys_advanced_settings: Permission;
  sys_information: Permission;
  sys_areas: Permission;
  sys_zones: Permission;
  sys_outputs: Permission;
  sys_sensors: Permission;
  sys_thermostats: Permission;
  sys_users: Permission;
  sys_receiver: Permission;
  sys_synchronisation: Permission;
  sys_transfer: Permission;
  sys_device_transfer: Permission;
  sys_reset_sensors: Permission;
  sys_assistance: Permission;
  sys_personal_permissions: Permission;
  sys_transfer_to_company: Permission;
  sys_notifications: Permission;
  sys_cameras: Permission;
  sys_events: Permission;
  system: Permission;
  dev_setup_templates: Permission;
  unassigned_devices: Permission;
  pending_systems: Permission;
  company_payments: Permission;
  system_notes: Permission;
  monitoring_stations: Permission;
};

export enum PermissionRole {
  SuperAdmin = 1,
  Company = 2,
  Installer = 3,
  GeneralUser = 4,
  SystemMaster = 5,
  SystemUser = 6,
};

type GetPermissionRulesRequest = {
    searchPhrase?: string;
    searchFields?: string[];
    paginationPage?: number;
    include_statistics?: boolean;
} | { forList: boolean };
type GetPermissionRuleRequest = { id: number };
type SavePermissionRuleRequest = PermissionRule;
type GetPermissionRulesResponse = BasicSuccessResponse & {
    rules: {
        total: number;
        per_page: number;
        current_page: number;
        last_page: number;
        next_page_url: string | null;
        prev_page_url: string | null;
        from: number;
        to: number;
        data: PermissionRule[];
    };
    built_in_default: PermissionRule[];
};
type GetPermissionRulesForListResponse = BasicSuccessResponse & {
    rules: {
        data: Pick<PermissionRule, 'id' | 'name' | 'role' | 'company_id' | 'companyName'>[];
    };
    built_in_default: PermissionRule[];
};
export type AddPermissionRuleResponse = BasicSuccessResponse & { id: number };
type GetPermissionRuleResponse = BasicSuccessResponse & { rule: PermissionRule };

const getPermissionRule = (req: GetPermissionRuleRequest) => http.get<GetPermissionRuleResponse, URLSearchParams>('/v3/api/permission-rule', objectToParams(req));
const addPermissionRule = (req: SavePermissionRuleRequest) => http.post<AddPermissionRuleResponse, SavePermissionRuleRequest>('/v3/api/permission-rule', req);
const savePermissionRule = (req: SavePermissionRuleRequest) => http.put<BasicSuccessResponse, SavePermissionRuleRequest>('/v3/api/permission-rule', req);
const deletePermissionRule = (req: GetPermissionRuleRequest) => http.delete<BasicSuccessResponse, URLSearchParams>('/v3/api/permission-rule', objectToParams(req));
const getPermissionRules = <T extends GetPermissionRulesRequest>(req: T) => http.get<T extends { forList: boolean } ? GetPermissionRulesForListResponse : GetPermissionRulesResponse, URLSearchParams>('/v3/api/permission-rules', objectToParams(req));

export default {
    addPermissionRule,
    getPermissionRule,
    getPermissionRules,
    savePermissionRule,
    deletePermissionRule,
};
