import { Component } from "@angular/core";
import { ChildrenOutletContexts } from "@angular/router";
import { pageTransitionAnimation } from "src/animations";
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

/**
 * Klasė, kurią reikia naudoti, kai norima turėti animacijas tarp langų.
 * Komponento template dalyje turi būti `router-outlet`.
 * Animacijos aprašymas vykdomas pridedant `[@pageTransition]="getPageTransitionAnimationData()"` į `div` tag'o parametrus ( div tago viduje **turi būti** `router-outlet`).
 * 
 * Konstruktoriuje reikia naudoti `contexts: ChildrenOutletContexts` ir iškviesti `super(contexts)`.
 */
@Component({
  template: ``,
  animations: [pageTransitionAnimation],
  imports: [BrowserAnimationsModule],
  standalone: true,
})
export class RouterWithTransition {
  constructor(private contexts: ChildrenOutletContexts) { }
  public getPageTransitionAnimationData(): string {
    const primaryContext = this.contexts.getContext('primary');
    return primaryContext?.route?.snapshot?.data?.animation ?? primaryContext?.route?.snapshot?.firstChild?.data?.animation ?? primaryContext?.route?.snapshot?.routeConfig?.path ?? 'default';
  }
}