import { Injectable, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { PendingSystem } from 'src/api/v3/system';
import { AuthService } from '../auth.service';
import { DataTableGetter } from 'src/app/company/components/data-table/data-table.component';
import requests from 'src/api/v3/requests';
import { LocatorService } from 'src/app/services/locator.service';
import { ToasterService } from 'src/app/services/toaster.service';
import { ErrorResponse } from 'src/api/v3/common';

@Injectable({
  providedIn: 'root'
})
export class PendingSystemService implements OnDestroy {
  private readonly tag = 'PendingSystemsService';
  private readonly systems = new Map<number, PendingSystem>();
  private cleanupSubscribtion: Subscription;
  public hasPendingSystems = false;

  public get systemCount() { return this.systems.size; }

  constructor(
    private auth: AuthService,
  ) {
    this.cleanupSubscribtion = this.auth.onAccountOrRegionChnage.subscribe(() => {
      this.systems.clear();
      this.hasPendingSystems = false;
    });
  }

  ngOnDestroy(): void {
    this.cleanupSubscribtion?.unsubscribe();
  }

  public ingestPendingSystem(item: PendingSystem) {
    if ( this.systems.has(item.id) ) { return; }
    this.systems.set(item.id, item);
    this.hasPendingSystems = true;
  }

  public removePendingSystem(item: number) {
    if ( !this.systems.has(item) ) { return; }
    this.systems.delete(item);
    this.hasPendingSystems = this.systems.size > 0;
  }

  public getPendingSystem(id: number): PendingSystem | undefined {
    return this.systems.get(id);
  }

  public getPendingSystemsGetter(): DataTableGetter<PendingSystem> {
    return async (current, columns, more) => {
      if (!more && current <= this.systems.size) {
        return [...this.systems.values()];
      }
      const result = await requests.system.getPendingSystems().toPromise();
      if ( result.success ) {
        if ( current === 0 ) {
          this.systems.clear();
        }
        for ( const iSystem of result.list ) {
          this.ingestPendingSystem(iSystem);
        }
      } else {
        const toaster = LocatorService.injector.get(ToasterService);
        toaster.postError((result as ErrorResponse).error);
      }
      return [...this.systems.values()];
    };
  }
}
