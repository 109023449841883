import { AfterViewChecked, ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NavigationEnd, Router, RouterLink, RouterOutlet } from '@angular/router';
import { HeaderService } from 'src/app/services/header.service';
import { LanguageAware } from 'src/app/general/language-aware';
import { NewSystemService } from 'src/app/services/new-system.service';
import { LocatorService } from 'src/app/services/locator.service';
import { Subscription } from 'rxjs';
import { BreakpointObserver } from '@angular/cdk/layout';
import { distinctUntilChanged } from 'rxjs/operators';
import { AppSettings } from 'src/app-settings';
import { PopupService } from 'src/app/services/popup.service';
import { HistorySystemsPopupComponent } from 'src/app/popups/history-systems-popup/history-systems-popup.component';
import { CommonModule } from '@angular/common';
import { CpSystemListItemComponent } from 'src/app/mobile-company/cp-system-list-item/cp-system-list-item.component';
import { ButtonSingleLineArrowComponent } from 'src/app/ui/button-single-line-arrow/button-single-line-arrow.component';
import { CompanyHeaderComponent } from 'src/app/ui/company-header/company-header.component';
import { PortalComponent } from 'src/app/teleport/portal/portal.component';
import { FooterComponent } from 'src/app/general/footer/footer.component';
import { InlineSVGModule } from 'ng-inline-svg-w';

@Component({
  selector: 'app-desktop-user-layout',
  templateUrl: './desktop-user-layout.component.html',
  styleUrls: ['./desktop-user-layout.component.scss'],
  providers: [],
  standalone: true,
  imports: [CommonModule, CpSystemListItemComponent, ButtonSingleLineArrowComponent, RouterLink, CompanyHeaderComponent, PortalComponent, RouterOutlet, FooterComponent, InlineSVGModule],
})
export class DesktopUserLayoutComponent extends LanguageAware implements OnInit, OnDestroy, AfterViewChecked {
  @ViewChild('main') main: ElementRef<HTMLDivElement>;
  public tag = 'DesktopUserLayoutComponent';
  public columnCount = 1;
  public hasSystems: boolean;
  public isOnHomeRoute: boolean;
  public isCenterRouteActive: boolean;
  private twoColumnRoutes = [
    '/d/new-system/',
    '/d/configure/',
    '/d/help',
  ];
  private get newSystemService() { return LocatorService.injector.get(NewSystemService); }

  private routerLocationCenterRoutes = ['/d/help', '/d/help/ticket'];

  private routeSubscription = this.router.events.subscribe(event => {
    if (event instanceof NavigationEnd) {
      const route = this.router.url;
      this.isCenterRouteActive = this.routerLocationCenterRoutes.includes(route);
      this.isOnHomeRoute = ('/' + this.g.getHomeUrl().filter(r => r !== '/').join('/')) === route;

      this.columnCount = this.systems.systems.length > 0 ? (this.twoColumnRoutes.find(r => route.startsWith(r)) ? 2 : 3) : 1;
      this.cdRef.detectChanges();
    }
  });
  private systemsLoadedListener: Subscription;
  private breakPointSubscription: Subscription;
  readonly breakpoint = this.brk.observe(['(min-width: 1150px)']).pipe( distinctUntilChanged() );

  constructor(
    cdRef: ChangeDetectorRef,
    private router: Router,
    private headerService: HeaderService,
    private brk: BreakpointObserver,
  ) {
    super(cdRef);
    this.headerService.isDesktopRoleSimpleUser = true;
    this.hasSystems = this.systems.systems.length > 0 ? true : false;
    this.checkIfSystemsLoaded();
    this.log('+');
    this.systemsLoadedListener = this.systems.onSystemsLoaded.subscribe(() => {
      this.hasSystems = this.systems.systems.length > 0 ? true : false;
      if ( this.hasSystems ) {
        this.columnCount = this.hasSystems ? (this.twoColumnRoutes.includes(this.router.url) ? 2 : 3) : 1;
        this.cdRef.detectChanges();
        if ( this.main ) { this.contentIsScrolling({target: this.main.nativeElement}); }
      }
    });
    this.breakPointSubscription = this.breakpoint.subscribe((state) => {
      AppSettings.companyDesktopEnabled = state.matches;
      if ( !AppSettings.companyDesktopEnabled ) {
        this.router.navigate(['/']);
      }
    });
  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    this.routeSubscription.unsubscribe();
    this.headerService.isDesktopRoleSimpleUser = false;
    this.systemsLoadedListener.unsubscribe();
    this.breakPointSubscription.unsubscribe();
  }

  ngAfterViewChecked() {
    if(this.isCenterRouteActive) {
      const host = document.querySelector('.bg-gray');
      host?.classList.remove('bg-gray');
    }
  }

  private checkIfSystemsLoaded() {
    if(this.hasSystems) { return; }
    setTimeout(() => {
      this.hasSystems = this.systems.systems.length > 0 ? true : false;
      if(this.hasSystems) {
        this.columnCount = this.hasSystems ? (this.twoColumnRoutes.includes(this.router.url) ? 2 : 3) : 1;
        this.cdRef.detectChanges();
        if ( this.main ) { this.contentIsScrolling({target: this.main.nativeElement}); }
      }
      this.checkIfSystemsLoaded();
    }, 200);
  }

  public newSystemClick() {
    this.newSystemService.setDefaults();
    this.router.navigate([...this.g.resolveRootRoute(), 'new-system']);
  }

  public setRef() {
    localStorage.setItem('ref', 'conf');
  }

  public loadMoreSystems() {
    if ( this.miniStatus.isVisible() ) {
      return;
    }
    if ( this.systems.systems.length % this.systems.systemPageSize !== 0 ) {
      return;
    }
    this.miniStatus.show(this.trans('general.pleaseWait'));
    this.systems.loadSystems(true, () => {
      this.miniStatus.hide();
    });
  }

  public contentIsScrolling(e) {
    const elementHeight = (e.target as HTMLElement).clientHeight;
    const elementInnerHeight = (e.target as HTMLElement).scrollHeight;
    const scrolledSoFar = (e.target as HTMLElement).scrollTop;
    if (elementHeight + scrolledSoFar >= elementInnerHeight - 50) {
      this.loadMoreSystems();
    }
  }

  public openHistorySystems() {
    LocatorService.injector.get(PopupService).openPopup(HistorySystemsPopupComponent);
  }
}
