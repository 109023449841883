<div *ngIf="!hasSystem()" class="grid grid-home-empty">
  <div id="home-empty-logo" *ngIf="!us.currentUser?.logo" [inlineSVG]="'assets/images/logo_text_subtext.svg'"></div>
  <div id="home-empty-logo" *ngIf="us.currentUser?.logo"><img [src]="us.currentUser.logo" /></div>
  <div id="home-empty-plus" (click)="newSystemClick()"><div [inlineSVG]="'assets/images/plus.svg'"></div></div>
  <div id="home-empty-subtext">{{ trans('systems.titles.clickPlusButton') }}</div>
</div>
<div *ngIf="shouldShowNewSystemWarning" class="white_round new_system_warning padding16">
  <span>{{trans('systems.labels.newSystemWarning')}}</span>
</div>
<div *ngIf="hasSystem()" class="grid grid-home">
  <div id="home-system-name">{{ systems.activeSystem.name }}</div>
  <div id="home-system-net-status">
    {{ systemNetState() }}
    <span *ngIf="this.systems.activeSystem.online"><app-signal-level-v2 [width]="17" [height]="14" [currentLevel]="systems.activeSystem.signalLevel"></app-signal-level-v2></span>
  </div>
  <app-assisting-installer
    id="home_row_assistance"
    *ngIf="systems.activeSystem.assistedById !== undefined && us.currentUser.permissions.role !== PermissionRole.Installer"
    [installerId]="systems.activeSystem.assistedById"
    [installerEmail]="systems.activeSystem.assistedByEmail"
    [systemId]="systems.activeSystem.id"
    [showRevokeButton]="true"
  ></app-assisting-installer>
  <ng-container *ngIf="systems.activeSystem.assistedById !== undefined && us.currentUser.permissions.role !== PermissionRole.GeneralUser">
    <div id="home_row_transfer" *ngIf="systems.activeSystem.installerAccess !== undefined && systems.activeSystem.installerAccess.transferred === 0">
      <div class="white_round system_not_transferred padding16">
        <span>{{trans('systems.labels.systemNotYetTransferred')}}</span>
        <app-button (click)="goToTransfer()">{{trans('systems.buttons.transfer')}}</app-button>
      </div>
    </div>
  </ng-container>
  <app-home-events
    *ngIf="systems.activeSystemHelper.can.see.events() && shouldShowType('events') && systems.activeSystem.events.length > 0"
    [ngClass]="'home-row-' + getPositionForType('events') + ' home_section'"
  ></app-home-events>
  <app-virtual-area-view
    *ngIf="['G17F', 'FC'].includes(systems.activeSystem.hwType) && shouldShowType('areas')"
    [ngClass]="'home-row-' + getPositionForType('areas') + ' home_section'"
  ></app-virtual-area-view>
  <app-home-areas
    *ngIf="systems.activeSystemHelper.can.see.areas() && shouldShowType('areas') && systems.activeSystem.areas.length > 0 && systems.activeSystem.hwType !== 'FC'"
    [ngClass]="'home-row-' + getPositionForType('areas') + ' home_section'"
  ></app-home-areas>
  <app-home-outputs
    *ngIf="systems.activeSystemHelper.can.see.outputs() && shouldShowType('outputs') && systems.activeSystem.pgms.length > 0"
    [ngClass]="'home-row-' + getPositionForType('outputs') + ' home_section'"
  ></app-home-outputs>
  <app-home-sensors
    *ngIf="systems.activeSystemHelper.can.see.sensors() && shouldShowType('sensors') && systems.activeSystem.sensors.length > 0"
    [ngClass]="'home-row-' + getPositionForType('sensors') + ' home_section'"
  ></app-home-sensors>
  <app-home-cameras
    *ngIf="systems.activeSystemHelper.can.see.cameras() && shouldShowType('cameras') && systems.activeSystem.cameras.length > 0"
    [ngClass]="'home-row-' + getPositionForType('cameras') + ' home_section'"
  ></app-home-cameras>
  <app-home-thermostats
    *ngIf="systems.activeSystemHelper.can.see.thermostats() && shouldShowType('thermostats') && systems.activeSystem.thermostats.length > 0"
    [ngClass]="'home-row-' + getPositionForType('thermostats') + ' home_section'"
  ></app-home-thermostats>
  <app-home-device-status *ngIf="systems.activeSystemHelper.supports.deviceStatus() && shouldShowType('device-status')" [ngClass]="'home-row-' + getPositionForType('device-status') + ' home_section'"></app-home-device-status>
</div>
<ng-container *ngIf="transferIsInProgress">
  <app-loader-round [displayText]="trans('general.pleaseWait')" [isVisible]="true" [overlayIsGray]="true"></app-loader-round>
</ng-container>

<app-sos-button *ngIf="systems.activeSystemHelper.can.see.sosButton()" [systemId]="systems.activeSystem.id"></app-sos-button>