import http from '../http';
import { BasicSuccessResponse, ErrorResponse, objectToParams } from './common';
import { Tag } from './tag';

export type DeviceSetupTemplate = {
    id: number;
    name: string;
    ip_com: number;
    backup_ip_com: number;
    host: string;
    backup_host: string;
    port: string;
    backup_port: string;
    encryption: string;
    backup_encryption: string;
    communication_type: number;
    backup_communication_type: number;
    receiver_nr: number;
    line_nr: number;
    test_enabled: boolean;
    ping_enabled: boolean;
    test_period: number;
    ping_period: number;
    backup_after: number;
    primary_after: number;
    company_id: number;
    backup_enabled: boolean;
    /**
     * Vartotojai tik redagavimui
     */
    users?: { id: number; name: string; email: string }[];
    cloud_enabled: boolean;
    companyEmail?: string; // ateina tik kai skaitoma drop down sąrašui.
    tags?: Tag[];
    wait_for_ack: boolean;
    receiver: string | null;
};
type GetDeviceSetupTemplateRequest = { id: number };
export type AddDeviceSetupTemplateResponse = BasicSuccessResponse & { id: number };
type GetTemplatesResponse = ErrorResponse | (BasicSuccessResponse & { list: DeviceSetupTemplate[]; has_receiver?: { [key: string]: number } });
type SaveDeviceSetupTemplateRequest = Omit<DeviceSetupTemplate, 'tags' | 'receiver'> & { tags: number[] };

const getTemplates = (req: { forList?: boolean }) => http.get<GetTemplatesResponse, URLSearchParams>('/v3/api/device-setup-templates', objectToParams(req));
const saveTemplate = (req: SaveDeviceSetupTemplateRequest) => http.put<BasicSuccessResponse, SaveDeviceSetupTemplateRequest>('/v3/api/device-setup-template', req);
const deleteTemplate = (req: GetDeviceSetupTemplateRequest) => http.delete<BasicSuccessResponse, URLSearchParams>('/v3/api/device-setup-template', objectToParams(req));
const addTemplate = (req: SaveDeviceSetupTemplateRequest) => http.post<AddDeviceSetupTemplateResponse, SaveDeviceSetupTemplateRequest>('/v3/api/device-setup-template', req);

export default {
    addTemplate,
    saveTemplate,
    getTemplates,
    deleteTemplate,
};
