import { AfterViewInit, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router, RouterLink, RouterLinkActive } from '@angular/router';
import { AppSettings } from 'src/app-settings';
import { TBackgroundType } from 'src/app/models/background-types';
import { DateService } from 'src/app/services/date.service';
import { LanguageAware } from '../language-aware';
import { PermissionRole } from 'src/api/v3/permission';
import { Company, CompanyBasicInfo } from 'src/api/v3/company';
import requests from 'src/api/v3/requests';
import { InlineSVGModule } from 'ng-inline-svg-w';
import { LogViewComponent } from '../log-view/log-view.component';
import { BackButtonComponent } from 'src/app/ui/back-button/back-button.component';
import { ButtonSingleLineArrowComponent } from 'src/app/ui/button-single-line-arrow/button-single-line-arrow.component';
import { ButtonComponent } from 'src/app/ui/button/button.component';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-help',
  standalone: true,
  imports: [InlineSVGModule, LogViewComponent, RouterLink, RouterLinkActive, BackButtonComponent, ButtonSingleLineArrowComponent, ButtonComponent, CommonModule],
  templateUrl: './help.component.html',
  styleUrls: ['./help.component.scss'],
})
export class HelpComponent extends LanguageAware implements OnInit, AfterViewInit {
  public readonly desktopView = this.platform.isDesktopView();
  public readonly PermissionRole = PermissionRole;
  public oldVersion = null;
  public newerVersion = null;
  public lastTimeChecked = null;
  public companyInfo: CompanyBasicInfo = null;

  constructor(
    cdRef: ChangeDetectorRef,
    public router: Router,
    public dt: DateService,
    private ar: ActivatedRoute,
  ) {
    super(cdRef, { backgroundType: TBackgroundType.Gray });
    this.tag = 'Help';
    if( !this.desktopView ) {
      this.headerBar.showHeader({
        backUrl: '*',
        headerText: this.trans('general.help'),
      });
    }
    this.l.log('Current ver: ', this.tag, { major: AppSettings.appVersionMajor, minor: AppSettings.appVersionMinor, build: AppSettings.appVersionBuild, date: AppSettings.appVersionDate });
    const savedOldVersion = localStorage.getItem('last_version');
    this.l.log('Old version: ', this.tag, {savedOldVersion});
    const savedOldBuild = localStorage.getItem('last_version_build');
    this.l.log('Old build: ', this.tag, {savedOldBuild});
    if (savedOldBuild !== null && parseInt(savedOldBuild, 10) !== AppSettings.appVersionBuild) {
      this.oldVersion = JSON.parse(savedOldVersion);
    }
    localStorage.setItem('last_version_build', AppSettings.appVersionBuild.toString());
    localStorage.setItem(
      'last_version',
      JSON.stringify({
        major: AppSettings.appVersionMajor,
        minor: AppSettings.appVersionMinor,
        build: AppSettings.appVersionBuild,
        date: AppSettings.appVersionDate,
      })
    );
    this.lastTimeChecked = localStorage.getItem('version_check_date');
    this.l.log('Version from server: ', this.tag, this.us.appVersionDetails);
    if (this.us.appVersionDetails !== null && AppSettings.appVersionBuild < this.us.appVersionDetails.build) {
      this.newerVersion = this.us.appVersionDetails;
    }
    this.loadInfoData();
  }

  ngOnInit(): void {}

  ngAfterViewInit() {
    if( this.platform.isDesktopView() ) {
      setTimeout(() => {
        const header = document.querySelector('.header-dark');
        if(header) {
          header.classList.replace('header-dark', 'header-bright');
        }
      });
    }
  }

  private async loadInfoData() {
    let company: Company | CompanyBasicInfo;
    if ( this.us.currentUser ) {
      if ( this.us.currentUser.belongsToCompany?.parent ) {
        company = this.us.currentUser.belongsToCompany.parent;
      }
      if ( this.us.currentUser.belongsToCompany && this.us.currentUser.permissions.role === PermissionRole.Installer ) {
        company = this.us.currentUser.belongsToCompany;
      }
      if ( this.systems.activeSystem && this.systems.activeSystem.company_id !== 0 ) {
        const found = this.companyService.getCompany(this.systems.activeSystem.company_id);
        if ( !found ) {
          try {
            const res = await requests.company.getBasicInfo({id: this.systems.activeSystem.company_id}).toPromise();
            if ( res.success && res.data ) {
              company = res.data;
            }
          } catch (e) {console.log(e);}
        } else {
          company = found;
        }
      }
    }
    if ( company ) {
      this.companyInfo = {
        name: company.name,
        phone: company.phone,
        address: company.address,
        email: company.email,
        support_email: company.support_email,
      };
      return;
    }
    
    this.companyInfo = {
      address: AppSettings.address,
      email: AppSettings.email,
      name: 'Trikdis, UAB',
      phone: '', // Algirdo ir Vytauto prašymu nerodome Trikdžio telefono
      support_email: 'support@trikdis.lt',
    };
  }

  public getTitle(): string {
    return AppSettings.title;
  }

  public getCurrentVersion(): string {
    return AppSettings.appVersionMajor + '.' + AppSettings.appVersionMinor;
  }

  public getBuild(): string {
    return AppSettings.appVersionBuild.toString();
  }

  public getCopyright(): string {
    return AppSettings.appCopyright;
  }

  public getDate(): number {
    return AppSettings.appVersionDate;
  }

  public getLastTimeCheckedDate(): string {
    const dateString = this.lastTimeChecked?.slice(0, 10);
    const timeString = this.lastTimeChecked?.slice(11, 16);

    return dateString && timeString ? dateString + ' ' + timeString : '';
  }

  public clickUpdate() {
    location.reload();
  }

  public clickEmail() {
    if (this.platform.isAndroid()) {
      this.platform.androidHandler().openExternalUrl('mailto:' + AppSettings.email);
    } else if (this.platform.isApple()) {
      this.platform.appleHandler().openExternalUrl.postMessage('mailto:' + AppSettings.email);
    } else {
      window.open('mailto:' + AppSettings.email, '_blank');
    }
  }

  public clickPhone() {
    if (this.platform.isAndroid()) {
      this.platform.androidHandler().openExternalUrl('tel:' + AppSettings.phone);
    } else if (this.platform.isApple()) {
      this.platform.appleHandler().openExternalUrl.postMessage('tel:' + AppSettings.phone);
    }
  }

  public clickUrl() {
    if (this.platform.isAndroid()) {
      this.platform.androidHandler().openExternalUrl('http://' + AppSettings.companyUrl);
    } else if (this.platform.isApple()) {
      this.platform.appleHandler().openExternalUrl.postMessage('http://' + AppSettings.companyUrl);
    } else {
      window.open('http://' + AppSettings.companyUrl, '_blank');
    }
  }

  public clickTicket() {
    this.router.navigate(['ticket'], { relativeTo: this.ar });
  }

  public shouldShowLoggerbutton(): boolean {
    return AppSettings.showLogger;
  }

  public logResolution() {
    if (this.l.showLogView) {
      this.l.log('Ekrano rezoliucija: ', '', {
        plotis: screen.availWidth,
        aukstis: screen.availHeight,
      });
      this.l.log('Lango rezoliucija: ', '', {
        plotis: window.innerWidth,
        aukstis: window.innerHeight,
      });
    }
  }
}
