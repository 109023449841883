import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-radio-button',
  templateUrl: './radio-button.component.html',
  styleUrls: ['./radio-button.component.scss'],
  standalone: true,
  imports: [],
})
export class RadioButtonComponent implements OnInit {
  @Input() checkValue = false;
  @Input() groupName = '';
  @Input() isDisabled = false;
  @Output() valueChanged: EventEmitter<any> = new EventEmitter();

  constructor() {}

  ngOnInit(): void {}
}
