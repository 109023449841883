import { ConfigType } from 'src/api/requests/config';
import { Routes } from 'src/api/v3/common';
import { gvType, wpType } from 'src/app/services/device.service';

export const advancedSettingsRoutes: Routes = [
  { path: ConfigType.SP3, loadChildren: () => import('src/app/configurators/sp3/src/app/app.module').then(m => m.AppModule) },
  { path: ConfigType.SP4, loadChildren: () => import('src/app/configurators/sp3/src/app/app.module').then(m => m.AppModule) },
  { path: gvType(), loadChildren: () => import('src/app/configurators/gv17/src/app/app.module').then(m => m.AppModule) },
  { path: wpType(), loadChildren: () => import('src/app/configurators/gv17/src/app/app.module').then(m => m.AppModule) },
  { path: ConfigType.G16, loadChildren: () => import('src/app/configurators/g16/src/app/app.module').then(m => m.AppModule) },
  { path: ConfigType.FC, loadChildren: () => import('src/app/configurators/fc/src/app/app.module').then(m => m.AppModule) },
  { path: ConfigType.G17, loadChildren: () => import('src/app/configurators/g17/src/app/app.module').then(m => m.AppModule) },
];