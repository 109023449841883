import { Injectable } from '@angular/core';
import { environment } from 'src/app-settings';
import { PhoneStateService } from './phone-state.service';

@Injectable({
  providedIn: 'root',
})
export class LoggerService {
  public entries: {
    ts: Date;
    tag: string;
    val: string;
  }[] = [];
  private disabledLoggingForTags: { [key: string]: boolean } = {};
  public showLogView = false;

  constructor(psjsi: PhoneStateService) {
    this.log('+', 'Logger');
    psjsi.onLog.subscribe((data) => {
      this.log(data.message, data.tag);
    });
  }

  public clear() {
    this.entries = [];
  }

  public log(msg: string, tag?: string, obj?: any) {
    if ( tag && this.disabledLoggingForTags[tag] === true ) { return; }
    let objectPart = '';
    if (obj !== undefined) {
      if (obj === null) {
        objectPart = '<null>';
      }
      if (obj !== null && typeof obj === 'object') {
        objectPart = JSON.stringify(obj);
      }
    } else {
      obj = '';
    }
    if (tag === undefined) {
      tag = '';
    }

    if (environment.logToConsole) {
      console.groupCollapsed('[' + tag + ']', msg, obj);
      // eslint-disable-next-line no-console
      console.trace();
      console.groupEnd();
    }
    if (environment.showLogger) {
      this.entries.unshift({
        ts: new Date(),
        tag,
        val: msg + ' ' + objectPart,
      });
    }
  }

  private download(blob: Blob, filename: string) {
    if ((window.navigator as any).msSaveOrOpenBlob) {
      // IE
      (window.navigator as any).msSaveOrOpenBlob(blob, filename);
    } else {
      // Others
      const a = document.createElement('a');
      const url = URL.createObjectURL(blob);
      a.href = url;
      a.download = filename;
      document.body.appendChild(a);
      a.click();
      setTimeout(() => {
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url);
      }, 0);
    }
  }

  public exportJson(): void {
    const c = JSON.stringify(this.entries);
    const file = new Blob([c], { type: 'text/json' });
    this.download(file, 'beta2_log.json');
  }

  public disableLogginForTag(tag: string) {
    this.disabledLoggingForTags[tag] = true;
  }

  public enableLogginForTag(tag: string) {
    this.disabledLoggingForTags[tag] = false;
  }
}
