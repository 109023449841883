import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { TBackgroundType } from '../models/background-types';
import { SystemsService } from './systems.service';

@Injectable({
  providedIn: 'root',
})
export class BackgroundService {
  public currentType = TBackgroundType.White;
  private backgroundChange = new BehaviorSubject<TBackgroundType>(TBackgroundType.White);
  public onBackgroundChange = this.backgroundChange.asObservable();

  constructor(private ss: SystemsService) {}

  public setUserSelected() {
    const body = document.querySelector('body');
    // body.style.backgroundColor = 'none';
    if (this.ss.activeSystem !== null && this.ss.activeSystem.theme.fullBackground !== '') {
      body.style.background = this.ss.activeSystem.theme.fullBackground;
    } else {
      body.style.background = 'linear-gradient(180deg, var(--home-background-start) 0%, var(--home-background-end) 100%)';
    }
    // body.style.backgroundRepeat = 'no-repeat';
    // body.style.backgroundAttachment = 'fixed';
    this.currentType = TBackgroundType.Gradient;
    if ( this.backgroundChange.getValue() !== this.currentType ) {
      this.backgroundChange.next(this.currentType);
    }
  }

  public setWhite() {
    const body = document.querySelector('body');
    body.style.backgroundColor = '#ffffff';
    body.style.backgroundImage = 'none';
    body.style.backgroundRepeat = 'no-repeat';
    body.style.backgroundAttachment = 'fixed';
    this.currentType = TBackgroundType.White;
    if ( this.backgroundChange.getValue() !== this.currentType ) {
      this.backgroundChange.next(this.currentType);
    }
  }

  public setLogin() {
    const body = document.querySelector('body');
    body.style.backgroundColor = 'none';
    body.style.backgroundImage = 'url(/assets/images/login-back.png)';
    body.style.backgroundRepeat = 'no-repeat';
    body.style.backgroundAttachment = 'stretch';
    body.style.backgroundSize = 'cover';
    this.currentType = TBackgroundType.Login;
    if ( this.backgroundChange.getValue() !== this.currentType ) {
      this.backgroundChange.next(this.currentType);
    }
  }

  public setGray() {
    const body = document.querySelector('body');
    body.style.backgroundColor = '#F6F6F6';
    body.style.backgroundImage = 'none';
    body.style.backgroundRepeat = 'no-repeat';
    body.style.backgroundAttachment = 'fixed';
    this.currentType = TBackgroundType.Gray;
    if ( this.backgroundChange.getValue() !== this.currentType ) {
      this.backgroundChange.next(this.currentType);
    }
  }

  public setBackground(type: TBackgroundType) {
    switch (type) {
      case TBackgroundType.Gradient:
        this.setUserSelected();
        break;
      case TBackgroundType.Gray:
        this.setGray();
        break;
      case TBackgroundType.Login:
        this.setLogin();
        break;
      default:
        this.setWhite();
        break;
    }
  }
}
