import { CommonModule } from '@angular/common';
import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { AutofocusDirective } from 'src/app/directives/autofocus.directive';

@Component({
  selector: 'app-text-edit',
  templateUrl: './text-edit.component.html',
  styleUrls: ['./text-edit.component.scss'],
  standalone: true,
  imports: [CommonModule, FormsModule, AutofocusDirective],
})
export class TextEditComponent implements OnInit {
  public fieldIsFocused = false;
  @Input() inputType: 'text' | 'float' | 'number' | 'email' = 'text';
  @Input() value;
  @Input() maxCharacters = 255;
  @Input() doFocus = false;
  @Input() isReadOnly = false;
  @Input() minNumber = -99999999;
  @Input() maxNumber = 99999999;
  @Input() transparentInput = false;
  @Output() enterClicked: EventEmitter<any> = new EventEmitter();
  @Output() valueChanged: EventEmitter<any> = new EventEmitter();
  @Output() exitedField: EventEmitter<any> = new EventEmitter();
  @ViewChild('textField') textField: ElementRef;
  @ViewChild('floatField') floatField: ElementRef;
  @ViewChild('numberField') numberField: ElementRef;
  @ViewChild('emailField') emailField: ElementRef;

  constructor() {}

  ngOnInit(): void {}

  public onEnter() {
    this.enterClicked.emit(this.value);
  }

  public onKeyUp() {
    this.valueChanged.emit(this.value);
  }

  public onExitField() {
    this.valueChanged.emit(this.value);
    this.exitedField.emit(this.value);
  }

  public focusEdit() {
    setTimeout(() => {
      if (this.inputType === 'text') {
        this.textField.nativeElement.focus();
      } else if (this.inputType === 'float') {
        this.floatField.nativeElement.focus();
      } else if (this.inputType === 'number') {
        this.numberField.nativeElement.focus();
      } else if (this.inputType === 'email') {
        this.emailField.nativeElement.focus();
      }
    }, 50);
  }

  public checkMaxLength() {
    if (this.inputType === 'float' && parseFloat(this.value)) {
      if (this.value > this.maxNumber) {
        this.value = this.maxNumber;
        this.floatField.nativeElement.value = this.value;
      } else if (this.value < this.minNumber) {
        this.value = this.minNumber;
        this.floatField.nativeElement.value = this.value;
      }
    }
  }

  public focusInput() {
    switch (this.inputType) {
      case 'text':
        this.textField.nativeElement.focus();
        break;
      case 'email':
        this.emailField.nativeElement.focus();
        break;
      case 'float':
        this.floatField.nativeElement.focus();
        break;
      case 'number':
        this.numberField.nativeElement.focus();
        break;
      default:
        break;
    }
  }
}
