import { Injectable, OnDestroy } from '@angular/core';
import { Company, CompanyDeletionOrigin } from 'src/api/v3/company';
import requests from 'src/api/v3/requests';
import { DataTableGetter } from '../company/components/data-table/data-table.component';
import { LanguageService } from '../services/language.service';
import { LocatorService } from '../services/locator.service';
import { DropdownItem } from '../ui/dropdown/dropdown.component';
import { AuthService } from './auth.service';
import { LoggerService } from './logger.service';
import { RegionService } from './region.service';
import { UserService } from './user.service';
import { Subject, Subscription } from 'rxjs';
import { DateTime } from 'luxon';
import { FilterCriteria } from 'src/api/v3/common';

@Injectable({
  providedIn: 'root'
})
export class CompanyService implements OnDestroy {
  private get languageService() { return LocatorService.injector.get(LanguageService); }
  public static readonly LOGO_BASE_PATH = 'app/company_logos/';
  public readonly companies = new Map<number, Company>();

  private tag = 'CompanyService';
  private previousFilter: string = null;
  private cleanUpSubscriber: Subscription;
  private filterCriteria: FilterCriteria = null;
  private companyInEditIdChange = new Subject<number>();

  public logoBasePath = '';
  public listBeingLoaded = false;
  public listStorage: DropdownItem<number>[] = [];
  public onCompanyInEditIdChange = this.companyInEditIdChange.asObservable();

  constructor(
    private l: LoggerService,
    private regionService: RegionService,
    auth: AuthService,
  ) {
    this.regionService.onRegionChanged.subscribe(() => {
      this.buildBaseLogoPath();
    });
    this.buildBaseLogoPath();
    this.cleanUpSubscriber = auth.onAccountOrRegionChnage.subscribe(() => {
      this.companies.clear();
      this.filterCriteria = null;
      this.previousFilter = null;
      this.listStorage = [];
    });

  }

  ngOnDestroy() {
    this.cleanUpSubscriber?.unsubscribe();
  }

  private buildBaseLogoPath() {
    this.logoBasePath = this.regionService.ActiveRegion.protocol
        + this.regionService.ActiveRegion.backEndHost
        + (this.regionService.ActiveRegion.port !== '' ? ':' + this.regionService.ActiveRegion.port : '')
        + '/' + CompanyService.LOGO_BASE_PATH;
  }

  public ingestCompany(company: Company) {
    if ( this.companies.has(company.id) ) { return; }
    this.l.log('Ingesting company ' + company.id, this.tag, { company });
    this.companies.set(company.id, company);
    if ( company.parent ) {
      const related = [...this.companies.values()].filter(c => c.id === company.parent.id || c.company_relations.find(cr => cr.company_id === company.parent.id));
      for ( const relatedParent of related ) {
        if ( relatedParent.company_relations.find( cr => cr.company_id === company.id) ) {
          continue;
        }
        relatedParent.company_relations.push({
          company_id: company.id,
          id: 0,
          top_parent_id: relatedParent.id,
        })
      }
    }
  }

  public getCompany(id: number): Company | undefined {
    return this.companies.get(id);
  }

  public async getCompanyAsync(id: number): Promise<Company | undefined> {
    if ( id === 0 ) { return; }
    const found = this.companies.get(id);
    if ( found ) { return found; }

    await this.loadCompanyById(id);
    return this.companies.get(id);
  }

  private async loadCompanyById(id: number) {
    try {
      const response = await requests.company.getCompany({id}).toPromise();
      this.ingestCompany(response.company);
    } catch (e) {
      console.log(e);
    }
  }

  public getCompaniesGetter(filterCriteria: FilterCriteria | null): DataTableGetter<Company> {
    const companyService = this;
    if ( filterCriteria ) {
      if (
        !this.filterCriteria
        || this.filterCriteria.searchPhrase !== filterCriteria.searchPhrase
        || JSON.stringify(filterCriteria.searchFields) !== JSON.stringify(this.filterCriteria.searchFields)
      ) {
        this.companies.clear();
      }
      this.previousFilter = JSON.stringify(this.filterCriteria);
      this.filterCriteria = JSON.parse(JSON.stringify(filterCriteria));
    } else if ( this.filterCriteria !== null ) {
      this.filterCriteria = null;
      this.companies.clear();
    }
    return async (current, columns, more) => {
      this.l.log('Load companies', '', { current, more });
      if ( !more ) { // data table nori atsinaujinti duomenis is atminties.
        if ( companyService.companies.size > 0 ) {
          return [...companyService.companies.values()];
        }
        if ( JSON.stringify(companyService.filterCriteria) === companyService.previousFilter ) {
          return [...companyService.companies.values()];
        }
      }
      companyService.previousFilter = JSON.stringify(companyService.filterCriteria);
      const result = await requests.company.getCompanies(filterCriteria ? {
        searchPhrase: filterCriteria.searchPhrase,
        searchFields: filterCriteria.searchFields,
        paginationPage: filterCriteria.currentPage + 1,
        include_statistics: true,
      } : { include_statistics: true }).toPromise();
      if ( !companyService.filterCriteria ) {
        companyService.filterCriteria = {
          searchPhrase: '',
          searchFields: [],
          currentPage: result.list.current_page,
          lastPage: result.list.last_page,
        };
        companyService.previousFilter = JSON.stringify(companyService.filterCriteria);
        if ( result.list.total === companyService.companies.size ) {
          companyService.filterCriteria.currentPage = result.list.last_page;
        }
      } else {
        companyService.filterCriteria.currentPage = result.list.current_page;
        companyService.filterCriteria.lastPage = result.list.last_page;
        companyService.previousFilter = JSON.stringify(companyService.filterCriteria);
      }
      result.list.data.forEach((c) => companyService.ingestCompany(c));
      return [...companyService.companies.values()];
    };
  }

  public clearFilter() {
    this.filterCriteria = null;
  }

  public async loadDropDownList(): Promise<void> {
    if ( this.listStorage.length > 0 ) { return; }
    if ( this.listBeingLoaded ) { return; }
    this.listBeingLoaded = true;
    try {
      const result = await requests.company.getCompanies({ forList: true }).toPromise();
      const userService = LocatorService.injector.get(UserService);
      this.listStorage.push({
        value: 0,
        label: this.languageService.get('general.none')
      });
      result.list.data.forEach(r => this.listStorage.push({
        value: r.id,
        label: r.name,
        subLabels: userService.currentUser.company_id === 0 ? [r.email] : undefined,
        extraParam: { level: r.level },
      }));
    } finally {
      this.listBeingLoaded = false;
    }
  }

  public changeCompanyInEditId(value: number) {
    this.companyInEditIdChange.next(value);
  }

  public synchronizeSubCompanies(company: Company) {
    for ( const relation of company.company_relations ) {
      const found = this.companies.get(relation.company_id);
      if ( !found ) { continue; }
      if ( found.parent?.id !== company.id ) { continue; } // Toliau dirbam tik su tiesioginiais vaikais.
      found.company_admin_rule = company.company_admin_rule;
      found.installer_rule = company.installer_rule;
      found.system_owner_rule = company.system_owner_rule;
      found.system_user_rule = company.system_user_rule;
      if ( !found.own_logo ) {
        found.logo = company.logo;
      }
      found.parent = company;
      this.synchronizeSubCompanies(found);
    }
  }

  public handleParentChange(oldParent: number | null, company: Company) {
    if ( oldParent === null && !company.parent ) { return; }
    const allCompanies = [...this.companies.values()];
    if ( oldParent !== null ) {
      const parentsToClean = allCompanies.filter(ac => ac.id === oldParent || ac.company_relations.find(cr => cr.company_id === oldParent));
      for ( const parent of parentsToClean ) {
        parent.company_relations = parent.company_relations.filter(cr => cr.company_id !== company.id && !company.company_relations.find(cr2 => cr2.company_id === cr.company_id));
      }
    }
    if ( company.parent ) {
      const parents = allCompanies.filter(ac => ac.id === company.parent.id || ac.company_relations.find(cr => cr.company_id === company.parent.id));
      for ( const parent of parents ) {
        // Pridedam pačią kompaniją prie parent
        parent.company_relations.push({ company_id: company.id, id: 0, top_parent_id: parent.id });
        // O paskui visas kompanijai priklausančias sub-kompanijas
        company.company_relations.map(r => parent.company_relations.push({ company_id: r.company_id, id: r.id, top_parent_id: parent.id }));
      }
    }
  }

  public deleteCompany(companyId: number) {
    this.companies.delete(companyId);
    this.listStorage.splice(this.listStorage.findIndex(lc => lc.value === companyId), 1);
    const children = [...this.companies.values()].filter(c => c.parent?.id === companyId);
    children.map(c => {
      c.deleted = DateTime.now().toUnixInteger();
      c.deletion_origin = CompanyDeletionOrigin.ParentSelf;
      c.parent = null;
      c.level = 1;
    });
  }

}
