import { SafeUrl } from '@angular/platform-browser';
import http from '../http';
import { BasicSuccessResponse, ErrorResponse, objectToFormData, objectToParams } from './common';

type EditIpComRequest = {
  id: number | 0;
  peer_name: string;
  host: string;
  port: number;
  user_name: string;
  password: string;
  own_ip: boolean;
  region: number;
};

type TestIpcomRequest = {
  peer_name: string;
  host: string;
  port: number;
  user_name: string;
  password: string;
};

export type StripeSettings = {
  id: number;
  company_id: number;
  enabled: boolean;
  test_mode: boolean;
  hasTestModeSet: boolean,
  hasLiveModeSet: boolean,
  webhook_id_live: string | null;
  webhook_id_test: string | null;
}

type RequestAssistanceRequest = {
  accessPeriod: number; // Hours
  describeIssue: string;
  id: number; // Installer ID
  systemId: number;
};

type GetAssistanceDataResponse =
  | ErrorResponse
  | (BasicSuccessResponse & {
      data: {
        installer_id: number;
        system_id: number;
        transferred: boolean;
        access_until: string;
        issue: string;
      }[];
    });

export type Company = {
  id: number;
  name: string;
  address: string;
  logo: string | null;
  phone: string;
  email: string;
  country: string;
  vat_id: string;
  registration_email: string;
  owner_id: number | null;
  active: boolean;
  city: string;
  assigned_systems?: number;
  stripe_customer_id: string | null;
  stripe_subscription_id: string | null;
  stripe_quote_id: string | null;
  stripe_test_customer_id: string | null;
  stripe_test_subscription_id: string | null;
  stripe_test_quote_id: string | null;
  can_be_seen: boolean;
  need_user_name: boolean;
  need_user_email: boolean;
  need_address: boolean;
  need_user_phone: boolean;
  company_relations: CompanyRelation[];
  parent: Company | null;
  own_logo: boolean;
  support_email: string | null;
  company_admin_rule: number | null;
  installer_rule: number | null;
  system_owner_rule: number | null;
  system_user_rule: number | null;
  deleted: number | null;
  deletion_origin: CompanyDeletionOrigin | null;
  deletion_policy_systems: CompanyDeletionPolicyForSystems;
  level: number;
  parent_id: number | null;
  stripe_settings: StripeSettings | null;
};

type AddCompanyRequest = Omit<Company, 'id' | 'logo' | 'assigned_systems' | 'company_relations' | 'parent' | 'deleted' | 'deletion_origin' | 'level'> & {
  parent_id: number | null;
  owner_name: string;
  owner_last_name: string;
  owner_phone: string;
};

type GetCompaniesRequest = {
  searchPhrase?: string;
  searchFields?: string[];
  paginationPage?: number;
  include_statistics?: boolean;
  forList?: boolean; // TRUE gražins tik { id, name }[]
};

type GetCompaniesResponse = BasicSuccessResponse & {
  list: {
    total: number;
    per_page: number;
    current_page: number;
    last_page: number;
    next_page_url: string | null;
    prev_page_url: string | null;
    from: number;
    to: number;
    data: Company[];
  };
};

type CompanyRelation = {
  id: number;
  top_parent_id: number;
  company_id: number;
}

type RegisterCompanyRequest = {
  name: string;
  address: string;
  phone: string;
  email: string;
  country: string;
  vat_id: string;
  registration_email: string;
  city: string;
  owner_name: string;
  owner_last_name: string;
  owner_phone: string;
  can_be_seen: boolean;
  support_email: string;
};

export enum CompanyDeletionOrigin {
  Self = 0,
  Parent = 1,
  ParentSelf = 2,
};

export enum CompanyDeletionPolicyForSystems {
  Release = 0,
  TransferToParent = 1,
};

export type SelectableCompany = Pick<Company, 'id' | 'name' | 'logo' | 'need_address' | 'need_user_phone' | 'need_user_email' | 'need_user_name'>;
export type CompanyBasicInfo = Pick<Company, 'address' | 'name' | 'email' | 'support_email' | 'phone'>;
export type HierarchyCompany = Pick<Company, 'name' | 'level' | 'id'>;

type GetCompanyRequest = { id: number };
type DeleteCompanyRequest = { id: number, quickDelete: boolean };
type GetAssistanceDataRequest = { systemId: number };
type RemoveCompanyLogoRequest = { company_id: number };
type SaveCompanyRequest = Exclude<AddCompanyRequest, 'owner_name' | 'owner_last_name' | 'owner_phone'>;
type RevokeAssistanceResponse = ErrorResponse | BasicSuccessResponse;
type RemoveCompanyLogoResponse = ErrorResponse | BasicSuccessResponse;
type RequestAssistanceResponse = ErrorResponse | BasicSuccessResponse;
type GetCompanyResponse = BasicSuccessResponse & { company: Company };
type SaveCompanyLogoRequest = { company_id: number; logo_image: Blob };
export type SaveCompanyLogoResponse = BasicSuccessResponse & { logoUrl: string };
type RevokeAssistanceRequest = { id: number; /* Installer ID */ systemId: number };
export type AddCompanyResponse = BasicSuccessResponse & { id: number };
type GetBasicInfoRequestResponse = ErrorResponse | (BasicSuccessResponse & { data: CompanyBasicInfo | null });
type DeleteCompanyResponse = ErrorResponse | (BasicSuccessResponse & Pick<Company, 'deleted' | 'deletion_origin'>);
type GetCompaniesForSystemTransferResponse = ErrorResponse | (BasicSuccessResponse & { list: SelectableCompany[] });

const addCompany = (req: AddCompanyRequest) => http.post<AddCompanyResponse, AddCompanyRequest>('/v3/api/company', req);
const saveCompany = (req: SaveCompanyRequest) => http.put<BasicSuccessResponse, SaveCompanyRequest>('/v3/api/company', req);
const getCompany = (req: GetCompanyRequest) => http.get<GetCompanyResponse, URLSearchParams>('/v3/api/company', objectToParams(req));
const deleteCompany = (req: DeleteCompanyRequest) => http.delete<DeleteCompanyResponse, DeleteCompanyRequest>('/v3/api/company', req);
const cancelDeletion = <T extends { id: number }>(req: T) => http.post<BasicSuccessResponse, T>('/v3/api/cancel-company-deletion', req);
const getCompanies = (req: GetCompaniesRequest) => http.get<GetCompaniesResponse, URLSearchParams>('/v3/api/companies', objectToParams(req));
const saveCompanyLogo = (req: SaveCompanyLogoRequest) => http.post<SaveCompanyLogoResponse, FormData>('/v3/api/company/logo', objectToFormData(req));
const removeCompanyLogo = (req: RemoveCompanyLogoRequest) => http.delete<RemoveCompanyLogoResponse, RemoveCompanyLogoRequest>('/v3/api/company/logo', req);
export const revokeAssistance = (req: RevokeAssistanceRequest) => http.post<RevokeAssistanceResponse, RevokeAssistanceRequest>('/v3/api/revoke-installer', req);
const getBasicInfoForCompany = (req: GetCompanyRequest) => http.get<GetBasicInfoRequestResponse, URLSearchParams>('/v3/api/company-basic-info', objectToParams(req));
export const requestAssistance = (req: RequestAssistanceRequest) => http.post<RequestAssistanceResponse, RequestAssistanceRequest>('/v3/api/installer-assistance', req);
export const getAssistanceData = (req: GetAssistanceDataRequest) => http.get<GetAssistanceDataResponse, URLSearchParams>('/v3/api/installer-assistance', objectToParams(req));
const registerCompany = (req: RegisterCompanyRequest) => http.post<BasicSuccessResponse | ErrorResponse, RegisterCompanyRequest>('/v3/api/register-company', req, { auth: false });
const getCompaniesForSystemTransfer = (req: { system_id: number }) => http.get<GetCompaniesForSystemTransferResponse, URLSearchParams>('/v3/api/companies-for-system', objectToParams(req));
const getHierarchy = (req: { company_id: number }) => http.get<ErrorResponse | (BasicSuccessResponse & { list: HierarchyCompany[] }),URLSearchParams>('/v3/api/company/hierarchy', objectToParams(req));

export default {
  getCompany,
  addCompany,
  saveCompany,
  getCompanies,
  getHierarchy,
  deleteCompany,
  cancelDeletion,
  saveCompanyLogo,
  registerCompany,
  removeCompanyLogo,
  getCompaniesForSystemTransfer,
  getBasicInfo: getBasicInfoForCompany,
};
