import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfigType } from 'src/api/requests/config';
import requests from 'src/api/v3/requests';
import { G16 } from 'src/app/configurators/g16/src/app/models/g16-devices';
import { TBackgroundType } from 'src/app/models/background-types';
import { PopupType } from 'src/app/models/popup-type';
import { DeviceService, gvType, wpType } from 'src/app/services/device.service';
import { PopupService } from 'src/app/services/popup.service';
import { LanguageAware } from '../language-aware';
import { LoaderRoundComponent } from 'src/app/components/loader-round/loader-round.component';
import { CheckboxComponent } from '../checkbox/checkbox.component';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-advanced-settings',
  templateUrl: './advanced-settings.component.html',
  styleUrls: ['./advanced-settings.component.scss'],
  standalone: true,
  imports: [LoaderRoundComponent, CheckboxComponent, CommonModule],
})
export class AdvancedSettingsComponent extends LanguageAware implements OnInit {
  public doNotShow = false;
  public shouldShowContent = true;

  constructor(
    cdRef: ChangeDetectorRef,
    private router: Router,
    private r: ActivatedRoute,
    private deviceService: DeviceService,
    private pp: PopupService,
  ) {
    super(cdRef, { backgroundType: TBackgroundType.Gray });
    this.headerBar.showHeader({
      backUrl: '*',
      headerText: this.trans('systems.menu.advancedSettings'),
      activatedRoute: this.r,
    });
    if ( this.systems.activeSystem ) {
      this.deviceService.deviceData = {
        adminCode: '123456',
        hwId: this.systems.activeSystem.deviceId,
        ipcom: 0,
        mpass: this.systems.activeSystem.mpass,
        uid: this.systems.activeSystem.imei,
        id: this.systems.activeSystem.id,
      };
    }
    const savedDoNowShow = localStorage.getItem('do_not_show_adv_' + this.us.currentUser.id + '_' + this.deviceService.deviceData.id);
    this.shouldShowContent = savedDoNowShow === null;
    this.doNotShow = savedDoNowShow !== null;
    if (!this.shouldShowContent) {
      this.openConfigurator();
    }
  }

  ngOnInit(): void {}

  public openConfigurator() {
    if (this.miniStatus.isVisible()) {
      this.miniStatus.flash();
      return;
    }
    this.miniStatus.show(this.trans('general.pleaseWait'));
    if (this.doNotShow) {
      localStorage.setItem('do_not_show_adv_' + this.us.currentUser.id + '_' + this.deviceService.deviceData.id, '1');
    }
    requests.config.info({
      systemUid: this.deviceService.deviceData.uid,
      mpass: this.deviceService.deviceData.mpass,
      srv: undefined,
    }, false).subscribe(
      (result) => {
        if (result.success) {
          // !!! pridedant naujus modulius, juos reikia prideti ir i systems.service prie supports.advancedSettings
          this.g.configurableDevice = {...result.data, mpass: this.deviceService.deviceData.mpass, adminCode: '123456', communicating: false};
          if (result.data.hwId === '3B') {
            // SP3
            this.buildPathAndNavigate(ConfigType.SP3);
          } else if (result.data.hwId === '55') {
            // SP4
            this.buildPathAndNavigate(ConfigType.SP4);
          } else if (result.data.hwId === '24') {
            this.buildPathAndNavigate(gvType());
          } else if (result.data.hwId === '37') {
            this.buildPathAndNavigate(wpType());
          } else if ([G16.E16_3A, G16.E16T_3C, G16.G16_42, G16.G16_43, G16.G16_44, G16.G16T_45, G16.G16T_46, G16.G16T_47, G16.G16T_48, G16.GET, G16.GT_PLUS, G16.GT, G16.G16T_50].includes(parseInt(result.data.hwId, 16))) {
            this.buildPathAndNavigate(ConfigType.G16);
          } else if (result.data.hwId === '51') {
            this.buildPathAndNavigate(ConfigType.FC);
          } else if (['20', '21'].includes(result.data.hwId)) { // CG17, G17F
            this.buildPathAndNavigate(ConfigType.G17);
          } else {
            this.g.configurableDevice = null;
            this.toaster.postError(this.trans('systems.titles.notSupported'));
          }
        } else {
          if ( result.error_code !== undefined && result.error_code === 6 ) {
            this.askForMpassAndRetry();
            this.miniStatus.hide();
            return;
          }
          this.toaster.postError(result.error);
        }
        this.miniStatus.hide();
      },
      (error) => {
        this.miniStatus.hide();
      }
    );
  }

  private buildPathAndNavigate(device: ConfigType | string) {
    const navigationPath = this.g.resolveRootRoute();
    if ( this.platform.isDesktopView() && this.systems.activeSystem ) {
      if ( this.us.currentUser.permissions.permissions.systems.view || this.us.currentUser.permissions.permissions.unassigned_devices.view ) {
        navigationPath.push('systems');
      }
      navigationPath.push(this.systems.activeSystem.id.toString());
    }
    navigationPath.push('configure');
    navigationPath.push(device);
    this.router.navigate(navigationPath);
  }

  private askForMpassAndRetry() {
    this.pp.showPopup({
      headerText: this.trans('systems.labels.mpass'),
      field1: {
        maxLen: 6,
        oldValue: '',
      },
      onSubmitString: (res) => {
        if ( res === '' ) { return; }
        this.deviceService.deviceData.mpass = res;
        this.openConfigurator();
      }
    }, PopupType.NumericPassword);
  }
}
