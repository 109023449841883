import { Injectable, NgZone } from '@angular/core';
import { LoginService } from '../login/login.service';
import { LocatorService } from '../services/locator.service';
import { GoogleAuthService } from './google-auth.service';
import { PlatformService } from './platform.service';

@Injectable()
export class GoogleAuthMobileService implements GoogleAuthService {
  public async signIn(): Promise<any> {
    if (this.platform.isAndroid()) {
      const res = new Promise<{ idToken: string; accessToken: string }>((resolve, reject) => {
        setTimeout(() => {
          reject('Timeout Android Google login.');
        }, 180000);
        window.loginJsi = {
          ...window.loginJsi,
          onGoogleCheck: (a: string, b: string) => {
            this.zone.run(() => {
              resolve({ idToken: a, accessToken: b });
            });
          },
        };
      });
      this.platform.androidHandler().doGoogleLogin();
      const { accessToken, idToken } = await res;
      this.login.handleGoogleLoginSuccess(idToken, false, accessToken);
      return;
    }
    if (this.platform.isApple()) {
      const res = new Promise<{ idToken: string; accessToken: string }>((resolve, reject) => {
        setTimeout(() => {
          reject('Timeout iOS Google login.');
        }, 180000);
        window.loginJsi = {
          ...window.loginJsi,
          onGoogleCheck: (a: string, b: string) => {
            this.zone.run(() => {
              resolve({ idToken: a, accessToken: b });
            });
          },
        };
      });
      this.platform.appleHandler().doGoogleLogin.postMessage('');
      const token = await res;
      this.login.handleGoogleLoginSuccess(token.idToken, true, token.accessToken);
      return;
    }
  }
  public signOut(arg0: boolean): Promise<any> {
    if (this.platform.isAndroid()) {
      this.platform.androidHandler().doLogOutGoogle();
    }
    return Promise.resolve();
  }
  public readonly supportsOneTap = false;
  private platform: PlatformService;
  private zone: NgZone;
  private get login() { return LocatorService.injector.get(LoginService); }
  constructor() {
    this.platform = LocatorService.injector.get(PlatformService);
    this.zone = LocatorService.injector.get(NgZone);
  }

  public whenReady(): Promise<void>;
  public whenReady(callback: () => void): void;
  public whenReady(callback?: () => void): void | Promise<void> {
    if (!callback) { return Promise.resolve(); }
    callback();
  }

  public renderButton(container: HTMLElement) {
    throw new Error('Method not supported.');
  }
}
