import { CommonModule } from '@angular/common';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss'],
  standalone: true,
  imports: [CommonModule],
})
export class CheckboxComponent implements OnInit {
  private localCheckValue = false;
  get checkValue(): boolean {
    return this.localCheckValue;
  }
  @Input()
  set checkValue(val: boolean) {
    this.localCheckValue = val;
  }
  @Input() isRound = true;
  /** Vietoj $color-primary bus naudojama $color-secondary */
  @Input() isSecondaryColor = false;
  /** Standartinis dydis yra 18 x 18 px. Didelis bus 28 x 28 px */
  @Input() isBig = false;
  /** Tiems atvejams, kai reikia kad automatiškai atsinaujintų reikšmė ir naudojamas masyvas */
  @Input() valueFromArray = null;
  /** Indeksas masyve, kuriame turėtų būti reikšmė. Galbūt tinka ir objektams. */
  @Input() indexInArray = null;
  @Input() isReadOnly = false;
  @Output() valueChanged: EventEmitter<any> = new EventEmitter();

  constructor() {}

  ngOnInit(): void {}

  public changeValue() {
    if ( this.isReadOnly ) { return; }
    this.valueChanged.emit(this.checkValue);
  }
}
