import { Component, NgZone } from '@angular/core';
import { ChildrenOutletContexts, RouterOutlet } from '@angular/router';
import { AppSettings } from 'src/app-settings';
import { LoggerService } from './api/logger.service';
import { PhoneStateService } from './api/phone-state.service';
import { PlatformService } from './api/platform.service';
import { RegionService } from './api/region.service';
import { AreaService } from './services/area.service';
import { FcmService } from './services/fcm.service';
import { FooterService } from './services/footer.service';
import { GlobalService } from './services/global.service';
import { HeaderService } from './services/header.service';
import { ProgressService } from './services/progress.service';
import { RefreshService } from './services/refresh.service';
import { CommonModule } from '@angular/common';
import { ProgressComponent } from './general/progress/progress.component';
import { PortalComponent } from './teleport/portal/portal.component';
import { BypassZonesComponent } from './system/bypass-zones/bypass-zones.component';
import { AreaKeypadComponent } from './system/area-keypad/area-keypad.component';
import { FooterComponent } from './general/footer/footer.component';
import { RefreshComponent } from './components/refresh/refresh.component';
import { HeaderComponent } from './general/header/header.component';
import { RouterWithTransition } from './general/router-with-transition';

@Component({
  selector: 'app-mobile-app',
  templateUrl: './mobile-app.component.html',
  styleUrls: ['./mobile-app.component.scss'],
  standalone: true,
  imports: [CommonModule, ProgressComponent, PortalComponent, BypassZonesComponent, AreaKeypadComponent, FooterComponent, RouterOutlet, RefreshComponent, HeaderComponent],
})
export class MobileAppComponent extends RouterWithTransition {
  public tag = 'MobileAppComponent';

  constructor(
    public progress: ProgressService,
    public header: HeaderService,
    public footer: FooterService,
    public logger: LoggerService,
    public areaService: AreaService,
    private g: GlobalService,
    public ps: PlatformService,
    region: RegionService,
    psJsi: PhoneStateService, // Tik tam, kad uzkrautu.
    fcmS: FcmService, // Tik tam, kad uzkrautu.
    rf: RefreshService,
    contexts: ChildrenOutletContexts,
    zone: NgZone,
  ) {
    super(contexts);
    this.logger.log('+', this.tag);
    if (ps.isDesktop()) {
      document.body.onmousedown = (e) => {
        g.onSwipeStartedSource.next({
          x: e.clientX,
          y: e.clientY,
          leftPercent: (e.clientX / window.innerWidth) * 100,
          topPercent: (e.clientY / window.innerHeight) * 100,
        });
      };
      document.body.onmousemove = (e) => {
        g.onSwipeContinueSource.next({
          x: e.clientX,
          y: e.clientY,
          leftPercent: (e.clientX / window.innerWidth) * 100,
          topPercent: (e.clientY / window.innerHeight) * 100,
        });
      };
      document.body.onmouseup = (e) => {
        g.onSwipeEndedSource.next({
          x: e.clientX,
          y: e.clientY,
          leftPercent: (e.clientX / window.innerWidth) * 100,
          topPercent: (e.clientY / window.innerHeight) * 100,
        });
      };
    } else {
      document.body.ontouchstart = (e) => {
        g.onSwipeStartedSource.next({
          x: e.touches.item(0).clientX,
          y: e.touches.item(0).clientY,
          leftPercent: (e.touches.item(0).clientX / window.innerWidth) * 100,
          topPercent: (e.touches.item(0).clientY / window.innerHeight) * 100,
        });
      };
      document.body.ontouchend = (e) => {
        g.onSwipeEndedSource.next({
          x: e.changedTouches.item(0).clientX,
          y: e.changedTouches.item(0).clientY,
          leftPercent: (e.changedTouches.item(0).clientX / window.innerWidth) * 100,
          topPercent: (e.changedTouches.item(0).clientY / window.innerHeight) * 100,
        });
      };
      document.body.ontouchmove = (e) => {
        g.onSwipeContinueSource.next({
          x: e.touches.item(0).clientX,
          y: e.touches.item(0).clientY,
          leftPercent: (e.touches.item(0).clientX / window.innerWidth) * 100,
          topPercent: (e.touches.item(0).clientY / window.innerHeight) * 100,
        });
      };
    }
    document.documentElement.style.setProperty('--height-progress', '10px');
    document.documentElement.style.setProperty('--height-header', '77px');
    document.documentElement.style.setProperty('--height-footer', '52px');
    document.documentElement.style.setProperty('--color-primary', AppSettings.primaryColor);
    document.documentElement.style.setProperty('--color-secondary', AppSettings.secondaryColor);
    psJsi.onPhoneResumed.subscribe(() => {
      zone.run(() => {
        rf.doSilentRefresh();
      });
    });

    if (AppSettings.regionsEnabled) {
      region.autoSelectRegion();
    }
  }
  public contentIsScrolling(e) {
    const elementHeight = (e.target as HTMLElement).clientHeight;
    const elementInnerHeight = (e.target as HTMLElement).scrollHeight;
    const scrolledSoFar = (e.target as HTMLElement).scrollTop;
    if (elementHeight + scrolledSoFar >= elementInnerHeight - 50) {
      this.g.onScrolledToBottomSource.next();
    }
  }
}
