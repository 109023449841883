<app-base-popup #basePopup (closed)="closed.emit()">
    <div class="white_round padding16 container">
        <div class="header">{{ls.get('systems.titles.history')}}</div>
        <div class="separator16"></div>
        <div class="history_holder">
            <app-button-single-line-arrow *ngFor="let system of us.currentUser.historySystems" [showArrow]="false">{{system.name}}</app-button-single-line-arrow>
        </div>
        <div class="separator16"></div>
        <app-button (click)="closeWindow()">{{ls.get('general.ok')}}</app-button>
    </div>
</app-base-popup>