import { Injectable } from '@angular/core';
import { GoogleAuthMobileService } from './google-auth-mobile.service';
import { GoogleAuthWebService } from './google-auth-web.service';
import { PlatformService } from './platform.service';

@Injectable()
export abstract class GoogleAuthService {
  public abstract signIn(): void;
  public abstract signOut(arg0?: boolean): Promise<any>;
  public readonly supportsOneTap;
  constructor() {}

  public abstract whenReady(): Promise<void>;
  public abstract whenReady(callback: () => void): void;

  public abstract renderButton(container: HTMLElement);
}

export const googleAuthFactory = (platform: PlatformService): GoogleAuthService => {
  if (platform.isAndroid() || platform.isApple()) {
    return new GoogleAuthMobileService();
  }
  return new GoogleAuthWebService();
};
