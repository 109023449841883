import http from '../http';
import { BasicSuccessResponse, ErrorResponse, WithLastSystem, ProtegusUserData, CurrentUserDataRaw, UserTag, objectToParams, objectToFormData } from './common';
import { PermissionRole, PermissionRule } from './permission';
import { Tag } from './tag';

type NameCreateRequest = { name: string };
type NameCreateResponse = ErrorResponse | BasicSuccessResponse;

export const nameCreate = (name: NameCreateRequest['name']) =>
  http.post<NameCreateResponse, NameCreateRequest>('/v3/api/name-create', {
    name,
  });

type MeRequest = { systemId: number | 0; getSystemStatus: boolean; areaId: number | 0 };
type MeResponse = ErrorResponse | (BasicSuccessResponse & CurrentUserDataRaw & WithLastSystem & { tags: Tag[] });

const me = (req: MeRequest) => http.get<MeResponse, URLSearchParams>('/v3/api/me', objectToParams(req));

type UpdateProfileRequest = {
  name?: string;
  phone?: string;
  dateFormat?: number;
  timeFormat?: string;
  company?: string; // Company name
  country?: string;
};

type UpdateProfileResponse = BasicSuccessResponse;

export const updateProfile = (req: UpdateProfileRequest) => http.post<UpdateProfileResponse, UpdateProfileRequest>('/v3/api/profile', req);

type ChangePasswordRequest = {
  newPassword: string;
  repeatPassword: string; // Duplicate of newPassword
};

type ChangePasswordResponse =
  | ErrorResponse
  | (ErrorResponse & { errorType: 'newPassword' })
  | (BasicSuccessResponse & {
      message: string;
    });
export const changePassword = (req: ChangePasswordRequest) => http.post<ChangePasswordResponse, ChangePasswordRequest>('/v3/api/change-password', req);

type DeleteAccountRequest = { sum: true; acknowledged: true };
type DeleteAccountResponse = ErrorResponse | (BasicSuccessResponse & { message: string });

export const deleteAccount = () =>
  http.delete<DeleteAccountResponse, DeleteAccountRequest>('/v3/api/account', {
    sum: true,
    acknowledged: true,
  });

type DownloadAccountResponse = BasicSuccessResponse;

export const downloadAccount = () => http.post<DownloadAccountResponse>('/v3/api/download-account', undefined);

type DownloadAccountFileRequest = {
  u: string; // Token
  an?: true;
};

export const downloadAccountFile = (req: DownloadAccountFileRequest) =>
  http.get<Blob, URLSearchParams, 'blob'>(
    '/v3/api/download-account-file',
    new URLSearchParams({
      u: req.u,
      an: req.an === undefined ? undefined : req.an.toString(),
    } as Record<string, string>),
    { returntype: 'blob' }
  );

type GetAllUsersRequest = {
  all?: boolean;
  loadedUsersCount?: number;
};
export type AllUsersUserData = Pick<ProtegusUserData, 'id' | 'name' | 'active' | 'email' | 'country' | 'access_permission_id' | 'company_id' | 'company' | 'device_setup_templates' | 'user_tags' | 'logo' | 'phone_number'>
& { systems?: {id: number; name: string}[] };
type GetAllUsersResponse =
  | ErrorResponse
  | (BasicSuccessResponse & {
      users: AllUsersUserData[];
      rules: PermissionRule[];
      tags?: Tag[]; // tik kai turi leidimą matyti tag'us
    });

const getAllUsers = (req: GetAllUsersRequest) => http.get<GetAllUsersResponse, URLSearchParams>('/v3/api/all-users', objectToParams(req));

type GetSingleUserRequest = {
  id: number;
};
export type SingleUserData = {
  id: number;
  name: string;
  company_id: number;
  company: null | string;
  systems: {
    id: number;
    name: string;
    imei: string;
  }[];
  access_permission_id: number;
};
export type GetSingleUserResponse =
  | ErrorResponse
  | (BasicSuccessResponse & {
      userData: SingleUserData;
    });
export const getSingleUser = (req: GetSingleUserRequest) => http.post<GetSingleUserResponse, GetSingleUserRequest>('/v3/api/get-user-data', req);

type AddUserProfileRequest = {
  password: string;
  name: string;
  email: string;
  country: string;
  active: 0 | 1;
  phone: string;
  access_permission_id: number;
  company_id: number;
  device_setup_templates: { id: number; name: string }[];
  user_tags: number[];
};
type AddUserProfileResponse = ErrorResponse | (BasicSuccessResponse & { newId: number });
const addUser = (req: AddUserProfileRequest) => http.post<AddUserProfileResponse, AddUserProfileRequest>('/v3/api/user-profile', req);

type EditUserProfileRequest<TChangePassword extends 'on' | '' | undefined = undefined> = Omit<AddUserProfileRequest, 'password'>
  & { id: number }
  & (TChangePassword extends 'on' ? { changePassword: TChangePassword; newPass: string } : { changePassword: TChangePassword });
const editUser = <TChangePassword extends 'on' | '' | undefined = undefined>(req: EditUserProfileRequest<TChangePassword>) => http.put<ErrorResponse | BasicSuccessResponse, EditUserProfileRequest<TChangePassword>>('/v3/api/user-profile', req);

type DeleteUserProfileRequest = { user_id: number; replace?: number };
const deleteUser = (req: DeleteUserProfileRequest) => http.delete<ErrorResponse | BasicSuccessResponse, DeleteUserProfileRequest>('/v3/api/user-profile', req);

type FilterUsersRequest = {
  searchPhrase: string;
  searchFields: string[];
  role: PermissionRole | -1;
  sort: 0 | 1 | 2 | 3;
  paginationPage?: number;
};
type FilterUsersResponse =
  | ErrorResponse
  | (BasicSuccessResponse & {
      list: {
        total: number;
        per_page: number;
        current_page: number;
        last_page: number;
        next_page_url: string | null;
        prev_page_url: string | null;
        from: number;
        to: number;
        data: {
          id: number;
          name: string;
          email: string;
          role: PermissionRole;
          active: 0 | 1;
          created_at: string;
          phone_number: string | null;
          company: null | string;
          country: string | null;
          dashboard_order: string;
          privacy_policy_accepted: boolean;
          soc_account: boolean;
          social_id: string;
          soc_type: string;
          company_id: number | 0;
          access_permission_id: number;
          device_setup_templates: { id: number; name: string }[];
          user_tags: UserTag[];
          logo: string | null;
        }[];
      };
      rules: PermissionRule[];
      tags: Tag[];
    });

export const filterUsers = (req: FilterUsersRequest) => http.get<FilterUsersResponse, URLSearchParams>('/v3/api/filter-users', objectToParams(req));

type SetUserAccessRequest = {
  system_id: number;
  present: boolean;
} & ({ protegus_user_id: number } | { user_id: number });
type SetUserAccessResponse = ErrorResponse | BasicSuccessResponse;

export const setUserAccess = (req: SetUserAccessRequest) => http.post<SetUserAccessResponse, SetUserAccessRequest>('/v3/api/set-user-access', req);

type SetOwnerPrivacyRequest = {
  system_id: number;
  user_id: number;
  privacy_settings: {
    show_email_admins: boolean;
    show_name_admins: boolean;
    show_phone_admins: boolean;
    show_email_users: boolean;
    show_name_users: boolean;
    show_phone_users: boolean;
  };
};
type SetOwnerPrivacyResponse = ErrorResponse | BasicSuccessResponse;
type GetDeviceSetupUserListResponse = BasicSuccessResponse & { users: { id: number; name: string; email: string; companyEmail: string|null }[] };

export const setOwnerPrivacy = (req: SetOwnerPrivacyRequest) => http.post<SetOwnerPrivacyResponse, SetOwnerPrivacyRequest>('/v3/api/owner-privacy', req);

const getDeviceSetupUserList = () => http.get<GetDeviceSetupUserListResponse>('/v3/api/device-setup-users', undefined);

type RemoveInstallerLogoRequest = { user_id: number };
type RemoveInstallerLogoResponse = ErrorResponse | BasicSuccessResponse;
type SaveInstallerLogoRequest = { user_id: number; logo_image: Blob };
export type SaveInstallerLogoResponse = BasicSuccessResponse & { logoUrl: string };
const saveInstallerLogo = (req: SaveInstallerLogoRequest) => http.post<SaveInstallerLogoResponse, FormData>('/v3/api/installer/logo', objectToFormData(req));
const removeInstallerLogo = (req: RemoveInstallerLogoRequest) => http.delete<RemoveInstallerLogoResponse, RemoveInstallerLogoRequest>('/v3/api/installer/logo', req);

export type InstallerBasicData = { id: number; name: string; email: string };
type GetInstallersForAssistanceResponse = ErrorResponse | (BasicSuccessResponse & { list: InstallerBasicData[] });
const getInstallersForAssistance = () => http.get<GetInstallersForAssistanceResponse>('/v3/api/users-for-assistance', undefined);

export enum UserCoordinateType {
  SOS = 0,
};

export default {
  me,
  getAllUsers,
  add: addUser,
  edit: editUser,
  saveInstallerLogo,
  delete: deleteUser,
  removeInstallerLogo,
  getDeviceSetupUserList,
  getInstallersForAssistance,
};
