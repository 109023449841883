export enum G16 {
  E16_3A = 0x3a,
  E16T_3C = 0x3c,
  G16_42 = 0x42,
  G16_43 = 0x43,
  G16_44 = 0x44,
  G16T_45 = 0x45,
  G16T_46 = 0x46,
  G16T_47 = 0x47, // amerika
  G16T_48 = 0x48,
  GET     = 0x4E,
  G16T_50 = 0x50, // GT, TRK8 only, tas pats kaip 0x47
  GT_PLUS = 0x52,
  GT      = 0x53,
}
