<div id='home-areas-section-header'>{{systems.activeSystem.hwType === 'FC' ? trans('systems.titles.groups') : trans('systems.titles.partitions')}}</div>
<div *ngFor='let area of visibleAreas' [ngClass]="{ 'hai-container': true, 'hai-container-regular': area.status !== AreaState.PanicButtons, 'hai-container-narrow': area.status === AreaState.PanicButtons }">
    <div class="hai-header" (click)='goToArea(area.id)'><span>{{ area.name }}</span><span [inlineSVG]="'assets/images/forward.svg'"></span></div>
    <div class="hai-last-status" *ngIf="area.status !== AreaState.PanicButtons; else panicButtonArea">
        <div [ngClass]="{ 'ale-icon': true, 'paint-armed': area.status === 2 || area.status === 4 || area.status === 3, 'paint-disarmed': area.status === 1, 'paint-alarmed': area.alarmed }" [inlineSVG]="areaService.getIconByAreaStatus(area.status, area.alarmed, area.alarmType)"></div>
        <div class="ale-text">{{areaService.getDescriptionForStatus(area.status, area.alarmed)}}</div>
        <div class="ale-user" *ngIf='area.lastPerson !== "" && !area.alarmed'>{{trans('general.by')}} {{area.lastPerson}}</div>
        <div class="ale-time" *ngIf='!area.alarmed && area.statusTime !== 0'><span *ngIf='!dt.isTodayFromNumber(area.statusTime)'>{{dt.getDayTitleFromNumber(area.statusTime) + ' ' + trans('general.timeAt') + ' '}}</span>{{dt.formatTimeFromNumber(area.statusTime)}}</div>
    </div>
    <ng-template #panicButtonArea>
      <div class="hai-last-status">
        <ng-container *ngIf="!area.alarmed; else panicButtonAreaActivated">
          <div [ngClass]="{ 'ale-icon ale-icon-check': true, 'paint-check': true }" [inlineSVG]="'assets/images/check-simple.svg'"></div>
          <div class="ale-text">{{ trans('events.list.noEvents') }}</div>
        </ng-container>
        <ng-template #panicButtonAreaActivated>
          <div [ngClass]="{ 'ale-icon': true, 'paint-armed': true }" [inlineSVG]="'assets/images/event_alarm.svg'"></div>
          <div class="ale-text">{{ trans('reactionNames.reactions.panic') }}</div>
          <div class="ale-user" *ngIf='area.lastPerson !== ""'>{{area.lastPerson}}</div>
          <div class="ale-time" *ngIf='area.alarmTime !== 0'><span *ngIf='!dt.isTodayFromNumber(area.alarmTime)'>{{dt.getDayTitleFromNumber(area.alarmTime) + ' ' + trans('general.timeAt') + ' '}}</span>{{dt.formatTimeFromNumber(area.alarmTime)}}</div>
        </ng-template>
    </div>
    </ng-template>
    <div *ngIf="area.status !== AreaState.PanicButtons" class="hai-buttons">
        <div *ngIf='areaService.showButtons(area.id) && canSleep()' [ngClass]="{ 'hai-button-sleep': true, 'area-action-button': true, 'area-action-arm-active': area.status === AreaState.Sleep, 'all-buttons': true, 'haibsm': canSleep() && canStay() }" (click)='setAreaState(AreaState.Sleep, area)'>
            <div class="hai_inner">
                <div *ngIf='!areaService.shouldShowLoading(AreaState.Sleep, area)' [inlineSVG]="'assets/images/sleep.svg'"></div>
                <app-loader-dots *ngIf='areaService.shouldShowLoading(AreaState.Sleep, area)' [width]="'32px'" [height]="'32px'"></app-loader-dots>
                <div class="hai_status_text">{{areaService.getDescriptionForStatus(AreaState.Sleep, false, area)}}</div>
            </div>
        </div>
        <div *ngIf='areaService.showButtons(area.id)' [ngClass]="{ 'hai-button-arm': true, 'area-action-button': true, 'area-action-arm-active': area.status === AreaState.Armed, 'all-buttons': canSleep() && canStay(), 'haibsm': canSleep() && canStay() }" (click)='setAreaState(AreaState.Armed, area)'>
            <div class="hai_inner">
                <div *ngIf='!areaService.shouldShowLoading(AreaState.Armed, area)' [inlineSVG]="'assets/images/arm.svg'"></div>
                <app-loader-dots *ngIf='areaService.shouldShowLoading(AreaState.Armed, area)' [width]="'32px'" [height]="'32px'"></app-loader-dots>
                <div class="hai_status_text">{{areaService.getDescriptionForStatus(AreaState.Armed, false, area)}}</div>
            </div>
        </div>
        <div *ngIf='areaService.showButtons(area.id)' [ngClass]="{ 'hai-button-disarm': true, 'area-action-button': true, 'area-action-disarm-active': area.status === AreaState.Disarmed, 'all-buttons': canSleep() && canStay(), 'haibsm': canSleep() && canStay() }" (click)='setAreaState(AreaState.Disarmed, area)'>
            <div class="hai_inner">
                <div *ngIf='!areaService.shouldShowLoading(AreaState.Disarmed, area)' [inlineSVG]="'assets/images/disarm.svg'"></div>
                <app-loader-dots *ngIf='areaService.shouldShowLoading(AreaState.Disarmed, area)' [width]="'32px'" [height]="'32px'"></app-loader-dots>
                <div class="hai_status_text">{{areaService.getDescriptionForStatus(AreaState.Disarmed, false, area)}}</div>
            </div>
        </div>
        <div *ngIf='areaService.showButtons(area.id) && canStay()' [ngClass]="{ 'hai-button-stay': true, 'area-action-button': true, 'area-action-arm-active': area.status === AreaState.Stay, 'all-buttons': true, 'haibsm': canSleep() && canStay() }" (click)='setAreaState(AreaState.Stay, area)'>
            <div class="hai_inner">
                <div *ngIf='!areaService.shouldShowLoading(AreaState.Stay, area)' [inlineSVG]="'assets/images/stay.svg'"></div>
                <app-loader-dots *ngIf='areaService.shouldShowLoading(AreaState.Stay, area)' [width]="'32px'" [height]="'32px'"></app-loader-dots>
                <div class="hai_status_text">{{areaService.getDescriptionForStatus(AreaState.Stay, false, area)}}</div>
            </div>
        </div>
        <div *ngIf='!systems.activeSystem.directControl && !areaService.isOutputAssigned(area.id) && systems.activeSystemHelper.can.edit.areas()' id='hai-control-info' (click)='goEditArea(area.id)'>{{trans('systems.labels.clickToAssignOut')}}</div>
    </div>
    <app-take-action-button *ngIf='area.status === AreaState.PanicButtons && area.alarmed || shouldShowTakeAction[area.id] !== undefined && shouldShowTakeAction[area.id]' class='hai_area_alarmed' [areaItem]="area"></app-take-action-button>
</div>
