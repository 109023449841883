import { EventEmitter, Injectable, NgZone } from '@angular/core';
import { TDotMenuItem } from '../models/dot-menu-item';
import { ActivatedRoute, Router } from '@angular/router';
import { THeaderOptions } from '../models/header-options';
import { BackBehaviourService } from './back-behaviour.service';
import { PlatformService } from '../api/platform.service';
import { Subscription, interval, Subject, BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class HeaderService {
  private show = false;
  private text = '';
  private back = '';
  private showHamburger = false;
  private showBackButton = false;
  private showText = false;
  private showDotMenu = false;
  private showLearningIcon = new BehaviorSubject<boolean>(false);
  public onShowLearningIconValueChange = this.showLearningIcon.asObservable();
  private showLogo = false;
  private showPlusButton = false;
  private isHeaderDarkMode = true;
  private showActionButton = false;
  private showFilterButton = false;
  private showXButton = false;
  private actionButtonText = '';
  private showHomeButton = false;
  private showFindButton = false;
  private showSettingsButton = false;
  private showInstallerAssistButton = false;
  /**
   * Jeigu funkcija gražina true tai back bus toliau vykdomas normaliai, jeigu false - tuomet back nevykdomas, tik pati custom funkcija kuri priskirta.
   */
  public onBackClicked: (() => boolean) = null;
  public onActionButtonClicked = null;
  public onXButtonClicked = null;
  public onHomeButtonClicked = null;
  private dotMenuItems: TDotMenuItem[] = [];
  public sideMenuOpen = false;
  public sideMenuIsDisabled = false;
  public isHeaderWhite = false;
  private homeColor = '#fff';
  public isDesktopRoleSimpleUser = false;
  private r: ActivatedRoute = undefined;
  private backButtonVisibilityChange = new BehaviorSubject<boolean>(false);
  public onBackButtonVisibilityChange = this.backButtonVisibilityChange.asObservable();
  private darkModeChange = new BehaviorSubject<boolean>(true);
  public onDarkModeChange = this.darkModeChange.asObservable();

  constructor(private ps: PlatformService, private router: Router, zone: NgZone, private bh: BackBehaviourService) {
    window.backJsi = {
      doBack: () => {
        zone.run(() => {
          this.doBack();
        });
      },
    };
  }

  /**
   * @param options.headerText Antraštė.
   * @param options.backUrl URL į kurį bus grįžtama. Nenurodžius reikšmės "Back" rodyklė nebus rodoma ir paspausti nebus galima.
   * @param options.showHamburger Parodo šoninio meniu mygtuką. Jeigu "Back" mygtukas turi reikšmę, tai _hamburger'is_ rodomas nebus.
   * @param options.showDotMenu Parodomas tritaškis dešiniame kampe. Tritaškis nerodomas, jeigu tuo pat metu rodomas _Action button_.
   * @param options.showLearningIcon Parodomas ikona daviklių primokymo metu dešiniame kampe. Ikona nerodoma, jeigu tuo pat metu rodomas _Action button_.
   * @param options.showLogo Parodomas aplikacijos logo. Rodoma tik tuo atveju, jeigu nėra nurodyta antraštė.
   * @param options.actionButtonText Nurodžius pavadinimą, parodomas papildomų veiksmų mygtukas. (Paspaudimas iššaukia onActionButtonClicked)
   * @param options.isWhiteBackground Jeigu true, tuomet headerio spalva bus balta, o ne permatoma.
   * @param options.showPlus Nurodo, jog reikia rodyti pliuso mygtuka. (Paspaudimas iššaukia onActionButtonClicked)
   * @param options.showFilter Nurodo, ar turėtų būti matomas filtro mygtukas. (Paspaudimas iššaukia onActionButtonClicked)
   * @param options.showX Nurodo, ar turėtų būti matomas X mygtukas. (Paspaudimas iššaukia onXButtonClicked)
   * @param options.showHome Nurodo, ar turėtų būti Home mygtukas. (Paspaudimas iššaukia onHomeButtonClicked)
   * @param options.showFind Nurodo, ar turėtų būti matomas Find mygtukas. (Paspaudus iššaukia onActionButtonClicked)
   * @param options.showInstallerAssist Nurodo, ar turėtų būti matomas Installer assist mygtukas. (Paspaudus iššaukia onActionButtonClicked)
   * @param options.homeButtonColor Nurodo, kokios spalvos turės būti Home mygtukas.
   * @param options.showSettings Nurodo, ar turėtų būti matomas Settings mygtukas. (Paspaudimas iššaukia onActionButtonClicked)
   * @param options.activatedRoute
   * @param options.brightMode Nurodo ar nustatyti šviesų režimą (t.y. ikonos bus šviesios, pritaikytos tamsiam fonui). Nenurodžius bus naudojamas dark mode.
   */
  public showHeader(options: THeaderOptions) {
    this.show = true;
    this.text = options.headerText === undefined ? '' : options.headerText;
    this.back = options.backUrl === undefined ? '' : options.backUrl;
    this.showBackButton = this.back !== '';
    this.showDotMenu = (options.actionButtonText === undefined || options.actionButtonText === '') && options.showDotMenu !== undefined && options.showDotMenu;
    this.showLearningIcon.next((options.actionButtonText === undefined || options.actionButtonText === '') && options.showLearningIcon !== undefined && options.showLearningIcon);
    this.showHamburger = !this.showBackButton && options.showHamburger !== undefined && options.showHamburger;
    this.showLogo = this.text === '' && options.showLogo !== undefined && options.showLogo;
    this.showText = this.text !== '';
    this.showActionButton = !this.showDotMenu && options.actionButtonText !== undefined && options.actionButtonText !== '';
    this.actionButtonText = options.actionButtonText ?? '';
    this.isHeaderDarkMode = !(options.brightMode ?? false);
    this.isHeaderWhite = options.isWhiteBackground ?? false;
    this.showPlusButton = options.showPlus ?? false;
    this.showFilterButton = options.showFilter ?? false;
    this.showXButton = options.showX ?? false;
    this.showHomeButton = options.showHome ?? false;
    this.showFindButton = options.showFind ?? false;
    this.showInstallerAssistButton = options.showInstallerAssist ?? false;
    if (options.homeButtonColor !== undefined) {
      this.homeColor = options.homeButtonColor;
    }
    this.showSettingsButton = options.showSettings ?? false;
    this.r = options.activatedRoute;

    if (this.ps.isAndroid()) {
      this.ps.androidHandler().setBackButtonType(this.showBackButton ? 'normal' : 'noop');
    }
    if ( this.backButtonVisibilityChange.getValue() !== this.showBackButton ) {
      this.backButtonVisibilityChange.next(this.showBackButton);
    }
    if ( this.darkModeChange.getValue() !== this.isHeaderDarkMode ) {
      this.darkModeChange.next(this.isHeaderDarkMode);
    }
  }

  public hideHeader() {
    this.show = false;
    if (this.ps.isAndroid()) {
      this.ps.androidHandler().setBackButtonType('noop');
    }
  }

  public isShowing(): boolean {
    return this.show;
  }

  public getText(): string {
    return this.text;
  }

  public getBackURL(): string {
    return this.back;
  }

  public setBackURL(value: string) {
    this.back = value;
  }

  public hamburgerIsVisible(): boolean {
    return this.showHamburger;
  }

  public backButtonIsVisible(): boolean {
    return this.showBackButton;
  }

  public logoIsVisible(): boolean {
    return this.showLogo;
  }

  public textIsVisible(): boolean {
    return this.showText;
  }

  public dotMenuIsVisible(): boolean {
    return this.showDotMenu;
  }

  public learningIconIsVisible(): boolean {
    return this.showLearningIcon.getValue();
  }

  public changeShowLearningIconValue(value: boolean) {
    this.showLearningIcon.next(value);
  }

  public doShowDotMenu() {
    this.showDotMenu = true;
  }

  public setDotMenuItems(items: TDotMenuItem[]) {
    this.dotMenuItems = items;
  }

  public getDotMenuItems(): TDotMenuItem[] {
    return this.dotMenuItems;
  }

  public headerDarkMode(): boolean {
    return this.isHeaderDarkMode;
  }

  public setHeaderDark() {
    this.isHeaderDarkMode = true;
    if ( this.darkModeChange.getValue() !== this.isHeaderDarkMode ) {
      this.darkModeChange.next(this.isHeaderDarkMode);
    }
  }

  public setHeaderBright() {
    this.isHeaderDarkMode = false;
    if ( this.darkModeChange.getValue() !== this.isHeaderDarkMode ) {
      this.darkModeChange.next(this.isHeaderDarkMode);
    }
  }

  public doBack() {
    if (this.onBackClicked !== null) {
      if ( !this.onBackClicked() ) { return; }
    }
    let urlToReturnTo = this.back;
    if (this.bh.overrideBackBehaviour) {
      urlToReturnTo = this.bh.backUrl;
      this.bh.reset();
    }
    if (urlToReturnTo === '') {
      return;
    }
    this.onBackClicked = null;
    if ( urlToReturnTo === '*' ) {
      history.back();
    } else if ( urlToReturnTo.length > 2 && urlToReturnTo.startsWith('../') && this.r !== undefined ) {
      this.router.navigate([urlToReturnTo], { relativeTo: this.r, replaceUrl: true });
    } else {
      this.router.navigateByUrl(urlToReturnTo);
    }
  }

  public actionButtonIsVisible(): boolean {
    return this.showActionButton;
  }

  public getActionButtonText(): string {
    return this.actionButtonText;
  }

  public disableSideMenu() {
    this.sideMenuIsDisabled = true;
  }

  public enableSideMenu() {
    this.sideMenuIsDisabled = false;
  }

  public plusButtonIsVisible(): boolean {
    return this.showPlusButton;
  }

  public filterButtonIsVisible(): boolean {
    return this.showFilterButton;
  }

  public xButtonIsVisible(): boolean {
    return this.showXButton;
  }

  public setXButtonVisibility(value: boolean) {
    this.showXButton = value;
  }

  public homeButtonIsVisible(): boolean {
    return this.showHomeButton;
  }

  public setHomeButtonVisibility(value: boolean) {
    this.showHomeButton = value;
  }

  public findButtonIsVisible(): boolean {
    return this.showFindButton;
  }

  public setFindButtonVisibility(value: boolean) {
    this.showFindButton = value;
  }

  public installerAssistButtonIsVisible(): boolean {
    return this.showInstallerAssistButton;
  }

  public setInstallerAssistButtonVisibility(value: boolean) {
    this.showInstallerAssistButton = value;
  }

  public getHomeButtonColor(): string {
    return this.homeColor;
  }

  public setHomeButtonColor(value: string) {
    this.homeColor = value;
  }

  public settingsButtonIsVisible(): boolean {
    return this.showSettingsButton;
  }

  public setSettingsButtonVisibility(value: boolean) {
    this.showSettingsButton = value;
  }
}
