import {
  RtcMessageAreaStatus,
  RtcMessageConfigurationEvent,
  RtcMessageEvent,
  RtcMessagePgm,
  RtcMessageSystemStatusReload,
  RtcMessageUserAccessControl,
  RtcMessageUserSystem,
  RtcMessageVersionUpdate,
  RtcService,
  WsPendingSystemMessage,
} from './rtc.service';
import * as socketIo from 'socket.io-client';
import { Injectable, OnDestroy } from '@angular/core';
import { useDebouncedFunction } from 'src/shim';
import { RegionService } from '../region.service';
import { UserService } from '../user.service';
import { Observable, Subject, Subscription } from 'rxjs';
import { AuthService } from '../auth.service';
import { LoggerService } from '../logger.service';
import { SystemsService } from 'src/app/services/systems.service';
import { SystemService } from '../system/system.service';
import { CurrentUserData } from 'src/api/v3/common';
import { LocatorService } from 'src/app/services/locator.service';
import { WebSocketOptions } from 'src/app/models/web-socket-options';

@Injectable()
export class WebRtcService implements RtcService, OnDestroy {
  private socket: any;

  private get region() { return LocatorService.injector.get(RegionService); }
  private get user() { return LocatorService.injector.get(UserService); }
  private get auth() { return LocatorService.injector.get(AuthService); }
  private get l() { return LocatorService.injector.get(LoggerService); }
  private lastIntrests: number[] = [];
  private lastUserId: number = null;
  private lastCompanyId: number = 0;
  private relatedCompanyIds: number[] = [];
  constructor() {
    setTimeout(
      () =>
        (this.accountChangeSubscription = this.auth.onAccountOrRegionChnage.subscribe(() => {
          this.reinitializeSafe();
        })),
      1500
    );
    const [reinitializeSafe, _, cancelReinitialize] = useDebouncedFunction(() => this.reInitialize(), 2000);
    this.reinitializeSafe = reinitializeSafe;
    this.cancelReinitialize = cancelReinitialize;
  }

  private reinitializeSafe: () => Promise<void>;
  private cancelReinitialize: () => void;

  ngOnDestroy(): void {
    this.close();
    this.accountChangeSubscription.unsubscribe();
  }

  private systemStatusChangeSubject = new Subject<RtcMessageSystemStatusReload>();
  private versionUpdateSubject = new Subject<RtcMessageVersionUpdate>();
  private eventsSubject = new Subject<RtcMessageEvent>();
  private areaStatusSubject = new Subject<RtcMessageAreaStatus>();
  private pgmSubject = new Subject<RtcMessagePgm>();
  private userSystemSubject = new Subject<RtcMessageUserSystem>();
  private configurationEventSubject = new Subject<RtcMessageConfigurationEvent>();
  private userAccessControlSubject = new Subject<RtcMessageUserAccessControl>();
  private pendingSystemsSubject = new Subject<WsPendingSystemMessage>();

  public systemStatusChange: Observable<RtcMessageSystemStatusReload> = this.systemStatusChangeSubject.asObservable();
  public versionUpdate: Observable<RtcMessageVersionUpdate> = this.versionUpdateSubject.asObservable();
  public events: Observable<RtcMessageEvent> = this.eventsSubject.asObservable();
  public areaStatus: Observable<RtcMessageAreaStatus> = this.areaStatusSubject.asObservable();
  public pgm: Observable<RtcMessagePgm> = this.pgmSubject.asObservable();
  public userSystem: Observable<RtcMessageUserSystem> = this.userSystemSubject.asObservable();
  public configurationEvent: Observable<RtcMessageConfigurationEvent> = this.configurationEventSubject.asObservable();
  public userAccessControl: Observable<RtcMessageUserAccessControl> = this.userAccessControlSubject.asObservable();
  public pendingSystems: Observable<WsPendingSystemMessage> = this.pendingSystemsSubject.asObservable();

  private accountChangeSubscription: Subscription;

  public close() {
    if (this.socket) {
      this.socket.disconnect();
      this.socket = null;
      this.l.log('Uždarytas socket', 'WebRtcService');
    }
  }

  public updateIntrests(systemIds: number[]): void {
    this.lastIntrests = systemIds;
    if (!this.socket) {
      this.reinitializeSafe();
      return;
    }
    const options: WebSocketOptions = {
      version: 3,
      systems: systemIds,
      user: this.lastUserId,
      company: this.lastCompanyId,
      companyRelations: this.relatedCompanyIds,
      channels: null,
    };
    this.socket.emit('client-options', options);
  }

  public connect(): void {
    if (this.socket) { return; }
    const oldSystems = LocatorService.injector.get(SystemsService).systems.map((s) => s.id);
    const systemService = LocatorService.injector.get(SystemService);
    const newSystems = systemService.systemsWithDevices.filter(d => d.system_id !== null ).map((d) => d.system_id);
    const filteredSystems = systemService.systemsWithDevices.filter(d => d.system_id !== null && !systemService.systemsWithDevices.find(sd => sd.system_id === d.system_id) ).map((d) => d.system_id);
    this.lastIntrests = [...new Set([...oldSystems, ...newSystems, ...filteredSystems]).values()];
    this.reinitializeSafe();
  }

  private async reInitialize(): Promise<void> {
    this.close();
    if (!this.auth.hasToken()) {
      this.l.log('Vartotojas neprisijungę, nabandome prisijungti.', 'WebRtcService');
      return;
    }
    let user: CurrentUserData = this.user.currentUser;
    if (!user?.socket_token) {
      this.l.log('Vartotojas neturi socket tokeno, bandome gauti tokeną ir tada bandysime vėl..', 'WebRtcService');
      user = await this.auth.loadUserData(false);
    }
    this.l.log('Prisijungiama prie socketo...', 'WebRtcService');
    this.socket = socketIo(this.region.ActiveRegion.backEndHost + ':' + user.socket_port, {
      multiplex: false,
      forceNew: false,
      query: 'token=' + user.socket_token,
    });
    this.lastUserId = user.id;
    this.lastCompanyId = user.company_id;
    this.relatedCompanyIds = user.belongsToCompany?.company_relations.map(cr => cr.company_id) ?? [];

    this.socket.on('connect', () => {
      this.l.log('Prisijungta prie socketo.', 'WebRtcService');
      this.updateIntrests(this.lastIntrests);
    });
    this.socket.on('system-status-reload', (msg) => {
      this.systemStatusChangeSubject.next(JSON.parse(msg));
    });
    this.socket.on('version-update', (msg) => {
      this.versionUpdateSubject.next(JSON.parse(msg));
    });
    this.socket.on('events-channel', (msg) => {
      this.eventsSubject.next(JSON.parse(msg));
    });
    this.socket.on('auto-reload-channel', (msg) => {
      this.areaStatusSubject.next(JSON.parse(msg));
    });
    this.socket.on('pgm-channel', (msg) => {
      this.pgmSubject.next(JSON.parse(msg));
    });
    this.socket.on('user-system:' + user.id, (msg) => {
      this.userSystemSubject.next(JSON.parse(msg));
    });
    this.socket.on('configuration-event:' + user.id, (msg) => {
      this.configurationEventSubject.next(JSON.parse(msg));
    });
    this.socket.on('pending-system', msg => {
      this.pendingSystemsSubject.next(JSON.parse(msg));
    });
  }
}
