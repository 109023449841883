import { Component, OnInit, ChangeDetectorRef, OnDestroy, Input } from '@angular/core';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { LanguageService } from 'src/app/services/language.service';
import { ApiRequestService } from 'src/app/services/api-request.service';
import { SystemsService } from 'src/app/services/systems.service';
import { NewSystemService } from 'src/app/services/new-system.service';
import { LoggerService } from 'src/app/api/logger.service';
import { PlatformService } from 'src/app/api/platform.service';
import { ToasterService } from 'src/app/services/toaster.service';
import { HeaderService } from 'src/app/services/header.service';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { AreaService } from 'src/app/services/area.service';
import { TSystemData } from 'src/app/models/system-data';
import { AppSettings } from 'src/app-settings';
import { GlobalService } from 'src/app/services/global.service';
import { PopupService } from 'src/app/services/popup.service';
import { PopupType } from 'src/app/models/popup-type';
import { RegionService } from 'src/app/api/region.service';
import { AppRegion } from 'src/environments/environment.types';
import { LoginService } from 'src/app/login/login.service';
import { ReactionService } from 'src/app/api/system/reaction.service';
import { PgmService } from 'src/app/api/system/pgm.service';
import { SensorService } from 'src/app/api/system/sensor.service';
import { UserService } from 'src/app/api/user.service';
import { AuthService } from 'src/app/api/auth.service';
import { EditSystemService } from 'src/app/services/edit-system.service';
import { TEditableComponent } from 'src/app/models/editable-component';
import { SystemService } from 'src/app/api/system/system.service';
import { CompanyService } from 'src/app/api/company.service';
import { PermissionRole } from 'src/api/v3/permission';
import { CommonModule } from '@angular/common';
import { PopupComponent } from 'src/app/popups/popup/popup.component';
import { DotComponent } from '../dot/dot.component';
import { InlineSVGModule } from 'ng-inline-svg-w';
import { AccountSwitcherComponent } from 'src/app/login/components/account-switcher/account-switcher.component';
import { ButtonSingleLineArrowComponent } from 'src/app/ui/button-single-line-arrow/button-single-line-arrow.component';
import { LoaderRoundComponent } from 'src/app/components/loader-round/loader-round.component';
import { SystemListItemComponent } from 'src/app/components/system-list-item/system-list-item.component';
import { CpSystemListItemComponent } from 'src/app/mobile-company/cp-system-list-item/cp-system-list-item.component';
import { PendingSystemService } from 'src/app/api/system/pending-system.service';

@Component({
  selector: 'app-sidemenu',
  templateUrl: './sidemenu.component.html',
  styleUrls: ['./sidemenu.component.scss'],
  animations: [trigger('spin', [state('1', style({ transform: 'rotate(0deg)' })), state('2', style({ transform: 'rotate(360deg)' })), transition('1 => 2', animate('0.2s'))])],
  standalone: true,
  imports: [CommonModule, PopupComponent, DotComponent, InlineSVGModule, AccountSwitcherComponent, ButtonSingleLineArrowComponent, RouterLink, LoaderRoundComponent, SystemListItemComponent, CpSystemListItemComponent],
})
export class SidemenuComponent implements OnInit, OnDestroy {
  private translationSubscription = null;
  public systemListShowing = false;
  public controlsShowingForSystem = 0;
  public regionsEnabled = AppSettings.regionsEnabled;
  public currentRegion = this.region.ActiveRegion.backEndHost;
  public newVersionAvailable = false;
  public isInRegions = false;
  public activeRegionName = '';
  public selectedRegion: any;
  private loginConfirmed = false;
  public hasAccounts = this.rs.hasAccounts;
  public PermissionRole = PermissionRole;
  public readonly desktopView = this.pl.isDesktopView();
  public moreSystemsLoading = false;
  public isInsideSystem = this.systems.activeSystem && this.pl.isDesktopView();
  isInProfile = false;
  @Input() menuPosition = -40;
  @Input() isDragging = false;

  constructor(
    public headerService: HeaderService,
    private cdRef: ChangeDetectorRef,
    public ls: LanguageService,
    private router: Router,
    private rs: ApiRequestService,
    public systems: SystemsService,
    private ns: NewSystemService,
    private l: LoggerService,
    public pl: PlatformService,
    private toaster: ToasterService,
    public areaService: AreaService,
    public us: UserService,
    public g: GlobalService,
    private pp: PopupService,
    public region: RegionService,
    private login: LoginService,
    private reactions: ReactionService,
    private pgmS: PgmService,
    private senS: SensorService,
    private api: ApiRequestService,
    private auth: AuthService,
    private es: EditSystemService,
    private systemService: SystemService,
    public companyService: CompanyService,
    private ar: ActivatedRoute,
    public pendingSystemService: PendingSystemService,
  ) {
    this.translationSubscription = this.ls.onTranslationsChange.subscribe(() => {
      this.translationChanged();
    });
    this.l.log('Turimas regionas: ' + this.currentRegion, 'SM');
    const savedState = localStorage.getItem('side_list_open');
    this.systemListShowing = !savedState || savedState === '1';
    this.updateActiveRegionName();
  }

  ngOnInit() {}

  public show() {
    this.l.log('Rodom', 'SideMenu');
    this.headerService.sideMenuOpen = true;
    setTimeout(() => {
      this.menuPosition = 0;
      this.newVersionAvailable = this.g.isNewVersionAvailable();
    }, 50);
  }

  public hide() {
    this.l.log('Paslepiam', 'SideMenu');
    this.menuPosition = -50;
    setTimeout(() => {
      this.headerService.sideMenuOpen = false;
      this.isInProfile = false;
      this.isInRegions = false;
    }, 300);
  }

  public toggleProfilePart() {
    if(this.isInRegions) { this.isInRegions = false; }
    this.isInProfile = !this.isInProfile;
  }

  toggleRegionPart() {
    if(this.isInProfile) { this.isInProfile = false; }
    this.isInRegions = !this.isInRegions;
  }

  ngOnDestroy() {
    this.translationSubscription.unsubscribe();
  }

  private translationChanged() {
    this.cdRef.markForCheck();
  }

  public trans(keyword) {
    return this.ls.get(keyword);
  }

  public isLoggedIn(): boolean {
    return this.rs.hasToken();
  }

  public helpClick() {
    this.hide();
    this.router.navigate([...this.g.resolveRootRoute(), 'help']);
  }

  public profileClick() {
    this.hide();
    this.router.navigate([...this.g.resolveRootRoute(), 'profile']);
  }

  public settingsClick() {
    if (this.pl.isAndroid()) {
      this.pl.androidHandler().openSettings();
    } else if (this.pl.isApple()) {
      this.pl.appleHandler().openSettings.postMessage('');
    }
  }

  public toggleSystemListView() {
    this.systemListShowing = !this.systemListShowing;
    localStorage.setItem('side_list_open', this.systemListShowing ? '1' : '0');
  }

  public systemClicked(systemId: number) {
    if (this.systems.activeSystem?.id === systemId) {
      return;
    }
    this.systems.setCurrentSystem(this.systems.getSystem(systemId) || this.systemService.systems.get(systemId));
    this.hide();
    this.router.navigate(this.g.getHomeUrl());
  }

  public systemSettingsClick() {
    this.hide();
  }

  public eventsClick() {
    this.hide();
  }

  public addSystemClick() {
    this.hide();
    this.ns.setDefaults();
    this.router.navigate([ ...this.g.resolveRootRoute(), 'new-system']);
  }

  public usersClick() {
    this.hide();
  }

  public sensorsClick() {
    this.hide();
  }

  public controlClick() {
    this.hide();
  }

  public getStatusIconForSystem(system: TSystemData) {
    if (system.areas.length !== 1) {
      return '';
    }

    return this.areaService.getIconByAreaStatusEx(system.areas[0]);
  }

  public systemCamerasClick() {
    this.hide();
  }

  public isSystemDisarmed(system: TSystemData): boolean {
    if (system.areas.length !== 1) {
      return false;
    }

    return system.areas[0].status === 1;
  }

  public isSystemAlarmed(system: TSystemData): boolean {
    if (system.areas.length !== 1) {
      return false;
    }

    return system.areas[0].alarmed;
  }

  public showHelp(): boolean {
    return AppSettings.showHelp;
  }

  public showVersion(): boolean {
    return AppSettings.showAppVersion;
  }

  public goBackToCompany() {
    this.hide();
    this.systems.setCurrentSystem(null);
    this.router.navigate(this.g.getHomeUrl());
  }

  public allEventsClicked() {
    this.hide();
    this.router.navigate([...this.g.getHomeUrl(), 'events']);
  }

  public allUsersClicked() {
    this.hide();
    this.router.navigate([...this.g.getHomeUrl(), 'users']);
  }

  public gotoGlobalSettings() {
    this.hide();
    this.router.navigate([...this.g.getHomeUrl(), 'global-settings']);
  }

  public onRegionSelect(region: any) {
    this.selectedRegion = region;
    if (this.us.userPassword === '' || !this.loginConfirmed) {
      this.pp.showPopup(
        {
          headerText: this.trans('auth.form.password'),
          field1: {
            oldValue: '',
          },
          onSubmitString: (res) => {
            this.us.userPassword = res;
            this.performLogin();
          },
        },
        PopupType.Password
      );
    } else {
      this.changeRegion();
    }
  }

  private performLogin() {
    this.api.post('/login', { email: this.us.currentUser.email, password: this.us.userPassword, }, false).subscribe(
      async (response) => {
        if (response.success) {
          await this.router.navigate(this.g.resolveRootRoute());
          this.loginConfirmed = true;
          this.changeRegion();
          this.loadNewRegionData(response);
        } else {
          this.us.userPassword = '';
          this.toaster.postError(response.error);
        }
      },
      (error) => {
        this.us.userPassword = '';
        this.toaster.postError(this.trans('auth.failed'));
      }
    );
  }

  private changeRegion() {
    if (this.selectedRegion.region_version < 2) {
      this.toaster.postError('Sena versija');
      return;
    }
    const beforeRegion = this.region.ActiveRegion;
    this.currentRegion = this.selectedRegion.api_host_to_use ? this.selectedRegion.api_host_to_use : this.selectedRegion.backEndHost;
    const bestRegion = AppSettings.regions.find((r) => r.backEndHost === this.currentRegion);

    if (bestRegion) {
      const regionToUse = bestRegion;
      this.region.useRegion(regionToUse);
    } else {
      this.region.useRegion({
        ...beforeRegion,
        id: this.currentRegion.substring(0, 5),
        backEndHost: this.currentRegion,
      });
    }
    this.doRegionLogin(beforeRegion);
  }

  public doRegionLogin(beforeRegion: AppRegion) {
    this.api.post('/login', { email: this.us.currentUser.email, password: this.us.userPassword, }, false).subscribe(
      async (response) => {
        if (response.success) {
          await this.router.navigate(this.g.resolveRootRoute());
          this.loadNewRegionData(response);
        } else {
          this.toaster.postError(response.error);
          this.region.useRegion(beforeRegion);
          this.currentRegion = beforeRegion.backEndHost;
          this.us.userPassword = '';
        }
      },
      (error) => {
        this.toaster.postError(this.trans('auth.failed'));
        this.region.useRegion(beforeRegion);
        this.currentRegion = beforeRegion.backEndHost;
      }
    );
  }

  private loadNewRegionData(response) {
    this.hide();
    this.currentRegion = this.region.ActiveRegion.backEndHost;
    this.api.setToken(response.token);
    this.us.setCurrentUserFromRaw(response);
    this.us.change();
    this.reactions.loadReactions();
    this.pgmS.loadIcons();
    this.senS.loadIcons();
    this.us.setLoginType('default');
    this.auth.loadMergedRegionAccounts();
    this.updateActiveRegionName();
  }

  public openVersion($event) {
    $event.stopPropagation();
    this.hide();
    this.router.navigate([...this.g.resolveRootRoute(), 'version']);
  }

  public addNewAccount() {
    localStorage.setItem('ref', 'addAccount');
    this.hide();
    this.router.navigate(['/login/email'], { replaceUrl: true });
  }

  public doLogout() {
    this.hide();
    this.login.logout();
    this.auth.mergedRegionAccounts.map(mra => mra.active = false);
    this.router.navigate(['/login'], { replaceUrl: true });
  }

  public allCompaniesClicked() {
    this.hide();
    this.router.navigate([...this.g.getHomeUrl(), 'companies']);
  }

  public allRolesClicked() {
    this.hide();
    this.router.navigate([...this.g.getHomeUrl(), 'permissions']);
  }

  public allTagsClicked() {
    this.hide();
    this.router.navigate([...this.g.getHomeUrl(), 'tags']);
  }

  public allSettingsClicked() {
    this.hide();
    this.router.navigate([...this.g.getHomeUrl(), 'settings']);
  }

  public companySettingsClicked() {
    this.hide();
    this.es.beginComponentEdit(TEditableComponent.Company, this.us.currentUser.belongsToCompany.id, this.us.currentUser.belongsToCompany);
    this.router.navigate([...this.g.getHomeUrl(), 'settings', 'general']);
  }

  public allSystemsClick() {
    this.hide();
    this.router.navigate([...this.g.getHomeUrl()]);
  }

  private updateActiveRegionName() {
    if(this.us.userRegions.length === 0) {
      this.us.populateUserRegions();
    }
    this.region.updateActiveRegionName(this.us.userRegions);
  }

  public loadMoreSystems(event: InputEvent) {
    event.stopPropagation();
    event.preventDefault();
    if ( this.moreSystemsLoading ) { return; }
    if ( this.systems.getSystemCount() % this.systems.systemPageSize !== 0 ) {
      return;
    }
    this.moreSystemsLoading = true;
    this.systems.loadSystems(true, () => {
      this.moreSystemsLoading = false;
    });
  }

  public pendingSystemsClicked() {
    this.hide();
    this.router.navigate([...this.g.getHomeUrl(), 'pending-systems']);
  }
}
