import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, UrlTree } from '@angular/router';
import { AuthService } from '../api/auth.service';
import { LoggerService } from '../api/logger.service';
import { UserService } from '../api/user.service';
import { LoginService } from '../login/login.service';
import { ToastType } from '../models/toaster-type';
import { LanguageService } from '../services/language.service';
import { LocatorService } from '../services/locator.service';
import { ToasterService } from '../services/toaster.service';

/**
 * 1. Patikrina ar localStorage turi išsaugotą token.
 * 2. Patikrina ar token laikas nepasibaigęs.
 * 3. Užkrauna vartotojo duomenis iš localStorage.
 * 4. Atnaujina vartotojo duomenis iš serverio.
 *   4.1 Blokuoja UI, jeigu vartotojo duomenų nėra localStorage.
 *   4.2 Neblokuoja UI, kai duomenys yra.
 */

@Injectable({
    providedIn: 'root',
})
export class AuthGuard  {
    private readonly tag = 'AuthGuard';
    constructor(
        private auth: AuthService,
        private router: Router,
        private l: LoggerService,
    ) {}

    public async canActivate(ar: ActivatedRouteSnapshot): Promise<boolean|UrlTree> {
        if ( !this.auth.hasToken() ) {
            this.l.log('Nėra token. Siunčiam į login.', this.tag);
            return this.router.parseUrl('/login');
        }
        const decoded = this.auth.getTokenData();
        this.l.log('Turim tokena: ', this.tag, new Date(decoded.exp * 1000));
        const nowTime = Date.now() / 1000;
        this.l.log('tikrinam token: (now)(decoded)', this.tag, { nowTime, exp: decoded.exp });
        if (nowTime > decoded.exp) {
            const loginService = LocatorService.injector.get(LoginService);
            const toaster = LocatorService.injector.get(ToasterService);
            const lang = LocatorService.injector.get(LanguageService);
            toaster.post(lang.get('auth.sessionExpired'), ToastType.Warning);
            loginService.logout();
            return false;
        }
        const userService = LocatorService.injector.get(UserService);
        if ( !userService.currentUser ) {
            this.l.log('Nėra vartotojo. Kraunam iš local storage.', this.tag);
            userService.loadUserDataFromStorage();
        }
        if ( !userService.currentUser ) {
            this.l.log('Vis dar nėra vartotojo. Kraunam iš serverio.', this.tag);
            try {
                await this.auth.loadUserData();
                this.l.log('Baigėm kraut vartotojo duomenis sync.', this.tag);
            } catch (e) {
                const loginService = LocatorService.injector.get(LoginService);
                loginService.logout();
                return this.router.parseUrl('/login');
            }
        } else {
            this.l.log('Naujinam duomenis iš serverio. async.', this.tag);
            this.auth.loadUserData(); // kadangi turime duomenis iš storage, tai atnaujinimą darome neblokuojant UI.
        }
        return true;
    }
}
