import { ConfigType } from 'src/api/requests/config';
import { Environment, UrlProtocol } from './environment.types';

export const environment: Environment = {
  production: false,
  regions: [
    {
      id: 'l',
      backEndHost: '192.168.1.101',
      port: '8000',
      protocol: UrlProtocol.http,
      gmtOffsetStart: 0,
      gmtOffsetEnd: 0,
    },
    {
      id: 'be',
      backEndHost: 'beta2.protegus.app',
      port: '',
      protocol: UrlProtocol.https,
      gmtOffsetStart: 0,
      gmtOffsetEnd: 0,
    },
    // { id: "xs2", backEndHost: "xweb.xsecure.no", port: "", protocol: UrlProtocol.https, gmtOffsetStart: -660, gmtOffsetEnd: 841, },
  ],
  logToConsole: true,
  showLogger: true,
  showHelp: true,
  showAppVersion: true,
  appVersionMajor: 0,
  appVersionMinor: 0,
  appVersionBuild: 0,
  appVersionDate: 0,
  appCopyright: 'UAB Trikdis Copyright &reg; Trikdis EU 018535771 Protegus &reg',
  deviceTransferEnabled: true,
  deviceTransferToAppEnabled: true,
  limitationSystemEnabled: true,
  loadAllUsersForSU: true,
  loadAllSystemsForSU: false,
  canAutoCreateAccounts: true,
  onlySUcanAddRemoveUsers: false,
  googleLoginSupported: false,
  appleLoginSupported: false,
  title: 'LocalProtegus',
  address: 'Draugystės g. 17, LT-51229 Kaunas, Lietuva',
  email: 'info@trikdis.lt',
  phone: '+370 37 408040',
  taxId: 'LT347641610',
  companyUrl: 'https://trikdis.lt',
  takeActionButtonDuration: 300,
  canDeleteUserFromList: false,
  canDeleteSystemFromList: false,
  showConfig: true,
  googleClientIdWeb: '131091336687-8kg1fhrbstoakft532hajc6kmke10ft3.apps.googleusercontent.com',
  sentry: null,
  localReport: true,
  companyDesktopEnabled: true,
  greSiteKey: '6LeDpgcjAAAAAJp5l5I-K4_9ZyG3UEZiyjFRzxhc',
  wifiGateDevice37Name: 'WP17',
  primaryColor: '#00337a',
  secondaryColor: '#7a0000',
  companyRegistrationSupported: true,
  configTypeOverrides: [ { type: ConfigType.WP17, override: 'WOPO' }],
  regionsEnabled: true,
  companyPaymentsEnabled: true,
};

export const AppSettings = environment;

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
