import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LoggerService } from 'src/app/api/logger.service';
import { SystemService } from 'src/app/api/system/system.service';
import { UserService } from 'src/app/api/user.service';
import { LoginService } from 'src/app/login/login.service';
import { GlobalService } from 'src/app/services/global.service';
import { SystemsService } from 'src/app/services/systems.service';

@Component({
  selector: 'app-loading-screen',
  template: '',
  standalone: true,
  imports: [],
})
export class LoadingScreenComponent implements OnInit {
  constructor(
    private login: LoginService,
    private user: UserService,
    private router: Router,
    private route: ActivatedRoute,
    private l: LoggerService,
    private ss: SystemsService,
    private systemService: SystemService,
    private g: GlobalService,
  ) {}

  ngOnInit() {
    if (this.user.currentUser) {
      const systemIdFromUrl = this.route.snapshot.paramMap.get('systemId');
      const areaIdFromUrl = this.route.snapshot.paramMap.get('areaId');
      const areaIdInt = areaIdFromUrl !== null ? parseInt(areaIdFromUrl, 10) : 0;
      if ( systemIdFromUrl !== null ) {
        this.l.log('Per parametrus gavom sistemos id, bandom ją užkraut', 'Loading', systemIdFromUrl);
        const systemIdInt = parseInt(systemIdFromUrl, 10);
        if ( !isNaN(systemIdInt) && (!this.ss.activeSystem || this.ss.activeSystem.id !== systemIdInt) ) {
          const found = this.ss.getSystem(systemIdInt) || this.systemService.systems.get(systemIdInt);
          if ( found ) {
            this.ss.setCurrentSystem(found);
            this.l.log('Sistema rasta ir užkrauta.', 'Loading');
          }
        }
      }
      if ( areaIdFromUrl !== null && !isNaN(areaIdInt) ) {
        this.l.log('Per parametrus gavom srities ID. Bandom ją užkraut.', 'Loading', areaIdInt);
        const areaSystem = this.ss.systems.find(s => s.areas.find(a => a.id === areaIdInt));
        if ( areaSystem ) {
          if ( areaSystem.id !== this.ss.activeSystem.id ) {
            this.ss.setCurrentSystem(areaSystem);
          }
          this.l.log('Dominanti sritis rasta, nustatom.', 'Loading');
          this.ss.setCurrentArea(areaIdInt);
          this.router.navigate([...this.g.getHomeUrl(), 'area-view']);
          return;
        }
      }
      const last = localStorage.getItem('last_system');
      if ( !this.user.currentUser.permissions.permissions.systems.view && !this.user.currentUser.permissions.permissions.unassigned_devices.view && last && last !== '0' && last !== '' ) {
        this.router.navigate(['/', last]);
        return;
      }
      this.router.navigate(this.g.getHomeUrl(), { replaceUrl: true });
    } else {
      this.router.navigate(['/login'], { replaceUrl: true });
    }
  }
}
