import { AfterViewInit, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { PgmService } from 'src/app/api/system/pgm.service';
import { SensorService } from 'src/app/api/system/sensor.service';
import { TBackgroundType } from 'src/app/models/background-types';
import { HomeConfiguration, HomeConfigurationElementType } from 'src/app/models/home-configuration';
import { TSwipeCoordinate } from 'src/app/models/swipe-coordinate';
import { GlobalService } from 'src/app/services/global.service';
import { LanguageAware } from '../language-aware';
import { CommonModule } from '@angular/common';
import { InlineSVGModule } from 'ng-inline-svg-w';
import { CheckboxComponent } from '../checkbox/checkbox.component';

@Component({
  selector: 'app-home-configuration',
  templateUrl: './home-configuration.component.html',
  styleUrls: ['./home-configuration.component.scss'],
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [CommonModule, InlineSVGModule, CheckboxComponent],
})
export class HomeConfigurationComponent extends LanguageAware implements OnInit, OnDestroy, AfterViewInit {
  private touchStartListener = null;
  private touchMoveListener = null;
  private touchEndListener = null;
  private touchStartedAt: TSwipeCoordinate = null;
  private configurationBefore: HomeConfiguration = null;
  private configurationModified: HomeConfiguration = null;
  public expandedType: HomeConfigurationElementType | '' = '';
  public draggingType: HomeConfigurationElementType | '' = '';
  private draggingElement: HTMLElement = null;
  private draggingElementTopStart = 0;
  private draggingElementHeight = 0;
  private draggingElementStartingPosition = 0;
  private draggingElementTopMargin = 15;
  private draggingElementCurrentPosition = 0;
  private maxPossiblePosition = 0;
  private headerHeight = 0;
  private footerTop = 0;
  public allowedAreas = [];
  public allowedOutputs = [];
  public allowedSensors = [];
  public allowedCameras = [];
  public allowedThermostats = [];
  private lastMovementSample: Date = new Date();

  constructor(
    cdRef: ChangeDetectorRef,
    private router: Router,
    g: GlobalService,
    private pgmS: PgmService,
    private senS: SensorService
  ) {
    super(cdRef, { backgroundType: TBackgroundType.Gradient });
    this.tag = 'HomeConfig';
    this.headerBar.showHeader({
      headerText: this.trans('systems.menu.editHomeScreen'),
      backUrl: '*',
      brightMode: true,
    });
    this.footerBar.showFooter(this.trans('general.cancel'), this.trans('users.buttons.confirm'), false, false);
    this.footerBar.setWhite();
    this.footerBar.onButton1Click = () => {
      this.onCancel();
    };
    this.footerBar.onButton2Click = () => {
      this.onSave();
    };
    this.touchStartListener = g.onSwipeStarted.subscribe((coords: TSwipeCoordinate) => {
      this.handleStart(coords);
    });
    this.touchMoveListener = g.onSwipeContinue.subscribe((coords: TSwipeCoordinate) => {
      this.handleMove(coords);
    });
    this.touchEndListener = g.onSwipeEnded.subscribe((coords: TSwipeCoordinate) => {
      this.handleEnd(coords);
    });
    this.loadConfigurations();
    this.maxPossiblePosition = this.configurationBefore.elementOrder.length;
    this.allowedAreas = this.getAllowedAreas();
    this.allowedOutputs = this.getAllowedOutputs();
    this.allowedSensors = this.getAllowedSensors();
    this.allowedCameras = this.getAllowedCameras();
    this.allowedThermostats = this.getAllowedThermostats();
  }

  ngOnInit(): void {
    // Panaudojam helperį, kad mums suskaičiuotų header ir footer aukščius.
    this.helper.setContentRowHeight(false, true, true, 'home-config');
    this.headerHeight = this.helper.headerHeight;
    this.footerTop = this.helper.headerHeight + this.helper.getContentRowHeight('home-config');
  }

  ngAfterViewInit() {
    if( this.platform.isDesktopView() ) {
      const homeConfiguration = document.querySelector('.bg-gray');
      homeConfiguration?.classList.remove('bg-gray');
    }
  }

  ngOnDestroy() {
    if (this.touchStartListener !== null) {
      this.touchStartListener.unsubscribe();
    }
    if (this.touchMoveListener !== null) {
      this.touchMoveListener.unsubscribe();
    }
    if (this.touchEndListener !== null) {
      this.touchEndListener.unsubscribe();
    }
  }

  private loadConfigurations() {
    const config = this.us.currentUser.homeConfigurations.find((c) => c.system_id === this.systems.activeSystem.id);
    if (config === undefined) {
      this.configurationBefore = this.systems.getDefaultHomeConfigurationForActiveSystem();
      this.configurationModified = this.systems.getDefaultHomeConfigurationForActiveSystem();
      this.configurationBefore.system_id = this.systems.activeSystem.id;
      this.configurationModified.system_id = this.systems.activeSystem.id;
      return;
    }
    const objstr = JSON.stringify(config);
    this.configurationBefore = JSON.parse(objstr);
    this.configurationModified = JSON.parse(objstr);
  }

  private onCancel() {
    history.back();
  }

  private onSave() {
    this.log('Saugoma konfigūracija', this.configurationModified);
    const beforeStr = JSON.stringify(this.configurationBefore);
    const activeSystemId = this.systems.activeSystem.id;
    this.applyChanges();
    this.api.post('/edit-home-configuration', this.configurationModified, true).subscribe(
      (result) => {
        if (!result.success) {
          this.toaster.postError(result.error);
          this.revertChanges(beforeStr, activeSystemId);
          this.log('Gauta klaida ' + result.error);
          return;
        }
        this.log('Nustatymai sėkmingai išsaugoti.');
      },
      (error) => {
        this.revertChanges(beforeStr, activeSystemId);
        this.log('Klaida serverio pusėje');
      }
    );
    history.back();
  }

  private handleStart(coords: TSwipeCoordinate) {
    this.touchStartedAt = coords;
    const elements = document.elementsFromPoint(coords.x, coords.y);
    let foundIcon = false;
    let parentType = '';
    let topOffset = 0;
    for (const iElement of elements) {
      if (iElement.className.indexOf === undefined) {
        continue;
      }
      if (iElement.className.indexOf('hcf-icon') !== -1) {
        foundIcon = true;
      } else if (iElement.className.indexOf('hcf-element') !== -1) {
        parentType = iElement.id.replace('hcf-', '');
        topOffset = iElement.getBoundingClientRect().top;
      }
    }

    if (!foundIcon) {
      this.draggingElement = null;
      return;
    }
    this.expandedType = ''; // Padarom taip, kad visi laukai būtų vienodo aukščio.
    this.draggingType = parentType as HomeConfigurationElementType;
    this.draggingElement = document.getElementById('hcf-' + parentType);
    this.draggingElement.style.top = topOffset.toString() + 'px';
    this.draggingElementTopStart = topOffset;
    this.draggingElementHeight = this.draggingElement.getBoundingClientRect().height;
    this.draggingElementStartingPosition = this.getTypePosition(parentType as HomeConfigurationElementType);
    this.draggingElementCurrentPosition = this.draggingElementStartingPosition;
  }

  private handleMove(coords: TSwipeCoordinate) {
    if (this.draggingType === '') {
      return;
    }
    if (new Date().getTime() - this.lastMovementSample.getTime() < 100) {
      return;
    }
    const deltaY = coords.y - this.touchStartedAt.y;
    let newTop = this.draggingElementTopStart + deltaY;
    let stopFurtherCalculations = false;
    if (newTop < this.headerHeight) {
      newTop = this.headerHeight;
      stopFurtherCalculations = true;
    } else if (newTop + this.draggingElementHeight > this.footerTop) {
      newTop = this.footerTop - this.draggingElementHeight;
      stopFurtherCalculations = true;
    }
    this.draggingElement.style.top = newTop.toString() + 'px';

    if (stopFurtherCalculations) {
      return;
    }
    const isUp = deltaY < 0;
    let positionsToMove = Math.abs(deltaY) - this.draggingElementHeight / 2 - this.draggingElementTopMargin >= 0 ? 1 : 0;
    if (positionsToMove > 0) {
      const deltaYMinusOne = Math.abs(deltaY) - this.draggingElementHeight / 2 - this.draggingElementTopMargin;
      positionsToMove += Math.floor(deltaYMinusOne / (this.draggingElementHeight + this.draggingElementTopMargin));
    }
    positionsToMove *= isUp ? -1 : 1;
    let repeat = true;
    while (repeat) {
      repeat = false;
      if (this.draggingElementStartingPosition + positionsToMove < 1) {
        positionsToMove = (this.draggingElementStartingPosition - 1) * -1;
      }
      if (this.draggingElementStartingPosition + positionsToMove > this.maxPossiblePosition) {
        positionsToMove = this.maxPossiblePosition - this.draggingElementStartingPosition;
      }

      if (this.draggingElementStartingPosition + positionsToMove !== this.draggingElementCurrentPosition) {
        this.draggingElementCurrentPosition = this.draggingElementStartingPosition + positionsToMove;
        const otherElementToMove = this.configurationModified.elementOrder.find((e) => e.position === this.draggingElementCurrentPosition);
        const ourElement = this.configurationModified.elementOrder.find((e2) => e2.type === this.draggingType);
        if (otherElementToMove !== undefined) {
          otherElementToMove.position = ourElement.position;
        }
        ourElement.position = this.draggingElementCurrentPosition;
        const otherElementNative = document.getElementById('hcf-' + otherElementToMove.type);
        if (otherElementNative !== undefined && otherElementNative.offsetHeight === 0) {
          otherElementToMove.visible = false;
          this.draggingElementCurrentPosition = ourElement.position;
          this.draggingElementStartingPosition = ourElement.position;
          repeat = true;
        }
      }
    }
  }

  private handleEnd(coords: TSwipeCoordinate) {
    this.draggingType = '';
    this.draggingElement = null;
    this.draggingElementTopStart = 0;
  }

  public typeChecked(type: string, value: boolean) {
    const found = this.configurationModified.elementOrder.find((t) => t.type === type);
    found.visible = !value;
  }

  public isTypeChecked(type: string): boolean {
    const found = this.configurationModified.elementOrder.find((t) => t.type === type);
    return found !== undefined && found.visible;
  }

  public expandType(type: HomeConfigurationElementType) {
    if (this.expandedType === type) {
      this.expandedType = '';
    } else {
      this.expandedType = type;
    }
  }

  public getTypePosition(type: HomeConfigurationElementType): number {
    if (!this.isTypeAvailable(type)) {
      return 0;
    }
    return this.configurationModified.elementOrder.find((t) => t.type === type).position;
  }

  private applyChanges() {
    const config = this.us.currentUser.homeConfigurations.find((c) => c.system_id === this.systems.activeSystem.id);
    const list = this.us.currentUser.homeConfigurations.filter((cf) => cf.system_id !== this.systems.activeSystem.id);
    list.push(this.configurationModified);
    this.us.currentUser.homeConfigurations = list;
    this.us.saveCurrentUser();
  }

  private revertChanges(oldSettings: string, systemId: number) {
    const config = this.us.currentUser.homeConfigurations.find((c) => c.system_id === systemId);
    if (config === undefined && !(this.systems.getSystem(systemId) || this.systemService.systems.get(systemId))) {
      return;
    } // Sistema ištrinta iki kol gavom atsakymą.
    const oldObj = JSON.parse(oldSettings);
    const list = this.us.currentUser.homeConfigurations.filter((cf) => cf.system_id !== systemId);
    list.push(oldObj);
    this.us.currentUser.homeConfigurations = list;
    this.us.saveCurrentUser();
  }

  public isTypeAvailable(type: string) {
    return this.configurationModified.elementOrder.find((t) => t.type === type) !== undefined;
  }

  public getAllowedAreas(): any {
    const allAreas = this.systems.activeSystem.areas;
    const areasToReturn = [];
    const areaConfig = this.configurationModified.visibleAreas;
    const selectAll = areaConfig.length === 0;
    if (!this.systems.activeSystem.amIMaster) {
      const systemPerson = this.systems.activeSystem.device_users.find((u) => u.protegus_user_id === this.us.currentUser.id);
      if (systemPerson === undefined) {
        return [];
      }
      for (const iArea of allAreas) {
        if (systemPerson.areas.length < iArea.queue_no) {
          continue;
        }
        if (systemPerson.areas.charAt(iArea.queue_no - 1) === '1') {
          areasToReturn.push({
            visible: selectAll,
            queue_no: iArea.queue_no,
            name: iArea.name,
          });
        }
      }
    } else {
      for (const iArea of allAreas) {
        areasToReturn.push({
          visible: selectAll,
          queue_no: iArea.queue_no,
          name: iArea.name,
        });
      }
    }

    if (!selectAll) {
      for (const iArea of areasToReturn) {
        iArea.visible = areaConfig.indexOf(iArea.queue_no) !== -1;
      }
    }
    return areasToReturn;
  }

  public checkArea(areaQueueNo: number, value?: boolean) {
    if (value !== undefined) {
      value = !value;
    }
    const areaConfig = this.configurationModified.visibleAreas;
    /** Buvo tuščia, o dabar vartotojas kažką paspaudė
     * Kol buvo tuščia, tai leidom matyti viską, ir viską pažymėjom (allowedAreas masyve viskas buvo visible),
     * o dabar jau reikia kažką nužymėti. Vadinasi visas kitas sritis reikia sudėti
     * į sąrašą su 'visible': true
     */
    if (areaConfig.length === 0 && this.allowedAreas.filter((aa) => aa.visible).length === this.allowedAreas.length) {
      for (const iArea of this.allowedAreas) {
        if (iArea.queue_no === areaQueueNo) {
          iArea.visible = false; // Dabartinę sritį tiesiog paslepiam.
          continue;
        }
        areaConfig.push(iArea.queue_no);
      }
      this.checkForNoneOrSome(this.allowedAreas, 'areas');
      return;
    }
    const index = areaConfig.indexOf(areaQueueNo);
    if (index === -1 && (value === undefined || value)) {
      areaConfig.push(areaQueueNo);
    } else if (index !== -1 && (value === undefined || !value)) {
      areaConfig.splice(index, 1);
    }
    const allowedArea = this.allowedAreas.find((a) => a.queue_no === areaQueueNo);
    allowedArea.visible = areaConfig.indexOf(areaQueueNo) !== -1;
    this.checkForNoneOrSome(this.allowedAreas, 'areas');
  }

  private getAllowedOutputs(): any {
    const allPgms = this.systems.activeSystem.pgms.filter((p) => !p.control_area && p.enabled);
    const pgmConfig = this.configurationModified.visibleOutputs;
    const pgmsToReturn = [];
    const selectAll = pgmConfig.length === 0;
    if (!this.systems.activeSystem.amIMaster) {
      const systemPerson = this.systems.activeSystem.device_users.find((u) => u.protegus_user_id === this.us.currentUser.id);
      if (systemPerson === undefined) {
        return [];
      }
      for (const iPgm of allPgms) {
        let addPgm = false;
        if (systemPerson.pgms === -1 || systemPerson.pgms === null) {
          addPgm = true;
          /* eslint-disable no-bitwise */
        } else if (((1 << (iPgm.queue_no - 1)) & systemPerson.pgms) === 1) {
          /* eslint-enable no-bitwise */
          addPgm = true;
        }
        if (addPgm) {
          pgmsToReturn.push({
            visible: selectAll,
            queue_no: iPgm.queue_no,
            name: iPgm.name,
            iconPath: this.pgmS.getPgmIcon(iPgm.icon, iPgm.on, iPgm.enabled),
          });
        }
      }
    } else {
      for (const iPgm of allPgms) {
        pgmsToReturn.push({
          visible: selectAll,
          queue_no: iPgm.queue_no,
          name: iPgm.name,
          iconPath: this.pgmS.getPgmIcon(iPgm.icon, iPgm.on, iPgm.enabled),
        });
      }
    }

    if (!selectAll) {
      for (const iPgm of pgmsToReturn) {
        iPgm.visible = pgmConfig.indexOf(iPgm.queue_no) !== -1;
      }
    }

    return pgmsToReturn;
  }

  public checkPgm(outputQueueNo: number, value?: boolean) {
    if (value !== undefined) {
      value = !value;
    }
    const pgmConfig = this.configurationModified.visibleOutputs;
    if (pgmConfig.length === 0 && this.allowedOutputs.filter((ao) => ao.visible).length === this.allowedOutputs.length) {
      for (const iPgm of this.allowedOutputs) {
        if (iPgm.queue_no === outputQueueNo) {
          iPgm.visible = false; // config nera, vadinasi visi ijungti. Todel nekreipiam demesio i value.
          continue;
        }
        pgmConfig.push(iPgm.queue_no);
      }
      this.checkForNoneOrSome(this.allowedOutputs, 'outputs');
      return;
    }
    const index = pgmConfig.indexOf(outputQueueNo);
    if (index === -1 && (value === undefined || value)) {
      pgmConfig.push(outputQueueNo);
    } else if (index !== -1 && (value === undefined || !value)) {
      pgmConfig.splice(index, 1);
    }
    const allowedPgm = this.allowedOutputs.find((a) => a.queue_no === outputQueueNo);
    allowedPgm.visible = pgmConfig.indexOf(outputQueueNo) !== -1;
    this.checkForNoneOrSome(this.allowedOutputs, 'outputs');
  }

  private getAllowedSensors(): any {
    const allSensors = this.systems.activeSystem.sensors.filter((s) => s.enabled);
    const sensorConfig = this.configurationModified.visibleSensors;
    const sensorsToReturn = [];
    const selectAll = sensorConfig.length === 0;
    for (const iSensor of allSensors) {
      sensorsToReturn.push({
        visible: selectAll,
        queue_no: iSensor.queue_no,
        name: iSensor.name,
        iconPath: this.senS.getSensorIcon(iSensor.type),
      });
    }

    if (!selectAll) {
      for (const iSensor of sensorsToReturn) {
        iSensor.visible = sensorConfig.indexOf(iSensor.queue_no) !== -1;
      }
    }

    return sensorsToReturn;
  }

  public checkSensor(sensorQueueNo: number, value?: boolean) {
    if (value !== undefined) {
      value = !value;
    }
    const sensorConfig = this.configurationModified.visibleSensors;
    if (sensorConfig.length === 0 && this.allowedSensors.filter((as) => as.visible).length === this.allowedSensors.length) {
      for (const iSensor of this.allowedSensors) {
        if (iSensor.queue_no === sensorQueueNo) {
          iSensor.visible = false;
          continue;
        }
        sensorConfig.push(iSensor.queue_no);
      }
      this.checkForNoneOrSome(this.allowedSensors, 'sensors');
      this.log('Matomi sensoriai:', this.allowedSensors);
      return;
    }
    const index = sensorConfig.indexOf(sensorQueueNo);
    if (index === -1 && (value === undefined || value)) {
      sensorConfig.push(sensorQueueNo);
    } else if (index !== -1 && (value === undefined || !value)) {
      sensorConfig.splice(index, 1);
    }
    const allowedSensor = this.allowedSensors.find((a) => a.queue_no === sensorQueueNo);
    allowedSensor.visible = sensorConfig.indexOf(sensorQueueNo) !== -1;
    this.checkForNoneOrSome(this.allowedSensors, 'sensors');
    this.log('Matomi sensoriai:', this.allowedSensors);
  }

  private getAllowedCameras(): any {
    const allCameras = this.systems.activeSystem.cameras;
    const cameraConfig = this.configurationModified.visibleCameras;
    const camerasToReturn = [];
    const selectAll = cameraConfig.length === 0;
    for (const iCamera of allCameras) {
      camerasToReturn.push({
        visible: selectAll,
        id: iCamera.id,
        name: iCamera.name,
        iconPath: 'assets/images/camera2.svg',
      });
    }

    if (!selectAll) {
      for (const iCamera of camerasToReturn) {
        iCamera.visible = cameraConfig.indexOf(iCamera.id) !== -1;
      }
    }

    return camerasToReturn;
  }

  public checkCamera(cameraId: number, value?: boolean) {
    if (value !== undefined) {
      value = !value;
    }
    const cameraConfig = this.configurationModified.visibleCameras;
    if (cameraConfig.length === 0 && this.allowedCameras.filter((ac) => ac.visible).length === this.allowedCameras.length) {
      for (const iCamera of this.allowedCameras) {
        if (iCamera.id === cameraId) {
          iCamera.visible = false;
          continue;
        }
        cameraConfig.push(iCamera.id);
      }
      this.checkForNoneOrSome(this.allowedCameras, 'cameras');
      this.log('Matomos kameros:', this.allowedCameras);
      return;
    }
    const index = cameraConfig.indexOf(cameraId);
    if (index === -1 && (value === undefined || value)) {
      cameraConfig.push(cameraId);
    } else if (index !== -1 && (value === undefined || !value)) {
      cameraConfig.splice(index, 1);
    }
    const allowedCamera = this.allowedCameras.find((a) => a.id === cameraId);
    allowedCamera.visible = cameraConfig.indexOf(cameraId) !== -1;
    this.checkForNoneOrSome(this.allowedCameras, 'cameras');
    this.log('Matomos kameros:', this.allowedCameras);
  }

  private getAllowedThermostats(): any {
    const allThermostats = this.systems.activeSystem.thermostats;
    const thermostatConfig = this.configurationModified.visibleThermostats;
    const thermostatsToReturn = [];
    const selectAll = thermostatConfig.length === 0;
    for (const iThermostat of allThermostats) {
      thermostatsToReturn.push({
        visible: selectAll,
        id: iThermostat.id,
        name: iThermostat.name,
        iconPath: this.senS.getSensorIcon(0),
      });
    }

    if (!selectAll) {
      for (const iThermostat of thermostatsToReturn) {
        iThermostat.visible = thermostatConfig.indexOf(iThermostat.id) !== -1;
      }
    }

    return thermostatsToReturn;
  }

  public checkThermostat(thermostatId: number, value?: boolean) {
    if (value !== undefined) {
      value = !value;
    }
    const thermostatConfig = this.configurationModified.visibleThermostats;
    if (thermostatConfig.length === 0 && this.allowedThermostats.filter((ac) => ac.visible).length === this.allowedThermostats.length) {
      for (const iThermostat of this.allowedThermostats) {
        if (iThermostat.id === thermostatId) {
          iThermostat.visible = false;
          continue;
        }
        thermostatConfig.push(iThermostat.id);
      }
      this.checkForNoneOrSome(this.allowedThermostats, 'thermostats');
      this.log('Matomi termostatai:', this.allowedThermostats);
      return;
    }
    const index = thermostatConfig.indexOf(thermostatId);
    if (index === -1 && (value === undefined || value)) {
      thermostatConfig.push(thermostatId);
    } else if (index !== -1 && (value === undefined || !value)) {
      thermostatConfig.splice(index, 1);
    }
    const allowedThermostats = this.allowedThermostats.find((a) => a.id === thermostatId);
    allowedThermostats.visible = thermostatConfig.indexOf(thermostatId) !== -1;
    this.checkForNoneOrSome(this.allowedThermostats, 'thermostats');
    this.log('Matomi termostatai:', this.allowedThermostats);
  }

  /**
   * Patikrina ar konkrečiam tipui priklauso bent vienas matomas elementas. Jeigu 0, tai nužymimamas visas tipas.
   * Jeigu >0, tai pažymimas.
   *
   * @param list Sąrašas, kurį reikia tikrinti. allowedXXXXXXX.
   * @param type Tipas (outputs, sensors, ...)
   */
  private checkForNoneOrSome(list: Array<any>, type: string) {
    if (list.filter((i) => i.visible).length === 0) {
      this.typeChecked(type, true);
    } else if (!this.isTypeChecked(type)) {
      this.typeChecked(type, false);
    }
  }

  public areaCount(): string {
    return '(' + this.allowedAreas.filter((a) => a.visible).length + '/' + this.allowedAreas.length + ')';
  }

  public pgmCount(): string {
    return '(' + this.allowedOutputs.filter((o) => o.visible).length + '/' + this.allowedOutputs.length + ')';
  }

  public sensorCount(): string {
    return '(' + this.allowedSensors.filter((s) => s.visible).length + '/' + this.allowedSensors.length + ')';
  }

  public cameraCount(): string {
    return '(' + this.allowedCameras.filter((c) => c.visible).length + '/' + this.allowedCameras.length + ')';
  }

  public thermostatCount(): string {
    return '(' + this.allowedThermostats.filter((c) => c.visible).length + '/' + this.allowedThermostats.length + ')';
  }
}
