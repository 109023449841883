import http from "../http";
import { BasicSuccessResponse, ErrorResponse, objectToParams } from "./common";

export type SystemNote = {
  id: number;
  created_at: number;
  updated_at: number;
  system_id: number;
  content: string;
  scope: SystemNoteScope;
  person: string;
  user_id: number | null;
};

export enum SystemNoteScope {
  All = 0,
  Installer = 1,
  Admin = 2,
};

type GetSystemNotesResponse = ErrorResponse | ( BasicSuccessResponse & { notes: SystemNote[] });
type UpdateSystemNoteResponse = ErrorResponse | (BasicSuccessResponse & { updated_at: number });
type AddSystemNoteResponse = ErrorResponse | (BasicSuccessResponse & { id: number; created_at: number });

const addNote = (req: SystemNote) => http.post<AddSystemNoteResponse, SystemNote>('/v3/api/system-note', req);
const updateNote = (req: SystemNote) => http.put<UpdateSystemNoteResponse, SystemNote>('/v3/api/system-note', req);
const deleteNote = (req: { id: number}) => http.delete<ErrorResponse | BasicSuccessResponse, { id: number}>('/v3/api/system-note', req);
const getSystemNotes = (req: { system_id: number}) => http.get<GetSystemNotesResponse, URLSearchParams>('/v3/api/system-notes', objectToParams(req));

export default {
  add: addNote,
  update: updateNote,
  delete: deleteNote,
  get: getSystemNotes,
};
