import { Component, OnInit, ChangeDetectorRef, ViewEncapsulation } from '@angular/core';
import { LanguageAware } from '../language-aware';
import { Router } from '@angular/router';
import { EditSystemService } from 'src/app/services/edit-system.service';
import { WebSocketService } from 'src/app/services/websocket.service';
import { DateService } from 'src/app/services/date.service';
import { PopupService } from 'src/app/services/popup.service';
import { PopupType } from 'src/app/models/popup-type';
import { GoogleAuthService } from 'src/app/api/google-auth.service';
import { MessageboxService } from 'src/app/services/messagebox.service';
import { CurrentUserData } from 'src/api/v3/common';
import { DropdownComponent, DropdownItem } from 'src/app/ui/dropdown/dropdown.component';
import { TBackgroundType } from 'src/app/models/background-types';
import { PermissionRole } from 'src/api/v3/permission';
import { CommonModule } from '@angular/common';
import { TextEditComponent } from 'src/app/ui/text-edit/text-edit.component';
import { NameValueComponent } from 'src/app/components/name-value/name-value.component';
import { ButtonSingleLineArrowComponent } from 'src/app/ui/button-single-line-arrow/button-single-line-arrow.component';
import { LocaleService } from 'src/app/api/locale.service';
import { countryList } from 'src/environments/countrylist';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [CommonModule, TextEditComponent, NameValueComponent, ButtonSingleLineArrowComponent, DropdownComponent],
})
export class ProfileComponent extends LanguageAware implements OnInit {
  public userDataChanged = false;
  public userToUse: CurrentUserData = null;
  private oldSettings = {
    name: '',
    phone: '',
    format: 0,
    company: '',
    country: null,
  };
  public dateFormatText = '';
  public PermissionRole = PermissionRole;
  public countryList: DropdownItem<string>[];

  constructor(
    cdRef: ChangeDetectorRef,
    public router: Router,
    public es: EditSystemService,
    private ws: WebSocketService,
    private dt: DateService,
    private google: GoogleAuthService,
    private pp: PopupService,
    private messagebox: MessageboxService,
    private locale: LocaleService,
  ) {
    super(cdRef, { backgroundType: TBackgroundType.Gray });
    this.tag = 'Profile';
    this.dt.populateDateTimeDropdownFormats();
    if (this.myOwnProfile()) {
      this.userToUse = this.us.currentUser;
    } else {
      this.userToUse = this.es.currentUserObj;
    }
    this.countryList = countryList.map((code) => ({
      value: code,
      label: (c: string) => this.locale.getCountryName(c),
    }));
    this.countryList.unshift({
      value: null,
      label: () => this.trans('users.labels.selectCountry'),
      default: true,
    });
    this.headerBar.showHeader({
      headerText: this.trans('users.titles.profileHeader'),
      backUrl: es.currentUserObj === null ? this.g.getHomeUrl().join('/') : '/users',
    });
    if (this.es.currentUserObj === null) {
      this.footerBar.showFooter(this.trans('general.cancel'), this.trans('general.save'), true, false);
    }
    const that = this;
    this.oldSettings.name = this.us.currentUser.name;
    this.oldSettings.phone = this.us.currentUser.phone;
    this.oldSettings.format = this.us.currentUser.date_format;
    this.oldSettings.country = this.us.currentUser.country;
    this.footerBar.onButton1Click = () => {
      that.revertChanges(JSON.stringify(this.oldSettings));
      that.router.navigate(this.g.getHomeUrl());
    };
    this.footerBar.onButton2Click = () => {
      let canNavigate = !that.userDataChanged;
      if (that.userDataChanged) {
        canNavigate = that.saveUserSettings();
      }
      if (canNavigate) {
        that.router.navigate(this.g.getHomeUrl());
      }
    };
    this.headerBar.onBackClicked = () => {
      if (es.currentUserObj === null) {
        that.revertChanges(JSON.stringify(this.oldSettings));
      }
      return true;
    };
    let fmt = this.dt.dateTimeDropdownFormats.find((f) => f.value === this.userToUse.date_format);
    if (fmt === undefined) {
      fmt = this.dt.dateTimeDropdownFormats[0];
    }
    this.dateFormatText = fmt.label as string;
  }

  ngOnInit(): void {}

  public myOwnProfile(): boolean {
    return this.es.currentUserObj === null || this.es.currentUserObj.id === this.us.currentUser.id;
  }

  public doLogout() {
    this.api.setToken('');
    this.ws.close();
    if (this.platform.isDesktop()) {
      const loginType = this.us.getLoginType();
      if (loginType !== null && loginType === 'google') {
        this.google.signOut(true);
      }
    } else if (this.platform.isAndroid()) {
      this.platform.androidHandler().doLogOutGoogle();
    }
    this.router.navigate(['/login']);
  }

  public saveUserSettings(userData?: any): boolean {
    if (!this.myOwnProfile()) {
      return false;
    }
    if (this.userToUse.name !== null || userData?.name) {
      this.userToUse.name = userData?.name ? userData.name.trim() : this.userToUse.name.trim();
    }
    if (this.userToUse.phone !== null || userData?.phone) {
      this.userToUse.phone = userData?.phone ? userData.phone.trim() : this.userToUse.phone.trim();
    }
    if (this.userToUse.phone !== null && this.userToUse.phone !== '' && !/^[0-9+\-\s]+$/.test(this.userToUse.phone)) {
      this.messagebox.open({
        buttons: this.messagebox.buttons.Close,
        headerText: this.trans('general.titleError'),
        messageContent: this.trans('validation.phoneInvalid'),
        iconType: this.messagebox.iconType.Error
      });
      return false;
    }
    this.log('laikas saugoti nustatymus.');
    const that = this;
    const old = JSON.stringify(this.oldSettings);
    this.api
      .post(
        '/profile',
        {
          name: this.userToUse.name,
          phone: this.userToUse.phone,
          dateFormat: userData ? userData.DateFormat : this.userToUse.date_format,
          country: this.userToUse.country,
        },
        true
      )
      .subscribe(
        (result) => {
          if (!result.success) {
            that.messagebox.open({
              buttons: that.messagebox.buttons.Close,
              headerText: that.trans('general.titleError'),
              messageContent: result.error,
              iconType: that.messagebox.iconType.Error
            });
            that.revertChanges(old);
            return;
          }
          that.us.saveCurrentUser();
          if (that.platform.isAndroid()) {
            that.platform.androidHandler().setDateTimeFormat(that.us.currentUser.date_format);
          } else if (that.platform.isApple()) {
            that.platform.appleHandler().setDateTimeFormat.postMessage(that.us.currentUser.date_format);
          }
        },
        (error) => {
          that.revertChanges(old);
        }
      );

    return true;
  }

  private revertChanges(oldSettings: string) {
    if (this.us.currentUser.id === 0) {
      return;
    }
    const settings = JSON.parse(oldSettings);
    this.us.currentUser.name = settings.name;
    this.us.currentUser.phone = settings.phone;
    this.us.currentUser.date_format = settings.format;
    this.us.saveCurrentUser();
  }

  public goChangePass() {
    this.revertChanges(JSON.stringify(this.oldSettings));
    this.router.navigate([...this.g.resolveRootRoute(), 'password-change']);
  }

  public goDeleteAccount() {
    this.revertChanges(JSON.stringify(this.oldSettings));
    this.router.navigate([...this.g.resolveRootRoute(), 'profile', 'delete']);
  }

  public languageClick() {
    this.router.navigate([...this.g.resolveRootRoute(), 'select-language']);
  }

  public doDownloadAccount() {
    if (this.miniStatus.isVisible()) {
      this.miniStatus.flash();
      return;
    }
    const that = this;
    this.miniStatus.show(this.trans('general.download.generating'));
    this.api.post('/download-account', {}, true).subscribe(
      (result) => {
        if (result.success) {
          that.getTheFile();
        } else {
          that.miniStatus.hide();
          that.messagebox.open({
            buttons: that.messagebox.buttons.Close,
            headerText: that.trans('general.titleError'),
            messageContent: result.error,
            iconType: that.messagebox.iconType.Error
          });
        }
      },
      (error) => {
        that.messagebox.open({
          buttons: that.messagebox.buttons.Close,
          headerText: that.trans('general.titleError'),
          messageContent: that.trans('auth.errors.serverSideError'),
          iconType: that.messagebox.iconType.Error
        });
        that.miniStatus.hide();
      }
    );
  }

  private getTheFile() {
    document.location.href = this.api.getBaseUrl() + '/download-account-file?u=' + this.api.getToken() + (this.platform.isAndroid() ? '&an=1' : '');
    if (this.platform.isDesktop()) {
      this.miniStatus.show(this.trans('general.download.started'));
      setTimeout(() => {
        this.miniStatus.hide();
      }, 2000);
    } else {
      this.miniStatus.hide();
    }
  }

  public getLangSubCode(): string {
    if (this.ls.selectedLanguage.value === null) {
      this.ls.selectedLanguage.value = 'en';
    }
    const minusPos = this.ls.selectedLanguage.value.lastIndexOf('-');
    const dashPos = this.ls.selectedLanguage.value.lastIndexOf('_');
    const lastPos = Math.max(minusPos, dashPos);
    const subCodePart = lastPos === -1 ? this.ls.selectedLanguage.value : this.ls.selectedLanguage.value.substr(lastPos + 1);
    return subCodePart.toUpperCase();
  }

  public changeDateFormat() {
    const that = this;
    this.pp.showSlideout<DropdownItem<number>>(
      {
        headerText: this.trans('users.labels.dateTimeFormat'),
        items: this.dt.dateTimeDropdownFormats,
        onSubmit: (res) => {
          const fmt = that.dt.dateTimeDropdownFormats.find((f) => f.value === res.value);
          that.dateFormatText = fmt.label as string;
          that.userToUse.date_format = fmt.value;
          that.userDataChanged = true;
        },
      },
      PopupType.SlideoutWithValue
    );
  }
}
