import { CommonModule } from '@angular/common';
import { Component, ElementRef, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { PlatformService } from 'src/app/api/platform.service';

export type SupportedInputTypes = string | number | boolean;

let uid = 0;
export const useUniqueId = (prefix?: string) => `${prefix ?? ''}${uid++}`;

@Component({
  template: '',
  standalone: true,
  imports: [],
})
export abstract class FormInputComponent<TModelValue extends SupportedInputTypes> implements OnInit {
  private autoId = useUniqueId('form-input-');
  private hexChars = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', 'a', 'b', 'c', 'd', 'e', 'f', 'A', 'B', 'C', 'D', 'E', 'F'];
  private goodKeys = [
    'Backspace', // backspace
    'ArrowLeft', // <-
    'ArrowRight', // ->
    'Delete', // delete
    'Del',
    'End',
    'Home',
    'Tab',
    'Enter',
  ];
  public get formId(): string {
    return this.name ?? this.autoId;
  }
  @Input() label: string;
  @Input() type?: TModelValue extends string
    ? 'text' | 'email' | 'password' | 'textarea' | 'color' | 'hex'
    : TModelValue extends number
    ? 'number' | 'range'
    : TModelValue extends boolean
    ? 'checkbox' | 'switch'
    : never;
  @Input() max: TModelValue extends number ? number : undefined;
  @Input() min: TModelValue extends number ? number : undefined;
  @Input() step: TModelValue extends number ? number : undefined;
  @Input() maxLength: TModelValue extends number ? number : undefined;
  @Input() rows: string;
  @Input() required = false;
  @Input() disabled = false;
  @Input() value: TModelValue;
  @Input() autocomplete?:
    | 'on'
    | 'off'
    | 'name'
    | 'honorific-prefix'
    | 'given-name'
    | 'additional-name'
    | 'family-name'
    | 'honorific-suffix'
    | 'nickname'
    | 'email'
    | 'username'
    | 'new-password'
    | 'current-password'
    | 'one-time-code'
    | 'organization-title'
    | 'organization'
    | 'street-address'
    | 'address-line1'
    | 'address-line2'
    | 'address-line3'
    | 'address-level4'
    | 'address-level3'
    | 'address-level2'
    | 'address-level1'
    | 'country'
    | 'country-name'
    | 'postal-code'
    | 'cc-name'
    | 'cc-given-name'
    | 'cc-additional-name'
    | 'cc-family-name'
    | 'cc-number'
    | 'cc-exp'
    | 'cc-exp-month'
    | 'cc-exp-year'
    | 'cc-csc'
    | 'cc-type'
    | 'transaction-currency'
    | 'transaction-amount'
    | 'language'
    | 'bday'
    | 'bday-day'
    | 'bday-month'
    | 'bday-year'
    | 'sex'
    | 'tel'
    | 'tel-country-code'
    | 'tel-national'
    | 'tel-area-code'
    | 'tel-local'
    | 'tel-local-prefix'
    | 'tel-local-suffix'
    | 'tel-extension'
    | 'impp'
    | 'url'
    | 'photo';
  @Input() hasError = false;
  @Input() name?: string;
  @Input() size?: 'normal' | 'small';
  @Output() valueChange = new EventEmitter<TModelValue>();
  @Output() Focus = new EventEmitter<FocusEvent>();
  @Output() Blur = new EventEmitter<Event>();
  public showPassword = false;

  constructor(private elRef: ElementRef, public platform: PlatformService) {}

  public get inputType(): string {
    switch (typeof this.value) {
      case 'string':
        return this.type === 'password'
                ? this.showPassword
                  ? 'text' : 'password'
                : this.type === 'email'
                  ? 'email'
                  : this.type === 'textarea'
                    ? 'textarea'
                    : this.type === 'color'
                      ? 'color'
                      : 'text';
      case 'number':
        return this.type === 'range' ? 'range' : 'number';
      case 'boolean':
        return 'checkbox';
      default:
        return 'text';
    }
  }
  public get ngType(): string {
    return this.type;
  }
  public get inputProxy(): TModelValue {
    return this.value;
  }
  public set inputProxy(value: TModelValue) {
    if (this.inputType === 'checkbox' || this.inputType === 'switch') { return; }
    if (this.inputType === 'range' || this.inputType === 'number') {
      if (value === '' || isNaN(value as number)) {
        this.valueChange.emit(NaN as TModelValue);
      } else {
        this.valueChange.emit(Number(value) as TModelValue);
      }
    } else {
      this.valueChange.emit(value as TModelValue);
    }
  }

  public get isEmpty(): boolean {
    return (
      this.inputType !== 'checkbox' && (this.value === null || this.value === undefined || this.value === '' || (typeof this.value === 'number' && isNaN(this.value as number)))
    );
  }

  onChange($event: any) {
    if (this.inputType === 'checkbox' || this.inputType === 'switch') {
      this.valueChange.emit($event.target.checked as TModelValue);
    }
  }

  ngOnInit(): void {}

  public focus() {
    this.elRef.nativeElement.querySelector('input')?.focus();
    this.elRef.nativeElement.querySelector('textarea')?.focus();
  }

  public analyzeInput(event: InputEvent) {
    if ( this.inputType === 'number' && this.maxLength > 0 && event.data !== null ) {
      const stringVal = this.value.toString();
      if ( stringVal.length + event.data?.length > this.maxLength ) {
        setTimeout(() => {
          this.value = parseInt(stringVal.substring(0, this.maxLength), 10) as TModelValue;
        }, 50);
      }
    } else if ( this.type === 'hex' && event.data !== null ) {
      const stringVal = this.value.toString().toUpperCase();
      const newData = [...event.data.toUpperCase()];
      let good = true;
      for ( const char of newData ) {
        if ( !this.hexChars.includes(char) ) {
          good = false;
          break;
        }
      }
      if ( !good ) {
        setTimeout(() => {
          this.value = stringVal.replace(event.data.toUpperCase(), '') as TModelValue;
        }, 50);
      } else {
      }
    }
  }

  public analyzeKeyDown(event: KeyboardEvent) {
    if ( this.type === 'hex' && !this.hexChars.includes(event.key) ) {
      if ( this.goodKeys.includes(event.key) ) {
        return;
      }
      event.preventDefault();
    } else if ( this.type === 'number' ) {
      if ( this.goodKeys.includes(event.key) ) {
        return;
      }
      if ( isNaN(parseInt(event.key, 10)) ) {
        event.preventDefault();
        return;
      }
      if ( this.maxLength > 0 && this.value.toString().length >= this.maxLength ) {
        event.preventDefault();
      }
    }
  }
}

@Component({
  selector: 'app-string-input, app-form-input[type=text], app-form-input[type=email], app-form-input[type=password], app-form-input[type=textarea], app-form-input[type=color], app-form-input[type=hex]',
  templateUrl: './form-input.component.html',
  styleUrls: ['./form-input.component.scss'],
  standalone: true,
  imports: [CommonModule, FormsModule],
})
export class StringFormInputComponent extends FormInputComponent<string> {}

@Component({
  selector: 'app-number-input, app-form-input[type=number], app-form-input[type=range]',
  templateUrl: './form-input.component.html',
  styleUrls: ['./form-input.component.scss'],
  standalone: true,
  imports: [CommonModule, FormsModule],
})
export class NumberFormInputComponent extends FormInputComponent<number> {}

@Component({
  selector: 'app-boolean-input, app-form-input[type=checkbox], app-form-input[type=switch]',
  templateUrl: './form-input.component.html',
  styleUrls: ['./form-input.component.scss'],
  standalone: true,
  imports: [CommonModule, FormsModule],
})
export class BooleanFormInputComponent extends FormInputComponent<boolean> {}
