import { Injectable } from '@angular/core';
import { merge, Observer, Subject } from 'rxjs';
import { debounceTime, filter, map } from 'rxjs/operators';
import { AppSettings } from 'src/app-settings';
import { AppRegion, AppRegionWithName } from 'src/environments/environment.types';
import { LoggerService } from '../api/logger.service';
import { PlatformService } from './platform.service';
import { RegionService } from './region.service';
import { RequestService } from './request.service';
import { UserService } from './user.service';
import { SystemService } from './system/system.service';
import { useDebouncedFunction } from 'src/shim';
import { RtcService } from './rtc/rtc.service';
import { PersistenceService } from './persistence.service';
import { CurrentUserData, Region } from 'src/api/v3/common';
import { SystemsService } from '../services/systems.service';
import { LocatorService } from '../services/locator.service';
import { Router } from '@angular/router';
import requests from 'src/api/v3/requests';
import { TagService } from './tag.service';
import { LanguageService } from '../services/language.service';
import { PermissionRole } from 'src/api/v3/permission';
import { FcmService } from '../services/fcm.service';

interface TokenData {
  iss: string;
  iat: number;
  exp: number;
  nbf: number;
  sub: number;
}

export interface AccountStoreItem {
  data: TokenData;
  token: string | null;
  name: string;
  email: string;
  role: number;
  loginType: string;
  region: string | AppRegion;
  roleName: string;
}

export type AccountStore = Record<string, Record<number, AccountStoreItem>>;
export type AccountStoreVersioned = {
  version: number;
  store: AccountStore;
}

export interface AccountInfo {
  id: number;
  token: string;
  data: TokenData;
  name: string;
  email: string;
  active: boolean;
  loginType: string;
  role: number;
  region: AppRegionWithName;
  regions?: Region[];
  roleName: string;
}

const storeToInfoTransformer = (a: AccountStoreItem, regionService: RegionService, currentUserId: number): AccountInfo => {
  const region = typeof a.region === 'object' ? a.region : AppSettings.regions.find((r) => r.id === a.region) ?? regionService.ActiveRegion;
  const regionWithName: AppRegionWithName = {
    ...region,
    name: regionService.getNameForRegion(region),
  };
  return {
    id: a.data.sub,
    token: a.token,
    data: a.data,
    name: a.name,
    email: a.email,
    active: a.data.sub === currentUserId && regionService.ActiveRegion.backEndHost === region?.backEndHost,
    loginType: a.loginType,
    role: a.role,
    region: regionWithName,
    roleName: a.roleName
  };
};

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private readonly tag = 'AuthService';

  private tokenChange = new Subject<string>();
  public onTokenChange = this.tokenChange.asObservable();
  public onTokenClear = this.tokenChange.pipe(filter((t) => !t));
  private accountChange = new Subject<AccountInfo | null>();
  public onAccountChange = this.accountChange.asObservable();
  public onAccountOrRegionChnage = merge(this.onAccountChange, this.region.onRegionChanged).pipe(map((): void => { }, debounceTime(40)));
  public registerCurrentUserData: Observer<CurrentUserData> = {
    next: (user) => { this.registerUserDataWithSwitcher(user); },
    error: () => undefined,
    complete: () => undefined,
  };
  private accountStore: AccountStoreVersioned = null;
  public accountStoreSubscriptions = this.persistence.subscribe('globalAccountsV').subscribe((store) => {
    this.accountStore = store;
  });
  public mergedRegionAccounts: AccountInfo[] = [];

  private get rtc() { return LocatorService.injector.get(RtcService); }
  private get user() { return LocatorService.injector.get(UserService); }
  private get ss() { return LocatorService.injector.get(SystemsService); }
  private get req() { return LocatorService.injector.get(RequestService); }
  private get system() { return LocatorService.injector.get(SystemService); }

  constructor(
    private router: Router,
    private l: LoggerService,
    private region: RegionService,
    private platform: PlatformService,
    private persistence: PersistenceService,
    ) {
    l.log('+', 'AuthService');
    const oldVersion = this.persistence.get('globalAccounts', null);
    const newVersion = this.persistence.get('globalAccountsV', {version: 1, store: {}});
    this.migrateAccountStore(oldVersion, newVersion);
    const [loadUserData] = useDebouncedFunction(() => this.loadUserDataInternal(), 0);
    this.loadUserData = loadUserData;
  }

  /** Funkcija netikrina ar turimas token yra geras. Ji patikrina tik ar toks išvis yra. */
  /** @returns true, kai yra išsaugotas token. */
  hasToken(): boolean {
    const token = localStorage.getItem('token');
    return token && token.includes('.');
  }

  /** Iš local storage paima išsaugotą token. */
  /** @returns token */
  public getToken(): string {
    const token = localStorage.getItem('token');
    if (token == null) {
      return '';
    }
    return token;
  }

  public setToken(token: string) {
    const oldUserId = this.GetUserId();
    this.l.log('setToken', this.tag, { token });
    localStorage.setItem('token', token);

    if (!token) {
      this.region.unlockRegion();
    } else {
      this.registerTokenWithSwitcher(token);
      this.region.lockRegion();
    }

    if (this.platform.isAndroid()) {
      this.platform.androidHandler().onNewToken(token);
    } else if (this.platform.isApple()) {
      this.platform.appleHandler().onNewToken.postMessage(token ?? '');
    }
    const newUserId = this.GetUserId();
    if (oldUserId !== newUserId) { this.tokenChange.next(token); }
  }

  public getLastAccount(): AccountInfo | undefined {
    let biggestIat = -1;
    const accounts = this.availableAccounts;
    this.availableAccounts.forEach((a) => (biggestIat = Math.max(biggestIat, a.data.iat)));
    return accounts.find((a) => a.data.iat === biggestIat);
  }
  /**
   * Before calling: must close webspcket, and clear systems
   */
  public switchAccount(account: AccountInfo) {
    if ( account?.token ) {
      this.region.useRegion(account.region);
      this.setToken(account.token);
      this.switchMergedRegionAccounts(account);
    } else if (account?.region) {
      this.region.useRegion(account.region);
    }
    this.accountChange.next(account);
  }

  private switchMergedRegionAccounts(account: AccountInfo) {
    this.mergedRegionAccounts.map(acc => {
      acc.active = acc.id === account.id;
      acc.region = acc.active ? account.region : acc.region;
    });
  };

  private locateAccount(locator: (account: AccountStoreItem) => boolean): AccountInfo | undefined {
    const store = this.accountStore.store;
    return Object.entries(store)
      .map(([, s]) => Object.entries(s).map(([, i]) => i))
      .reduce((s, a) => [...a, ...s], [])
      .filter((a) => locator(a))
      .map((a) => storeToInfoTransformer(a, this.region, this.GetUserId() ?? -1))
      .pop();
  }

  public getAccountByEmail(email: string): AccountInfo | undefined {
    return this.locateAccount((a) => a.email === email && a.region === this.region.currentRegion);
  }

  public get availableAccounts(): AccountInfo[] {
    const store = this.accountStore.store;
    return Object.entries(store)
      .map(([, s]) => Object.entries(s).map(([, i]) => i))
      .reduce((s, a) => [...a, ...s], [])
      .map((a) => storeToInfoTransformer(a, this.region, this.GetUserId() ?? -1));
  }

  public async loadMergedRegionAccounts(): Promise<void> {
    await this.region.loadRegions();
    const mergedAccounts: any[] = [];
    for (const account of this.availableAccounts) {
      const existingAccount = mergedAccounts.find(a => a.id === account.id);
      if (existingAccount) {
        if (account.active) {
          existingAccount.active = true;
          existingAccount.role = account.role;
          existingAccount.region = account.region;
          existingAccount.token = account.token;
        }
        existingAccount.regions.push(account.region);
      } else {
        mergedAccounts.push({
          ...account,
          regions: [account.region]
        });
      }
    }
    this.mergedRegionAccounts = mergedAccounts;
  }

  public get hasAccounts(): boolean {
    return this.availableAccounts.length > 0;
  }

  public logOutFromSwitcher(id: number | null | 0) {
    if ( !id ) { return; }
    this.accountStore.store[this.region.regionId][id].token = null;
    this.persistence.set('globalAccountsV', this.accountStore);
    const loggedOutAccount = this.mergedRegionAccounts.find(mra => mra.id === id);
    if(!loggedOutAccount) { return; }
    loggedOutAccount.token = null;
    loggedOutAccount.active = false;
  }

  public forgetAccount(account: AccountInfo) {
    delete this.accountStore.store[account.region.id][account.id];
    this.persistence.set('globalAccountsV', this.accountStore);
    this.loadMergedRegionAccounts();
  }

  public GetUserId(): number | null {
    try {
      const tokenData = JSON.parse(atob(this.getToken().split('.')[1]));
      return tokenData.sub;
    } catch (error) {
      return null;
    }
  }

  public getTokenData(): TokenData | null {
    try {
      const tokenData = JSON.parse(atob(this.getToken().split('.')[1]));
      return tokenData;
    } catch (error) {
      return null;
    }
  }

  private registerTokenWithSwitcher(token: string) {
    const b64Data = token.split('.')[1];
    const data = JSON.parse(atob(b64Data)) as TokenData;
    const regionStore = this.accountStore.store[this.region.regionId] ?? {};
    if (regionStore[data.sub]) {
      regionStore[data.sub] = {
        ...regionStore[data.sub],
        data,
        token,
        region: this.region.currentRegion,
      };
    } else {
      regionStore[data.sub] = {
        data,
        token,
        name: '',
        email: '',
        role: 1,
        loginType: 'default',
        region: this.region.currentRegion,
        roleName: '',
      };
    }
    this.accountStore.store[this.region.regionId] = regionStore;
    this.persistence.set('globalAccountsV', this.accountStore);
  }

  private async loadUserDataInternal(connectToRtc: boolean = true): Promise<CurrentUserData & { raw?: any }> {
    if ( this.user.currentUser && this.user.isCurrentUserFetched ) { return this.user.currentUser; }
    if (!this.hasToken()) {
      this.router.navigate(['/']);
      return;
     }
    const lastSystemId = this.persistence.get('last_system', 0);
    const result = await requests.user.me({systemId: lastSystemId, areaId: 0, getSystemStatus: true }).toPromise();
    if (result.success) {
      const { success, lastSystem, tags, ...user } = result;
      this.user.isCurrentUserFetched = true;
      this.user.setCurrentUserFromRaw(user);
      const tagService = LocatorService.injector.get(TagService);
      tags.forEach(t => tagService.ingestTag(t));
      const ingestedSystem = this.system.ingestSystem(lastSystem);
      if ( ingestedSystem ) {
        this.ss.setCurrentSystem(ingestedSystem);
      }
      this.setToken(user.token);
      this.registerCurrentUserData.next(this.user.currentUser);
      this.user.change();
      if (connectToRtc) { this.rtc.connect(); }
      LocatorService.injector.get(FcmService).requestForToken();
      return { ...this.user.currentUser, raw: user };
    }
    throw new Error('Failed to load user data');
  }

  public registerUserDataWithSwitcher(user: CurrentUserData) {
    if (!user || !this.hasToken()) { return; }
    this.l.log('registerUserDataWithSwitcher', this.tag);
    let regionStore = this.accountStore.store[this.region.regionId] ?? {};
    if (!regionStore[user.id]) {
      this.l.log(`Šio user (${user.id}) token niekada nebuvo registruotas store, tai WTF.`, this.tag);
      this.registerTokenWithSwitcher(this.getToken());
      regionStore = this.accountStore.store[this.region.regionId] ?? {};
      if (!regionStore[user.id]) {
        this.l.log(`Nepavyko registruoti tokeno.`, this.tag);
        return;
      }
    }
    if (!user.email) {
      this.l.log(`UserService neturi vartotojo duomenu, nekeičiame switcher dumenu.`, this.tag);
      return;
    }
    regionStore[user.id].name = user.name;
    regionStore[user.id].email = user.email;
    regionStore[user.id].role = user.permissions.role;
    regionStore[user.id].roleName = user.permissions.name;
    // regionStore[user.id].loginType = this.us.getLoginType()
    this.accountStore.store[this.region.regionId] = regionStore;
    this.persistence.set('globalAccountsV', this.accountStore);
    this.loadMergedRegionAccounts();
  }

  /**
   * Atliekam migraciją tarp AccountStore versijų.
   * Atliekant kažkokius pakeitimus AccountStore struktūroje, būtina atlikti duomenų konvertaciją.
   * 
   * @param unversioned Seni duomenys neversijuoti.
   * @param versioned Versijuojamas AccountStore.
   */
  private migrateAccountStore(unversioned: AccountStore | null, versioned: AccountStoreVersioned) {
    const lang = LocatorService.injector.get(LanguageService);
    let modified = false;
    const updated: AccountStoreVersioned = { version: 1, store: {}};
    if ( unversioned !== null ) { // v0 -> v1
      this.l.log('Naujinam AccountStore iki v1', this.tag, unversioned);
      for ( const key of Object.keys(unversioned) ) {
        updated.store[key] = {};
        for ( const key2 of Object.keys(unversioned[key])) {
          updated.store[key][key2] = {
            ...unversioned[key][key2],
            roleName: lang.get('permissions.roles.' + unversioned[key][key2].role)
          } as AccountStoreItem;
        }
      }
      localStorage.removeItem('globalAccounts');
      modified = true;
    }
    if ( modified ) {
      this.persistence.set('globalAccountsV', updated);
    } else {
      this.accountStore = versioned;
    }
  }

  public loadUserData: AuthService['loadUserDataInternal'];
}
