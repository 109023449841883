<div class="bold_header" *ngIf="!originalInstallerId">
    {{trans('systems.titles.requestInstaller')}}
</div>
<div class="bold_header" *ngIf="systems.activeSystem.assistedById && originalInstallerId">
    {{trans('systems.titles.ongoingAssistance')}}
</div>
<div id="ia_ongoing_grid" *ngIf="systems.activeSystem.assistedById && originalInstallerId">
    <div class="bold_header">
        {{trans('users.labels.assistanceTimeLeft')}}:
    </div>
    <div id="ia_hours">{{hoursLeft}}</div>
</div>
<div class="white_round padding16">
    <div id="ia_active_system" *ngIf="canChangeInstaller">
        <div id="ia_system_item">
            <app-cp-system-list-item #systemToAssist [systemItem]="systems.activeSystem" [showInstaller]="true" [showAddress]="true" [showBorder]="true" [showArrow]="false" [showBookmark]="false" [isClickable]="false"></app-cp-system-list-item>
        </div>
        <div id="ia_change_inst" *ngIf="!originalInstallerId">
            <div id="ia_change_inst_text" (click)="changeInstaller()">{{trans('systems.labels.changeInstaller')}}</div>
            <div id="ia_change_inst_icon" [inlineSVG]="'assets/images/installer_edit.svg'"></div>
        </div>
    </div>
    <app-name-value *ngIf="!originalInstallerId || editMode" [nameText]="trans('systems.labels.accessPeriod')" (clicked)="changePeriod()">{{periodTime}}</app-name-value>
    <div id="ia_issue" *ngIf="!originalInstallerId || editMode">
        <textarea type="text" [(ngModel)]="formContent" rows="10" [disabled]="miniStatus.isVisible()" [placeholder]="trans('systems.labels.describeIssue')"></textarea>
        <app-form-error *ngIf="formContent === ''">{{trans('general.required')}}</app-form-error>
    </div>
    <div id="ia_issue_assisted" *ngIf="systems.activeSystem.assistedById && originalInstallerId && !editMode">
        <div id="ia_issue_readonly">{{formContent}}</div>
        <div id="ia_issue_edit" [inlineSVG]="'assets/images/edit.svg'" (click)="updateIssue()"></div>
    </div>
</div>
<div id="ia_button_holder">
    <div *ngIf="!originalInstallerId" class="button" id="ia_button" (click)="performRequest()">{{trans('systems.buttons.request')}}</div>
    <div *ngIf="systems.activeSystem.assistedById && originalInstallerId && !editMode" class="button" id="ia_button" (click)="initRevoke()">{{trans('users.buttons.revokeInstaller')}}</div>
    <div *ngIf="editMode" class="button" id="ia_button" (click)="performRequest()">{{trans('systems.buttons.updateRequest')}}</div>
</div>