import { Injectable, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { ReactionBasicData, ReactionData, ReactionGroupData } from 'src/api/v3/common';
import { LanguageService } from 'src/app/services/language.service';
import { environment } from 'src/environments/environment';
import { LoggerService } from '../logger.service';
import { PersistenceService } from '../persistence.service';
import { RegionService } from '../region.service';
import { RequestService } from '../request.service';
import { LocatorService } from 'src/app/services/locator.service';
import { IconsService } from 'src/app/services/icons.service';

@Injectable({
  providedIn: 'root',
})
export class ReactionService implements OnDestroy {
  public readonly reactions = new Map<number, ReactionData>();
  public readonly basicReactions = new Map<number, ReactionBasicData>();
  public readonly reactionGroups = new Map<number, ReactionGroupData>();
  public readonly reactionGroupReactions = new Map<number, Set<number>>();
  private readonly tag = 'Reactions';
  private regionChangeSubscribtion = this.region.onRegionChanged.subscribe(() => {
    this.reactions.clear();
    this.basicReactions.clear();
    this.reactionGroups.clear();
    this.reactionGroupReactions.clear();
  });
  private isLoadingReactions = false;
  private reactionInEditIdChange = new Subject<number>();
  public onReactionInEditIdChange = this.reactionInEditIdChange.asObservable();

  private get iconService() { return LocatorService.injector.get(IconsService); }

  constructor(
    private req: RequestService,
    private region: RegionService,
    private sanitizer: DomSanitizer,
    private storage: PersistenceService,
    private l: LoggerService,
    private lang: LanguageService
  ) {
    this.storage.get('basicReactions', []).map((r) => this.ingestBasicReaction(r));
    if ( !environment.production ) {
      this.l.log('Užsikrovėm basic reakcijas: ' + ([...this.basicReactions.entries()].length), this.tag);
    }
  }

  ngOnDestroy(): void {
    this.regionChangeSubscribtion.unsubscribe();
  }

  public get version() {
    return this.storage.get('reactionsIconsVersion', '');
  }

  public ingestReaction(reaction?: ReactionData): ReactionData | undefined {
    if (!reaction) { return; }
    this.reactions.set(reaction.id, reaction);
    if (!this.reactionGroupReactions.has(reaction.group_id)) {
      this.reactionGroupReactions.set(reaction.group_id, new Set());
    }
    this.reactionGroupReactions.get(reaction.group_id).add(reaction.id);
    return reaction;
  }

  public ingestBasicReaction(reaction?: ReactionBasicData): ReactionBasicData | undefined {
    if (!reaction) { return; }
    this.basicReactions.set(reaction.id, reaction);
    return reaction;
  }

  public ingestReactionGroup(reactionGroup?: ReactionGroupData): ReactionGroupData | undefined {
    if (!reactionGroup) { return; }
    this.reactionGroups.set(reactionGroup.id, reactionGroup);
    return reactionGroup;
  }

  public async loadReactions() {
    if (this.isLoadingReactions) { return; }
    this.l.log('Nuskaitom iš serverio basic reakcijas', this.tag);
    this.isLoadingReactions = true;
    const result = await this.req.misc.getReactions().toPromise();
    if (result.success) {
      result.icons.map((reaction) => this.ingestBasicReaction(reaction));
      this.storage.set('basicReactions', [...this.basicReactions.values()]);
      this.storage.set('reactionsIconsVersion', result.version);
      this.l.log(`Atsiuntėm reakcijas: ${result.icons.length}. Versija: ${result.version}`, this.tag);
      this.iconService.retrieveIcons('reactions', '0'); // Tokiu būdu perduosim native appsui naujas ikonas.
    }
    this.isLoadingReactions = false;
  }

  public getReactionIcon(reactionId: number, png?: boolean): string {
    if (this.reactions.has(reactionId)) {
      const reaction = this.reactions.get(reactionId);
      return png ? reaction.image_png : reaction.image;
    }
    if (this.basicReactions.has(reactionId)) {
      const reaction = this.basicReactions.get(reactionId);
      return png ? reaction.iconPathPng : reaction.iconPath;
    }
    this.loadReactions();
    return '';
  }

  public getReactionIconUrl(reactionId: number, png: boolean = false): SafeUrl {
    const urlString = this.getReactionIcon(reactionId, png);
    if ( urlString !== '' ) {
      const url = new URL(urlString, this.region.regionBaseUrl);
      return this.sanitizer.bypassSecurityTrustUrl(url.href);
    }
    return '';
  }

  public getReactionName(id: number): string {
    const reaction = this.reactions.get(id);
    if(!reaction) { return ''; }
    let reactionName = reaction.name;
    if(reaction.default_name) {
      reactionName = this.lang.get(`reactionNames.reactions.${reactionName}`);
    }
    return reactionName;
  }

  public changeReactionInEditId(value: number) {
    this.reactionInEditIdChange.next(value);
  }

}
