<div class="account-switcher {{ tallView ? 'account-switcher-tall' : ''}}" [ngClass]="[forgetMode ? 'forgetMode' : '']">
  <div class="account" *ngFor="let a of forgetMode ? availableAccounts : auth.mergedRegionAccounts" role="button" tabindex="0" (click)="click(a)" [ngClass]="!forgetMode && a.active ? 'active' : ''">
    <span class="account-icon">
      <div class="userIcon"></div>
    </span>
    <span class="name">{{ a.name }}</span>
    <span class="email">{{ a.email }}</span>
    <span class="loginState">{{ a.token ? '' : trans('auth.labels.loggedOut') }}</span>
    <span class="region" *ngIf="AppSettings.regionsEnabled">{{ a.region.name }}</span>
    <span class="role">{{ a.roleName }}</span>
  </div>
</div>
