import { CommonModule } from '@angular/common';
import { Component, OnInit, Input, ViewEncapsulation } from '@angular/core';
import { InlineSVGModule } from 'ng-inline-svg-w';

@Component({
  selector: 'app-button-double-line-arrow',
  templateUrl: './button-double-line-arrow.component.html',
  styleUrls: ['./button-double-line-arrow.component.scss'],
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [InlineSVGModule, CommonModule],
})
export class ButtonDoubleLineArrowComponent implements OnInit {
  @Input() firstLine = '';
  @Input() secondLine = '';
  @Input() withBorder = false;
  @Input() showArrow = true;
  @Input() icon = '';
  @Input() firstLineIsBold = false;
  @Input() isDisabled = false;

  constructor() {}

  ngOnInit(): void {}
}
