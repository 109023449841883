import http from '../http';
import { BasicSuccessResponse, ErrorResponse, objectToParams } from './common';

export type Tag = {
    id: number;
    name: string;
    company_id: number;
    color: string;
    textColor?: string;
    description: string;
    assigned_systems?: number;
    companyName?: string; // Siunčiama tik tada, kai vartotojas turi teisę matyti kompanijas.
};

type SaveTagRequest = Tag;
type GetTagsRequest = {
    forList?: boolean;
    searchPhrase?: string;
    searchFields?: string[];
    paginationPage?: number;
    include_statistics?: boolean;
};
type GetTagRequest = { id: number };
type GetTagsResponse = ErrorResponse | (BasicSuccessResponse & {
    list: {
        total: number;
        per_page: number;
        current_page: number;
        last_page: number;
        next_page_url: string | null;
        prev_page_url: string | null;
        from: number;
        to: number;
        data: Tag[];
    };
});
type GetTagResponse = BasicSuccessResponse & { tag: Tag };
export type AddTagResponse = BasicSuccessResponse & { id: number };

const addTag = (req: SaveTagRequest) => http.post<AddTagResponse, SaveTagRequest>('/v3/api/tag', req);
const saveTag = (req: SaveTagRequest) => http.put<BasicSuccessResponse, SaveTagRequest>('/v3/api/tag', req);
const getTag = (req: GetTagRequest) => http.get<GetTagResponse, URLSearchParams>('/v3/api/tag', objectToParams(req));
const getTags = (req: GetTagsRequest) => http.get<GetTagsResponse, URLSearchParams>('/v3/api/tags', objectToParams(req));
const deleteTag = (req: GetTagRequest) => http.delete<BasicSuccessResponse, URLSearchParams>('/v3/api/tag', objectToParams(req));

export default {
    getTag,
    addTag,
    getTags,
    saveTag,
    deleteTag,
};
