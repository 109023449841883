import { Injectable } from '@angular/core';
import { LanguageService } from './language.service';
import { DateTime, Interval } from 'luxon';
import { UserService } from '../api/user.service';
import { DropdownItem } from '../ui/dropdown/dropdown.component';

@Injectable({
  providedIn: 'root',
})
export class DateService {
  private formats = [
    { locale: '', format: '' },
    { locale: 'lt-LT', format: '{0}-{1}-{2}' },
    { locale: 'lt-LT', format: '{2}/{1}/{0}' },
    { locale: 'en-US', format: '{0}-{1}-{2}' },
    { locale: 'en-US', format: '{2}/{1}/{0}' },
  ];
  public dateTimeDropdownFormats: DropdownItem<number>[] = [];
  private timeFormats = ['t', 'HH:mm', 'h:m a', 'HH:mm:ss'];

  constructor(private us: UserService, private ls: LanguageService) { }

  getUsersLocale(defaultValue: string): string {
    if (typeof window === 'undefined' || typeof window.navigator === 'undefined') {
      return defaultValue;
    }
    const wn = window.navigator as any;
    let lang = wn.languages ? wn.languages[0] : defaultValue;
    lang = lang || wn.language || wn.browserLanguage || wn.userLanguage;
    return lang;
  }

  public formatDateTime(dateTime: Date, reversed?: boolean, specificFormat?: number, includeSeconds?: boolean, includeMilliseconds?: boolean): string {
    let currentFormat = this.us.currentUser?.date_format ?? 0;
    if (specificFormat !== undefined) {
      currentFormat = specificFormat;
    }
    if (currentFormat === 0) {
      currentFormat = 1; // Force kad naudotu custom formata.
    }
    const f = this.formats[currentFormat];
    const datePart = f.format
      .replace('{0}', dateTime.getFullYear().toString())
      .replace('{1}', (dateTime.getMonth() + 1).toString().padStart(2, '0'))
      .replace('{2}', dateTime.getDate().toString().padStart(2, '0'));
    const timePart = DateTime.fromJSDate(dateTime)
      .setLocale(f.locale)
      .toFormat(includeSeconds ? (includeMilliseconds ? 'ttt' : 'tt') : 't');
    if (reversed !== undefined && reversed) {
      return timePart + ' ' + datePart;
    }
    return datePart + ' ' + timePart;
  }

  public formatDateTimeFromNumber(dateTimeInt: number, reversed?: boolean, showSeconds?: boolean, showMillis?: boolean): string {
    return this.formatDateTime(new Date(dateTimeInt), reversed, undefined, showSeconds, showMillis);
  }

  /**
   * Formatuojam laiką pagal varototojo nustatymą. Skirta formatuoti timestamp tipo laikui (sekundės nuo epochos pradžios)
   *
   * @param dateTimeInt number. Laikas sekundemis. Šis kintamasis bus padaugintas iš 1000, kad sekundės virstų milisekundėmis.
   */
  public formatTimeFromNumber(dateTimeInt: number): string {
    return this.formatTime(new Date(dateTimeInt * 1000));
  }

  /**
   * Formatuojam laiką, pagal vartotojo datos formato nustatymą.
   *
   * @param dateTime Date.Laikas kurį reikia formatuot.
   * @param specificFormat number. Naudojama kai norima ignoruoti vartotojo nustatymą ir naudoti tam tikrą formatą.
   */
  public formatTime(dateTime: Date, specificFormat?: number): string {
    let currentFormat = this.us.currentUser.date_format ?? 0;
    if (specificFormat !== undefined) {
      return DateTime.fromJSDate(dateTime).toFormat(this.timeFormats[specificFormat]);
    }
    if (currentFormat === 0) {
      currentFormat = 1;
    } else if ( currentFormat >= this.formats.length ) {
      currentFormat = 1;
    }
    const f = this.formats[currentFormat];
    return DateTime.fromJSDate(dateTime).setLocale(f.locale).toFormat('t');
  }

  public formatDate(dateTime: Date, specificFormat?: number): string {
    let currentFormat = this.us.currentUser.date_format ?? 0;
    if (specificFormat !== undefined) {
      currentFormat = specificFormat;
    }
    if (currentFormat === 0) {
      currentFormat = 1;
    } else if ( currentFormat >= this.formats.length ) {
      currentFormat = 1;
    }
    const f = this.formats[currentFormat];
    const datePart = f.format
      .replace('{0}', dateTime.getFullYear().toString())
      .replace('{1}', (dateTime.getMonth() + 1).toString().padStart(2, '0'))
      .replace('{2}', dateTime.getDate().toString().padStart(2, '0'));
    return datePart;
  }

  public formatDateFromNumber(dateTimeInt: number): string {
    return this.formatDate(new Date(dateTimeInt));
  }

  public getDayTitle(dateTime: Date): string {
    if (this.isToday(dateTime)) {
      return this.ls.get('events.list.today');
    }
    const startOfYesterday = DateTime.now().startOf('day').minus({ days: 1 });
    const endOfYesterday = DateTime.now().endOf('day').minus({ days: 1 });
    if (Interval.fromDateTimes(startOfYesterday, endOfYesterday).contains(DateTime.fromJSDate(dateTime))) {
      return this.ls.get('events.list.yesterday');
    }

    return this.formatDate(dateTime);
  }

  public getDayTitleFromNumber(dateTimeInt: number): string {
    return this.getDayTitle(new Date(dateTimeInt * 1000));
  }

  public isToday(dateTime: Date): boolean {
    const startOfToday = DateTime.now().startOf('day');
    const endOfToday = DateTime.now().endOf('day');
    return Interval.fromDateTimes(startOfToday, endOfToday).contains(DateTime.fromJSDate(dateTime));
  }

  public isTodayFromNumber(dateTimeInt: number): boolean {
    return this.isToday(new Date(dateTimeInt * 1000));
  }

  public populateDateTimeDropdownFormats() {
    this.dateTimeDropdownFormats = [
      { value: 1, label: this.formatDateTime(new Date(), false, 1) },
      { value: 2, label: this.formatDateTime(new Date(), false, 2) },
      { value: 3, label: this.formatDateTime(new Date(), false, 3) },
      { value: 4, label: this.formatDateTime(new Date(), false, 4) },
    ];
  }

}
