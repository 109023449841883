import { Injectable } from '@angular/core';
import { Router, UrlTree } from '@angular/router';
import { PlatformService } from '../api/platform.service';

@Injectable({
    providedIn: 'root',
})
export class DesktopGuard  {
    constructor(
        private platform: PlatformService,
        private router: Router,
    ) {}

    public async canActivate(): Promise<UrlTree | boolean> {
          if ( !this.platform.isDesktopView() ) {
            return this.router.parseUrl('/');
          }

          return true;
    }
}
