import { IPCom } from 'src/app/models/ipcom';
import http from '../http';
import { BasicSuccessResponse, ErrorResponse, objectToParams } from './common';
import event_description from './settings.event_escription';
import { Tag } from './tag';
import { IPComReceiverOutputUnion } from 'src/app/company/components/ipcom-receivers-data-table/ipcom-receivers-data-table.component';

export const DEFAULT_COMPANY_MAX_LEVEL = '4';
export const DEFAULT_COMPANY_DELETION_DAYS = '14';
export const DEFAULT_PENDING_SYSTEM_DAYS = '7';

export type IPComSettings = {
    receivers: IPComReceiver[];
    outputs: IPComOutput[];
};

export type IPComReceiver = {
    id?: number;
    ipcom_id: number | 0;
    receiver_id: number;
    receiver_name: string;
    receiver_number: string;
    line_number: string;
    encryption_password: string;
    port: number;
    com: number[];
    smpp: number[];
    network_mode: 'Both' | 'TCP' | 'UDP' | 'Off';
    parent_id: number;
    outputs: number[];
    deviation_negative: number;
    deviation_positive: number;
    tags: Tag[];
    company_id: number;
    deleted?: boolean; // reikalingas tik trinant egzistuojantį
    deleting?: boolean; // reikalingas tik trinant, kad būtų rodoma animacija
};

export type IPComOutput = {
    ipcom_id: number | 0;
    output_id: number;
    type: 0 | 1;
    ip: string;
    tcp_port: number;
    com_id: number;
    hb_enable: 'True' | 'False';
    hb_interval: number;
    active: 'True' | 'False';
    out_mode: IPComOutputMode;
    buffer_size: number;
    name: string;
    identificator: number;
    encryption_enabled?: 'True' | 'False';  // IPCom 4.64+
    sia_key?: string;                       // IPCom 4.64+
};

export type IPComReceiverForTemplate = Omit<IPComReceiver, 'id'> & { id: number; input_host: string; ipcom_name: string };

export enum IPComOutputMode {
    Surgard,
    Monas3,
    Surgard8,
    SurgardNoEnd,
    Ademco685,
    Ademco685Cid,
    Surgard2000,
    SiaDc09,
}

export type GatewayIpcom = {
    id: number,
    name: string,
    host_for_devices: string,
    host: string;
    currentReceivers: { receiver_id: number, port: number }[],
    currentOutputs: { output_id: number, identificator: number }[],
};

type ReadSettingsRequest = { id: number | undefined };
type IpcomRequest = { main: IPCom; settings: IPComSettings };
type WriteSettingsRequest = { id: number; settings: IPComSettings };
export type AddIpcomRequestResponse = BasicSuccessResponse & { id: number };
type TestIpcomRequest = Pick<IPCom, 'peer_name' | 'host' | 'port' | 'user_name'>;
type ReadSettingsRequestResponse = BasicSuccessResponse & { data: IPComSettings };
type ReadUserReceiversRequestResponse = BasicSuccessResponse & { data: IPComSettings, gateways: GatewayIpcom[] };
type TestIpcomRequestResponse = BasicSuccessResponse & { status: boolean; settings: IPComSettings | false };
type GetReceiversForTemplateRequestResponse = BasicSuccessResponse & { receivers: IPComReceiverForTemplate[] };

const saveIpcom = (req: IpcomRequest) => http.put<BasicSuccessResponse, IpcomRequest>('/v3/api/ipcom', req);
const addIpcom = (req: IpcomRequest) => http.post<AddIpcomRequestResponse, IpcomRequest>('/v3/api/ipcom', req);
const testIpcom = (req: TestIpcomRequest) => http.get<TestIpcomRequestResponse, URLSearchParams>('/v3/api/test-ipcom', objectToParams(req));
const getReceiversForTemplate = () => http.get<GetReceiversForTemplateRequestResponse, URLSearchParams>('/v3/api/ipcom-receivers', undefined);
const writeIPComSettings = (req: WriteSettingsRequest) => http.post<BasicSuccessResponse, WriteSettingsRequest>('/v3/api/ipcom-settings', req);
const deleteIpcom = (req: {id: number}) => http.delete<(BasicSuccessResponse | ErrorResponse), URLSearchParams>('/v3/api/ipcom', objectToParams(req));
const readIPcomSettings = <T extends ReadSettingsRequest>(req: T) => http.get<T extends { id: undefined } ? ReadUserReceiversRequestResponse : ReadSettingsRequestResponse, URLSearchParams>('/v3/api/ipcom-settings', objectToParams(req), { silentOnError: true });
const addIpcomReceiver = (req: IPComReceiverOutputUnion) => http.post<(BasicSuccessResponse & { id: number }) | ErrorResponse, IPComReceiverOutputUnion>('/v3/api/ipcom-receiver', req);
const saveIpcomReceiver = (req: IPComReceiverOutputUnion) => http.put<BasicSuccessResponse | ErrorResponse, IPComReceiverOutputUnion>('/v3/api/ipcom-receiver', req);
const deleteIpcomReceiver = (req: { id: number }) => http.delete<BasicSuccessResponse | ErrorResponse, URLSearchParams>('/v3/api/ipcom-receiver', objectToParams(req));

export type PanicSettings = {
    id: number;
    company_id: number;
    grg_host: string;
    grg_port: number;
    grg_code: string;
    grg_zone: string;
    grg_enabled: boolean;
};

type SetPanicButtonSettingsRequest = {
    grg_host: string;
    grg_port: number;
    grg_code: string;
    grg_zone: string;
    grg_enabled: boolean | 'on' | 'off';
    company_id: number;
};
type SetPanicButtonSettingsResponse = ErrorResponse | (BasicSuccessResponse & { id: number; });
const setPanicButtonSettings = (req: SetPanicButtonSettingsRequest) => http.put<SetPanicButtonSettingsResponse, SetPanicButtonSettingsRequest>('/v3/api/panic-button-settings', req);

export default {
    event_description,
    ipcom: {
        add: addIpcom,
        save: saveIpcom,
        test: testIpcom,
        delete: deleteIpcom,
        read: readIPcomSettings,
        write: writeIPComSettings,
        getReceivers: getReceiversForTemplate,
        addReceiver: addIpcomReceiver,
        saveReceiver: saveIpcomReceiver,
        deleteReceiver: deleteIpcomReceiver,
    },
    savePanicSettings: setPanicButtonSettings,
};
