import { Injectable } from '@angular/core';

import { CompanyService } from '../api/company.service';
import { UserService } from '../api/user.service';

@Injectable({
    providedIn: 'root',
})
export class CompanyDropdownListResolver  {
    constructor(private companyService: CompanyService, private userService: UserService) { }
    public async resolve() {
        if ( !this.userService.currentUser.permissions.permissions.company_settings.view ) { return; }
        await this.companyService.loadDropDownList();
    }
}
