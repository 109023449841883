import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { TCamera } from 'src/app/models/camera';
import { TEditableComponent } from 'src/app/models/editable-component';
import { ElementResizePopupComponent } from 'src/app/popups/element-resize-popup/element-resize-popup.component';
import { EditSystemService } from 'src/app/services/edit-system.service';
import { LanguageService } from 'src/app/services/language.service';
import { PopupService } from 'src/app/services/popup.service';
import { LanguageAware } from '../../language-aware';
import { CommonModule } from '@angular/common';
import { LongPressMenuComponent } from '../../long-press-menu/long-press-menu.component';
import { InlineSVGModule } from 'ng-inline-svg-w';

@Component({
  selector: 'app-home-cameras',
  templateUrl: './home-cameras.component.html',
  styleUrls: ['./home-cameras.component.scss'],
  standalone: true,
  imports: [CommonModule, LongPressMenuComponent, InlineSVGModule],
})
export class HomeCamerasComponent extends LanguageAware implements OnInit, OnDestroy {
  @Input() nameTextColor = '';
  @Input() flatButton = false;
  public visibleCameras: TCamera[] = [];
  private changeSubscriber = null;
  private currentSystem = 0;

  constructor(cdRef: ChangeDetectorRef, public lang: LanguageService, private es: EditSystemService, private router: Router, private pp: PopupService) {
    super(cdRef, { noVisualModifications: true, shouldScrollTop: false });
    this.tag = 'home-cameras';

    const that = this;
    this.loadVisibleCameras();
    this.changeSubscriber = this.systems.onActiveSystemChange.subscribe(() => {
      if ( !this.systems.activeSystem ) { return; }
      that.handleSystemChange();
    });
  }

  ngOnInit(): void {}

  ngOnDestroy() {
    if (this.changeSubscriber !== null) {
      this.changeSubscriber.unsubscribe();
      this.changeSubscriber = null;
    }
  }

  private loadVisibleCameras() {
    this.visibleCameras = [];
    const systemConfig = this.us.currentUser.homeConfigurations.find((c) => c.system_id === this.systems.activeSystem.id);
    if (systemConfig === undefined) {
      this.log('systemConfig neegzistuoja.');
    } else {
      this.log('systemConfig.visibleSensors', systemConfig.visibleCameras);
    }
    if (systemConfig === undefined || systemConfig.visibleCameras.length === 0) {
      this.log('Filtruojam.', this.systems.activeSystem.cameras);
      this.visibleCameras = this.systems.activeSystem.cameras;
      return;
    }
    for (const iCamera of this.systems.activeSystem.cameras) {
      if (systemConfig.visibleCameras.indexOf(iCamera.id) !== -1) {
        this.visibleCameras.push(iCamera);
      }
    }
  }

  private handleSystemChange() {
    if (this.systems.activeSystem === null) {
      return;
    }
    this.loadVisibleCameras();
    if (this.currentSystem !== this.systems.activeSystem.id) {
      this.currentSystem = this.systems.activeSystem.id;
    }
  }

  public cameraClick(camera: TCamera) {
    if (camera.fullUrl === '') {
      if (camera.host === '' || (camera.pass !== '' && camera.user === '')) {
        this.toaster.postError(this.trans('systems.errors.notEnoughCameraDetails'));
        return;
      }
      camera.fullUrl = 'rtsp://';
      if (camera.user !== '' && camera.pass !== '') {
        camera.fullUrl += camera.user + ':' + camera.pass + '@';
      }
      camera.fullUrl += camera.host + ':' + (camera.port || '554');
      camera.fullUrl += camera.path;
    }
    const assignedPgmIds: number[] = [];
    const assignedPgmNames: string[] = [];
    for (const iPgm of camera.assignedPgms) {
      const pgm = this.systems.activeSystem.pgms.find((p) => p.queue_no === iPgm);
      if (pgm !== undefined) {
        assignedPgmIds.push(pgm.id);
        assignedPgmNames.push(pgm.name);
      }
    }
    if (this.platform.isAndroid()) {
      this.log('Atidarom kameros vaizda androide', {
        c: camera.fullUrl,
        n: camera.name,
        p: assignedPgmIds,
      });
      try {
        this.platform.androidHandler().openVideo(camera.fullUrl, camera.name, assignedPgmIds, assignedPgmNames, this.systems.activeSystem.id);
      } catch (err) {
        this.platform.androidHandler().openVideo(camera.fullUrl, camera.name);
      }
    } else if (this.platform.isApple()) {
      this.log('Atidarom kameros vaizda iOSe', {
        c: camera.fullUrl,
        n: camera.name,
        p: assignedPgmIds,
      });
      this.platform.appleHandler().openVideo.postMessage({
        url: camera.fullUrl,
        name: camera.name,
        pgmIds: assignedPgmIds,
        pgmNames: assignedPgmNames,
        system: this.systems.activeSystem.id,
      });
    } else {
      // desktope kol kas nieko
    }
  }

  public getCameraSizeClass(camera: TCamera): string {
    return 'camera-button_grid_' + (localStorage.getItem('home_camera_button_size_' + camera.id) ?? '_11');
  }

  public editCamera(camera: TCamera) {
    this.es.beginComponentEdit(TEditableComponent.Camera, camera.id, camera);
    localStorage.setItem('ref', 'home');
    this.router.navigate([...this.g.getHomeUrl(), 'settings', 'edit-camera']);
  }

  public editCameraSize(camera: TCamera) {
    this.pp.openPopup(ElementResizePopupComponent, {
      paramSetter: (p) => {
        p.elementName = camera.name;
        p.elementId = camera.id;
        p.sizeSelected.subscribe((size: { width: number; height: number }) => {
          localStorage.setItem('home_camera_button_size_' + camera.id, '' + size.width + size.height);
        });
      }
    });
  }
}
