import { Injectable, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { GoogleLoginRequest } from 'src/api/v3/login';
import { AppSettings } from 'src/app-settings';
import { AuthService } from '../api/auth.service';
import { GoogleAuthService } from '../api/google-auth.service';
import { LoggerService } from '../api/logger.service';
import { PlatformService } from '../api/platform.service';
import { RequestService } from '../api/request.service';
import { RtcService } from '../api/rtc/rtc.service';
import { SystemService } from '../api/system/system.service';
import { UserService } from '../api/user.service';
import { AppleService } from '../services/apple.service';
import { FcmService } from '../services/fcm.service';
import { GlobalService, companyRootRoute } from '../services/global.service';
import { LocatorService } from '../services/locator.service';
import { SystemsService } from '../services/systems.service';
import { LanguageService } from '../services/language.service';
import { ApiRequestService } from '../services/api-request.service';
import { PermissionRole } from 'src/api/v3/permission';

type EmailCheckResponseNoUser = {
  result: 'no_user';
};

type EmailCheckResponseLocal = {
  result: 'local';
  noPassword?: true;
};

type EmailCheckResponseSocial = {
  result: 'social';
  type: 'google' | string;
};

type EmailCheckResponseDisabled = {
  result: 'disabled';
};

export const ForgotPasswordTarget = Symbol('ForgotPasswordTarget');

type EmailCheckResponse = EmailCheckResponseNoUser | EmailCheckResponseLocal | EmailCheckResponseSocial | EmailCheckResponseDisabled;
/**
 * A service for common auth tasks.
 */
@Injectable({
  providedIn: 'root',
})
export class LoginService {
  public email = '';
  public password = '';

  public activationToken?: string;
  public passwordToken?: string;

  public get gauth() { return LocatorService.injector.get(GoogleAuthService); }

  constructor(
    private auth: AuthService,
    private req: RequestService,
    private rtc: RtcService,
    private system: SystemService,
    private systems: SystemsService,
    private user: UserService,
    private ls: LanguageService,
    private api: ApiRequestService,
    private l: LoggerService,
    private router: Router,
    private apple: AppleService,
    private zone: NgZone,
    private fcm: FcmService,
    private pl: PlatformService,
    private g: GlobalService,
  ) {}

  public clearLoginState() {
    this.email = '';
    this.password = '';
    this.activationToken = undefined;
  }

  public checkEmail(email: string): Observable<EmailCheckResponse> {
    return this.req.login.checkEmail(email).pipe(
      map((res) => {
        if (res.success) {
          if (res.isNew) {
            return { result: 'no_user' };
          } else if (!(res as { isActive: boolean }).isActive) {
            return { result: 'disabled' };
          } else if (!res.isNew && (res as { isSoc: boolean }).isSoc) {
            return { result: 'social', type: (res as { soc_type: string }).soc_type };
          } else if ((res as { isPasswordSet: boolean }).isPasswordSet) {
            return { result: 'local' };
          } else {
            return { result: 'local', noPassword: true };
          }
        } else if ('error' in res) {
          throw new Error(res.error);
        } else {
          throw new Error('Unknown login error');
        }
      })
    );
  }

  public login(email: string, password: string) {
    return this.req.login.login(email, password).pipe(
      map((res) => {
        if (res.success) {
          const { success, lastSystem, ...user } = res;
          this.rtc.close();
          this.user.clearUserData();
          this.auth.switchAccount(undefined);
          this.auth.setToken(user.token);
          this.user.setCurrentUserFromRaw(user);
          this.user.change();
          this.auth.registerUserDataWithSwitcher(this.user.currentUser);
          if (lastSystem) { this.system.ingestSystem(lastSystem); }
          this.rtc.connect();
          this.fcm.requestForToken();
          if(this.user.currentUser.language !== localStorage.getItem('lang')) {
            this.api.post('/translations', {
              language: this.user.currentUser.language,
              version: '-1',
            }, false).subscribe((data) => {
              if (data.success) {
                this.ls.selectedLanguage.value = data.code;
                this.ls.applyTranslations(data.translations, data.version, data.code);
                this.api.post('/language/set', { language: this.ls.selectedLanguage.value }, true);
              }
            });
          }
        }
        return res;
      })
    );
  }

  public determineLoginDestination() {
    switch (this.user.currentUser?.permissions?.role) {
      case PermissionRole.SuperAdmin:
        if (AppSettings.companyDesktopEnabled && this.pl.isDesktop()) { return [companyRootRoute]; }
        return ['/sadmin'];
      case PermissionRole.Company:
        if (AppSettings.companyDesktopEnabled && this.pl.isDesktop()) { return [companyRootRoute]; }
        return ['/company'];
      case PermissionRole.Installer:
        if (AppSettings.companyDesktopEnabled && this.pl.isDesktop()) { return ['/d']; }
        return ['/installer'];
      case PermissionRole.GeneralUser:
        return [(AppSettings.companyDesktopEnabled && this.pl.isDesktop()) ? '/d' : '/'];
      default:
        if ( !this.user.currentUser ) {
          return ['/'];
        } else {
          return [(AppSettings.companyDesktopEnabled && this.pl.isDesktop()) ? '/d' : '/'];
        }
    }
  }

  public logout() {
    this.auth.logOutFromSwitcher(this.auth.GetUserId());
    this.auth.setToken(null);
    this.user.setCurrentUser(null);
    this.rtc.close();
    this.gauth.signOut(false);
    this.user.clearUserData();
    this.auth.switchAccount(undefined);
  }

  public handleGoogleLoginSuccess(id_token: string, ios: boolean = false, ac?: string) {
    this.zone.run(() => {
      const params: GoogleLoginRequest = { id_token };
      if (ios) { params.ios = true; }
      if (ac) { params.ac = ac; }
      this.req.login.googleLogin(params).subscribe(
        res => {
          if (res.success) {
            const { success, ...user } = res;
            this.rtc.close();
            this.user.clearUserData();
            this.auth.setToken(user.token);
            this.user.setCurrentUserFromRaw(user);
            this.user.change();
            this.rtc.connect();
            this.auth.registerUserDataWithSwitcher(this.user.currentUser);
            this.fcm.requestForToken();
            this.router.navigate(this.g.resolveRootRoute());
          } else {
            this.l.log('Google login failed', 'Login', { res, id_token, ac });
          }
        }
      );
    });
  }

  public handleAppleLogin() {
    this.apple.performLogin(
      (data) => {
        const { success, ...user } = data;
        this.rtc.close();
        this.user.clearUserData();
        this.auth.setToken(user.token);
        this.user.setCurrentUserFromRaw(user);
        this.user.change();
        this.rtc.connect();
        this.auth.registerUserDataWithSwitcher(this.user.currentUser);
        this.fcm.requestForToken();
        this.router.navigate(this.g.resolveRootRoute());
      },
      (error?) => {
        if ( !error ) {
          this.l.log(error);
        }
      }
    );
  }
}
