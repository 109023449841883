import http from '../http';
import { BasicSuccessResponse, ErrorResponse } from './common';

type EditThermostatRequest = {
  systemId: number;
  thermostatId: number;
  name: string;
  value: number;
};
type EditThermostatResponse = ErrorResponse | BasicSuccessResponse;

const saveThermostat = (req: EditThermostatRequest) => http.put<EditThermostatResponse, EditThermostatRequest>('/v3/api/thermostat', req);

export default {
  save: saveThermostat,
};
