import { AppColors } from 'src/app-colors';
import { AppSettings } from 'src/app-settings';

export const Colors: AppColors = {
    primaryColor: AppSettings.primaryColor,
    secondaryColor: AppSettings.secondaryColor,
    areaStates: {
        armed: '#0072bb',
        alarmed: '#D21F26',
        disarmed: '#06d6a0',
        armingInProgress: '#dd7600',
        disarmingInProgress: '#dd7600',
        unknown: '#c0c0c0',
    },
};
