import { CommonModule } from '@angular/common';
import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { LanguageAware } from 'src/app/general/language-aware';
import { MessageboxService } from 'src/app/services/messagebox.service';

@Component({
  selector: 'app-assisting-installer',
  templateUrl: './assisting-installer.component.html',
  styleUrls: ['./assisting-installer.component.scss'],
  standalone: true,
  imports: [CommonModule],
})
export class AssistingInstallerComponent extends LanguageAware implements OnInit {
  @Input() installerId = 0;
  @Input() installerEmail = '';
  @Input() systemId = 0;
  @Input() showRevokeButton = true;

  constructor(cdRef: ChangeDetectorRef, private msg: MessageboxService) {
    super(cdRef, { noVisualModifications: true });
  }

  ngOnInit(): void {}

  public doRevoke(onSuccess?: () => void) {
    if (this.miniStatus.isVisible()) {
      this.miniStatus.flash();
      return;
    }
    this.miniStatus.show(this.trans('general.pleaseWait'));
    this.api.post('/revoke-installer', {
      id: this.installerId,
      systemId: this.systemId,
    }, true).subscribe(
      (result) => {
        if (result.success) {
          this.toaster.postSuccess(this.trans('systems.notifications.installerAccessRevoked'));
          const system = this.systems.getSystem(this.systemId) || this.systemService.systems.get(this.systemId);
          if (system !== undefined) {
            system.assistedById = undefined;
            system.assistedByEmail = undefined;
          }
          if ( onSuccess ) { onSuccess(); }
        } else {
          this.toaster.postError(result.error);
        }
        this.miniStatus.hide();
      },
      (error) => {
        this.miniStatus.hide();
      }
    );
  }

  public initRevoke() {
    this.msg.open({
      buttons: this.msg.buttons.OkCancel,
      headerText: this.trans('systems.notifications.areYouSure'),
      messageContent: this.trans('users.labels.revokeInstallerAccess'),
    }, (m) => {
      m.okClicked.subscribe(() => { this.doRevoke(); });
    });
  }
}
