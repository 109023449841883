import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AreaState } from 'src/api/v3/system.area';
import { TAreaData } from 'src/app/models/area-data';
import { TEditableComponent } from 'src/app/models/editable-component';
import { TEventData } from 'src/app/models/event-data';
import { AreaService } from 'src/app/services/area.service';
import { BackBehaviourService } from 'src/app/services/back-behaviour.service';
import { DateService } from 'src/app/services/date.service';
import { EditSystemService } from 'src/app/services/edit-system.service';
import { ZoneService } from 'src/app/services/zone.service';
import { AreaAlarmPopupComponent } from 'src/app/system/area-alarm-popup/area-alarm-popup.component';
import { LanguageAware } from '../../language-aware';
import { CommonModule } from '@angular/common';
import { LoaderDotsComponent } from 'src/app/components/loader-dots/loader-dots.component';
import { InlineSVGModule } from 'ng-inline-svg-w';
import { TakeActionButtonComponent } from 'src/app/components/take-action-button/take-action-button.component';

@Component({
  selector: 'app-home-areas',
  templateUrl: './home-areas.component.html',
  styleUrls: ['./home-areas.component.scss'],
  standalone: true,
  imports: [CommonModule, LoaderDotsComponent, InlineSVGModule, TakeActionButtonComponent],
})
export class HomeAreasComponent extends LanguageAware implements OnInit, OnDestroy {
  @ViewChild('alarmPopup') alarmPopup: AreaAlarmPopupComponent;
  public readonly AreaState = AreaState;
  public visibleAreas: TAreaData[] = [];
  public visibleOuputs = [];
  private systemChangeListener = null;
  public alarmedAreaClicked: TAreaData = null;
  public shouldShowTakeAction = {};
  private takeActionButtonVisibilitySubscriber = null;

  constructor(
    cdRef: ChangeDetectorRef,
    public areaService: AreaService,
    private router: Router,
    public dt: DateService,
    private zs: ZoneService,
    private es: EditSystemService,
    private bh: BackBehaviourService,
    private ar: ActivatedRoute,
  ) {
    super(cdRef, { noVisualModifications: true, shouldScrollTop: false });
    this.tag = 'HomeAreas';

    this.systems.setCurrentArea(0);
    this.getVisibleAreas();
    const that = this;
    this.takeActionButtonVisibilitySubscriber = this.areaService.onTakeActionButtonVisibilityChanged.subscribe((data: any) => {
      that.shouldShowTakeAction[data.id] = data.visible;
    });
    this.systemChangeListener = this.systems.onActiveSystemChange.subscribe(() => {
      if ( !this.systems.activeSystem ) { return; }
      that.getVisibleAreas();
      that.updateTakeAction();
    });
    that.updateTakeAction();
  }

  ngOnInit(): void {}

  ngOnDestroy() {
    if (this.systemChangeListener !== null) {
      this.systemChangeListener.unsubscribe();
      this.systemChangeListener = null;
    }
    if (this.takeActionButtonVisibilitySubscriber !== null) {
      this.takeActionButtonVisibilitySubscriber.unsubscribe();
      this.takeActionButtonVisibilitySubscriber = null;
    }
  }

  private getVisibleAreas() {
    const perm = this.systems.currentUserPermissions;
    if (perm?.permissions.systems.view) {
      this.visibleAreas = this.prioritizePanicButtonsArea(this.systems.activeSystem.areas);
      return;
    }
    this.visibleAreas = [];
    const filteredAreas = this.systems.activeSystem.areas.filter((a) => this.systems.activeSystemHelper.can.see.area(a.queue_no));
    const systemConfig = this.us.currentUser.homeConfigurations.find((c) => c.system_id === this.systems.activeSystem.id);
    if (systemConfig === undefined || systemConfig.visibleAreas.length === 0) {
      this.visibleAreas = this.prioritizePanicButtonsArea(filteredAreas);
      return;
    }
    for (const iArea of filteredAreas) {
      if (systemConfig.visibleAreas.indexOf(iArea.queue_no) !== -1) {
        if (iArea.status === AreaState.PanicButtons) {
          this.visibleAreas.unshift(iArea);
        } else {
          this.visibleAreas.push(iArea);
        }
      }
    }
  }

  private prioritizePanicButtonsArea(areas: TAreaData[]): TAreaData[] {
    return areas.sort((a, b) => {
      const aIsPanicButtonArea = a.status === AreaState.PanicButtons ? 1 : 0;
      const bIsPanicButtonArea = b.status === AreaState.PanicButtons ? 1 : 0;
      return bIsPanicButtonArea - aIsPanicButtonArea;
    });
  }

  public goToArea(areaId: number) {
    this.systems.setCurrentArea(areaId);
    this.router.navigate(['area-view'], { relativeTo: this.ar });
  }

  public goEditArea(areaId: number) {
    this.es.setDefaults();
    const area = this.systems.activeSystem.areas.find((a) => a.id === areaId);
    this.es.beginComponentEdit(TEditableComponent.Area, area.queue_no, area);
    for (const iPgm of this.systems.activeSystem.pgms) {
      if (iPgm.area_id === areaId && iPgm.control_area) {
        this.es.beginComponentEdit(TEditableComponent.Output, iPgm.queue_no, iPgm);
      }
    }
    this.router.navigate(['settings', 'system-area'], { relativeTo: this.ar });
  }

  public getLastEvent(areaId: number): TEventData {
    const areaEvents = this.systems.activeSystem.events.filter((e) => e.areaId === areaId);
    const empty: TEventData = {
      id: 0,
      reaction: 0,
      time: 0,
      title: this.trans('events.list.empty'),
      areaText: '',
      areaId: 0,
      areaInfo: '',
      isAreaEvent: true,
      systemId: 0,
      iconPath: '',
      timeString: '',
      latitude: null,
      longitude: null,
      userName: null,
    };
    if (areaEvents.length === 0) {
      return empty;
    }

    for (const iEvent of areaEvents) {
      if (this.systems.activeSystem.eventConfiguration.length === 0) {
        return iEvent;
      } else if (this.systems.activeSystem.eventConfiguration.indexOf(iEvent.reaction) !== -1) {
        return iEvent;
      }
    }
    return empty;
  }

  public setAreaState(newState: AreaState, area: TAreaData) {
    if (!this.systems.activeSystemHelper.can.control.area(area.queue_no)) {
      this.toaster.postError(this.trans('systems.errors.operationNotAllowed'));
      return;
    }
    if (this.areaService.isBusy()) {
      this.toaster.postError(this.trans('systems.errors.busyArea'));
      return;
    }
    this.log('Bandoma keisti būseną', {
      areaName: area.name,
      newStatus: newState,
    });
    if (newState === area.status) {
      this.areaService.alarmedActionInProgress = false;
      this.log('Srities būsena jau tokia, kokios reikia.');
      return;
    }
    this.areaService.busyArea = {
      systemId: this.systems.activeSystem.id,
      areaId: area.id,
      oldRememberPin: !area.showKeypad,
      newRememberPin: !area.showKeypad,
      showPinKeypad: area.showKeypad,
      oldStatus: area.status,
      newStatus: newState,
      pinToUse: '',
      zonesToBypass: [],
      showZoneBypass: false,
    };
    this.refresher.disableRefresher();
    if (!this.systems.activeSystem.directControl || !this.areaService.busyArea.showPinKeypad) {
      this.areaService.busyArea.showPinKeypad = false;
      this.areaService.performStatusChange();
    }
  }

  public canSleep(): boolean {
    return this.systems.activeSystemHelper.supports.sleepState();
  }

  public canStay(): boolean {
    return this.systems.activeSystemHelper.supports.stayState();
  }

  public closeAlarmWindow() {
    this.alarmedAreaClicked = null;
  }

  public showAlarmWindow(area: TAreaData) {
    if (this.alarmedAreaClicked !== null) {
      return;
    }
    if (this.areaService.alarmedActionInProgress) {
      return;
    }
    this.alarmedAreaClicked = area;
    if (this.alarmPopup !== null) {
      this.alarmPopup.showWindow();
    }
  }

  public disarmTheAlarmedArea() {
    this.areaService.alarmedActionInProgress = true;
    this.setAreaState(1, this.alarmedAreaClicked);
    this.alarmedAreaClicked = null;
  }

  private updateTakeAction() {
    this.shouldShowTakeAction = {};
    for (const iArea of this.visibleAreas) {
      this.areaService.addOrUpdateAreaForTakeAction(iArea);
    }
  }
}
