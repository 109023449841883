<div [ngClass]="{'hai-container': true, 'hai-container-alarmed': isAlarmed || activeLoopText !== ''}" (click)="onAreaStatusClick()">
  <div *ngIf="!isAlarmed && activeLoopText === ''" class="hai-status-icon hai-status-icon-sensor" [inlineSVG]="'assets/images/sensor.svg'"></div>
  <div *ngIf="isAlarmed || activeLoopText !== ''" class="hai-status-icon hai-status-icon-fire" [inlineSVG]="'assets/images/event_fire.svg'"></div>
  <div class="hai-status-time" *ngIf="isAlarmed || activeLoopText !== ''"><span *ngIf='!dt.isTodayFromNumber(alarmTime)'>{{dt.getDayTitleFromNumber(alarmTime) + ' ' + trans('general.timeAt') + ' '}}</span>{{dt.formatTimeFromNumber(alarmTime)}}</div>
  <div class="hai-status"><div [ngClass]="isAlarmed || activeLoopText !== '' ? 'hai-alarm' : 'hai-standby'"></div></div>
  <div class="hai-header"><span>{{ trans('systems.statuses.status') }}</span></div>
  <div class="hai-last-status">
      <div class="ale-text">{{ isAlarmed || activeLoopText !== '' ? trans('systems.statuses.alarmed') : trans('systems.statuses.noAlarms') }}</div>
  </div>
  <div *ngIf="isAlarmed || activeLoopText !== ''" class="hai-loops">
    <span *ngIf="activeGroupText !== ''">{{ activeGroupText + (activeLoopText === '' ? '' : ', ') }}</span>
    <span *ngIf="activeLoopText !== ''">{{ trans('systems.labels.loop') + ': ' + activeLoopText }}</span>
  </div>
</div>
