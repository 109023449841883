import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { PlatformService } from 'src/app/api/platform.service';
import { BackButtonService } from './back-button.service';
import { InlineSVGModule } from 'ng-inline-svg-w';

@Component({
  selector: 'app-back-button',
  templateUrl: './back-button.component.html',
  styleUrls: ['./back-button.component.scss'],
  standalone: true,
  imports: [InlineSVGModule],
})
export class BackButtonComponent implements OnInit {
  /**
   * Nurodo rodyklytės spalvą.
   */
  @Input() buttonColor = '';
  /**
   * Nurodo kokio tipo back operacija bus perduodama android aplikacijai.
   */
  @Input() type: 'normal' | 'noop' = 'normal';
  /**
   * Nurodo ar vykdyti standartinę back operaciją. True - bus grįžtama ten, kur nurodyta per showHeader
   */
  @Input() defaultBehaviour = true;
  @Output() buttonPressed = new EventEmitter<void>();

  constructor(private b: BackButtonService, ps: PlatformService) {
    if (ps.isAndroid()) {
      ps.androidHandler().setBackButtonType(this.type);
    }
  }

  ngOnInit(): void {
  }

  public doBack() {
    if ( this.defaultBehaviour ) {
      this.b.performBack();
    }

    this.buttonPressed.emit();
  }
}
