<div class="formGroup">
    <label [ngClass]="['label', _val === '' && selectedTags.length === 0 && fixedTags.length === 0 ? 'empty' : '', selectedTags.length > 0 || fixedTags.length > 0 ? 'withBottomOffset' : '']" [for]="name">{{ label }}</label>
    <div (click)="tagInput?.focus()">
        <ng-container *ngFor="let iTag of fixedTags">
            <div [style.background-color]="iTag.color" [style.color]="iTag.textColor" class="tagItem" (click)="$event.stopPropagation()">{{iTag.name}}</div>
        </ng-container>
        <ng-container *ngFor="let iTag of selectedTags">
            <div [style.background-color]="iTag.color" [style.color]="iTag.textColor" class="tagItem" (click)="tagDeselected(iTag); $event.stopPropagation()"><div *ngIf="!isReadOnly" class="removeTag"><div [inlineSVG]="'assets/images/delete.svg'"></div></div>{{iTag.name}}</div>
        </ng-container>
    </div>
    <input #tagInput type="text" [(ngModel)]="val" id="{{name}}" class="input" [disabled]="isReadOnly" (focus)="lookUpTags()" autocomplete="off"/>
    
    <div class="wrap" *ngIf="loadedLookupTags.length > 0 || newTags.length > 0 || noTagsShowing">
        <div class="popupSection">
            <ng-container *ngIf="!creatingTag">
                <div *ngIf="loadedLookupTags.length === 0 && newTags.length === 0">{{lang.get('tags.labels.noTagsFound')}}</div>
                <ng-container *ngFor="let iTag of loadedLookupTags">
                    <div [style.background-color]="iTag.color" [style.color]="iTag.textColor" class="tagItem" (click)="tagSelected(iTag)">{{iTag.name}}<div *ngIf="iTag.company !== ''" class="companyText"><div [title]="iTag.company">{{iTag.company}}</div></div></div>
                </ng-container>
                <ng-container *ngFor="let iTag of newTags">
                    <div [style.background-color]="iTag.color" [style.color]="iTag.textColor" class="tagItem" (click)="createTagAndSelect(iTag)"><div class="addTag"><div>{{lang.get('general.new')}}</div></div>{{iTag.name}}</div>
                </ng-container>
            </ng-container>
            <div *ngIf="creatingTag" class="loader">
                <app-loader-round [isInline]="true" [isVisible]="true" [height]="1.7" [heightUnits]="'em'"></app-loader-round>
                <span>{{lang.get('general.creating')}}...</span>
            </div>
        </div>
    </div>
</div>