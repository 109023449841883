import { EventEmitter, Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { MessageBoxButtons } from '../popups/messagebox/messagebox-config';
import { TSwipeCoordinate } from '../models/swipe-coordinate';
import { AppSettings } from 'src/app-settings';
import { UserService } from '../api/user.service';
import { PlatformService } from '../api/platform.service';
import { SystemsService } from './systems.service';
import { G16 } from '../configurators/g16/src/app/models/g16-devices';
import { GV } from '../configurators/gv17/src/app/models/gv-devices';
import { G17 } from '../configurators/g17/src/app/models/g17-devices';
import { PermissionRole } from 'src/api/v3/permission';
export const companyRootRoute = '/d/company';

export const dualModeDevices: number[] = [0x18, 0x30, 0x31, 0x38, G16.E16_3A, 0x3e, G16.G16_42, G16.G16_43, G16.G16_44, G16.GET, G16.GT_PLUS, G16.GT];
export const indirectOnlyDevices: number[] = [0x19, 0x1c, G17.G17F, GV.GV17, 0x27, 0x2a, 0x2c, 0x2e, GV.WP17, 0x39, G16.E16T_3C, 0x3f, 0x41, G16.G16T_45, G16.G16T_46, G16.G16T_47, G16.G16T_48, 0x4f, G16.G16T_50, 0x51];
export const directOnlyDevices: number[] = [0x3b];
export const gFamily: number[] = [0x18, 0x19, 0x1c, 0x27, 0x2c, 0x2e, 0x30, 0x31, 0x38, 0x39, G16.E16_3A, G16.E16T_3C, 0x3e, 0x3f, 0x41, G16.G16_42, G16.G16_43, G16.G16_44, G16.G16T_45, G16.G16T_46, G16.G16T_47, G16.G16T_48, G16.GET, G16.G16T_50, G16.GT_PLUS, G16.GT];
export const spFamily: number[] = [0x3b, 0x51];
export const gvFamily: number[] = [GV.GV17, GV.WP17];
export const trk8Devices: number[] = [G16.G16T_47, G16.GET, G16.GT, G16.GT_PLUS, G16.G16T_50];
export const g17Family: number[] = [G17.CG17, G17.G17F];
export const virtualAreaDevices: number[] = [0x51, G17.G17F];

export type ConfigurableDevice = {
  uid: string;
  mpass: string;
  sn: string;
  bt: string;
  version: string;
  hwId: string;
  adminCode: string;
  dr: string;
  communicating: boolean;
};

@Injectable({
  providedIn: 'root',
})
export class GlobalService {
  public static MIN_THERMOSTAT_TEMPERATURE = -20; // reali modulio galimybe -55
  public static MAX_THERMOSTAT_TEMPERATURE = 50; // reali modulio galimybe +125
  private loaded = false;
  private redirectionURL = '';
  public onSwipeStartedSource = new Subject<TSwipeCoordinate>();
  public configurableDevice: ConfigurableDevice | null = null;
  public MessageBoxButtons = MessageBoxButtons;
  /**
   * Nurodo jog pradėtas tempimas pirštu.
   *
   * @param koordinatės TSwipeCoordinate
   */
  public onSwipeStarted = this.onSwipeStartedSource.asObservable();

  public onSwipeEndedSource = new Subject<TSwipeCoordinate>();
  /**
   * Nurodo jog baigtas tempimas pirštu. Pirštas pakeltas.
   *
   * @param koordinatės TSwipeCoordinate
   */
  public onSwipeEnded = this.onSwipeEndedSource.asObservable();

  public onSwipeContinueSource = new Subject<TSwipeCoordinate>();
  /**
   * Nurodo jog vis dar vykdomas tempimas pirštu.
   *
   * @param koordinatės TSwipeCoordinate
   */
  public onSwipeContinue = this.onSwipeContinueSource.asObservable();

  public onScrolledToBottomSource = new Subject<void>();
  public onScrolledToBottom = this.onScrolledToBottomSource.asObservable();
  public onNewVersionAvailable: EventEmitter<void> = new EventEmitter();

  constructor(private us: UserService, private platform: PlatformService, private systems: SystemsService) { }

  public isLoaded(): boolean {
    return this.loaded;
  }

  public setLoaded() {
    this.loaded = true;
  }

  public setRedirectionURL(url: string) {
    this.redirectionURL = url;
  }

  public hasRedirectionURL(): boolean {
    return this.redirectionURL.length > 0;
  }

  public getRedirectionURL(): string {
    return this.redirectionURL;
  }

  /**
   * Pagal `root` URL, vartotojo teises bei aktyvią sistemą sudaromas `Home` URL. Taip pat priklauso nuo to ar atidaroma per Desktop.
   * @returns Masyvas elementų, kurie nusako `Home` URL. Galima naudoti `router.navigate` metode.
   */
  public getHomeUrl(): string[] {
    const result = this.resolveRootRoute();
    const isDekstop = this.platform.isDesktopView();
    if ( !isDekstop ) {
      if (this.us.currentUser?.permissions?.role === PermissionRole.Company) {
        result.push('a');
      } else if (this.us.currentUser?.permissions?.role === PermissionRole.Installer) {
        result.push('a');
      } else if (this.us.currentUser?.permissions?.role === PermissionRole.SuperAdmin) {
        result.push('a');
      }
    }
    if ( !this.us.currentUser?.permissions.permissions.systems.view && !this.us.currentUser?.permissions.permissions.unassigned_devices.view && this.systems.activeSystem ) {
      result.push(this.systems.activeSystem.id.toString());
    } else if ( isDekstop && this.us.currentUser?.permissions.permissions.systems.view && this.systems.activeSystem ) {
      result.push('systems');
      result.push(this.systems.activeSystem.id.toString());
    } else if ( !isDekstop && this.systems.activeSystem ) {
      result.push(this.systems.activeSystem.id.toString());
    } else if ( !isDekstop && !this.systems.hasSystems() && !this.us.currentUser?.permissions.permissions.systems.view && !this.us.currentUser?.permissions.permissions.unassigned_devices.view ) {
      result.push('welcome');
    }
    return result;
  }

  public getMobileSettingsUrl(): string[] {
    const urlToReturn = this.getHomeUrl();
    const isDekstop = this.platform.isDesktopView();
    if ( !isDekstop ) {
      if (
        this.us.currentUser?.permissions?.role === PermissionRole.Company ||
        this.us.currentUser?.permissions?.role === PermissionRole.Installer ||
        this.us.currentUser?.permissions?.role === PermissionRole.SuperAdmin
      ) {
        urlToReturn.push('settings');
      }
    }
    return urlToReturn;
  }

  public getAppVersion(): string {
    return AppSettings.appVersionMajor.toString() + '.' + AppSettings.appVersionMinor.toString() + '(' + AppSettings.appVersionBuild.toString() + ')';
  }

  public isNewVersionAvailable(): boolean {
    localStorage.setItem('version_check_date', new Date().toISOString());
    if (this.us.appVersionDetails === null) {
      return false;
    }
    return this.us.appVersionDetails.build > AppSettings.appVersionBuild;
  }

  public checkAndNotifyAboutNewVersion() {
    if ( this.isNewVersionAvailable() ) {
      this.onNewVersionAvailable.emit();
    }
  }

  /**
   * Pagal vartotoją (ar jo nebuvimą) nustato koks bus realus `/` URL, kadangi jis gali pakisti priklausomai nuo to ar prisijungta Desktop versijoje ir ar vartotojas turi tam tikras teises.
   * 
   * @returns Masyvas elementų, kuriuos galima panaudoti `router.navigate` metode.
   */
  public resolveRootRoute(): string[] {
    const result = ['/'];
    if ( this.platform.isDesktopView() ) {
      result.push('d');
      if ( this.us.currentUser?.permissions.permissions.systems.view || this.us.currentUser?.permissions.permissions.unassigned_devices.view ) {
        result.push('company');
      }
    }
    return result;
  }
}
