import { EventEmitter, Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { LoggerService } from '../api/logger.service';

@Injectable({
  providedIn: 'root',
})
export class MiniStatusService {
  private statusMessage = '';
  public visible = false;
  private showTime = false;
  private timeValue = 0;
  private timeValueStr = '00:00';
  private timeWorker = null;
  private flashState = '1';
  private tag = 'MiniStatus';
  /** 
   * Languose, kur mini status gali būti iššauktas kelių skirtingų operacijų, galima naudoti šį kintamąjį tam,
   * kad paskui būtų galima patikrinti kuri operacija aktyvi (jei to reikia)
  */
  private operationId = '';

  private visibilityChange = new BehaviorSubject<boolean>(false);
  public onVisibilityChange = this.visibilityChange.asObservable();
  constructor(private l: LoggerService) {}

  /** Ekrane parodo pranešimą su nurodytu tekstu. */
  /** @param message Tekstas, kuris bus rodomas. */
  /** @param countDownTime __number__: Nurodo ar reikia rodyti operacijos laiką. */
  show(message: string, countDownTime: number = 0, operationId?: string) {
    if ( message === '' ) { return; }
    this.statusMessage = message;
    if (countDownTime > 0) {
      this.timeValue = countDownTime;
      this.startTimeWorker();
    } else {
      this.stopTimeWorker();
    }
    if ( operationId ) {
      this.operationId = operationId;
    }
    this.visible = true;
    this.l.log(message, this.tag);
    if ( !this.visibilityChange.getValue() ) {
      this.visibilityChange.next(true);
    }
  }

  /** Paslėpia šiuo metu rodomą pranešimą. Išvalo operationId reikšmę. */
  hide() {
    this.visible = false;
    this.showTime = false;
    this.statusMessage = '';
    this.operationId = '';
    this.stopTimeWorker();
    if ( this.visibilityChange.getValue() ) {
      this.visibilityChange.next(false);
    }
  }

  public isVisible(opeartionId?: string): boolean {
    return this.visible && (!opeartionId || opeartionId === this.operationId);
  }

  public getMessage(): string {
    return this.statusMessage;
  }

  public timerIsVisible(): boolean {
    return this.showTime;
  }

  private startTimeWorker() {
    const that = this;
    this.timeWorker = setInterval(() => {
      if (this.timeValue <= 0) {
        this.stopTimeWorker();
        return;
      }
      that.showTime = true;
      const minutes = Math.floor(that.timeValue / 60);
      let minutesStr = '';
      if (minutes < 10) {
        minutesStr = '0' + minutes.toString();
      } else {
        minutesStr = minutes.toString();
      }
      const seconds = Math.floor(that.timeValue % 60);
      let secondsStr = '';
      if (seconds < 10) {
        secondsStr = '0' + seconds.toString();
      } else {
        secondsStr = seconds.toString();
      }
      that.timeValueStr = minutesStr + ':' + secondsStr;
      that.timeValue--;
    }, 1000);
  }

  private stopTimeWorker() {
    if (this.timeWorker === null) {
      return;
    }
    clearInterval(this.timeWorker);
    this.timeWorker = null;
    this.showTime = false;
  }

  public getTime() {
    return this.timeValueStr;
  }

  public flash() {
    this.flashState = '2';
    const that = this;
    setTimeout(() => {
      that.flashState = '1';
    }, 500);
  }

  public getFlashState(): string {
    return this.flashState;
  }
}
