import { CommonModule } from '@angular/common';
import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { LoggerService } from 'src/app/api/logger.service';
import { PlatformService } from 'src/app/api/platform.service';
import { RefreshService } from 'src/app/services/refresh.service';

@Component({
  selector: 'app-long-press-menu',
  templateUrl: './long-press-menu.component.html',
  styleUrls: ['./long-press-menu.component.scss'],
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [CommonModule],
})
export class LongPressMenuComponent implements OnInit, OnDestroy {
  private longPressStarted = false;
  private longPressTimer = null;
  public shouldFadeIn = false;
  private routerSubscriber = null;
  private longPressComfirmed = false;
  private reenableRefresher = false;
  /**
   * Identifikatorius, kad keli popup nesusimaišytų
   */
  @Input() id = '';
  @Input() topAdjustment = 0;

  @ViewChild('overlay') overlay: ElementRef<HTMLDivElement>;
  @ViewChild('container') overlayContainer: ElementRef<HTMLDivElement>;
  @ViewChild('popup') popup: ElementRef<HTMLDivElement>;
  @ViewChild('button') button: ElementRef<HTMLDivElement>;
  @ViewChild('popupButton') popupButton: ElementRef<HTMLDivElement>;
  @ViewChild('tip') popupTip: ElementRef<HTMLElement>;

  constructor(private l: LoggerService, private pl: PlatformService, private router: Router, private refresher: RefreshService) {
    const that = this;
    this.routerSubscriber = this.router.events.subscribe(() => {
      that.hideMenu();
      that.routerSubscriber.unsubscribe();
      that.routerSubscriber = null;
    });
  }

  ngOnDestroy() {
    if (this.routerSubscriber !== null) {
      this.routerSubscriber.unsubscribe();
    }
  }

  ngOnInit(): void {}

  public showMenu() {
    const that = this;
    if (!this.button.nativeElement || !this.overlay.nativeElement || !this.overlayContainer.nativeElement) {
      return;
    }

    // Let's move overlay to body
    this.overlay.nativeElement.parentNode.removeChild(this.overlay.nativeElement);
    document.body.appendChild(this.overlay.nativeElement);

    //FIX ME: Angular kartais nepavyksta prikabinti onClick.
    this.overlay.nativeElement.addEventListener('click', (event) => {
      if (!that.shouldFadeIn) {
        return;
      }
      that.hideMenu();
      event.stopPropagation();
    });

    this.overlay.nativeElement.style.display = 'block';
    this.l.log('Showing menu', 'LongPressMenu');

    this.positionMenu();

    setTimeout(() => {
      that.shouldFadeIn = true;
      that.longPressComfirmed = true;
    }, 50);
  }

  public positionMenu() {
    const buttonRect = this.button.nativeElement.getBoundingClientRect();

    // Computing Bottom position
    let newTop = buttonRect.top + buttonRect.height;
    let newLeft = buttonRect.left + buttonRect.width / 2 - this.popupButton.nativeElement.offsetWidth / 2;
    let newCarret = this.popupButton.nativeElement.offsetWidth / 2 - this.popupTip.nativeElement.offsetWidth / 2;

    // Moving caret to middle of popup

    const bottomBound = newTop + this.popupButton.nativeElement.offsetHeight + this.popupTip.nativeElement.offsetHeight;
    if (bottomBound > document.body.offsetHeight) {
      this.l.log('Menu will overflow to the bottom of screen, rendering it to the top.', 'LongPressMenu');
      newTop = buttonRect.top - this.popupButton.nativeElement.offsetHeight - this.popupTip.nativeElement.offsetHeight;

      this.popupTip.nativeElement.style.top = this.popupButton.nativeElement.offsetHeight + 'px';
      this.popupTip.nativeElement.style.transform = 'rotate(180deg)';
    } else {
      this.popupTip.nativeElement.style.top = null;
      this.popupTip.nativeElement.style.transform = null;
    }

    const rightBound = newLeft + this.popupButton.nativeElement.offsetWidth;
    const screenWidth = document.body.offsetWidth;
    if (newLeft < 0) {
      this.l.log('Menu will overflow to the left of screen, moving to the rigth.', 'LongPressMenu');
      newCarret += newLeft;
      newLeft = 0;
    } else if (rightBound > screenWidth) {
      this.l.log('Menu will overflow to the right of screen, moving to the left.', 'LongPressMenu');
      newCarret += rightBound - screenWidth;
      newLeft -= rightBound - screenWidth;
    }

    // Render popup to the bottom
    this.l.log('Final Cords', 'LongPressMenu', { newLeft, newTop });
    this.popup.nativeElement.style.top = newTop + 'px';
    this.popup.nativeElement.style.left = newLeft + 'px';
    this.popupTip.nativeElement.style.left = newCarret + 'px';
  }

  public hideMenu() {
    //if ( !this.longPressComfirmed ) { return; }
    if (this.reenableRefresher) {
      this.refresher.enableRefresher();
    }
    this.longPressComfirmed = false;
    this.longPressStarted = false;
    this.shouldFadeIn = false;
    this.l.log('hide menu', 'LongPressMenu');
    if (this.overlay.nativeElement !== null) {
      this.overlay.nativeElement.style.display = 'none';
      this.overlay.nativeElement.parentNode.removeChild(this.overlay.nativeElement);
      this.overlayContainer.nativeElement.appendChild(this.overlay.nativeElement);
    } else {
      this.l.log('Failed to hide menu', 'LongPressMenu');
    }
  }

  public showContextMenu(event: MouseEvent) {
    if (this.refresher.isRefreshingSilently()) {
      this.l.log('Dirba refresheris. Nieko nedarom', 'LongPressMenu');
      return;
    }
    event.preventDefault();
    this.reenableRefresher = this.refresher.refresherIsEnabled;
    this.refresher.disableRefresher();
    this.showMenu();
  }

  public startLongPress() {
    if (this.refresher.isRefreshingSilently()) {
      this.l.log('Dirba refresheris. Nieko nedarom', 'LongPressMenu');
      return;
    }
    this.reenableRefresher = this.refresher.refresherIsEnabled;
    this.refresher.disableRefresher();
    this.longPressStarted = true;
    const that = this;
    this.longPressTimer = setTimeout(() => {
      if (that.longPressStarted) {
        that.showMenu();
      }
      that.longPressTimer = null;
    }, 500);
  }

  public endLongPress() {
    if (this.longPressStarted && this.longPressTimer !== null) {
      if (!this.longPressComfirmed) {
        if (this.reenableRefresher) {
          this.refresher.enableRefresher();
        }
      }
      clearTimeout(this.longPressTimer);
      this.longPressTimer = null;
    }
  }

  public leaveComponent() {
    if (this.longPressStarted) {
      this.longPressStarted = false;
      if (this.longPressTimer) {
        if (this.reenableRefresher) {
          this.refresher.enableRefresher();
        }
        clearTimeout(this.longPressTimer);
        this.longPressTimer = null;
      }
    }
  }
}
