import { CommonModule } from '@angular/common';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { GlobalService } from 'src/app/services/global.service';
import { LoaderRoundComponent } from '../loader-round/loader-round.component';

@Component({
  selector: 'app-fragmenter',
  templateUrl: './fragmenter.component.html',
  styleUrls: ['./fragmenter.component.scss'],
  standalone: true,
  imports: [CommonModule, LoaderRoundComponent],
})
export class FragmenterComponent implements OnInit, OnDestroy {
  @Input() chunkFetcher = null;
  @Input() context = null;
  @Input() isEnabled = true;
  @Input() loaderBackground = '#F6F6F6';
  public fetchingChunk = false;
  private scrollSubscriber = null;
  private noMoreChunks = false;

  constructor(g: GlobalService) {
    const that = this;
    this.scrollSubscriber = g.onScrolledToBottom.subscribe(() => {
      that.performCheckFetch();
    });
  }

  ngOnInit(): void {}

  ngOnDestroy(): void {
    if (this.scrollSubscriber !== null) {
      this.scrollSubscriber.unsubscribe();
      this.scrollSubscriber = null;
    }
  }

  private performCheckFetch() {
    if (!this.isEnabled) {
      return;
    }
    if (this.fetchingChunk) {
      return;
    }
    if (this.noMoreChunks) {
      return;
    }
    this.fetchingChunk = true;
    const that = this;
    this.chunkFetcher(this.context, () => {
      that.fetchingChunk = false;
    });
  }
}
