import http from '../http';
import { BasicSuccessResponse, ErrorResponse, objectToParams } from './common';

export type EventDescriptionData = {
    id: number;
    qualifier: 'E' | 'R' | '-';
    event_code: string;
    default_name: string;
    reaction_id: number;
    active: boolean;
    company_id: number;
    area_event: boolean;
    created_at: string;
    updated_at: string;
};

type SetEventDescriptionRequest = {
    qualifier: 'E' | 'R' | '-';
    code: string;
    name: string;
    reaction: number;
    active: boolean;
    area_event: boolean;
    id?: number;
};
type DeleteEventDescriptionRequest = { id: number };
type DeleteEventDescriptionResponse = ErrorResponse | BasicSuccessResponse;
type GetEventDescriptionsRequest = { companyId?: number; forList?: boolean };
type SetEventDescriptionResponse = ErrorResponse | (BasicSuccessResponse & { newId: number });
type GetSingleDescriptionResponse = ErrorResponse | (BasicSuccessResponse & { item: EventDescriptionData });
type GetEventDescriptionsResponse = ErrorResponse
                                    | (BasicSuccessResponse & { list: EventDescriptionData[] })
                                    | (BasicSuccessResponse & { list: { id: number; default_name: string }[] });

const setEventDescription = (req: SetEventDescriptionRequest) => http.put<SetEventDescriptionResponse, SetEventDescriptionRequest>('/v3/api/event-description', req);
const getSingleEventDescription = (req: { id: number }) => http.get<GetSingleDescriptionResponse, URLSearchParams>('/v3/api/event-description', objectToParams(req));
const getEventDescriptions = (req: GetEventDescriptionsRequest) => http.get<GetEventDescriptionsResponse, URLSearchParams>('/v3/api/event-descriptions', objectToParams(req));
const deleteEventDescription = (req: DeleteEventDescriptionRequest) => http.delete<DeleteEventDescriptionResponse, DeleteEventDescriptionRequest>('/v3/api/event-description', req);

export default {
    get: getEventDescriptions,
    save: setEventDescription,
    delete: deleteEventDescription,
    getSingle: getSingleEventDescription,
};
