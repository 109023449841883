import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { LoginService } from 'src/app/login/login.service';
import { AppSettings } from 'src/app-settings';
import { LanguageAware } from 'src/app/general/language-aware';
import { companyRootRoute } from 'src/app/services/global.service';
import { Subscription } from 'rxjs';
import { LocatorService } from 'src/app/services/locator.service';
import { NewSystemService } from 'src/app/services/new-system.service';
import { PermissionRole } from 'src/api/v3/permission';
import { CommonModule } from '@angular/common';
import { CompanyHeaderSettingsComponent } from '../company-header-settings/company-header-settings.component';
import { ButtonComponent } from '../button/button.component';
import { RouterLink } from '@angular/router';
import { DotComponent } from 'src/app/general/dot/dot.component';
import { CompanyHeaderButtonComponent } from '../company-header-button/company-header-button.component';
import { SettingsListComponent } from '../settings-list/settings-list.component';
import { RegionSwitcherComponent } from '../region-switcher/region-switcher.component';
import { PendingSystemService } from 'src/app/api/system/pending-system.service';

@Component({
  selector: 'app-company-header',
  templateUrl: './company-header.component.html',
  styleUrls: ['./company-header.component.scss'],
  standalone: true,
  imports: [CommonModule, CompanyHeaderSettingsComponent, ButtonComponent, RouterLink, DotComponent, CompanyHeaderButtonComponent, SettingsListComponent, RegionSwitcherComponent],
})
export class CompanyHeaderComponent extends LanguageAware implements OnInit, OnDestroy {
  public readonly AppSettings = AppSettings;
  public ProtegusLogo = '';
  public CompanyHeaderButton = '';
  public CompanyDashButton = '';
  public CompanySystemsButton = '';
  public CompanyEventsButton = '';
  public CompanyUsersButton = '';
  public CompanyTagsButton = '';
  public CompanySettingsButton = '';
  public UserIcon = '';
  public showSettings = false;
  public showProfileSettings = false;
  public rootRoute = companyRootRoute;
  public newVersionAvailable = false;
  public readonly roles = PermissionRole;
  public hasAnySettingsAvailable = false;
  private userDataChangeListener = null;
  private newVersionSubscriber: Subscription;

  constructor(
    cdRef: ChangeDetectorRef,
    public login: LoginService,
    public pendingSystemService: PendingSystemService,
  ) {
    super(cdRef);
    this.reinitVariables();
    this.userDataChangeListener = this.us.onUserDataChanged.subscribe(() => { this.reinitVariables(); });
    this.newVersionSubscriber = this.g.onNewVersionAvailable.subscribe(() => {
      this.newVersionAvailable = AppSettings.showAppVersion && this.g.isNewVersionAvailable();
    });
  }

  ngOnInit(): void {}

  ngOnDestroy() {
    this.userDataChangeListener?.unsubscribe();
    this.newVersionSubscriber?.unsubscribe();
  }

  toggleSettings(): void {
    this.showSettings = !this.showSettings;
    this.showProfileSettings = false;
  }

  toggleProfileSettings(): void {
    this.showProfileSettings = !this.showProfileSettings;
  }

  onShowSettingsChange(value: boolean): void {
    this.showProfileSettings = value;
  }

  private reinitVariables() {
    const userPermissions = this.us.currentUser?.permissions?.permissions;
    if ( !userPermissions ) { return; }
    this.hasAnySettingsAvailable = [
      userPermissions.company_settings.view && this.us.currentUser?.company_id !== 0,
      userPermissions.ipcom_settings.view,
      userPermissions.reactions.view,
      userPermissions.global_settings.view,
      userPermissions.panic_settings.view,
      userPermissions.regions.view,
      userPermissions.transfer_device.execute
    ].includes(true);
  }

  public onAddNewSystemClick() {
    const ns = LocatorService.injector.get(NewSystemService);
    ns.setDefaults();
  }
}
