import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ReactionService } from 'src/app/api/system/reaction.service';
import { TEventData } from 'src/app/models/event-data';
import { DateService } from 'src/app/services/date.service';
import { LanguageAware } from '../../language-aware';
import { CommonModule } from '@angular/common';
import { InlineSVGModule } from 'ng-inline-svg-w';
import { Subscription } from 'rxjs';
import { WebSocketService } from 'src/app/services/websocket.service';

@Component({
  selector: 'app-home-events',
  templateUrl: './home-events.component.html',
  styleUrls: ['./home-events.component.scss'],
  standalone: true,
  imports: [CommonModule, InlineSVGModule],
})
export class HomeEventsComponent extends LanguageAware implements OnInit, OnDestroy {
  public visibleEvents: TEventData[] = [];
  private systemChangeListener = null;
  private eventListener: Subscription;

  constructor(
    cdRef: ChangeDetectorRef,
    public dt: DateService,
    private router: Router,
    public reactions: ReactionService,
    private ar: ActivatedRoute,
    private ws: WebSocketService,
  ) {
    super(cdRef, { noVisualModifications: true, shouldScrollTop: false });

    this.buildEvents();
    const that = this;
    this.systemChangeListener = this.systems.onActiveSystemChange.subscribe(() => {
      that.buildEvents();
    });
    this.eventListener = this.ws.onEventReceived.subscribe(() => this.buildEvents());
  }

  ngOnInit(): void {}

  ngOnDestroy() {
    if (this.systemChangeListener !== null) {
      this.systemChangeListener.unsubscribe();
      this.systemChangeListener = null;
    }
    this.eventListener?.unsubscribe();
  }

  private buildEvents() {
    if ( !this.systems.activeSystem ) { return; }
    let maxEventsToShow = 5;
    if (this.systems.activeSystem.hwType === 'G17F' || this.systems.activeSystem.hwType === 'FC') {
      maxEventsToShow = 10;
    }
    this.visibleEvents = [];
    for (const iEvent of this.systems.activeSystem.events.sort((a, b) => b.id - a.id)) {
      if (this.visibleEvents.length >= maxEventsToShow) {
        break;
      }
      if (this.systems.activeSystem.eventConfiguration.length === 0) {
        this.visibleEvents.push(iEvent);
      } else if (this.systems.activeSystem.eventConfiguration.indexOf(iEvent.reaction) !== -1) {
        this.visibleEvents.push(iEvent);
      }
    }
  }

  public openEvents(event: TEventData, $event: MouseEvent) {
    let foundDiv = $event.target as HTMLElement;
    while (foundDiv && (foundDiv as HTMLElement).tagName.toLowerCase() !== 'div') {
      foundDiv = (foundDiv as HTMLElement).parentElement;
    }
    if ( event.latitude && foundDiv && (foundDiv.classList.contains('hes-event-icon') || foundDiv.classList.contains('hes-event-coord')) ) {
      try {
        if ( this.platform.isAndroid() ) {
          this.platform.androidHandler().openMapProvider(event.latitude, event.longitude, event.userName);
        } else if ( this.platform.isApple() ) {
          this.platform.appleHandler().openMapProvider.postMessage({ lat: event.latitude, lon: event.longitude, user: event.userName});
        } else {
          const mapUrl = `https://www.google.com/maps?q=${event.latitude},${event.longitude}`;
          window.open(mapUrl, '_blank');
        }
      } catch (e) { }
      return;
    }
    this.router.navigate(['events'], { relativeTo: this.ar });
  }
}
