import http from '../http';
import { CommonResponse } from '../requests/common';
import { ConfigType } from '../requests/config';
import { BasicSuccessResponse, objectToParams } from './common';

const getTranslations = (params: LanguageRequestParams) => http.get<LanguageRequestResponse, URLSearchParams>('/v3/api/translations', objectToParams(params));
const getSingleTranslation = (params: { key: string }) => http.get<SingleTranslationResponse, URLSearchParams>('/v3/api/get-translations', objectToParams(params));

export type LanguageRequestParams = {
  language: string;
  version: string;
  config: ConfigType | 'gv17';
};

export type LanguageRequestResponse = CommonResponse & {
  translations?: string;
  version?: string;
  code?: string;
};
type SingleTranslationResponse = BasicSuccessResponse & {
  translation: string;
};

export default {
  getTranslations,
  getSingleTranslation,
};
