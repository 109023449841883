import { EventEmitter, Injectable, OnDestroy } from '@angular/core';
import { Subscription, Subject } from 'rxjs';
import requests from 'src/api/v3/requests';
import { GatewayIpcom, IPComReceiverForTemplate, IPComSettings } from 'src/api/v3/settings';
import { DataTableGetter } from '../company/components/data-table/data-table.component';
import { IPCom } from '../models/ipcom';
import { LocatorService } from '../services/locator.service';
import { AuthService } from './auth.service';
import { LoggerService } from './logger.service';

@Injectable({
  providedIn: 'root'
})
export class IpcomService implements OnDestroy {
  private readonly tag = 'SettingsService';
  public readonly ipcoms = new Map<number, IPCom>();
  public readonly ipcomSettings = new Map<number, IPComSettings>();
  public gateways: GatewayIpcom[] = [];
  public ipcomSettingsByCompany: IPComSettings;

  private cleanUpSubscriber: Subscription;
  private get auth() { return LocatorService.injector.get(AuthService); }
  private get logger() { return LocatorService.injector.get(LoggerService); }

  public ipcomReceiversForTemplate: IPComReceiverForTemplate[] = [];
  private ipcomInEditIdChange = new Subject<number>();
  public onIpcomInEditIdChange = this.ipcomInEditIdChange.asObservable();
  public receiverInEditId = -1;
  public receiverDataChanged = new EventEmitter<void>();
  public beforeDeletingReceiver = new EventEmitter<number>();

  constructor() {
    this.logger.log('+', this.tag);
    this.cleanUpSubscriber = this.auth.onAccountOrRegionChnage.subscribe(() => {
      this.clear();
    });
  }

  ngOnDestroy() {
    this.cleanUpSubscriber?.unsubscribe();
  }

  public ingestIpcom(ipcom: IPCom) {
    if ( this.ipcoms.has(ipcom.id) ) { return; }
    this.logger.log('Ingesting ipcom ', this.tag, ipcom);
    this.ipcoms.set(ipcom.id, ipcom);
  }

  public ingestIpcomSettings(ipcomId: number, settings: IPComSettings) {
    if ( this.ipcomSettings.has(ipcomId) ) { return; }
    this.logger.log('Ingesting settings for ' + ipcomId, this.tag, settings);
    this.ipcomSettings.set(ipcomId, settings);
  }

  public removeIpcom(ipcomId: number) {
    this.ipcomSettings.delete(ipcomId);
    this.ipcoms.delete(ipcomId);
  }

  public clear() {
    this.ipcoms.clear();
    this.ipcomSettings.clear();
    this.ipcomReceiversForTemplate = [];
    this.gateways = [];
    this.ipcomSettingsByCompany = undefined;
  }

  public async loadSettingsForIpcom(id: number): Promise<IPComSettings> | undefined {
    if ( this.ipcomSettings.has(id) ) {
      return this.ipcomSettings.get(id);
    }
    if ( id === 0 ) { return; }
    try {
      const result = (await requests.settings.ipcom.read({id}).toPromise()).data;
      this.ingestIpcomSettings(id, result);
      return result;
    } catch (e) {
      this.logger.log('Error', this.tag, e);
    }
  }

  public getIpcomsGetter(): DataTableGetter<IPCom> {
    const ipcService = this;
    return async (current, columns, more) => [...ipcService.ipcoms.values()];
  }

  public async loadReceiversForTemplates() {
    if ( this.ipcomReceiversForTemplate.length > 0 ) { return; }
    const result = await requests.settings.ipcom.getReceivers().toPromise();
    if ( result.success ) {
      this.ipcomReceiversForTemplate = result.receivers;
    }
  }

  public changeIpcomInEditId(value: number) {
    this.ipcomInEditIdChange.next(value);
  }
}
