import { Injectable } from '@angular/core';
import { PgmData } from '../models/pgm';
import { TAreaData } from '../models/area-data';
import { TZoneData } from '../models/zone-data';
import { SystemsService } from './systems.service';
import { TDeviceUser } from '../models/device-user';
import { TSensorData } from '../models/sensor-data';
import { ApiRequestService } from './api-request.service';
import { ToasterService } from './toaster.service';
import { ToastType } from '../models/toaster-type';
import { TSystemData } from '../models/system-data';
import { LanguageService } from './language.service';
import { UserService } from '../api/user.service';
import { CurrentUserData, ErrorResponse, WithWarning } from 'src/api/v3/common';
import { TEditableComponent } from '../models/editable-component';
import { LocatorService } from './locator.service';
import { LoggerService } from '../api/logger.service';
import requests from 'src/api/v3/requests';
import { SystemService } from '../api/system/system.service';
import { PermissionRole } from 'src/api/v3/permission';

@Injectable({
  providedIn: 'root',
})
export class EditSystemService {
  private currentComponentIds = {};
  private currentComponentObjects = {};
  private componentsBeforeModification = {};

  private currentSensor = 0;
  private currentSensorObj: TSensorData = null;
  private sensorDataBeforeModification: TSensorData = null;

  public currentUserObj: CurrentUserData = null;
  public currentDeviceUserObj: TDeviceUser = null;

  public systemInfoBeforeModification = null;
  /** Vartotojas pasirenka iš egzistuojančių asmenų kam perduoti sistemą. To asmes email talpinamas čia ir panaudojamas Transfer lange. */
  public emailToTransferTo = '';
  private get logger() { return LocatorService.injector.get(LoggerService); }
  private tag = 'EditService';

  constructor(private ss: SystemsService, private api: ApiRequestService, private toaster: ToasterService, private us: UserService, private lang: LanguageService) {}

  public setDefaults() {
    this.currentComponentIds = {};
    this.currentComponentObjects = {};
    this.componentsBeforeModification = {};
    this.currentSensor = 0;
    this.currentUserObj = null;
    this.currentSensorObj = null;
    this.currentDeviceUserObj = null;
    this.sensorDataBeforeModification = null;
  }

  public getPgm(pgmNumber: number): PgmData {
    let restult: PgmData = null;
    for (const i of this.ss.activeSystem.pgms) {
      if (i.queue_no === pgmNumber) {
        restult = i;
        break;
      }
    }
    return restult;
  }

  public getArea(areaNumber: number) {
    let result: TAreaData = null;
    for (const i of this.ss.activeSystem.areas) {
      if (i.queue_no === areaNumber) {
        result = i;
        break;
      }
    }
    return result;
  }

  public getZone(zoneNumber: number) {
    let result: TZoneData = null;
    for (const iZone of this.ss.activeSystem.zones) {
      if (iZone.queue_no === zoneNumber) {
        result = iZone;
        break;
      }
    }

    return result;
  }

  public setCurrentSensor(value: number, sensor?: TSensorData) {
    this.currentSensor = value;
    this.currentSensorObj = sensor === undefined ? null : sensor;
  }

  public getCurrentSensor(): number {
    return this.currentSensor;
  }

  public getCurrentSensorObj(): TSensorData {
    return this.currentSensorObj;
  }

  public setSensorBeforeModification(sensor: TSensorData) {
    if (sensor === null || sensor === undefined) {
      this.sensorDataBeforeModification = null;
      return;
    }
    this.sensorDataBeforeModification = {
      active: sensor.active,
      alarmHigh: sensor.alarmHigh,
      alarmLow: sensor.alarmLow,
      enabled: sensor.enabled,
      name: sensor.name,
      queue_no: sensor.queue_no,
      temperature: sensor.temperature,
      type: sensor.type,
      notificationAboutHigh: sensor.notificationAboutHigh,
      notificationAboutLow: sensor.notificationAboutLow,
      highValue: sensor.highValue,
      lowValue: sensor.lowValue,
    };
  }

  public getSensorBeforeModification(): TSensorData {
    return this.sensorDataBeforeModification;
  }

  /**
   * Pradedama komponento redakcija
   *
   * @param componentType TEditableComponent
   * @param id Komponento, kuris bus redaguojamas ID
   * @param obj Komponento, kuris bus redaguojamas objektas
   */
  public beginComponentEdit(componentType: TEditableComponent, id: number | string, obj?: any) {
    this.logger.log('Begin [' + componentType + '], ID [' + id + ']', this.tag);
    this.currentComponentIds[componentType] = id;
    this.currentComponentObjects[componentType] = obj ?? null;
    this.componentsBeforeModification[componentType] = JSON.stringify(obj ?? null);
  }

  /**
   * Gražina redaguojamo komponento ID
   *
   * @param componentType TEditableComponent
   */
  public getId(componentType: string): number | string | -1 {
    return this.currentComponentIds[componentType] ?? -1;
  }

  /**
   * Gražina redaguojamą komponentą
   *
   * @param componentType TEditableComponent
   * @returns object | null
   */
  public getEditableComponent(componentType: TEditableComponent): any | null {
    return this.currentComponentObjects[componentType] ?? null;
  }

  /**
   * Gražina redaguojamo komponento objektą prieš redagavimą.
   *
   * @param componentType TEditableComponent
   * @returns object | null
   */
  public getComponentBeforeModification(componentType: TEditableComponent): any {
    return JSON.parse(this.componentsBeforeModification[componentType] ?? 'null');
  }

  /**
   * Pašalina redaguojamą objektą.
   *
   * @param componentType TEditableComponent
   */
  public endComponentEdit(componentType: TEditableComponent) {
    this.logger.log('End [' + componentType + ']', this.tag);
    this.currentComponentIds[componentType] = -1;
    this.currentComponentObjects[componentType] = null;
    this.componentsBeforeModification[componentType] = null;
  }

  public deleteSystem(systemId?: number) {
    const systemToHold = systemId === undefined ? this.ss.activeSystem : this.ss.getSystem(systemId); // Pasilaikom jeigu teks grazinti.
    if (systemToHold === undefined || systemToHold === null) {
      return;
    }
    this.ss.removeSystem(systemToHold.id);

    requests.system.delete({ systems: [systemToHold.id] }).subscribe(
      (result) => {
        if (!result.success) {
          this.toaster.postError((result as ErrorResponse).error);
          this.revertDeletion(systemToHold);
        } else if (this.us.currentUser.permissions?.role === PermissionRole.Company) {
          if (this.ss.systems.length === 0) {
            for (const iLastSeen of this.us.currentUser.lastSeenReactions ?? []) {
              iLastSeen.unseen_events = 0;
            }
            this.us.change();
          }
        } else {
          const systemService = LocatorService.injector.get(SystemService);
          systemService.removeSystemLocally(systemToHold.id);
        }

        if (result.success && (result as WithWarning).warning !== undefined) {
          this.toaster.post((result as WithWarning).warning, ToastType.Warning, true, 15000);
        }
      },
      (error) => {
        this.revertDeletion(systemToHold);
      }
    );
  }

  private revertDeletion(system: TSystemData) {
    this.ss.addSystem(system);
  }

  public getAvailableZoneNumber(tryToUse: number, min: number, userId: number): number {
    if (tryToUse < min) {
      tryToUse = min;
    }
    // Toks jau užimtas, ieškom kito
    let max = this.ss.activeSystem.maxDeviceUsers;
    if (max === 0) {
      max = 9999;
    }
    if (tryToUse > max) {
      return 0;
    }
    const found = this.ss.activeSystem.device_users.find((u) => u.zone_number === tryToUse && u.id !== userId);
    if (found === undefined) {
      return tryToUse;
    }

    if (this.ss.activeSystemHelper.supports.gvTypeUsers()) {
      max += 3; // nes yra tarpas tarp adminu ir paprastu vartotoju
    }
    for (let i = min; i <= max; i++) {
      const numberFound = this.ss.activeSystem.device_users.find((u1) => u1.zone_number === i && u1.id !== userId);
      if (numberFound === undefined) {
        return i;
      }
    }

    return 0; // neturėtų taip atsitikti
  }

  public hasChanged(componentType: TEditableComponent): boolean {
    const before = this.componentsBeforeModification[componentType] ?? null;
    const now = this.currentComponentObjects[componentType] ? JSON.stringify(this.currentComponentObjects[componentType]) : null;
    return before !== now;
  }
}
