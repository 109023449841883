import { Injectable } from '@angular/core';
import { Colors } from 'src/assets/css/instance-colors';
import { THomeTheme } from '../models/home-theme';

@Injectable({
  providedIn: 'root',
})
export class ThemesService {
  constructor() {
    document.documentElement.style.setProperty('--color-disarmed', Colors.areaStates.disarmed);
    document.documentElement.style.setProperty('--color-armed', Colors.areaStates.armed);
    document.documentElement.style.setProperty('--color-arming-in-progress', Colors.areaStates.armingInProgress);
    document.documentElement.style.setProperty('--color-alarmed', Colors.areaStates.alarmed);
    document.documentElement.style.setProperty('--color-disarming-in-progress', Colors.areaStates.disarmingInProgress);
  }

  public resetToDefault() {
    document.documentElement.style.setProperty('--home-background-start', Colors.primaryColor);
    document.documentElement.style.setProperty('--home-background-end', Colors.secondaryColor);
  }

  public setHomeTheme(theme: THomeTheme) {
    if (theme.fullBackground !== '') {
      document.documentElement.style.background = theme.fullBackground;
    } else {
      document.documentElement.style.setProperty('--home-background-start', theme.startColor);
      document.documentElement.style.setProperty('--home-background-end', theme.endColor);
    }
  }
}
