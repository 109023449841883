import { Injectable, OnDestroy } from '@angular/core';
import { DataTableGetter } from '../company/components/data-table/data-table.component';
import requests from 'src/api/v3/requests';
import { DeviceSetupTemplate } from 'src/api/v3/device-setup-templates';
import { Subscription, Subject } from 'rxjs';
import { LocatorService } from './locator.service';
import { AuthService } from '../api/auth.service';
import { LoggerService } from '../api/logger.service';
import { DropdownItem } from '../ui/dropdown/dropdown.component';
import { LanguageService } from './language.service';
import { UserService } from '../api/user.service';
import { TagService } from '../api/tag.service';

@Injectable({
  providedIn: 'root'
})
export class DeviceSetupTemplateService implements OnDestroy {
  private cleanUpSubscriber: Subscription;
  private get auth() { return LocatorService.injector.get(AuthService); }
  private get logger() { return LocatorService.injector.get(LoggerService); }
  private get lang() { return LocatorService.injector.get(LanguageService); }

  public listBeingLoaded = false;
  public listStorage: DropdownItem<number>[] = [];
  public deviceSetupTemplates: DeviceSetupTemplate[] = [];
  private deviceTemplateInEditIdChange = new Subject<number>();
  public onDeviceTemplateInEditIdChange = this.deviceTemplateInEditIdChange.asObservable();

  constructor(
    private tagService: TagService,
  ) {
    this.cleanUpSubscriber = this.auth.onAccountOrRegionChnage.subscribe(() => {
      this.clear();
    });
  }

  ngOnDestroy() {
    this.cleanUpSubscriber?.unsubscribe();
  }

  private clear() {
    this.deviceSetupTemplates = [];
    this.listStorage = [];
  }

  public async loadDeviceSetupTemplates() {
    if ( this.deviceSetupTemplates.length > 0 ) { return; }
    this.deviceSetupTemplates = [];
    const result = await requests.deviceSetupTemplates.getTemplates({}).toPromise();
    if ( result.success ) {
      this.deviceSetupTemplates = result.list.map(t => ({...t, ip_com: t.ip_com ?? null, backup_ip_com: t.backup_ip_com ?? null}));
      result.list.forEach(t => t.tags?.forEach(tt => this.tagService.ingestTag(tt)) );
    }
  }

  public getDeviceSetupTemplateGetter(): DataTableGetter<DeviceSetupTemplate> {
    return async () => this.deviceSetupTemplates;
  };

  public async loadDropDownList(): Promise<void> {
    if ( this.listStorage.length > 0 ) { return; }
    if ( this.listBeingLoaded ) { return; }
    this.listBeingLoaded = true;
    try {
      const result = await requests.deviceSetupTemplates.getTemplates({ forList: true }).toPromise();
      const userService = LocatorService.injector.get(UserService);
      this.listStorage.push({
        value: 0,
        label: this.lang.get('setup.device.selectTemplate'),
      });
      if ( result.success ) {
        result.list.forEach(r => this.listStorage.push({
          value: r.id,
          label: r.name,
          extraParam: { company_id: r.company_id },
          subLabels: userService.currentUser.company_id === 0 ? (r.companyEmail ? [r.companyEmail] : undefined) : undefined,
        }));
      }
    } finally {
      this.listBeingLoaded = false;
    }
  }

  public changeDeviceTemplateInEditId(value: number) {
    this.deviceTemplateInEditIdChange.next(value);
  }
}
