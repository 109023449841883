import { Injectable, OnDestroy } from '@angular/core';
import { Subscription, Subject } from 'rxjs';
import requests from 'src/api/v3/requests';
import { DataTableGetter } from '../company/components/data-table/data-table.component';
import { DropdownItem } from '../ui/dropdown/dropdown.component';
import { AuthService } from './auth.service';
import { LoggerService } from './logger.service';
import { TagService } from './tag.service';
import { Permission, PermissionList, PermissionRole, PermissionRule } from 'src/api/v3/permission';
import { FilterCriteria } from 'src/api/v3/common';

@Injectable({
  providedIn: 'root'
})
export class PermissionService implements OnDestroy {
  // Naudojamas tik taisyklių rodymui lentelėje, kai neatfiltruota.
  public readonly permissionsRules = new Map<number, PermissionRule>();
  // Naudojamas įvairioms random taisyklėms, kurios reikalingos kaip pagalbinės užkrautiems kitiems duomenims.
  public sessionStorage = new Map<number, PermissionRule>();
  public listStorage: DropdownItem<number>[] = [];
  public listBeingLoaded = false;
  public readonly allGeneralPermissions: (keyof PermissionList)[] = [
    'company_settings',
    'ipcom_settings',
    'reactions',
    'roles',
    'systems',
    'events',
    'tags',
    'users',
    'support_tickets',
    'global_settings',
    'panic_settings',
    'regions',
    'impersonation',
    'transfer_company',
    'transfer_device',
    'system',
    'dev_setup_templates',
    'unassigned_devices',
    'pending_systems',
    'company_payments',
    'monitoring_stations',
  ];
  public readonly allSystemPermissions: (keyof PermissionList)[] = [
    'sys_advanced_settings',
    'sys_information',
    'sys_areas',
    'sys_zones',
    'sys_outputs',
    'sys_sensors',
    'sys_thermostats',
    'sys_users',
    'sys_receiver',
    'sys_synchronisation',
    'sys_transfer',
    'sys_device_transfer',
    'sys_reset_sensors',
    'sys_assistance',
    'sys_personal_permissions',
    'sys_transfer_to_company',
    'sys_notifications',
    'sys_cameras',
    'sys_events',
    'system_notes',
  ];
  public readonly allAdvancedPermissions: (keyof PermissionList)[] = [];

  /**
   * Sąrašas, atitinkantis PermissionType.php faile esančių konstantų reikšmes.
   */
  public static readonly indexedPermissionKeys: (keyof PermissionList)[] = [
    /*  0 */ 'company_settings',
    /*  1 */ 'ipcom_settings',
    /*  2 */ 'reactions',
    /*  3 */ 'roles',
    /*  4 */ 'systems',
    /*  5 */ 'events',
    /*  6 */ 'tags',
    /*  7 */ 'users',
    /*  8 */ 'support_tickets',
    /*  9 */ 'global_settings',
    /* 10 */ 'panic_settings',
    /* 11 */ 'regions',
    /* 12 */ 'impersonation',
    /* 13 */ 'transfer_company',
    /* 14 */ 'transfer_device',
    /* 15 */ 'sys_advanced_settings',
    /* 16 */ 'sys_information',
    /* 17 */ 'sys_areas',
    /* 18 */ 'sys_zones',
    /* 19 */ 'sys_outputs',
    /* 20 */ 'sys_sensors',
    /* 21 */ 'sys_thermostats',
    /* 22 */ 'sys_users',
    /* 23 */ 'sys_receiver',
    /* 24 */ 'sys_synchronisation',
    /* 25 */ 'sys_transfer',
    /* 26 */ 'sys_device_transfer',
    /* 27 */ 'sys_reset_sensors',
    /* 28 */ 'sys_assistance',
    /* 29 */ 'sys_personal_permissions',
    /* 30 */ 'sys_transfer_to_company',
    /* 31 */ 'sys_notifications',
    /* 32 */ 'sys_cameras',
    /* 33 */ 'sys_events',
    /* 34 */ 'system',
    /* 35 */ 'dev_setup_templates',
    /* 36 */ 'unassigned_devices',
    /* 37 */ 'pending_systems',
    /* 38 */ 'company_payments',
    /* 39 */ 'system_notes',
    /* 40 */ 'monitoring_stations',
  ];

  /**
   * Sąrašas atitinka AccessLevel.php faile esantį konstantų sąrašą, tik čia yra indeksai nurodantys bit numerį
   */
  public static readonly indexedAccessLevels: (keyof Permission)[] = [
    /* bit0 */ 'view',
    /* bit1 */ 'create',
    /* bit2 */ 'edit',
    /* bit3 */ 'delete',
    /* bit4 */ 'execute',
  ];
  public static readonly defaultGeneralUserRule: PermissionRule = {
    company_id: 0,
    default: true,
    description: '',
    id: 4,
    name: '',
    parent_id: null,
    companyName: '',
    permissions: {
      company_settings: { create: false, view: false, edit: false, delete: false, execute: false },
      ipcom_settings: { create: false, view: false, edit: false, delete: false, execute: false },
      reactions: { create: false, view: false, edit: false, delete: false, execute: false },
      roles: { create: false, view: false, edit: false, delete: false, execute: false },
      systems: { create: false, view: false, edit: false, delete: false, execute: false },
      events: { create: false, view: false, edit: false, delete: false, execute: false },
      tags: { create: false, view: false, edit: false, delete: false, execute: false },
      users: { create: false, view: false, edit: false, delete: false, execute: false },
      support_tickets: { create: false, view: false, edit: false, delete: false, execute: false },
      global_settings: { create: false, view: false, edit: false, delete: false, execute: false },
      panic_settings: { create: false, view: false, edit: false, delete: false, execute: false },
      regions: { create: false, view: false, edit: false, delete: false, execute: false },
      impersonation: { create: false, view: false, edit: false, delete: false, execute: false },
      transfer_company: { create: false, view: false, edit: false, delete: false, execute: false },
      transfer_device: { create: false, view: false, edit: false, delete: false, execute: false },
      sys_advanced_settings: { create: false, view: false, edit: false, delete: false, execute: false },
      sys_information: { create: false, view: false, edit: false, delete: false, execute: false },
      sys_areas: { create: false, view: false, edit: false, delete: false, execute: false },
      sys_zones: { create: false, view: false, edit: false, delete: false, execute: false },
      sys_outputs: { create: false, view: false, edit: false, delete: false, execute: false },
      sys_sensors: { create: false, view: false, edit: false, delete: false, execute: false },
      sys_thermostats: { create: false, view: false, edit: false, delete: false, execute: false },
      sys_users: { create: false, view: false, edit: false, delete: false, execute: false },
      sys_receiver: { create: false, view: false, edit: false, delete: false, execute: false },
      sys_synchronisation: { create: false, view: false, edit: false, delete: false, execute: false },
      sys_transfer: { create: false, view: false, edit: false, delete: false, execute: false },
      sys_device_transfer: { create: false, view: false, edit: false, delete: false, execute: false },
      sys_reset_sensors: { create: false, view: false, edit: false, delete: false, execute: false },
      sys_assistance: { create: false, view: false, edit: false, delete: false, execute: false },
      sys_personal_permissions: { create: false, view: false, edit: false, delete: false, execute: false },
      sys_transfer_to_company: { create: false, view: false, edit: false, delete: false, execute: false },
      sys_notifications: { create: false, view: false, edit: false, delete: false, execute: false },
      sys_cameras: { create: false, view: false, edit: false, delete: false, execute: false },
      sys_events: { create: false, view: false, edit: false, delete: false, execute: false },
      system: { create: true, view: false, edit: false, delete: false, execute: false },
      dev_setup_templates: { create: false, view: false, edit: false, delete: false, execute: false },
      unassigned_devices: { create: false, view: false, edit: false, delete: false, execute: false },
      pending_systems: { create: false, view: false, edit: false, delete: false, execute: false },
      company_payments: { create: false, view: false, edit: false, delete: false, execute: false },
      system_notes: { create: false, view: false, edit: false, delete: false, execute: false },
      monitoring_stations: { create: false, view: false, edit: false, delete: false, execute: false },
    },
    role: PermissionRole.GeneralUser,
    tags: [],
  };
  // !!! Toks pats sąrašas yra PHP dalyje PermissionType.php
  public static readonly maxSuperAdminPermissions: PermissionList = {
    company_settings: { create: true, view: true, edit: true, delete: true, execute: false },
    ipcom_settings: { create: true, view: true, edit: true, delete: true, execute: false },
    reactions: { create: true, view: true, edit: true, delete: true, execute: false },
    roles: { create: true, view: true, edit: true, delete: true, execute: false },
    systems: { create: true, view: true, edit: true, delete: true, execute: false },
    events: { create: false, view: true, edit: false, delete: false, execute: false },
    tags: { create: true, view: true, edit: true, delete: true, execute: false },
    users: { create: true, view: true, edit: true, delete: true, execute: false },
    support_tickets: { create: false, view: true, edit: false, delete: false, execute: true },
    global_settings: { create: false, view: true, edit: true, delete: false, execute: false },
    panic_settings: { create: false, view: true, edit: true, delete: false, execute: true },
    regions: { create: true, view: true, edit: true, delete: true, execute: false },
    impersonation: { create: false, view: false, edit: false, delete: false, execute: true },
    transfer_company: { create: false, view: false, edit: false, delete: false, execute: true },
    transfer_device: { create: false, view: false, edit: false, delete: false, execute: true },
    sys_advanced_settings: { create: false, view: true, edit: true, delete: false, execute: false },
    sys_information: { create: false, view: true, edit: true, delete: false, execute: false },
    sys_areas: { create: true, view: true, edit: true, delete: true, execute: true },
    sys_zones: { create: true, view: true, edit: true, delete: true, execute: true },
    sys_outputs: { create: true, view: true, edit: true, delete: true, execute: true },
    sys_sensors: { create: false, view: true, edit: true, delete: false, execute: false },
    sys_thermostats: { create: false, view: true, edit: true, delete: false, execute: true },
    sys_users: { create: true, view: true, edit: true, delete: true, execute: false },
    sys_receiver: { create: false, view: true, edit: false, delete: false, execute: false },
    sys_synchronisation: { create: false, view: false, edit: false, delete: false, execute: true },
    sys_transfer: { create: false, view: false, edit: false, delete: false, execute: true },
    sys_device_transfer: { create: false, view: false, edit: false, delete: false, execute: true },
    sys_reset_sensors: { create: false, view: false, edit: false, delete: false, execute: true },
    sys_assistance: { create: false, view: false, edit: false, delete: false, execute: true },
    sys_personal_permissions: { create: false, view: true, edit: true, delete: false, execute: false },
    sys_transfer_to_company: { create: false, view: false, edit: false, delete: false, execute: true },
    sys_notifications: { create: false, view: true, edit: true, delete: false, execute: false },
    sys_cameras: { create: true, view: true, edit: true, delete: true, execute: false },
    sys_events: { create: false, view: true, edit: false, delete: false, execute: false },
    system: { create: true, view: false, edit: false, delete: true, execute: false },
    dev_setup_templates: { create: true, view: true, edit: true, delete: true, execute: true },
    unassigned_devices: { create: true, view: true, edit: true, delete: true, execute: false },
    pending_systems: { create: false, view: true, edit: false, delete: false, execute: true },
    company_payments: { create: true, view: true, edit: true, delete: true, execute: false },
    system_notes: { create: true, view: true, edit: true, delete: true, execute: false },
    monitoring_stations: { create: true, view: true, edit: true, delete: true, execute: false },
  };
  // !!! Toks pats sąrašas yra PHP dalyje PermissionType.php
  public static readonly maxCompanyAdminPermissions: PermissionList = {
    company_settings: { create: true, view: true, edit: true, delete: true, execute: false },
    ipcom_settings: { create: true, view: true, edit: true, delete: true, execute: false },
    reactions: { create: true, view: true, edit: true, delete: true, execute: false },
    roles: { create: true, view: true, edit: true, delete: true, execute: false },
    systems: { create: true, view: true, edit: true, delete: true, execute: false },
    events: { create: false, view: true, edit: false, delete: false, execute: false },
    tags: { create: true, view: true, edit: true, delete: true, execute: false },
    users: { create: true, view: true, edit: true, delete: true, execute: false },
    support_tickets: { create: false, view: true, edit: false, delete: false, execute: true },
    global_settings: { create: false, view: false, edit: false, delete: false, execute: false },
    panic_settings: { create: false, view: true, edit: true, delete: false, execute: true },
    regions: { create: false, view: false, edit: false, delete: false, execute: false },
    impersonation: { create: false, view: false, edit: false, delete: false, execute: false },
    transfer_company: { create: false, view: false, edit: false, delete: false, execute: true },
    transfer_device: { create: false, view: false, edit: false, delete: false, execute: false },
    sys_advanced_settings: { create: false, view: true, edit: true, delete: false, execute: false },
    sys_information: { create: false, view: true, edit: true, delete: false, execute: false },
    sys_areas: { create: true, view: true, edit: true, delete: true, execute: true },
    sys_zones: { create: true, view: true, edit: true, delete: true, execute: true },
    sys_outputs: { create: true, view: true, edit: true, delete: true, execute: true },
    sys_sensors: { create: false, view: true, edit: true, delete: false, execute: false },
    sys_thermostats: { create: false, view: true, edit: true, delete: false, execute: true },
    sys_users: { create: true, view: true, edit: true, delete: true, execute: false },
    sys_receiver: { create: false, view: false, edit: false, delete: false, execute: false },
    sys_synchronisation: { create: false, view: false, edit: false, delete: false, execute: true },
    sys_transfer: { create: false, view: false, edit: false, delete: false, execute: true },
    sys_device_transfer: { create: false, view: false, edit: false, delete: false, execute: true },
    sys_reset_sensors: { create: false, view: false, edit: false, delete: false, execute: true },
    sys_assistance: { create: false, view: false, edit: false, delete: false, execute: true },
    sys_personal_permissions: { create: false, view: true, edit: true, delete: false, execute: false },
    sys_transfer_to_company: { create: false, view: false, edit: false, delete: false, execute: true },
    sys_notifications: { create: false, view: false, edit: false, delete: false, execute: false },
    sys_cameras: { create: true, view: true, edit: true, delete: true, execute: false },
    sys_events: { create: false, view: true, edit: false, delete: false, execute: false },
    system: { create: true, view: false, edit: false, delete: true, execute: false },
    dev_setup_templates: { create: true, view: true, edit: true, delete: true, execute: true },
    unassigned_devices: { create: true, view: true, edit: true, delete: true, execute: false },
    pending_systems: { create: false, view: true, edit: false, delete: false, execute: true },
    company_payments: { create: true, view: true, edit: true, delete: true, execute: true },
    system_notes: { create: true, view: true, edit: true, delete: true, execute: false },
    monitoring_stations: { create: true, view: true, edit: true, delete: true, execute: false },
  };
  // !!! Toks pats sąrašas yra PHP dalyje PermissionType.php
  public static readonly maxInstallerPermissions: PermissionList = {
    company_settings: { create: false, view: false, edit: false, delete: false, execute: false },
    ipcom_settings: { create: false, view: false, edit: false, delete: false, execute: false },
    reactions: { create: false, view: false, edit: false, delete: false, execute: false },
    roles: { create: true, view: true, edit: true, delete: true, execute: false },
    systems: { create: true, view: true, edit: true, delete: true, execute: false },
    events: { create: false, view: true, edit: false, delete: false, execute: false },
    tags: { create: true, view: true, edit: true, delete: true, execute: false },
    users: { create: true, view: true, edit: true, delete: true, execute: false },
    support_tickets: { create: false, view: false, edit: false, delete: false, execute: false },
    global_settings: { create: false, view: false, edit: false, delete: false, execute: false },
    panic_settings: { create: false, view: false, edit: false, delete: false, execute: true },
    regions: { create: false, view: false, edit: false, delete: false, execute: false },
    impersonation: { create: false, view: false, edit: false, delete: false, execute: false },
    transfer_company: { create: false, view: false, edit: false, delete: false, execute: false },
    transfer_device: { create: false, view: false, edit: false, delete: false, execute: false },
    sys_advanced_settings: { create: false, view: true, edit: true, delete: false, execute: false },
    sys_information: { create: false, view: true, edit: true, delete: false, execute: false },
    sys_areas: { create: true, view: true, edit: true, delete: true, execute: true },
    sys_zones: { create: true, view: true, edit: true, delete: true, execute: true },
    sys_outputs: { create: true, view: true, edit: true, delete: true, execute: true },
    sys_sensors: { create: false, view: true, edit: true, delete: false, execute: false },
    sys_thermostats: { create: false, view: true, edit: true, delete: false, execute: true },
    sys_users: { create: true, view: true, edit: true, delete: true, execute: false },
    sys_receiver: { create: false, view: false, edit: false, delete: false, execute: false },
    sys_synchronisation: { create: false, view: false, edit: false, delete: false, execute: true },
    sys_transfer: { create: false, view: false, edit: false, delete: false, execute: true },
    sys_device_transfer: { create: false, view: false, edit: false, delete: false, execute: true },
    sys_reset_sensors: { create: false, view: false, edit: false, delete: false, execute: true },
    sys_assistance: { create: false, view: false, edit: false, delete: false, execute: true },
    sys_personal_permissions: { create: false, view: true, edit: true, delete: false, execute: false },
    sys_transfer_to_company: { create: false, view: false, edit: false, delete: false, execute: true },
    sys_notifications: { create: false, view: false, edit: false, delete: false, execute: false },
    sys_cameras: { create: true, view: true, edit: true, delete: true, execute: false },
    sys_events: { create: false, view: true, edit: false, delete: false, execute: false },
    system: { create: true, view: false, edit: false, delete: true, execute: false },
    dev_setup_templates: { create: false, view: false, edit: false, delete: false, execute: true },
    unassigned_devices: { create: true, view: true, edit: true, delete: true, execute: false },
    pending_systems: { create: false, view: false, edit: false, delete: false, execute: false },
    company_payments: { create: false, view: false, edit: false, delete: false, execute: false },
    system_notes: { create: true, view: true, edit: true, delete: true, execute: false },
    monitoring_stations: { create: false, view: false, edit: false, delete: false, execute: false },
  };
  // !!! Toks pats sąrašas yra PHP dalyje PermissionType.php
  public static readonly maxGeneralPermissions: PermissionList = {
    company_settings: { create: false, view: false, edit: false, delete: false, execute: false },
    ipcom_settings: { create: false, view: false, edit: false, delete: false, execute: false },
    reactions: { create: false, view: false, edit: false, delete: false, execute: false },
    roles: { create: false, view: false, edit: false, delete: false, execute: false },
    systems: { create: false, view: false, edit: false, delete: false, execute: false },
    events: { create: false, view: false, edit: false, delete: false, execute: false },
    tags: { create: false, view: false, edit: false, delete: false, execute: false },
    users: { create: false, view: false, edit: false, delete: false, execute: false },
    support_tickets: { create: false, view: false, edit: false, delete: false, execute: false },
    global_settings: { create: false, view: false, edit: false, delete: false, execute: false },
    panic_settings: { create: false, view: false, edit: false, delete: false, execute: false },
    regions: { create: false, view: false, edit: false, delete: false, execute: false },
    impersonation: { create: false, view: false, edit: false, delete: false, execute: false },
    transfer_company: { create: false, view: false, edit: false, delete: false, execute: false },
    transfer_device: { create: false, view: false, edit: false, delete: false, execute: false },
    sys_advanced_settings: { create: false, view: false, edit: false, delete: false, execute: false },
    sys_information: { create: false, view: false, edit: false, delete: false, execute: false },
    sys_areas: { create: false, view: false, edit: false, delete: false, execute: false },
    sys_zones: { create: false, view: false, edit: false, delete: false, execute: false },
    sys_outputs: { create: false, view: false, edit: false, delete: false, execute: false },
    sys_sensors: { create: false, view: false, edit: false, delete: false, execute: false },
    sys_thermostats: { create: false, view: false, edit: false, delete: false, execute: false },
    sys_users: { create: false, view: false, edit: false, delete: false, execute: false },
    sys_receiver: { create: false, view: false, edit: false, delete: false, execute: false },
    sys_synchronisation: { create: false, view: false, edit: false, delete: false, execute: false },
    sys_transfer: { create: false, view: false, edit: false, delete: false, execute: false },
    sys_device_transfer: { create: false, view: false, edit: false, delete: false, execute: false },
    sys_reset_sensors: { create: false, view: false, edit: false, delete: false, execute: false },
    sys_assistance: { create: false, view: false, edit: false, delete: false, execute: false },
    sys_personal_permissions: { create: false, view: false, edit: false, delete: false, execute: false },
    sys_transfer_to_company: { create: false, view: false, edit: false, delete: false, execute: false },
    sys_notifications: { create: false, view: false, edit: false, delete: false, execute: false },
    sys_cameras: { create: false, view: false, edit: false, delete: false, execute: false },
    sys_events: { create: false, view: false, edit: false, delete: false, execute: false },
    system: { create: true, view: false, edit: false, delete: false, execute: false },
    dev_setup_templates: { create: false, view: false, edit: false, delete: false, execute: false },
    unassigned_devices: { create: false, view: false, edit: false, delete: false, execute: false },
    pending_systems: { create: false, view: false, edit: false, delete: false, execute: false },
    company_payments: { create: false, view: false, edit: false, delete: false, execute: false },
    system_notes: { create: false, view: false, edit: false, delete: false, execute: false },
    monitoring_stations: { create: false, view: false, edit: false, delete: false, execute: false },
  };
  // !!! Toks pats sąrašas yra PHP dalyje PermissionType.php
  public static readonly maxOwnerPermissions: PermissionList = {
    company_settings: { create: false, view: false, edit: false, delete: false, execute: false },
    ipcom_settings: { create: false, view: false, edit: false, delete: false, execute: false },
    reactions: { create: false, view: false, edit: false, delete: false, execute: false },
    roles: { create: false, view: false, edit: false, delete: false, execute: false },
    systems: { create: false, view: false, edit: false, delete: false, execute: false },
    events: { create: false, view: false, edit: false, delete: false, execute: false },
    tags: { create: false, view: false, edit: false, delete: false, execute: false },
    users: { create: false, view: false, edit: false, delete: false, execute: false },
    support_tickets: { create: false, view: false, edit: false, delete: false, execute: false },
    global_settings: { create: false, view: false, edit: false, delete: false, execute: false },
    panic_settings: { create: false, view: false, edit: false, delete: false, execute: true },
    regions: { create: false, view: false, edit: false, delete: false, execute: false },
    impersonation: { create: false, view: false, edit: false, delete: false, execute: false },
    transfer_company: { create: false, view: false, edit: false, delete: false, execute: false },
    transfer_device: { create: false, view: false, edit: false, delete: false, execute: false },
    sys_advanced_settings: { create: false, view: true, edit: true, delete: false, execute: false },
    sys_information: { create: false, view: true, edit: true, delete: false, execute: false },
    sys_areas: { create: true, view: true, edit: true, delete: true, execute: true },
    sys_zones: { create: true, view: true, edit: true, delete: true, execute: true },
    sys_outputs: { create: true, view: true, edit: true, delete: true, execute: true },
    sys_sensors: { create: false, view: true, edit: true, delete: false, execute: false },
    sys_thermostats: { create: false, view: true, edit: true, delete: false, execute: true },
    sys_users: { create: true, view: true, edit: true, delete: true, execute: false },
    sys_receiver: { create: false, view: false, edit: false, delete: false, execute: false },
    sys_synchronisation: { create: false, view: false, edit: false, delete: false, execute: true },
    sys_transfer: { create: false, view: false, edit: false, delete: false, execute: true },
    sys_device_transfer: { create: false, view: false, edit: false, delete: false, execute: true },
    sys_reset_sensors: { create: false, view: false, edit: false, delete: false, execute: true },
    sys_assistance: { create: false, view: false, edit: false, delete: false, execute: true },
    sys_personal_permissions: { create: false, view: true, edit: true, delete: false, execute: false },
    sys_transfer_to_company: { create: false, view: false, edit: false, delete: false, execute: true },
    sys_notifications: { create: false, view: true, edit: true, delete: false, execute: false },
    sys_cameras: { create: true, view: true, edit: true, delete: true, execute: false },
    sys_events: { create: false, view: true, edit: false, delete: false, execute: false },
    system: { create: false, view: false, edit: false, delete: true, execute: false },
    dev_setup_templates: { create: false, view: false, edit: false, delete: false, execute: false },
    unassigned_devices: { create: false, view: false, edit: false, delete: false, execute: false },
    pending_systems: { create: false, view: false, edit: false, delete: false, execute: true },
    company_payments: { create: false, view: false, edit: false, delete: false, execute: false },
    system_notes: { create: true, view: true, edit: true, delete: true, execute: false },
    monitoring_stations: { create: false, view: false, edit: false, delete: false, execute: false },
  };
  // !!! Toks pats sąrašas yra PHP dalyje PermissionType.php
  public static readonly maxSimplePermissions: PermissionList = {
    company_settings: { create: false, view: false, edit: false, delete: false, execute: false },
    ipcom_settings: { create: false, view: false, edit: false, delete: false, execute: false },
    reactions: { create: false, view: false, edit: false, delete: false, execute: false },
    roles: { create: false, view: false, edit: false, delete: false, execute: false },
    systems: { create: false, view: false, edit: false, delete: false, execute: false },
    events: { create: false, view: false, edit: false, delete: false, execute: false },
    tags: { create: false, view: false, edit: false, delete: false, execute: false },
    users: { create: false, view: false, edit: false, delete: false, execute: false },
    support_tickets: { create: false, view: false, edit: false, delete: false, execute: false },
    global_settings: { create: false, view: false, edit: false, delete: false, execute: false },
    panic_settings: { create: false, view: false, edit: false, delete: false, execute: true },
    regions: { create: false, view: false, edit: false, delete: false, execute: false },
    impersonation: { create: false, view: false, edit: false, delete: false, execute: false },
    transfer_company: { create: false, view: false, edit: false, delete: false, execute: false },
    transfer_device: { create: false, view: false, edit: false, delete: false, execute: false },
    sys_advanced_settings: { create: false, view: true, edit: true, delete: false, execute: false },
    sys_information: { create: false, view: true, edit: false, delete: false, execute: false },
    sys_areas: { create: false, view: true, edit: false, delete: false, execute: true },
    sys_zones: { create: false, view: true, edit: false, delete: false, execute: true },
    sys_outputs: { create: false, view: true, edit: false, delete: false, execute: true },
    sys_sensors: { create: false, view: true, edit: false, delete: false, execute: false },
    sys_thermostats: { create: false, view: true, edit: false, delete: false, execute: true },
    sys_users: { create: true, view: true, edit: true, delete: true, execute: false },
    sys_receiver: { create: false, view: false, edit: false, delete: false, execute: false },
    sys_synchronisation: { create: false, view: false, edit: false, delete: false, execute: false },
    sys_transfer: { create: false, view: false, edit: false, delete: false, execute: false },
    sys_device_transfer: { create: false, view: false, edit: false, delete: false, execute: false },
    sys_reset_sensors: { create: false, view: false, edit: false, delete: false, execute: false },
    sys_assistance: { create: false, view: false, edit: false, delete: false, execute: false },
    sys_personal_permissions: { create: false, view: true, edit: false, delete: false, execute: false },
    sys_transfer_to_company: { create: false, view: false, edit: false, delete: false, execute: false },
    sys_notifications: { create: false, view: true, edit: true, delete: false, execute: false },
    sys_cameras: { create: false, view: true, edit: false, delete: false, execute: false },
    sys_events: { create: false, view: true, edit: false, delete: false, execute: false },
    system: { create: false, view: false, edit: false, delete: true, execute: false },
    dev_setup_templates: { create: false, view: false, edit: false, delete: false, execute: false },
    unassigned_devices: { create: false, view: false, edit: false, delete: false, execute: false },
    pending_systems: { create: false, view: false, edit: false, delete: false, execute: false },
    company_payments: { create: false, view: false, edit: false, delete: false, execute: false },
    system_notes: { create: false, view: false, edit: false, delete: false, execute: false },
    monitoring_stations: { create: false, view: false, edit: false, delete: false, execute: false },
  };
  private filterCriteria: FilterCriteria = null;
  private previousFilter: string = null;
  private tag = 'PermissionService';
  private cleanUpSubscriber: Subscription;
  private permissionInEditIdChange = new Subject<number>();
  public onPermissionInEditIdChange = this.permissionInEditIdChange.asObservable();

  constructor(private l: LoggerService, auth: AuthService) {
    //this.l.disableLogginForTag(this.tag);
    this.cleanUpSubscriber = auth.onAccountOrRegionChnage.subscribe(() => {
      this.clear();
    });
  }

  ngOnDestroy() {
    this.cleanUpSubscriber?.unsubscribe();
  }

  public ingestPermissionRule(rule: PermissionRule) {
    if ( this.permissionsRules.has(rule.id) ) { return; }
    this.l.log('Ingesting rule ' + rule.id, this.tag, { rule });
    rule.tags?.forEach((t) => { t.textColor = TagService.getTextColor(t.color); });
    this.permissionsRules.set(rule.id, rule);
  }

  public ingestIntoSessionStorage(rule: PermissionRule) {
    if ( this.permissionsRules.has(rule.id) ) { return; } // Nedubliuojam
    if ( this.sessionStorage.has(rule.id) ) { return; }
    this.l.log('Ingesting rule [Session] ' + rule.id, this.tag, { rule });
    rule.tags?.forEach((t) => { t.textColor = TagService.getTextColor(t.color); });
    this.sessionStorage.set(rule.id, rule);
  }

  public getPermission(id: number): PermissionRule | undefined {
    return this.permissionsRules.get(id) ?? this.sessionStorage.get(id);
  }

  public async getPermissionAsync(id: number): Promise<PermissionRule | undefined> {
    const found = this.permissionsRules.get(id) ?? this.sessionStorage.get(id);
    if ( found ) { return found; }
    if ( id === 0 ) { return undefined; }

    await this.loadPermissionById(id);
    return this.permissionsRules.get(id) ?? this.sessionStorage.get(id);
  }

  public async loadPermissionById(id: number): Promise<PermissionRule> {
    try {
      const response = await requests.permission.getPermissionRule({id}).toPromise();
      this.ingestIntoSessionStorage(response.rule);
      // Tuo atveju jeigu taisyklė nauja, tai ją pridedam ir į listStorage
      if ( !this.listStorage.find(li => li.value === id) ) {
        this.listStorage.push({
          value: response.rule.id,
          label: response.rule.name,
          extraParam: { role: response.rule.role, company_id: response.rule.company_id },
        });
      }
      return response.rule;
    } catch (e) {
      console.log(e);
    }
  }

  public static rulesAreEqual(rule1: PermissionList, rule2: PermissionList): boolean {
    const keys = Object.keys(rule1);
    for ( const iKey of keys ) {
      const p1: Permission = rule1[iKey];
      const p2: Permission = rule2[iKey];
      if (
        p1.view !== p2.view
        || p1.create !== p2.create
        || p1.delete !== p2.delete
        || p1.edit !== p2.edit
        || p1.execute !== p2.execute
      ) {
        return false;
      }
    }

    return true;
  }

  public getDefaultPermissionFor(role: PermissionRole, companyId: number): PermissionRule {
    const foundByCompany = [...this.permissionsRules.values(), ...this.sessionStorage.values()].find(r => r.default && r.role === role && r.company_id === companyId);
    if ( foundByCompany ) { return foundByCompany; }
    return [...this.permissionsRules.values(), ...this.sessionStorage.values()].find(r => r.default && r.role === role && r.company_id === 0);
  }

  public static getCopyOf(list: PermissionList): PermissionList | undefined {
    if ( !list ) { return undefined; }
    const keys = Object.keys(list);
    const result = { ...list };
    for ( const iKey of keys ) {
      result[iKey] = { ...list[iKey] };
    }
    return result;
  }

  public static getCopyOfRule(rule: PermissionRule): PermissionRule | undefined {
    if ( !rule ) { return undefined; }
    const result = { ...rule };
    result.permissions = PermissionService.getCopyOf(rule.permissions);
    result.tags = [ ...rule.tags ];
    return result;
  }

  public getPermissionsGetter(filterCriteria: FilterCriteria | null): DataTableGetter<PermissionRule> {
    const permissionService = this;
    if ( filterCriteria ) {
      if (
        !this.filterCriteria
        || this.filterCriteria.searchPhrase !== filterCriteria.searchPhrase
        || JSON.stringify(filterCriteria.searchFields) !== JSON.stringify(this.filterCriteria.searchFields)
      ) {
        this.permissionsRules.clear();
      }
      this.previousFilter = JSON.stringify(this.filterCriteria);
      this.filterCriteria = JSON.parse(JSON.stringify(filterCriteria));
    } else if ( this.filterCriteria !== null ) {
      this.filterCriteria = null;
      this.permissionsRules.clear();
    }
    return async (current, columns, more) => {
      this.l.log('Load permission rules', '', { current, more, f: permissionService.filterCriteria, pf: permissionService.previousFilter });
      if ( !more ) { // data table nori atsinaujinti duomenis is atminties.
        if ( permissionService.permissionsRules.size > 0 ) {
          return [...permissionService.permissionsRules.values()];
        }
        if ( JSON.stringify(permissionService.filterCriteria) === permissionService.previousFilter ) {
          return [...permissionService.permissionsRules.values()];
        }
      }
      permissionService.previousFilter = JSON.stringify(permissionService.filterCriteria);
      const result = await requests.permission.getPermissionRules(filterCriteria ? {
        searchPhrase: filterCriteria.searchPhrase,
        searchFields: filterCriteria.searchFields,
        paginationPage: filterCriteria.currentPage + 1,
      } : null).toPromise();
      if ( !permissionService.filterCriteria ) {
        permissionService.filterCriteria = {
          searchPhrase: '',
          searchFields: [],
          currentPage: result.rules.current_page,
          lastPage: result.rules.last_page,
        };
        permissionService.previousFilter = JSON.stringify(permissionService.filterCriteria);
        if ( result.rules.total === permissionService.permissionsRules.size ) {
          permissionService.filterCriteria.currentPage = result.rules.last_page;
        }
      } else {
        permissionService.filterCriteria.currentPage = result.rules.current_page;
        permissionService.filterCriteria.lastPage = result.rules.last_page;
        permissionService.previousFilter = JSON.stringify(permissionService.filterCriteria);
      }
      result.rules.data.forEach((p) => permissionService.ingestPermissionRule(p));
      const generalUser = result.built_in_default.find(df => df.role === PermissionRole.GeneralUser);
      if ( generalUser ) { permissionService.ingestIntoSessionStorage(generalUser); }
      return [...permissionService.permissionsRules.values()];
    };
  }

  public clearFilter() {
    this.filterCriteria = null;
  }

  public async getAllPermissions() {
    const result = await requests.permission.getPermissionRules({}).toPromise();
    result.rules.data.forEach((p) => this.ingestPermissionRule(p));
    result.built_in_default.forEach((pd) => this.ingestIntoSessionStorage(pd));
  }

  public removePermissionRule(id: number) {
    this.permissionsRules.delete(id);
    this.sessionStorage.delete(id);
    const foundInList = this.listStorage.find(i => i.value === id);
    if ( foundInList ) {
      this.listStorage.splice(this.listStorage.indexOf(foundInList), 1);
    }
  }

  public clear() {
    this.permissionsRules.clear();
    this.sessionStorage.clear();
    this.listStorage = [];
    this.filterCriteria = null;
    this.previousFilter = null;
  }

  public async loadDropDownList(): Promise<void> {
    if ( this.listStorage.length > 0 ) { return; }
    if ( this.listBeingLoaded ) { return; }
    this.listBeingLoaded = true;
    try {
      const result = await requests.permission.getPermissionRules({ forList: true }).toPromise();
      result.rules.data.forEach(r => this.listStorage.push({
        value: r.id,
        label: r.name,
        extraParam: { role: r.role, company_id: r.company_id },
        subLabels: r.company_id === 0 ? undefined : [r.companyName],
      }));
    } finally {
      this.listBeingLoaded = false;
    }
  }

  public static checkPermissionInAString(permissions: string, permissionKey: keyof PermissionList, accessLevel: keyof Permission): boolean {
    const index = PermissionService.indexedPermissionKeys.indexOf(permissionKey);
    if ( index === -1 ) { return false; }
    if ( index*2 + 2 > permissions.length ) { return false; }
    const accessIndex = PermissionService.indexedAccessLevels.indexOf(accessLevel);
    if ( accessIndex === -1 ) { return false; }
    if ( accessIndex + 1 > permissions.length ) { return false; }
    const byteStr = permissions.substring(index, index + 2);
    const byte = parseInt(byteStr, 16);
    // eslint-disable-next-line no-bitwise
    return (byte & (1 << accessIndex)) !== 0;
  }

  public changePermissionInEditId(value: number) {
    this.permissionInEditIdChange.next(value);
  }
}
