import { Injectable, OnDestroy } from '@angular/core';
import { SystemThermostatData } from 'src/api/v3/common';
import { TThermostatData } from 'src/app/models/thermostat-data';
import { AuthService } from '../auth.service';

@Injectable({
  providedIn: 'root',
})
export class ThermostatService implements OnDestroy {
  public readonly thermostats = new Map<number, TThermostatData>();
  public readonly systemThermostats = new Map<number, Set<number>>();

  private cleanupSubscribtion = this.auth.onAccountOrRegionChnage.subscribe(() => {
    this.thermostats.clear();
    this.systemThermostats.clear();
  });
  constructor(private auth: AuthService) {}
  ngOnDestroy(): void {
    this.cleanupSubscribtion.unsubscribe();
  }

  ingestThermostat(thermostat?: SystemThermostatData | TThermostatData): TThermostatData | undefined {
    if (!thermostat) return;
    const processedThermostat: TThermostatData = {
      activeSensor: thermostat.hasOwnProperty('active_sensor') ? (thermostat as SystemThermostatData).active_sensor : (thermostat as TThermostatData).activeSensor,
      assignedOutput: thermostat.hasOwnProperty('assigned_output') ? (thermostat as SystemThermostatData).assigned_output : (thermostat as TThermostatData).assignedOutput,
      assignedSensors: thermostat.hasOwnProperty('assigned_sensors') ? JSON.parse((thermostat as SystemThermostatData).assigned_sensors) ?? [] : (thermostat as TThermostatData).assignedSensors,
      queueNo: thermostat.hasOwnProperty('queue_no') ? (thermostat as SystemThermostatData).queue_no : (thermostat as TThermostatData).queueNo,
      systemId: thermostat.hasOwnProperty('system_id') ? (thermostat as SystemThermostatData).system_id : (thermostat as TThermostatData).systemId,
      temperatures: Array.isArray(thermostat.temperatures) ? thermostat.temperatures : JSON.parse(thermostat.temperatures) ?? [],
      id: thermostat.id,
      name: thermostat.name,
      action: thermostat.action,
    };
    this.thermostats.set(processedThermostat.id, processedThermostat);
    if (!this.systemThermostats.has(processedThermostat.systemId)) {
      this.systemThermostats.set(processedThermostat.systemId, new Set());
    }
    this.systemThermostats.get(processedThermostat.systemId)?.add(processedThermostat.id);
    return processedThermostat;
  }
}
