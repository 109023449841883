<app-back-button></app-back-button> <h4>{{us.currentUser?.name ? trans('users.settings.title2').replace(':username', us.currentUser.name) : trans('users.settings.title') }}</h4>
<div class="profileSection">
  <app-string-input *ngIf="us.currentUser" [label]="trans('users.labels.email')" [(value)]="us.currentUser.email" [disabled]="true"></app-string-input>
  <app-string-input [label]="trans('users.labels.nameProfile')" [(value)]="userSettingsData.name" (valueChange)="onInputChange()"></app-string-input>
  <app-string-input [label]="trans('users.labels.phoneNumber')" [(value)]="userSettingsData.phone" (valueChange)="onInputChange()">
    <app-form-error *ngIf="val.hasError('phone')">{{ val.getError('phone') }}</app-form-error>
  </app-string-input>
  <app-dropdown [label]="trans('users.settings.menu.language')" [items]="languageItems" [(value)]="currentLanguage" (valueChange)="onInputChange()"></app-dropdown>
  <app-dropdown [label]="trans('users.labels.country')" [items]="countryList" [(value)]="userSettingsData.country" (valueChange)="onInputChange()"></app-dropdown>
  <app-dropdown [label]="trans('users.labels.dateTimeFormat')" [items]="dt.dateTimeDropdownFormats" [(value)]="userSettingsData.DateFormat" (valueChange)="onInputChange()"></app-dropdown>
  <a class="link" (click)="showChangePasswordComponent = true">{{trans('users.buttons.changePassword')}}</a>
  <div *ngIf="showChangePasswordComponent">
    <app-messagebox
      [buttons]="changePasswordMessageboxButtons"
      [button1Text]="trans('general.cancel')"
      [button2Text]="trans('general.save')"
      [beforeSumbitButton2]="validatePassword.bind(this)"
      (button2Clicked)="postPasswordChange({password, newPassword})"
    >
      <p class="custom_header_text">{{trans('users.buttons.changePassword')}}</p>
      <app-string-input type="password" [label]="trans('users.labels.passwordCurrent')" [(value)]="password">
        <app-form-error *ngIf="valPasswordChange.hasError('password')">{{valPasswordChange.getError('password')}}</app-form-error>
      </app-string-input>
      <app-string-input type="password" [label]="trans('users.labels.passwordNew')" [(value)]="newPassword">
        <app-form-error *ngIf="valPasswordChange.hasError('newPassword')">{{valPasswordChange.getError('newPassword')}}</app-form-error>
      </app-string-input>
    </app-messagebox>
  </div>
</div>
<div *ngIf="us.currentUser?.user_tags?.length > 0 && roleSupportsLogo" class="logo_container">
  <div id="suipi_logo_h">{{trans('companies.labels.uploadLogo')}}</div>
  <div id="suipi_logo_h_desc">{{trans('companies.labels.uploadLogoDesc')}}</div>
  <div>
    <input id="suipi_input" type="file" name="cpicon" accept="image/" (change)="handleNewFile($event.target.files)" #fileInput>
    <label for="suipi_input">
      <div id="suipi_upload">
        <img id="suipi_preview" [src]="fileToUploadUrl" *ngIf="fileToUploadUrl !== null">
        <img id="suipi_preview" [src]="us.logoBasePath + us.currentUser.logo" *ngIf="us.currentUser.logo && fileToUploadUrl === null && ownLogo">
        <div id="suipi_icon" *ngIf="fileToUploadUrl === null && (!us.currentUser.logo || !ownLogo)" [inlineSVG]="'assets/images/upload.svg'"></div>
      </div>
    </label>
    <div id="suipi_text">{{trans('companies.labels.companyLogo')}}</div>
    <app-button type="filled-secondary" *ngIf="(us.currentUser.logo && ownLogo) || fileToUploadUrl !== null" (click)="removeLogo()">{{trans('companies.labels.removeLogo')}}</app-button>
  </div>
</div>
<div class="separator16"></div>
<div class="dataTable" (click)='doDownloadAccount()'>
  <div class="dataItemContainer">
    <p class="settings-item">{{trans('users.buttons.downloadAccount')}}</p>
    <div class='icon' [inlineSVG]="'assets/images/forward.svg'"></div>
  </div>
</div>
<div class="dataTable" (click)="onDeleteProfileClick()">
  <div class="dataItemContainer">
    <p class="settings-item">{{trans('users.labels.deleteProfile')}}</p>
    <div class='icon' [inlineSVG]="'assets/images/forward.svg'"></div>
  </div>
</div>
<div *ngIf="inputChanged">
  <ng-template appTeleport="footer">
    <app-desktop-footer [buttons]="footerButtons" [loading]="isRequestLoading"></app-desktop-footer>
  </ng-template>
</div>
