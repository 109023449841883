import { Injectable, OnDestroy } from '@angular/core';
import { ErrorResponse } from 'src/api/v3/common';
import { PgmData, PgmIconData, SystemPgmData } from 'src/app/models/pgm';
import { IconsService } from 'src/app/services/icons.service';
import { LocatorService } from 'src/app/services/locator.service';
import { RefreshService } from 'src/app/services/refresh.service';
import { SystemsService } from 'src/app/services/systems.service';
import { ToasterService } from 'src/app/services/toaster.service';
import { environment } from 'src/environments/environment';
import { AuthService } from '../auth.service';
import { LoggerService } from '../logger.service';
import { PersistenceService } from '../persistence.service';
import { RequestService } from '../request.service';
import { RtcMessagePgm } from '../rtc/rtc.service';
import { AreaService } from './area.service';
import { SystemService } from './system.service';

@Injectable({
  providedIn: 'root',
})
export class PgmService implements OnDestroy {
  public readonly pgms = new Map<number, PgmData>();
  public readonly systemPgms = new Map<number, Set<number>>();
  public readonly pgmIcons = new Map<number, PgmIconData>();
  private readonly defaultPgmIcon: PgmIconData = {
    id: 0,
    disabled: '',
    on: 'assets/images/pgm_switch_on.svg',
    off: 'assets/images/pgm_switch_off.svg',
    disabled_png: '',
    off_png: '',
    on_png: '',
    undetermined: '',
    undetermined_png: ''
  };
  private readonly tag = 'Pgm';

  private pgmQueue = [];
  private iconsLoaded = false;
  private iconsLoading = false;
  private reenableRefresher = false;

  private get areaService() { return LocatorService.injector.get(AreaService); }
  private get systemsService() { return LocatorService.injector.get(SystemsService); }
  private get systemService() { return LocatorService.injector.get(SystemService); }
  private get iconService() { return LocatorService.injector.get(IconsService); }

  private cleanupSubscribtion = this.auth.onAccountOrRegionChnage.subscribe(() => {
    this.pgms.clear();
    this.systemPgms.clear();
  });
  constructor(
    private auth: AuthService,
    private req: RequestService,
    private l: LoggerService,
    private refresher: RefreshService,
    private toaster: ToasterService,
    private storage: PersistenceService,
  ) {
    this.l.log('+', this.tag);
    this.l.disableLogginForTag(this.tag);
    this.storage.get('pgmIcons', []).map((i) => this.pgmIcons.set(i.id, i));
    if ( this.pgmIcons.size === 0 ) {
      this.loadIcons();
    }
    if ( !environment.production ) {
      this.l.log('Užsikrovėm pgm ikonų duomenis: ' + ([...this.pgmIcons.entries()].length), this.tag);
    }
    refresher.onSilentRefreshComplete.subscribe(() => {
      this.checkQueue();
    });
  }

  ngOnDestroy(): void {
    this.cleanupSubscribtion.unsubscribe();
  }

  public get iconsVersion() {
    return this.storage.get('pgmIconsVersion', '');
  }

  private checkQueue() {
    if (this.pgmQueue.length === 0) {
      return;
    }
    this.performToggle(this.pgmQueue[0].pgmId, this.pgmQueue[0].pgmQueue);
  }

  ingestPgm(pgm?: SystemPgmData, systemId?: number): PgmData | undefined {
    if (!pgm || !systemId) { return; }
    if (pgm.io_type === 3 && pgm.io_ability === 'W') { return; }
    const { pgm_as_area_ck: controlArea, enabled, icon_number: icon, on, pulse_time_in_seconds: pulseTime, area_to_arm: areaId, pulse_start_time, ...rest } = pgm;

    const processedPgm: PgmData = {
      area_no: 0,
      control_area: controlArea !== 0,
      enabled: enabled !== 0 && enabled !== false,
      icon,
      on: on !== 0 && on !== false,
      pulseTime,
      area_id: areaId,
      pulse_activated_at: Number(pulse_start_time) ?? 0,

      ...rest,
    };
    this.ingestPgmData(processedPgm, systemId);
    return processedPgm;
  }

  public ingestPgmData(pgm: PgmData, systemId: number) {
    if (this.areaService.areas.has(pgm.area_id)) {
      pgm.area_no = this.areaService.areas.get(pgm.area_id).queue_no ?? 0;
    }

    this.pgms.set(pgm.id, pgm);
    this.l.log('+ Pgm.', this.tag, pgm);
    if (!this.systemPgms.has(systemId)) {
      this.systemPgms.set(systemId, new Set());
    }
    this.systemPgms.get(systemId)?.add(pgm.id);
  }

  // Iškvietimai ateina iš pgm mygtukų
  public togglePgm(pgmId: number, pgmQueue: number, onFinish?: any) {
    if (this.pgmQueue.length > 0) {
      this.pgmQueue.push({
        pgmId,
        pgmQueue,
        onFinish,
      });
      this.l.log('Ne pirmas, dedam i eile PGM-' + pgmQueue, this.tag);
      return;
    }
    this.pgmQueue.push({
      pgmId,
      pgmQueue,
      onFinish,
    });
    if (this.refresher.isRefreshingSilently()) {
      this.l.log('Refresheris siuo metu dirba. Reikia palaukti.', this.tag);
      return;
    }
    this.reenableRefresher = this.refresher.refresherIsEnabled;
    this.refresher.disableRefresher();
    this.performToggle(pgmId, pgmQueue);
  }

  private performToggle(pgmId: number, pgmQueue: number) {
    this.l.log('Vykdom komanda PGM-' + pgmQueue, this.tag);
    this.req.systemPgm.togglePgm({ pgmId }).subscribe(
      (result) => {
        const job = this.pgmQueue.shift();
        this.l.log('Ivykdyta. Imam is eiles PGM-' + (job ? job.pgmQueue : 'neliko'), this.tag);
        if (result.success) {
          if (job && job.onFinish !== undefined) {
            job.onFinish(result.pgm);
          }
          if (this.pgmQueue.length > 0) {
            this.performToggle(this.pgmQueue[0].pgmId, this.pgmQueue[0].pgmQueue);
          } else {
            if (this.reenableRefresher) {
              this.refresher.enableRefresher();
            }
          }
          return;
        }
        this.toaster.postError((result as ErrorResponse).error);
        if (job && job.onFinish !== undefined) {
          job.onFinish(undefined);
        }
        if (this.pgmQueue.length > 0) {
          this.performToggle(this.pgmQueue[0].pgmId, this.pgmQueue[0].pgmQueue);
        } else {
          if (this.reenableRefresher) {
            this.refresher.enableRefresher();
          }
        }
      },
      (error) => {
        const job = this.pgmQueue.shift();
        this.l.log('Klaida. Imam is eiles PGM-' + (job ? job.pgmQueue : 'neliko'), this.tag);
        if (job && job.onFinish !== undefined) {
          job.onFinish(undefined);
        }
        if (this.pgmQueue.length > 0) {
          this.performToggle(this.pgmQueue[0].pgmId, this.pgmQueue[0].pgmQueue);
        } else {
          if (this.reenableRefresher) {
            this.refresher.enableRefresher();
          }
        }
      }
    );
  }

  public updatePgmData(newData: RtcMessagePgm | undefined) {
    if (newData === undefined) {
      return;
    }
    const system = this.systemsService.getSystem(newData.system_id) || this.systemService.systems.get(newData.system_id);
    if (system === undefined) {
      return;
    }
    const found = system.pgms.find((p) => p.id === newData.id) || this.pgms.get(newData.id);
    if (found === undefined) {
      return;
    }
    let changed = false;
    if ( newData.pulse_start_time !== undefined && found.pulse_activated_at !== newData.pulse_start_time * 1000 ) {
      found.pulse_activated_at = newData.pulse_start_time * 1000;
      changed = true;
    }
    if ( newData.on !== undefined && found.on !== (newData.on === 1)) {
      found.on = newData.on === 1;
      changed = true;
    }
    if ( newData.icon_number !== undefined && found.icon !== newData.icon_number) {
      found.icon = newData.icon_number;
      changed = true;
    }
    if ( newData.enabled !== undefined && found.enabled !== (newData.enabled === 1)) {
      found.enabled = newData.enabled === 1;
      changed = true;
    }
    if (changed) {
      this.systemsService.saveActiveSystem(newData.system_id);
    }
  }

  public isPgmInQueue(pgmId: number) {
    return this.pgmQueue.find((p) => p.pgmId === pgmId) !== undefined;
  }

  public setNewCallbackFunction(pgmId: number, callback: any) {
    const queueItem = this.pgmQueue.find((p) => p.pgmId === pgmId);
    if (queueItem === undefined) {
      return;
    }
    queueItem.onFinish = callback;
  }

  public getPgms(): PgmData[] {
    return this.systemsService.activeSystem.pgms.filter((p) => !p.control_area && p.enabled);
  }

  public getPgmIcon(iconId: number, isOn: boolean, isEnabled: boolean): string {
    let result = '';
    if ( this.pgmIcons.has(iconId) ) {
      const iconData = this.pgmIcons.get(iconId);
      result = isEnabled ? isOn ? iconData.on : iconData.off : iconData.disabled;
    } else if ( !this.iconsLoaded ) {
      this.loadIcons();
    }
    return result === '' ? this.defaultPgmIcon.on : result;
  }

  public async loadIcons() {
    if ( this.iconsLoading ) { return; }
    this.iconsLoading = true;
    this.l.log('Nuskaitom iš serverio pgm ikonas', this.tag);
    const result = await this.req.misc.getPgmIcons().toPromise();
    if ( result.success ) {
      this.iconsLoaded = true;
      result.icons.map((i) => this.pgmIcons.set(i.id, i));
      this.storage.set('pgmIcons', [...this.pgmIcons.values()]);
      this.storage.set('pgmIconsVersion', result.version);
      this.l.log(`Atsiuntėm ikonas: ${result.icons.length}. Versija: ${result.version}`, this.tag);
      this.iconService.retrieveIcons('pgm', '0'); // Tokiu būdu perduosim native appsui naujas ikonas.
    }
    this.iconsLoading = false;
  }
}
