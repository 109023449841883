import { CommonModule } from '@angular/common';
import { Component, ElementRef, EventEmitter, HostListener, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { InlineSVGModule } from 'ng-inline-svg-w';
import requests from 'src/api/v3/requests';
import { Tag } from 'src/api/v3/tag';
import { TagService } from 'src/app/api/tag.service';
import { UserService } from 'src/app/api/user.service';
import { LoaderRoundComponent } from 'src/app/components/loader-round/loader-round.component';
import { LanguageService } from 'src/app/services/language.service';
import { LocatorService } from 'src/app/services/locator.service';

@Component({
  selector: 'app-tag-input',
  templateUrl: './tag-input.component.html',
  styleUrls: ['./tag-input.component.scss'],
  standalone: true,
  imports: [CommonModule, FormsModule, InlineSVGModule, LoaderRoundComponent],
})
export class TagInputComponent implements OnInit, OnChanges {
  @ViewChild('tagInput') tagInput;
  @Input() label = '';
  @Input() name = '';
  @Input() initialTags: Tag[] = [];
  @Input() isReadOnly = false;
  @Input() fixedTags: Tag[] = [];
  @Input() canCreateNewTags = false;
  @Output() tagsChanged = new EventEmitter<void>();

  public _val = '';
  public newTags: Tag[] = [];
  public creatingTag = false;
  public noTagsShowing = false;
  public selectedTags: Tag[] = [];
  public loadedLookupTags: (Tag & {company: string})[] = [];

  public get tagService() {return LocatorService.injector.get(TagService); }

  public get val() {return this._val; };
  public set val(value: string) {
    this._val = value;
    this.noTagsShowing = false;
    this.lookUpTags();
  }

  constructor(public lang: LanguageService, private elementRef: ElementRef) { }

  ngOnInit(): void {
    this.selectedTags = this.initialTags;
  }

  ngOnChanges(changes: SimpleChanges) {
    if ( changes.initialTags ) {
      this.selectedTags = this.initialTags ?? [];
      if ( this.fixedTags.length > 0 ) {
        this.selectedTags = this.selectedTags.filter(st => !this.fixedTags.find(ft => ft.id === st.id));
      }
    }
    if ( changes.fixedTags ) {
      this.selectedTags = this.selectedTags.filter(st => !this.fixedTags.find(ft => ft.id === st.id));
    }
  }

  public onChange($event) {
  }

  public async lookUpTags() {
    if ( this.isReadOnly ) { return; }
    const trimmedVal = this._val.trim();
    this.loadedLookupTags = [];
    this.newTags = [];
    if ( !this.tagService.hasTagsForList ) {
      await this.tagService.loadAllTags();
    }
    if ( trimmedVal === '' ) {
      this.tagService.allTags.forEach(at => this.loadedLookupTags.push({
        ...at,
        company: at.companyName ?? '',
      }));
    } else {
      this.tagService.allTags.forEach(at => at.name.includes(trimmedVal) && this.loadedLookupTags.push({
        ...at,
        company: at.companyName ?? '',
      }));
      if ( this.canCreateNewTags && !this.loadedLookupTags.find(lt => lt.name === trimmedVal) && !this.fixedTags.find(ft => ft.name === trimmedVal) ) {
        const userService = LocatorService.injector.get(UserService);
        const newColor = this.tagService.generateNewColor();
        this.newTags.push({
          color: newColor,
          company_id: userService.currentUser?.company_id ?? 0,
          description: '',
          id: 0,
          name: trimmedVal,
          assigned_systems: 0,
          textColor: TagService.getTextColor(newColor),
          companyName: userService.currentUser?.belongsToCompany?.name,
        });
      }
    }

    this.loadedLookupTags = this.loadedLookupTags.filter(t => !this.selectedTags.find(st => st.id === t.id) && !this.fixedTags.find(ft => ft.id === t.id));
    if ( this.loadedLookupTags.length === 0 && this.newTags.length === 0 ) {
      this.noTagsShowing = true;
    }
  }

  public tagSelected(tag: Tag, selectedByLoading?: boolean) {
    this.selectedTags.push(tag);
    this.loadedLookupTags = [];
    if ( !selectedByLoading ) {
      this._val = '';
    }
    this.tagsChanged.emit();
  }

  public tagDeselected(tag: Tag) {
    if ( this.isReadOnly ) { return; }
    this.selectedTags.splice(this.selectedTags.indexOf(this.selectedTags.find(t => t.id === tag.id)), 1);
    this.tagsChanged.emit();
  }

  @HostListener('document:click', ['$event'])
  onClick(event: MouseEvent) {
    const target = event.target as HTMLButtonElement;
    const clickedInside = this.elementRef.nativeElement.contains(target);
    if (!clickedInside && target.id !== 'toggleSettings') {
      this.noTagsShowing = false;
      this.loadedLookupTags = [];
      this.newTags = [];
    }
  }
  @HostListener('document:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    if (event.key === 'Escape') {
      this.noTagsShowing = false;
      this.loadedLookupTags = [];
      this.newTags = [];
    }
  }

  public async createTagAndSelect(tag: Tag) {
    if ( this.creatingTag ) { return; }
    this.creatingTag = true;
    try {
      const result = await requests.tag.addTag(tag).toPromise();
      if ( result.success ) {
        tag.id = result.id;
        this.tagService.ingestTag(tag);
        this.tagSelected(tag, false);
      }
    } catch (e) {}
    this.creatingTag = false;
  }
}
