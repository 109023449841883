import { Component, OnInit, ChangeDetectorRef, ViewEncapsulation, OnDestroy } from '@angular/core';
import { LanguageAware } from 'src/app/general/language-aware';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { EventsService } from 'src/app/services/events.service';
import { DateService } from 'src/app/services/date.service';
import { AreaService } from 'src/app/services/area.service';
import { WebSocketService } from 'src/app/services/websocket.service';
import { TAreaData } from 'src/app/models/area-data';
import { ReactionService } from 'src/app/api/system/reaction.service';
import { TBackgroundType } from 'src/app/models/background-types';
import { TEditableComponent } from 'src/app/models/editable-component';
import { CommonModule } from '@angular/common';
import { LoaderDotsComponent } from 'src/app/components/loader-dots/loader-dots.component';
import { InlineSVGModule } from 'ng-inline-svg-w';
import { TakeActionButtonComponent } from 'src/app/components/take-action-button/take-action-button.component';
import { ButtonSingleLineArrowComponent } from 'src/app/ui/button-single-line-arrow/button-single-line-arrow.component';

@Component({
  selector: 'app-area-view',
  templateUrl: './area-view.component.html',
  styleUrls: ['./area-view.component.scss'],
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [CommonModule, LoaderDotsComponent, InlineSVGModule, TakeActionButtonComponent, RouterLink, ButtonSingleLineArrowComponent],
})
export class AreaViewComponent extends LanguageAware implements OnInit, OnDestroy {
  public areaEvents: any[] = [];
  private eventSubscription = null;
  private activeSystemSubscription = null;
  public alarmedAreaClicked: TAreaData = null;
  private areaOperationSubscriber = null;
  public showForgetPin = false;
  public showAlarmButton = false;
  private takeActionButtonVisibilitySubscriber = null;
  private readonly panicButtonsAreaStatus = 18;
  public readonly areaIsPanicButtonsArea = this.systems.activeArea?.status === this.panicButtonsAreaStatus;

  constructor(
    cdRef: ChangeDetectorRef,
    private router: Router,
    private ev: EventsService,
    public dt: DateService,
    public areaService: AreaService,
    ws: WebSocketService,
    public reactions: ReactionService,
    private ar: ActivatedRoute,
  ) {
    super(cdRef, { backgroundType: TBackgroundType.Gray });
    if (this.systems.activeArea === null) {
      this.router.navigate(this.g.getHomeUrl());
      return;
    }
    this.headerBar.showHeader({
      headerText: this.areaIsPanicButtonsArea ? this.trans('systems.titles.panicButtons') : this.systems.activeArea.name,
      backUrl: '*',
    });
    this.showForgetPin = !this.systems.activeArea.showKeypad && this.systems.activeSystem.directControl;
    this.areaEvents = this.getEvents();
    this.eventSubscription = ws.onEventReceived.subscribe(() => {
      this.areaEvents = this.getEvents();
    });
    this.activeSystemSubscription = this.systems.onActiveSystemChange.subscribe(() => {
      if ( !this.systems.activeSystem ) { return; }
      if ( !this.systems.activeArea ) { return; }
      this.showForgetPin = !this.systems.activeArea.showKeypad && this.systems.activeSystem.directControl;
    });
    this.areaOperationSubscriber = this.areaService.onAreaOperationCompleted.subscribe(() => {
      this.areaService.alarmedActionInProgress = false;
    });
    this.takeActionButtonVisibilitySubscriber = this.areaService.onTakeActionButtonVisibilityChanged.subscribe((data: any) => {
      if ( !this.systems.activeArea ) { return; }
      if ( this.systems.activeArea.id !== data.id ) { return; }
      this.showAlarmButton = data.visible;
    });
    this.areaService.addOrUpdateAreaForTakeAction(this.systems.activeArea);
  }

  ngOnInit(): void {}

  ngOnDestroy() {
    if (this.eventSubscription !== null) {
      this.eventSubscription.unsubscribe();
      this.eventSubscription = null;
    }
    if (this.activeSystemSubscription !== null) {
      this.activeSystemSubscription.unsubscribe();
      this.activeSystemSubscription = null;
    }
    if (this.areaOperationSubscriber !== null) {
      this.areaOperationSubscriber.unsubscribe();
      this.areaOperationSubscriber = null;
    }
    if (this.takeActionButtonVisibilitySubscriber !== null) {
      this.takeActionButtonVisibilitySubscriber.unsubscribe();
      this.takeActionButtonVisibilitySubscriber = null;
    }
  }

  public getEvents(): any[] {
    const events = this.systems.activeSystem.events.filter((e) => e.areaId === this.systems.activeArea.id && e.isAreaEvent);
    const finalEvents = [];
    for (const iEvent of events) {
      if (this.systems.activeSystem.eventConfiguration.length === 0) {
        finalEvents.push(iEvent);
      } else if (this.systems.activeSystem.eventConfiguration.indexOf(iEvent.reaction) !== -1) {
        finalEvents.push(iEvent);
      }
    }
    return this.ev.groupEvents(finalEvents, 0, 4);
  }

  public changeAreaStateTo(newState: number) {
    if (!this.systems.activeSystemHelper.can.control.area(this.systems.activeArea.queue_no)) {
      this.toaster.postError(this.trans('systems.errors.operationNotAllowed'));
      return;
    }
    if (this.areaService.isBusy()) {
      this.toaster.postError(this.trans('systems.errors.busyArea'));
      return;
    }
    if (newState === this.systems.activeArea.status) {
      return;
    }
    this.areaService.busyArea = {
      systemId: this.systems.activeSystem.id,
      areaId: this.systems.activeArea.id,
      oldRememberPin: !this.systems.activeArea.showKeypad,
      newRememberPin: !this.systems.activeArea.showKeypad,
      showPinKeypad: this.systems.activeArea.showKeypad,
      oldStatus: this.systems.activeArea.status,
      newStatus: newState,
      pinToUse: '',
      zonesToBypass: [],
      showZoneBypass: false,
    };
    if (!this.systems.activeSystem.directControl || !this.areaService.busyArea.showPinKeypad) {
      this.areaService.busyArea.showPinKeypad = false;
      this.areaService.performStatusChange();
    }
  }

  public goToSettings() {
    this.editSystemService.setDefaults();
    const area = this.systems.activeArea;
    this.editSystemService.beginComponentEdit(TEditableComponent.Area, area.queue_no, area);
    for (const iPgm of this.systems.activeSystem.pgms) {
      if (iPgm.area_id === area.id && iPgm.control_area) {
        this.editSystemService.beginComponentEdit(TEditableComponent.Output, iPgm.queue_no, iPgm);
      }
    }
    this.router.navigate([...this.g.getHomeUrl(), 'settings', 'system-area']);
  }

  public goToEvents() {
    this.router.navigate([...this.g.getHomeUrl(), 'events']);
  }

  public forgetPin() {
    const systemId = this.systems.activeSystem.id;
    const areaId = this.systems.activeArea.id;
    const oldValue = this.systems.activeArea.showKeypad;
    this.systems.activeArea.showKeypad = true;
    this.log('Paspausta pamiršti srities ' + this.systems.activeArea.id + ' PIN');
    this.headerBar.showHeader({
      headerText: this.systems.activeArea.name,
      backUrl: '*',
    });
    this.api.post('/forget-pin', { system_id: systemId, area_id: areaId }, true).subscribe(
      (result) => {
        if (result.success) {
          this.systems.saveActiveSystem(systemId);
          this.log('PIN pamirštas sėkmingai');
        } else {
          this.toaster.postError(result.error);
          this.rollbackForgetPin(systemId, areaId, oldValue);
        }
      },
      (error) => {
        this.toaster.postError(this.trans('auth.errors.serverSideError'));
        this.rollbackForgetPin(systemId, areaId, oldValue);
      }
    );
  }

  private rollbackForgetPin(systemId: number, areaId: number, oldValue: boolean) {
    this.log('Nepavyko pamiršti PIN');
    const system = this.systems.getSystem(systemId) || this.systemService.systems.get(systemId);
    if (system === undefined) {
      return;
    }
    const area = system.areas.find((a) => a.id === areaId);
    if (area === undefined) {
      return;
    }
    area.showKeypad = oldValue;
    this.systems.saveActiveSystem(systemId);
  }
}
